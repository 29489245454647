import { Component, OnInit } from '@angular/core';
import { UserRoleConstants } from '../../constants/AppConstants';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { User } from '../../models/user.model';
import { AuthServiceService } from '../../services/auth-service.service';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-sidebar-admin',
  templateUrl: './sidebar-admin.component.html',
  styleUrls: ['./sidebar-admin.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({ marginLeft: '0' })),
      state('false', style({ marginLeft: '-20rem' })),
      transition('false <=> true', animate(500))
    ])
  ],
})
export class SidebarAdminComponent implements OnInit {

  menuitems: any;
  adminUser: boolean = false;
  userInfo: User = new User();
  id: any;

  constructor(
    public themeService: ThemeService,
    private authService: AuthServiceService
    ) { }

  ngOnInit() {
    this.adminUser = this.authService.hasRole(UserRoleConstants.ADMIN);
    this.userInfo = this.authService.getUserInfo();
   this.id = this.authService.getUserInfo().lastLoggedIn
  }

}
