<div class="container-fluid my-2">
    <div class="card">
        <div class="card-body">
            <div class="row" [hidden]="submit">
                <div class="col-md-12 text-right">
                    <button class="btn btn-sm btn-info mr-2 mb-3" (click)="exportPayments()">Export to Excel</button>
                    <button class="btn btn-sm btn-info mb-3" (click)="onSubmit()" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.ADD_COMMISSION_PAYMENT]">New Payment</button>
                </div>
            </div>
            <div [hidden]="submit" class="wrap table-responsive">
                <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Amount Paid (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                            <th>Payment Date</th>
                            <th>Mode of Payment</th>
                            <th>Comments</th>
                            <th>Last Updated By</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let payment of Payments.payments">
                            <td>{{payment.name}}</td>
                            <td>{{payment.amountPaid.toLocaleString('en-IN')}}</td>
                            <td>{{payment.paymentDate | date}}</td>
                            <td>{{payment.modeOfPayment}}</td>
                            <td>{{payment.comments !== 'null' ? payment.comments : ''}}</td>
                            <td>{{payment.lastUpdatedBy}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div [hidden]="!submit">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <!-- <div class="col-md-6">
                                <h6 class="card-title text-dark position-relative pb-2 h6">New Payment</h6>
                            </div> -->
                            <div class="col-md-12 text-right">
                                <button class="btn btn-sm btn-info rounded-circle" (click)="onShow()"><i class="fa fa-times" aria-hidden="true"></i></button>
                            </div>
                        </div>
                        <app-addcommissionpayments></app-addcommissionpayments>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>