<ul class="list-unstyled my-4">
    <li>
        <div class="row">
            <div class="col-md-4">Name:</div>
            <div class="col-md-8"><b>{{selectedCustomer.name}}</b></div>
            <div class="col-md-4">Contact:</div>
            <div class="col-md-8"><b>{{selectedCustomer.contactNumber}}</b></div>
            <div class="col-md-4">City:</div>
            <div class="col-md-8"><b>{{selectedCustomer.city}}</b></div>
            <div class="col-md-4">Introduced By:</div>
            <div class="col-md-8"><b>{{selectedCustomer.introducedBy}}</b></div>
            <div class="col-md-4">Introduced Venture:</div>
            <div class="col-md-8"><b>{{selectedCustomer.introducedVenture}}</b></div>
            <div class="col-md-4">Introduced Plot:</div>
            <div class="col-md-8"><b>{{selectedCustomer.introducedPlot}}</b></div>
        </div>
    </li>

</ul>