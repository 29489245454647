import { Component, OnInit } from '@angular/core';
import Stepper from 'bs-stepper';
import { Location } from '@angular/common';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-addtenant',
  templateUrl: './addtenant.component.html',
  styleUrls: ['./addtenant.component.scss']
})
export class AddtenantComponent implements OnInit {

  private stepper: Stepper;
  permAddress: any = {};
  commAddress: any = {};
  managingDirector: any = {};
  primaryCompany: any = {};
  address2: any = false;
  //addMarketer: AddMarketer = new AddMarketer();

  marketerData: any;
  path: string = 'tenant/marketer'
  addTenant: any = {};
  constructor(private location: Location,
    private restService: RestService,
    private authService: AuthServiceService) { }

  ngOnInit(): void {
    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true
    });
  }

  onsubmit(value: any) {

    this.addTenant.tenantId = this.authService.getUserInfo().tenantId;
    if (this.addTenant.marketerType == 'company') {
      this.addTenant.primaryCompany = this.primaryCompany;
      this.addTenant.primaryCompany.tenantId = this.authService.getUserInfo().tenantId;

      this.addTenant.managingDirector = this.managingDirector;
    } else {
      this.addTenant.managingDirector = this.managingDirector;
    }
    this.addTenant.primaryAddress = this.permAddress;
    if (this.address2 == true) {
      //  this.addMarketer.secondaryAddress = null;
      this.addTenant.secondaryAddress = this.permAddress;
    } else {
      this.addTenant.secondaryAddress = this.commAddress;
    }

    if (this.addTenant) {
      const pathF = this.path + '/addMarketer';
      this.restService.submitPostRequest(pathF, this.addTenant).subscribe(
        (response: any) => {
          if (response && response.data) {
            this.marketerData.push(response.data)
          }
        }, (error: any) => {
          // TODO Error handling
        });
    }
    this.location.back()
  }


  sendBack() {
    this.location.back()
  }
  next() {
    this.stepper.next()
  }

}

