import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserRoleConstants } from '../../constants/AppConstants';
import { AuthServiceService } from '../../services/auth-service.service';
import { RestService } from '../../services/rest.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  userDetails: any = [];
  person: any = [];
  userName: any;
  reset: any;
  passwordchanged: any;
  display = true;
  constructor(private authService: AuthServiceService,
    private restService: RestService,
    private location: Location,
    private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.userName = params['username'];
    });
  }

  ngOnInit(): void {
    const path: string = (this.authService.hasRole(UserRoleConstants.MARKETER)) ? 'marketer/getMarketerUsers' :
      'tenant/getTenantUsers';
    let data = {
      tenantId: this.authService.getUserInfo().tenantId,
      marketerId: this.authService.getUserInfo().marketerId,
    }
    this.restService.submitPostRequest(path, data).subscribe(response => {
      if (response) {
        this.userDetails = response.data.filter(a => a.username == this.userName);
        this.person = this.userDetails[0].person;
      }
    });
  }

  onSubmit(password) {
    const path: string = (this.authService.hasRole(UserRoleConstants.MARKETER)) ? 'marketer/user/change-password' :
      'tenant/user/change-password';
    const data = {
      username: this.userName,
      newPassword: password.newPassword,
    }
    this.restService.submitPostRequest(path, data).subscribe(response => {
      if (response) {
        this.passwordchanged = response;
      }
      this.location.back();
    });
  }

  sendBack() {
    this.location.back();
  }
  expand() {
    this.display = !this.display;
  }
}
