import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { UserPrivileges } from 'src/app/constants/AppConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-all-salesrep-advances',
  templateUrl: './all-salesrep-advances.component.html',
  styleUrls: ['./all-salesrep-advances.component.scss']
})
export class AllSalesrepAdvancesComponent implements OnInit {

  salesrepAdvancesList: any = [];
  userInfo: any;
  ventureId: any;
  userPrivileges = UserPrivileges;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private restService: RestService,
    private authService: AuthServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonServiceService
  ) { }

  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    this.ventureId = this.commonService.getVenture()?.ventureId;


    let data = {
      "tenantId": this.userInfo.tenantId,
      "marketerId": this.userInfo.marketerId,
      "ventureId": this.ventureId
    }

    let url = `/generic/all-salesrep-advances?tenantId=${this.userInfo.tenantId}&ventureId=${this.ventureId}`;

    if (data.marketerId != null && data.marketerId != undefined) {
      url += `&marketerId=${this.userInfo.marketerId}`;
    }

    this.restService.getAllSalesrepAdvances(url).subscribe(response => {
      if (response.data != null && response.data != undefined) {
        this.salesrepAdvancesList = response.data;
      }
    },
    (error) => {
      console.log(error.status);
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      searching: false
    };
  }

  checkSalesrepAdvance(salesrepAdvance) {
    sessionStorage.setItem('activeTab', '4');
    this.commonService.setVentureTeam(salesrepAdvance);
    this.router.navigate(['../alldetails/'+salesrepAdvance.salesrepId], { relativeTo: this.route });
  }

}
