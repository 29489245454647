import { Component, OnInit } from '@angular/core';
import { UserPrivileges, UserRoleConstants } from 'src/app/constants/AppConstants';
import{Location} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CommonServiceService } from '../../../../services/common-service.service';


@Component({
  selector: 'app-commissiondetails',
  templateUrl: './commissiondetails.component.html',
  styleUrls: ['./commissiondetails.component.scss']
})
export class CommissiondetailsComponent implements OnInit {

  active = 1;
  userPrivileges = UserPrivileges;
  ventureId: any;
  ventureName: any;
  show: any = false;
  showCommissionStructure: any;

  constructor(private location: Location,
    private commonService: CommonServiceService,
    private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.ventureId = params['ventureId'];
      this.showCommissionStructure = params['commissionStructure'];
    });
   }

  ngOnInit(): void {
    this.ventureName = this.commonService.getVenture()?.displayName;

    if (this.ventureId) {
      this.show = true;
      this.active = 1;
    }
    if (this.showCommissionStructure) {
      this.active = 3;
    }
  }
  sendBack(){
    this.location.back();
  }

}
