import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { RoutesConstants } from 'src/app/constants/RoutesConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-myteamsales',
  templateUrl: './myteamsales.component.html',
  styleUrls: ['./myteamsales.component.scss']
})
export class MyteamsalesComponent implements OnInit {

  myTeamsales: any = [];
  error: boolean;
  errorMessage: any;
  userInfo: any;
  constructor(
    private restService: RestService,
    private router: Router,
    private authService: AuthServiceService) { }
  title = 'angulardatatables';
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();

  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    let data = {
      "tenantId": this.userInfo.tenantId,
      "marketerId": this.userInfo.marketerId,
      "salesrepId": this.userInfo.salesrepId
    }
    this.restService.getmyTeamSales(data).subscribe(response => {
      if (data != null) {
        this.myTeamsales = response.data;
        this.dtTrigger.next();
      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      })
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      searching: false
    };
  }
  editSale(id) {
    this.router.navigate(['/' + RoutesConstants.SALESREPLAYOUT + '/' + RoutesConstants.SALEDETAILS], { queryParams: { 'salesRepId': id.salesrepId, 'plotId': id.plotId, 'saleId': id.saleId } });
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
