import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserRoleConstants } from 'src/app/constants/AppConstants';
import { payNow } from 'src/app/models/paynow';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-pay-now',
  templateUrl: './pay-now.component.html',
  styleUrls: ['./pay-now.component.scss']
})
export class PayNowComponent implements OnInit {

  @Output() newPaymentEvent = new EventEmitter<any>();
  payments: any = ['Online', 'Wire Transfer', 'Cash'];
  payment = new payNow(null, '', '', '', '', '','');
  submit = false;
  alert = false;
  disableAddBtn = false;
  Customers: any = [];
  error: boolean;
  errorMessage: any;
  salesRep: any = [];
  ModelObject: any = {};


  constructor(
    private restService: RestService,
    private authService: AuthServiceService,
    private commonService: CommonServiceService
    ) {
    this.getCustomerData();
    this.getsalesRep();
  }

  ngOnInit() {
  }

  onSubmit(payment: any) {
    this.newPaymentEvent.emit(payment);
    
    this.alert = true;
    this.disableAddBtn = false;
  }

  closeAlert() {
    this.alert = false;
  }

  getCustomerData() {
    const userInfo = this.authService.getUserInfo();
   
    const path: string = userInfo.role.toLowerCase() + '/get-customers';
    let data = {
      "tenantId": this.authService.getUserInfo().tenantId,
      "marketerId": this.authService.getUserInfo().marketerId
    }

    this.restService.submitPostRequest(path,data).subscribe(response => {
      if (response != null) {
        this.Customers = response;
      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      })
  }

  getsalesRep() {
    const user = this.authService.getUserInfo();

    let data = {
      'tenantId': user.tenantId,
      'marketerId': user.marketerId,
      'ventureId': this.commonService.getVenture()?.ventureId
    }
    const path: string = (this.authService.hasRole(UserRoleConstants.MARKETER))? 'venture-team/get-venture-team':
    'tenant/get-venture-team';
        this.restService.submitPostRequest(path, data).subscribe(response => {
      if (response != null) {
        this.salesRep = response.teams
      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      })
  }

  public paidEvent(e): void {
    let name = e.target.value;
    let list = this.Customers.filter(x => x.name === name)[0];
    this.ModelObject.customerId = list.customerId;

  }
  public receivedEvent(s): void {
    let name = s.target.value;
    let list = this.salesRep.filter(x => x.name === name)[0];
    this.ModelObject.salesrepId = list.salesrepId;
  }
}
