<!-- <div class="pt-2 wrap" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.TENANT_PRIVILEGE,userPrivileges.USERS_ADD]">
    <table class="table">
        <thead>
            <tr>
                <th>#</th>
                <th>View</th>
                <th>ViewAll</th>
                <th>Add</th>
                <th>Update</th>
                <th>Block</th>
                <th *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.USERS_ADD]">Delete</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Plots:</td>
                <td>
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check1" name="PLOT_VIEW" [(ngModel)]="onCheck.PLOT_VIEW" (change)="onChanges()">
                    </div>
                </td>
                <td>
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check2" name="PLOT_VIEWALL" [(ngModel)]="onCheck.PLOT_VIEWALL" (change)="onChanges()">
                    </div>
                </td>
                <td>
                </td>
                <td *ngxPermissionsOnly="[userPrivileges.TENANT_PRIVILEGE]">
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check4" name="PLOT_UPDATE" [(ngModel)]="onCheck.PLOT_UPDATE" (change)="onChanges()" (click)="selectAllPlots()">
                    </div>
                </td>
                <td>
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check5" name="PLOT_BLOCK_UNBLOCK" [(ngModel)]="onCheck.PLOT_BLOCK_UNBLOCK"
                            (change)="onChanges()" (click)="onChanges()">
                    </div>
                </td>
            </tr>
            <tr>
                <td>Plot Pricing:</td>
                <td>
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check1" name="PLOTPRICING_VIEW" [(ngModel)]="onCheck.PLOTPRICING_VIEW" (change)="onChanges()">
                    </div>
                </td>
                <td>
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check2" name="PLOTPRICING_VIEWALL" [(ngModel)]="onCheck.PLOTPRICING_VIEWALL" (change)="onChanges()">
                    </div>
                </td>
                <td *ngxPermissionsOnly="[userPrivileges.TENANT_PRIVILEGE]">
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check3" name="PLOTPRICING_ADD" [(ngModel)]="onCheck.PLOTPRICING_ADD" (change)="onChanges()" (click)="selectAllPlotPricing()">
                    </div>
                </td>
                <td *ngxPermissionsOnly="[userPrivileges.TENANT_PRIVILEGE]">
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check4" name="PLOTPRICING_UPDATE" [(ngModel)]="onCheck.PLOTPRICING_UPDATE" (change)="onChanges()" (click)="selectAllPlotPricing()">
                    </div>
                </td>

            </tr>
            <tr>
                <td>Sales:</td>
                <td>
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check1" name="SALES_VIEW" [(ngModel)]="onCheck.SALES_VIEW" (change)="onChanges()">
                    </div>
                </td>
                <td>
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check2" name="SALES_VIEWALL" [(ngModel)]="onCheck.SALES_VIEWALL" (change)="onChanges()">
                    </div>
                </td>
                <td *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.USERS_ADD]">
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check3" name="SALES_ADD" [(ngModel)]="onCheck.SALES_ADD" (change)="onChanges()" (click)="selectAllSales()">
                    </div>
                </td>

            </tr>
            <tr>
                <td>Customers:</td>
                <td>
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check1" name="CUSTOMERS_VIEW" [(ngModel)]="onCheck.CUSTOMERS_VIEW" (change)="onChanges()">
                    </div>
                </td>
                <td>
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check2" name="CUSTOMERS_VIEWALL" [(ngModel)]="onCheck.CUSTOMERS_VIEWALL" (change)="onChanges()">
                    </div>
                </td>
                <td *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.USERS_ADD]">
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check3" name="CUSTOMERS_ADD" [(ngModel)]="onCheck.CUSTOMERS_ADD" (change)="onChanges()" (click)="selectAllCustomers()">
                    </div>
                </td>

            </tr>

            <tr>
                <td>Commission Defn:</td>
                <td>
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check1" name="COMMDEFN_VIEW" [(ngModel)]="onCheck.COMMDEFN_VIEW" (change)="onChanges()">
                    </div>
                </td>
                <td>
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check2" name="COMMDEFN_VIEWALL" [(ngModel)]="onCheck.COMMDEFN_VIEWALL" (change)="onChanges()">
                    </div>
                </td>
                <td *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.USERS_ADD]">
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check3" name="COMMDEFN_ADD" [(ngModel)]="onCheck.COMMDEFN_ADD" (change)="onChanges()" (click)="selectAllCommDefn()">
                    </div>
                </td>
                <td *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.USERS_ADD]">
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check4" name="COMMDEFN_UPDATE" [(ngModel)]="onCheck.COMMDEFN_UPDATE" (change)="onChanges()" (click)="selectAllCommDefn()">
                    </div>
                </td>
                <td *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.USERS_ADD]">
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check5" name="COMMDEFN_DELETE" [(ngModel)]="onCheck.COMMDEFN_DELETE" (change)="onChanges()" (click)="selectAllCommDefn()">
                    </div>
                </td>

            </tr>
            <tr>
                <td>Sales Rep:</td>
                <td>
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check1" name="SALESREP_VIEW" [(ngModel)]="onCheck.SALESREP_VIEW" (change)="onChanges()">
                    </div>
                </td>
                <td>
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check2" name="SALESREP_VIEWALL" [(ngModel)]="onCheck.SALESREP_VIEWALL" (change)="onChanges()">
                    </div>
                </td>
                <td *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.USERS_ADD]">
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check3" name="SALESREP_ADD" [(ngModel)]="onCheck.SALESREP_ADD" (change)="onChanges()" (click)="selectAllSalesRep()">
                    </div>
                </td>
                <td *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.USERS_ADD]">
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check4" name="SALESREP_UPDATE" [(ngModel)]="onCheck.SALESREP_UPDATE" (change)="onChanges()" (click)="selectAllSalesRep()">
                    </div>
                </td>
                <td *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE, userPrivileges.USERS_ADD]">
                    <div class="form-check pr-3 mx-2">
                        <input type="checkbox" class="form-check-input" id="check5" name="SALESREP_DELETE" [(ngModel)]="onCheck.SALESREP_DELETE" (change)="onChanges()" (click)="selectAllSalesRep()">
                    </div>
                </td>

            </tr>
            <br>
        </tbody>
    </table>
</div> -->

<div class="pt-2 wrap" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.TENANT_PRIVILEGE]">
    <div>
        <div>
            <h6>Ventures</h6>
        </div>
        <div>
            <div *ngFor="let venture of ventureList" class="form-check p-0">
                <span class="d-flex">
                    <input type="checkbox" class="form-control mr-2" id="{{ 'ventureCheckbox_' + venture.ventureId }}" [(ngModel)]="venture.selected" (change)="onChanges()">
                    <label for="{{ 'ventureCheckbox_' + venture.ventureId }}">{{ venture.displayName }}</label>
                </span>
              </div>
          </div>
    </div>
    <div>
        <div>
            <h6>Permissions</h6>
        </div>
        <div>
            <div class="form-check p-0" *ngxPermissionsOnly="[userPrivileges.TENANT_PRIVILEGE]">
                <span class="d-flex">
                    <input type="checkbox" class="form-control mr-2" id="REGISTER_PLOT" name="REGISTER_PLOT" [(ngModel)]="onCheck.REGISTER_PLOT" (change)="onChanges()">
                    <label for="REGISTER_PLOT"> Register Plot </label>
                </span>                
            </div>
            <div class="form-check p-0" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE]">
                <span class="d-flex">
                    <input type="checkbox" class="form-control mr-2" id="ADD_SALE" name="ADD_SALE" [(ngModel)]="onCheck.ADD_SALE" (change)="onChanges()">
                    <label for="ADD_SALE"> Add Sale </label>
                </span>
            </div>
            <div class="form-check p-0" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE]">
                <span class="d-flex">
                    <input type="checkbox" class="form-control mr-2" id="ADD_CUSTOMER_PAYMENT" name="ADD_CUSTOMER_PAYMENT" [(ngModel)]="onCheck.ADD_CUSTOMER_PAYMENT" (change)="onChanges()">
                    <label for="ADD_CUSTOMER_PAYMENT"> Add Customer Payment </label>
                </span>
            </div>
            <div class="form-check p-0" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE]">
                <span class="d-flex">
                    <input type="checkbox" class="form-control mr-2" id="ADD_COMMISSION_PAYMENT" name="ADD_COMMISSION_PAYMENT" [(ngModel)]="onCheck.ADD_COMMISSION_PAYMENT" (change)="onChanges()">
                    <label for="ADD_COMMISSION_PAYMENT"> Add Commission Payment </label>
                </span>
            </div>
            <div class="form-check p-0" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE]">
                <span class="d-flex">
                    <input type="checkbox" class="form-control mr-2" id="ADD_PASSIVE_ADVANCE" name="ADD_PASSIVE_ADVANCE" [(ngModel)]="onCheck.ADD_PASSIVE_ADVANCE" (change)="onChanges()">
                    <label for="ADD_PASSIVE_ADVANCE"> Add Passive Advance </label>
                </span>
            </div>
            <div class="form-check p-0" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE]">
                <span class="d-flex">
                    <input type="checkbox" class="form-control mr-2" id="UPDATE_CUSTOMER_DETAILS" name="UPDATE_CUSTOMER_DETAILS" [(ngModel)]="onCheck.UPDATE_CUSTOMER_DETAILS" (change)="onChanges()">
                    <label for="UPDATE_CUSTOMER_DETAILS"> Update Customer Details </label>
                </span>
            </div>
        </div>
    </div>
</div>