<div class=" my-3" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.TENANT_PRIVILEGE,userPrivileges.TENANT_BASIC_PRIVILEGE,userPrivileges.MARKETER_BASIC_PRIVILEGE]">
    <div class="row m-3">
        <div class="col-md-12 mb-3">
            <div class="card">
                <div class="card-body">
                    <div class="wrap">
                        <table class="table table-striped table-bordered table-sm row-border hover">
                            <thead>
                                <tr>
                                    <th>Plot Type</th>
                                    <th>Total</th>
                                    <th>Sold</th>
                                    <th>Blocked</th>
                                    <th>Registered</th>
                                    <th>Available</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let plot of summary">
                                    <td>{{plot.plotType | titlecase}}</td>
                                    <td>{{plot.total}}</td>
                                    <td>{{plot.sold}}</td>
                                    <td>{{plot.blocked}}</td>
                                    <td>{{plot.registered}}</td>
                                    <td>{{plot.available}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="card mb-3">
                <div class="card-body h-100">
                    <div>
                        <div class="wrap" *ngIf="lineChartData">
                            <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions" [plugins]="lineChartPlugins" [legend]="lineChartLegend" [colors]="lineChartColors" [chartType]="lineChartType">
                            </canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card shadow mb-3">
                <div class="card-body px-3 pb-4 pt-2">
                    <h6 class="card-title text-dark position-relative pb-2">Plots</h6>
                    <app-venture-graph [plots]="performance"></app-venture-graph>
                </div>
            </div>
        </div>
    </div>
</div>