<div class=" my-3" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.TENANT_PRIVILEGE,userPrivileges.TENANT_BASIC_PRIVILEGE,userPrivileges.MARKETER_BASIC_PRIVILEGE]">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <h6 class="card-title text-dark position-relative pb-2 h6">Customers</h6>
                </div>
            </div>
            <div class="wrap">
                <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Contact</th>
                            <th>City</th>
                            <th>Introduced by</th>
                            <th>Introduced Venture</th>
                            <th>Introduced Plot</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let customer of customers">
                            <td class="pointer" (click)="onShowDetails(customer)">{{customer.name}}</td>
                            <!-- <td>{{customer.email}}</td> -->
                            <td>{{ customer.contactNumber}}</td>
                            <td>{{customer.city}}</td>
                            <td>{{customer.introducedBy}}</td>
                            <td>{{customer.introducedVenture}}</td>
                            <td>{{customer.introducedPlot}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>

</div>