import { Location } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { UserPrivileges } from 'src/app/constants/AppConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-advances',
  templateUrl: './advances.component.html',
  styleUrls: ['./advances.component.scss']
})
export class AdvancesComponent implements OnInit {

  error: boolean;
  errorMessage: any;
  @ViewChild('makePassiveAdvance', { static: false }) makePassiveAdvance: ElementRef;
  @Input() sales;
  @Input() id;
  ventureSelected = 'all';
  ventures: any = [];
  salesrepInfo: any;

  ventureAdvancesInfo: any;
  passiveAdvanceInfo: any;
  userInfo: any;
  ventureId: any;
  salesrep: any;
  userPrivileges = UserPrivileges;
  closeResult : String;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  passiveAdvanceEntry : number;
  commentsEntry: String;
  passiveAdvanceForm: any;

  constructor(private authService: AuthServiceService,
    private location: Location,
    private restService: RestService,
    private modalService: NgbModal,
    private commonService: CommonServiceService) { }

  ngOnInit() {

    this.userInfo = this.authService.getUserInfo();
    if(this.userInfo?.salesrepId) {
      const path: string = 'salesrep/assigned-ventures/' + this.userInfo.salesrepId;
      this.restService.submitGetRequest(path).subscribe(response => {
        this.ventures = response.data;
      });
    }
    if (this.sales) {
      if (this.id) { 
        this.ventureId = this.commonService.getVenture()?.ventureId;
        this.getSalesrepAdvances(this.userInfo.tenantId, this.ventureId, this.sales);   
      } else {
        this.ventureId=null;
        this.getSalesrepAllVentureAdvances(this.sales, this.userInfo.tenantId);
      }

      let data = {
        "salesrepId": this.sales,
        "tenantId": this.userInfo.tenantId,
        "marketerId": this.userInfo.marketerId
      }

      this.restService.getmyDetails(data).subscribe(response => {
        if (response.data != null) {
          this.salesrepInfo = response.data;

        }
      },
        (error) => {
          this.error = true;
          this.errorMessage = error.status;
        });




    }
    else {
      this.ventureId=null;
      this.getSalesrepAllVentureAdvances(this.userInfo.salesrepId, this.userInfo.tenantId);
    }

  }


  getSalesrepAdvances(tenantId: any, ventureId: any, salesrepId: any) {

    let url = `generic/salesrep/advances?tenantId=${tenantId}&ventureId=${ventureId}&salesrepId=${salesrepId}`;
  
    this.restService.getSalesrepAdvances(url).subscribe(response => {
      if (response.data != null && response.data != undefined) {
        this.passiveAdvanceInfo = response.data;
      }
    },
    (error) => {
      console.log(error.status);
    });
  }

  getSalesrepAllVentureAdvances(salesrepId: any, tenantId: any) {
    let url = `generic/salesrep/all-venture-advances?tenantId=${tenantId}&salesrepId=${salesrepId}`;
  
    this.restService.getSalesrepAllVentureAdvances(url).subscribe(
      response => {
        if (response.data != null && response.data != undefined) {
          this.ventureAdvancesInfo = response.data;
        }
      },
      error => {
        console.log(error.status);
      }
    );
  }

  getCommissionsByVenture() {
    if(this.ventureSelected == 'all') {
      this.ventureId=null;
      this.getSalesrepAllVentureAdvances(this.userInfo.salesrepId, this.userInfo.tenantId);
    } else {

      this.ventureId = this.ventureSelected;
      this.getSalesrepAdvances(this.userInfo.tenantId, this.ventureId, this.userInfo.salesrepId);
    }
  }




  showMakePassiveAdvance(e:Event,salesrepId) {
    e.stopPropagation();
    this.salesrep = {"salesrepId" :salesrepId , "salesrepName" : this.salesrepInfo.firstName+" "+this.salesrepInfo.lastName};
    this.open(this.salesrep);
  }

  createPassiveAdvance(){

    const roundedPassiveAdvanceEntry = Math.round(this.passiveAdvanceEntry);

  const requestPayload = {
    salesrepId: this.salesrep.salesrepId,
    ventureId: this.commonService.getVenture()?.ventureId,
    tenantId: this.userInfo.tenantId,
    marketerId: this.userInfo.marketerId,
    passiveAdvanceAmount: roundedPassiveAdvanceEntry,
    comments: this.commentsEntry,
    createdOn: new Date().toISOString(),
  };

  this.restService.createPassiveAdvance(requestPayload).subscribe(
    (response: any) => {
      if (response != null) {
        this.location.back();
      }
    }, (error) => {
      this.error = true;
      this.errorMessage = error.status;
    })


  }

  open(content) {
		this.modalService.open(this.makePassiveAdvance, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

  private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}



}