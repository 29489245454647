<div>
    <ul class="list-group">
        <li class="list-group-item d-flex align-items-center" *ngFor="let venture of ventureList">
            <input type="radio" name="displayName" value="{{venture.displayName}}" (click)="onVentureChange(venture)"
                id="{{venture.ventureId}}" [checked]="isVentureLaunched(venture.ventureId)">
            <label style="cursor:pointer" class="ml-1 mb-0" for="{{venture.ventureId}}">
                {{venture.displayName}}
            </label>
        </li>
    </ul>
    <button type="submit" value="ventures" (click)="launchDashboard()"
        class="btn btn-sm btn-info float-right mt-2">Launch</button>
</div>