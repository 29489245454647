<div>
  <div  *ngIf="pieChartData">
      <canvas baseChart height="250%" [data]="pieChartData"
          [labels]="pieChartLabels"
          [options]="pieChartOptions"
          [plugins]="pieChartPlugins"
          [legend]="pieChartLegend"
          [colors]="pieChartColors"
          [chartType]="pieChartType">
      </canvas>
  </div>
</div>