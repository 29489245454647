<div class="row">
  <div class="col-md-8">
    <div class="row">
      <div class="col-md-6">
        <div class="card shadow  h-10 mb-2">
          <div class="card-body pb-0 px-3 pt-2">
            <h6 class="card-title text-dark position-relative pb-2">Search for a Sales Rep:</h6>
            <!-- <label for="typeahead-prevent-manual-entry">Search for a SalesRep:</label> -->
            <input id="typeahead-prevent-manual-entry" type="text" class="form-control" [ngbTypeahead]="searchRep"
              [inputFormatter]="formatter" [resultFormatter]="formatter" [editable]='false' />
            <hr>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card shadow  h-10 mb-2">
          <div class="card-body pb-0 px-3 pt-2">
            <h6 class="card-title text-dark position-relative pb-2">Search for a Plot:</h6>
            <!-- <label for="typeahead-prevent-manual-entry">Search for a Plot:</label> -->
            <input id="typeahead-prevent-manual-entry" type="text" class="form-control" [ngbTypeahead]="searchPlots"
              [inputFormatter]="formatter1" [resultFormatter]="formatter" [editable]='false' />
            <hr>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card shadow  h-100 mb-2">
          <div class="card-body pb-0 px-3 pt-2">
            <h5 class="card-title">Plot Details</h5>
            <p class="card-text">This card will be populated with plot details</p>
            <a href="#" class="btn btn-primary">ok</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-4">
    <div class="card shadow  h-100 mb-2">
      <div class="card-body pb-0 px-3 pt-2">
        <form>
          <div class="form-group">
            <label for="">Current price per yard</label>
            <input type="text" class="form-control">
          </div>
          <div class="form-group">
            <label for="">Agreed price</label>
            <input type="text" class="form-control">
          </div>
          <div class="form-group">
            <label for="">Total Sale price</label>
            <input type="text" class="form-control">
          </div>
        </form>
        <a href="#" class="btn btn-primary">Submit</a>
      </div>
    </div>
  </div>


</div>

<div class="row">
  <div class="col-lg-6 col-md-6 mb-3">
    <div class="card shadow  h-100 mb-2">
      <div class="card-body pb-0 px-3 pt-2">
        <h5 class="card-title">Standard Plot Pricing</h5>
        <p class="card-text">This card will be populated with Standard pricing of plots</p>
        <a href="#" class="btn btn-primary">ok</a>
      </div>
    </div>
  </div>

  <div class="col-lg-6 col-md-6 mb-3">
    <div class="card shadow  h-100 mb-2">
      <div class="card-body pb-0 px-3 pt-2">
        <h5 class="card-title">Customer Details</h5>
        <p class="card-text">This card will be populated with Customer details</p>
        <a href="#" class="btn btn-primary">ok</a>
      </div>
    </div>
  </div>

</div>

<!-- <pre>Model: {{ model | json }}</pre> -->

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <table class="table table-striped table-bordered table-sm row-border hover">
          <thead>
            <tr>
              <th>Level</th>
              <th>Name</th>
              <th>Per yard Commission</th>
              <th>Calculated</th>
              <th>Adjusted</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let sales of salesLevel[0]">
              <td>
                <span *ngIf="!sales.editable">{{sales.level}}</span>
                <input type="text" class="form-control" [(ngModel)]="sales.level"
                  *ngIf="sales.editable" />
              </td>
              <td>
                <span *ngIf="!sales.editable">{{sales.name}}</span>
                <input type="text" class="form-control" [(ngModel)]="sales.name"
                  *ngIf="sales.editable" /></td>
              <td>
                <span *ngIf="!sales.editable">{{sales.per_yard_commission}}</span>
                <input type="text" class="form-control" [(ngModel)]="sales.per_yard_commission"
                  *ngIf="sales.editable" />
                </td>
        
              <td>
                <span *ngIf="!sales.editable">{{sales.calculated}}</span>
                <input type="text" class="form-control" [(ngModel)]="sales.calculated"
                  *ngIf="sales.editable" />
                </td>
              <td><span *ngIf="!sales.editable">{{sales.adjusted}}</span>
                <input type="text" class="form-control" [(ngModel)]="sales.adjusted"
                  *ngIf="sales.editable" /></td>
                  <td><button class="btn btn-primary" (click)="editSale(sales)">Edit</button></td>
            </tr>
          </tbody>
        </table>
        <a href="#" class="btn btn-primary">Proceed</a>&nbsp;
        <a href="#" class="btn btn-primary">Issues</a>
      </div>
    </div>
  </div>
</div>