import { Component, Input, OnInit } from '@angular/core';
import { AuthServiceService } from '../../../services/auth-service.service';
import { CommonServiceService } from '../../../services/common-service.service';
import { Location } from '@angular/common'
import { RestService } from '../../../services/rest.service';

@Component({
  selector: 'app-addbankdetails',
  templateUrl: './addbankdetails.component.html',
  styleUrls: ['./addbankdetails.component.scss']
})
export class AddbankdetailsComponent implements OnInit {
  @Input() sales;
  bankdetails: any = [];
  bankdata: any = [];
  path: string;
  account: any = {};
  data: { tenantId: number; bankAccountId: any; };

  constructor(private restService: RestService,
    private authService: AuthServiceService,
    private location: Location,
    private commonService: CommonServiceService) { }
  ngOnInit(): void {
    const userInfo = this.authService.getUserInfo()
    const pathF = 'bank/get-account';
    if (this.sales) {
      this.data = {
        tenantId: userInfo.tenantId,
        bankAccountId: this.sales,
      }
    } else {
      this.data = {
        tenantId: userInfo.tenantId,
        bankAccountId: userInfo.personId,
      }
    }
    this.restService.submitPostRequest(pathF, this.data).subscribe(response => {
      this.bankdetails = response.data;
      //  this.bankdetails.push(this.account);
    });
  }
  onsubmit(bank: any) {
    const userInfo = this.authService.getUserInfo();
    if (this.bankdetails != null) {
      this.path = 'bank/update-account'
      this.restService.submitPutRequest(this.path, this.bankdetails).subscribe(response => {
        this.bankdata = response;
      });
    } else {
      this.path = 'bank/add-account'
      this.account.tenantId = userInfo.tenantId;
      if (this.sales) {
        this.account.bankAccountId = this.sales;
      } else {
        this.account.bankAccountId = userInfo.personId;
      }
      this.restService.submitPostRequest(this.path, this.account).subscribe(response => {
        this.bankdata = response;
      });
    }
    this.location.back();
  }

}
