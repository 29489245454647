<div class="mt-3 position-relative right-tabs clearfix mb-0 card">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="justify-content-end pt-0 pr-3">
        <li class="mr-auto pt-3 px-4 mb-0">
            <h6>Marketer Details</h6>
        </li>
        <li [ngbNavItem]="1">
            <a ngbNavLink>Ventures</a>
            <ng-template ngbNavContent>
                <app-marketerventure [id]="marketerId"></app-marketerventure>
            </ng-template>
        </li>
        <li [ngbNavItem]="2">
            <a ngbNavLink>Update Marketer</a>
            <ng-template ngbNavContent>
                <app-updatemarketer [id]="marketerId"></app-updatemarketer>
            </ng-template>
        </li>
    </ul>
    <a class="position-absolute close-popup-link bg-white" (click)="sendBack()"><i class="fa fa-times"
            aria-hidden="true"></i>
    </a>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
<router-outlet></router-outlet>