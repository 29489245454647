import { Component, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UserPrivileges, UserRoleConstants } from 'src/app/constants/AppConstants';
import { RoutesConstants } from 'src/app/constants/RoutesConstants';
import { payNow } from 'src/app/models/paynow';
import { customerDetails, PlotsDetails, salesRepDetails } from 'src/app/models/plots-details';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';
import _ from 'underscore';

@Component({
  selector: 'app-editSale',
  templateUrl: './editSale.component.html',
  styleUrls: ['./editSale.component.scss']
})
export class EditSaleComponent implements OnInit, OnChanges {

  salesLevel: any = [];
  Customers: any = [];
  myDetails: any = [];
  error: boolean;
  errorMessage: any;
  result: any;
  isShown: boolean = false
  submitNew = false;
  newCustomerData: any = [];
  salesRep: any = [];
  keyword = 'name';
  plots: any = [];
  keyword1 = 'plotNumber';
  plotDetails: PlotsDetails
  salesRepDetails: any = {};
  ModelObject: any = {};
  keyword2 = 'name';
  submitExisting = false;
  customerDetails: customerDetails;
  /**
   * Complete Sale Details
   */
  plotSaleDetails: any = {};
  payment = new payNow(null, '', '', '', '', '', '');
  alert = false;
  showPayment = false;
  salesRepId: any;
  plotId: any;
  saleId: any;
  customerId: any;
  CustomerName: any;
  payments: any = ['Online', 'Wire Transfer', 'Cash', 'Cheque'];
  newPayment: any;
  customerCity: any;
  introducedBy: any;
  contactNumber: any;
  userInfo: any;
  ventureId: void;
  plotsales: any = [];
  customerdata: any = [];
  userPrivileges = UserPrivileges;
  commissions: any = [];
  paymentHistory: any = [];
  show: any = false;
  isCancelRequested = false;
  isCanceled = false;
  cancelalert = false;
  closeResult = '';
  customerName: string;
  isCancelledSale = false;
  perMeasurePrice: any;
  ventureName: any;
  selectedFile: File;
  saleFiles: any = [];
  cancelledReason: String;
  saleDetails: any;
  availablePlots: any;
  selectedNewPlot: any;

  constructor(
    private restService: RestService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private authService: AuthServiceService,
    private modalService: NgbModal,
    public commonService: CommonServiceService
  ) {
    this.activatedRouter.queryParams.subscribe(
      (params: any) => {
        this.salesRepId = params['salesRepId'];
        this.plotId = params['plotId'];
        this.saleId = params['saleId'];
        this.isCancelledSale = params.status === 'cancelled';
      });
  }

  ngOnInit() {
    this.ventureName = this.commonService.getVenture()?.displayName;
    this.userInfo = this.authService.getUserInfo();
    this.ventureId = this.commonService.getVenture()?.ventureId;
    const marketer = this.authService.hasRole(UserRoleConstants.TENANT) ? this.plotSaleDetails.marketerId :
      this.userInfo.marketerId;
    // Fetch cancellation status of sale
    this.getSaleCancelStatus(this.userInfo.personId, this.saleId, this.ventureId);
    this.viewSale();
    this.getPlots();
    this.getSaleFIles()

  }

  /**
   * @descripion Initiate Plot Transfer - assign a different plot for a sale
   * 
   */

  getSelectedPlotDetails() {
    return this.availablePlots.find(plot => plot.plotNum === this.selectedNewPlot) || {};
  }

  openChangePlotModal(content) {
    this.fetchAvailablePlots();
    this.open(content);
  }

  fetchAvailablePlots() {
    const user = this.authService.getUserInfo();
    let data = {
      'tenantId': user.tenantId,
      'ventureId': this.commonService.getVenture()?.ventureId
    }
    this.restService.getAvailablePlots(data).subscribe(response => {
      if (!_.isEmpty(response.data)) {
        const newPlotsList = response.data.filter((plot) => plot.size === this.plots.size);
        this.availablePlots = newPlotsList;
      }
    }, (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });
  }

  saveNewPlotNumber(content) {
    this.modalService.dismissAll();
    const data = {
      saleId: this.saleId,
      newPlotId: this.getSelectedPlotDetails().plotId
    }
    this.restService.transferPlotNumber(data).pipe(
      tap((response) => {
        if (!_.isEmpty(response.data)) {
          this.open(content)
        }
      }),
      catchError((error) => {
        this.error = true;
        this.errorMessage = error.status;
        return throwError(error);
      })
    ).subscribe()
  }


  /**
   * @description Initiate cancel sale request
   * @param content 
   * 
   */
  cancelSaleRequest(content) {
    const requestData = {
      saleId: this.saleId,
      tenantId: this.userInfo.tenantId,
      ventureId: this.ventureId,
      plotId: this.plotId,
      personId: this.userInfo.personId
    };
    this.isCancelRequested ? this.open(content) : (this.restService.cancelSaleRequest(requestData).pipe(
      tap((response) => {
        if (!_.isEmpty(response.data)) {
          this.isCancelRequested = true;
        }
      }),
      catchError((error) => {
        this.error = true;
        this.errorMessage = error.status;
        return throwError(error);
      })
    ).subscribe());
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    });
  }

  cancelSale() {
    const requestData = {
      saleId: this.saleId,
      plotId: this.plotId,
      personId: this.userInfo.personId,
      cancelledReason: this.cancelledReason
    };
    this.restService.cancelSale(requestData).subscribe((response) => {
      if (!_.isEmpty(response.data)) {
        this.isCanceled = true;
        this.cancelalert = true;
        this.modalService.dismissAll();
        setTimeout(() => {
          this.goBack();
        }, 3000);
      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      });
  }

  /**
   * Get Cancellation status of sale
   * @param personId 
   * @param ventureId 
   * @yield {boolean} True If cancel request initiated
   */
  getSaleCancelStatus(personId, saleId, ventureId) {
    if (personId && ventureId) {
      this.restService.getSaleCancelStatus(personId, saleId, ventureId).subscribe(response => {
        if (!_.isEmpty(response.data) && response.data?.saleId == this.saleId) {
          this.isCancelRequested = true;
        }
      },
        (error) => {
          this.error = true;
          this.errorMessage = error.status;
        });
    }
  }

  ngOnChanges() {

  }

  goBack() {
    this.router.navigate(['/' + this.commonService.getUrlSuffix(), RoutesConstants.VENTURELAYOUT, RoutesConstants.VENTURESALES]);
  }

  getVentureCustomer() {
    let data = {
      "personId": this.customerId,
      "tenantId": this.userInfo.tenantId,
      "marketerId": this.userInfo.marketerId
    }
    this.restService.getVentureCustomer(data).subscribe(response => {
      if (response) {
        this.customerdata = response.data;
        this.customerName = this.customerdata.firstName + " " + this.customerdata.lastName;
        this.customerCity = this.customerdata.city;
        this.contactNumber = response.data.contactNumber;
        this.introducedBy = response.data.introducedBy;
      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      });
  }

  getCustomerData(customerId) {
    let data = {
      "tenantId": this.userInfo.tenantId,
      "marketerId": this.userInfo.marketerId,
      "personId": customerId
    }
    this.restService.getCustomer(data).subscribe(response => {
      if (data != null) {
        this.Customers = response.customers;
        this.customerdata = response.data;
        this.customerName = this.customerdata.firstName + " " + this.customerdata.lastName;
        this.customerCity = this.customerdata.city;
        this.contactNumber = response.data.contactNumber;
        this.introducedBy = response.data.introducedBy;
      }
    }, (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });
  }

  getPlots() {
    let data = {
      "tenantId": this.userInfo.tenantId,
      "ventureId": this.ventureId,
      "plotId": parseInt(this.plotId)
    }
    this.restService.getPlotsById(data).subscribe(response => {
      if (data != null) {
        this.plots = response.data;
      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      })
  }


  formulateData() {
    let sale = {
      "saleId": parseInt(this.saleId)
    }
    let plotSales = {
      "plotId": parseInt(this.plotId)
    }
    let commissions = []
    let customerPayins = []
    let getSalesDetails = {
      "tenantId": this.userInfo.tenantId,
      "ventureId": this.ventureId,
      "marketerId": this.userInfo.marketerId,
      "sale": sale,
      "plotSales": plotSales,
      "commissions": commissions,
      "customerPayins": customerPayins
    }
    return getSalesDetails;
  }

  viewSale() {
    if (this.isCancelledSale) {
      this.restService.fetchCancelledSale(this.saleId).subscribe(
        (response: any) => {
          if (response && response.data) {
            this.plotSaleDetails = response.data;
            this.customerId = this.plotSaleDetails.cancelledSale?.primaryCustomerId;
            this.plotsales = this.plotSaleDetails.cancelledSale;
            let commission = this.plotSaleDetails.cancelledCommissionsDetailsList;
            this.paymentHistory = this.plotSaleDetails.cancelledCustomerPayins;
            this.getCustomerData(this.plotSaleDetails.cancelledSale?.primaryCustomerId);
            this.salesLevel = this.plotSaleDetails.cancelledCommissionsDetailsList;
            this.show = this.plotsales.saleValue === this.plotsales.saleAmountReceived;
            this.cancelledReason = this.plotSaleDetails.cancelledSale?.cancelledReason;
            this.perMeasurePrice = this.plotSaleDetails.cancelledPlotSales?.perMeasurePrice;
            commission?.sort((a, b) => a.salesrepCommissionLevel - b.salesrepCommissionLevel);
          }
        },
        (error) => {
          this.error = true;
          this.errorMessage = error.status;
        }
      );
    } else {
      const sendObject = this.formulateData();
      this.restService.getSaleDetails(sendObject).subscribe(
        (response: any) => {
          if (response && response.data) {
            this.plotSaleDetails = response.data;
            this.customerId = this.plotSaleDetails.sale?.primaryCustomerId;
            this.plotsales = this.plotSaleDetails.sale;
            this.saleDetails = this.plotSaleDetails.plotSales;
            let commission = this.plotSaleDetails.commissions;
            this.paymentHistory = this.plotSaleDetails.customerPayins;
            this.getCustomerData(this.plotSaleDetails.plotSales.primaryCustomerId);
            this.salesLevel = this.plotSaleDetails.commissionsDetailsList;
            this.show = this.plotsales.saleValue === this.plotsales.saleAmountReceived;
            this.perMeasurePrice = this.plotSaleDetails.plotSales?.perMeasurePrice;
            commission?.sort((a, b) => a.salesrepCommissionLevel - b.salesrepCommissionLevel);
          }
        },
        (error) => {
          this.error = true;
          this.errorMessage = error.status;
        }
      );
    }
  }

  commissionPayment(id) {
    if (this.isCancelledSale) {
      return;
    }
    this.router.navigate(['/' + this.commonService.getUrlSuffix(), RoutesConstants.MARKETERLAYOUT, RoutesConstants.COMMISSION_PAYMENTS], { queryParams: { salesrepId: id, plotId: this.plots.plotId } })
  }

  paymentData() {
    let customerPayins = [{
      "plotId": parseInt(this.plotId),
      "amountReceived": Math.round(this.payment.amount),
      "paidFor": this.ModelObject.payforCustomer,
      "paidBy": this.ModelObject.paybyCustomer,
      "saleId": parseInt(this.saleId),
      "paymentMode": this.payment.paymentMode,
      "comments": this.payment.comments,
      "lastUpdatedBy": this.authService.getUserInfo().personId,
      "paidDate": this.payment.paidDate,
    }]
    let getpaymentDetails = {
      "tenantId": this.userInfo.tenantId,
      "ventureId": this.ventureId,
      "marketerId": this.userInfo.marketerId,
      "customerPayins": customerPayins
    }
    return getpaymentDetails;
  }

  addPayment() {
    this.ModelObject.payFor = this.customerName;
    this.ModelObject.payBy = this.customerName;

    this.ModelObject.payforCustomer = this.customerId;
    this.ModelObject.paybyCustomer = this.customerId;
    this.showPayment = true;
  }
  onSubmit(payment) {
    let data = this.paymentData()
    this.restService.addPayment(data).subscribe(response => {
      if (response.data != null) {
        this.plotSaleDetails.customerPayins.push(response.data);
        this.viewSale();
        this.showPayment = false;
        this.alert = true;
      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
        this.showPayment = false;
        this.alert = false;
      });
    payment.resetForm();
  }

  closeAlert() {
    this.alert = false;
  }
  capitalTitle(plot: any) {
    const str = plot;
    return str.toUpperCase();
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  uploadFile() {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      formData.append('tenantId', this.userInfo.tenantId);
      formData.append('ventureId', this.commonService.getVenture()?.ventureId);
      formData.append('saleId', this.saleId);

      this.restService.uploadSaleFile(formData).subscribe(response => {
        if (response) {
          this.selectedFile = null;
          this.ngOnInit();
        }
      });
    } else {
      console.log('No file selected');
    }
  }

  getSaleFIles() {
    let url;
    url = '/generic/saleDocument?' + 'saleId=' + this.saleId + '&tenantId=' + this.userInfo.tenantId;
    this.restService.getLandingPageFilesUrls(url).subscribe(response => {
      if (response != null && response != undefined) {
        this.saleFiles = response.data;   
      }
    });
  }

  extractFileNameFromUrl(url: string): string {
    const fileNameWithExtension = url.substring(url.lastIndexOf('/') + 1);
    const decodedFileName = decodeURIComponent(fileNameWithExtension);
    const fileName = decodedFileName.split('%2F').pop();
    return fileName.substring(fileName.lastIndexOf('/') + 1);
  }
}