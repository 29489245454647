<div class="my-3">
  <div class="position-relative right-tabs clearfix mb-0 card">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="justify-content-end pt-0 pr-3">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Commissions</a>
        <ng-template ngbNavContent>
          <div class="mx-4 mb-4">
            <app-mycommissions></app-mycommissions>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Payments</a>
        <ng-template ngbNavContent>
          <div class="mx-4 mb-4">
            <app-mypayments></app-mypayments>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink>Advances</a>
        <ng-template ngbNavContent>
          <div class="mx-4 mb-4">
            <app-advances></app-advances>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>
<router-outlet></router-outlet>