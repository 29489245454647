<div class="container-fluid my-3">
  <div class="card  mb-3">
    <div class="card-body">
      <div class="wrap">
      <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions"
        [dtTrigger]="dtTrigger">
        <thead>
          <tr>
            <th>Sales Rep</th>
            <th>Plots Sold</th>
            <th>{{commService.getMeasure()}} Sold</th>
            <th>Revenue (<i class="fas fa-rupee-sign mr-1"></i>)</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let sales of salesrepVentureSales">
            <td>{{sales.salesrep}}</td>
            <td (click)="checkSalesDetails(sales)" style="cursor: pointer;">{{sales.plotsSold}}</td>
            <td>{{sales.yardsSold.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
            <td>{{sales.revenue.toLocaleString('en-IN')}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
  </div>
</div>