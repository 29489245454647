import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-salesrep-commission',
  templateUrl: './salesrep-commission.component.html',
  styleUrls: ['./salesrep-commission.component.scss']
})
export class SalesrepCommissionComponent implements OnInit {

  active = 1;

  constructor() { }

  ngOnInit(): void {
  }

}
