import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Stepper from 'bs-stepper';
import { UserKeys } from 'src/app/models/user.model';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-attach-salesrep',
  templateUrl: './attach-salesrep.component.html',
  styleUrls: ['./attach-salesrep.component.scss']
})
export class AttachSalesrepComponent implements OnInit {
  @ViewChild('successMsg') successMsg: ElementRef<HTMLElement>;
  @Input() isMarketerTeams: boolean;
  @Input() ventureTeam: any;
  @Output() displayTeam = new EventEmitter<void>();
  genders: any = ['Male', 'Female', 'Others'];
  salesRepDetails: any = { personType: 'SalesRep', themeOption: 'theme1' };
  permAddress: any = {};
  commAddress: any = {};
  submit = false;
  keyword = 'name'
  alert = false;
  address2: any = false;
  salesRepData: any;
  private stepper: Stepper;
  error: boolean;
  errorMessage: any;
  newSalesRep: any = [];
  userInfo: any;
  salesRep: any;
  closeResult: string;
  message: string;

  constructor(
    private restService: RestService,
    private authService: AuthServiceService,
    private commonService: CommonServiceService,
    private modalService: NgbModal
  ) { }
  ngOnInit() {
    this.userInfo = this.authService.getUserInfo();
    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true
    });
    if (this.ventureTeam) {
      this.salesRep = this.ventureTeam.teams.filter(a => a.name != null)
    }
  }
  onSubmit(newSalesrep: NgForm) {
    if (this.permAddress && this.permAddress.addressLine1) {
      this.permAddress.sequenceNum = 1;
      this.permAddress.addressCatogery = "Permanent"
      this.salesRepDetails.primaryAddress = this.permAddress;
    }
    if (this.address2 == true) {
      this.commAddress.sequenceNum = 2;
      this.salesRepDetails.secondaryAddress = this.permAddress;
    } else {
      this.commAddress.sequenceNum = 2;
      this.salesRepDetails.secondaryAddress = this.commAddress;
    }
    this.salesRepDetails.status = 'Active';
    this.salesRepDetails.tenantId = this.userInfo.tenantId;
    this.salesRepDetails.marketerId = this.userInfo.marketerId;
    if (this.salesRepData) {
      this.salesRepDetails.reportingManagerId = this.salesRepData.salesrepId;
    }
    if (this.commonService.getVenture()) {
      this.salesRepDetails.ventureId = this.commonService.getVenture().ventureId;
    }
    let path: string = '';
    if (this.isMarketerTeams) {
      path = 'marketer/salesrep/add-salesrep';
    } else {
      path = 'venture-team/add-venture-team';
    }
    this.restService.submitPostRequest(path, this.salesRepDetails).subscribe(response => {
      if (response != null) {
        this.newSalesRep = response.data;
        newSalesrep.resetForm();
      }
      this.message = "SalesRep created successfully!";
      this.open(newSalesrep);
    }, (error) => {
      this.error = true;
      this.errorMessage = error.status;
      this.message = this.errorMessage;
      this.open(newSalesrep);
    });
  }
  closeAlert() {
    this.modalService.dismissAll('Cross click');
    this.displayTeam.emit();
  }
  next() {
    this.stepper.next();
  }

  open(content) {
    this.modalService.open(this.successMsg, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
