import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-saleschallenges',
  templateUrl: './saleschallenges.component.html',
  styleUrls: ['./saleschallenges.component.scss']
})
export class SaleschallengesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
