import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AuthServiceService } from "src/app/services/auth-service.service";
import { CommonServiceService } from "src/app/services/common-service.service";
import { RestService } from "src/app/services/rest.service";
import { Location } from "@angular/common";
import _ from "underscore";
import { CommissionDefinitionStatus, CommissionType } from "src/app/models/Commissions";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RoutesConstants } from "src/app/constants/RoutesConstants";

@Component({
	selector: "app-addcommissionstructure",
	templateUrl: "./addcommissionstructure.component.html",
	styleUrls: ["./addcommissionstructure.component.scss"],
})
export class AddcommissionstructureComponent implements OnInit {
	@ViewChild('successMsg') successMsg: ElementRef<HTMLElement>;	
	errorMessage: any;
	error: boolean;
	commissionStructure: any = {};
	underscore = _;
	commissionType: CommissionType;
	isReadOnly = false;
	isEditView = false;
	closeResult: string;
	message: any;

	constructor(
		private restService: RestService,
		private location: Location,
		private authService: AuthServiceService,
		private commonService: CommonServiceService,
		private router: Router,
		private cdr: ChangeDetectorRef,
		private modalService: NgbModal
	) {
	}

	ngOnInit(): void {
		if (this.router.url.includes('editcommissionstructure')){
			this.isEditView = true;
			this.commissionStructure = this.commonService.getCommissionStructure();
			this.commissionStructure.effectiveDate = this.commissionStructure.effectiveDate.split('T')[0];
			if (this.commissionStructure.commissionType === 'perMeasure') {
				this.commissionStructure.fieldValue = this.commissionStructure.commissionPerMeasure;
			  } else if (this.commissionStructure.commissionType === 'percentage') {
				this.commissionStructure.fieldValue = this.commissionStructure.commissionPercentage;
			  } else if (this.commissionStructure.commissionType === 'fixed') {
				this.commissionStructure.fieldValue = this.commissionStructure.commissionFixed;
			  }
		}		
	}



	makeDistReadOnly(val) {
		val === 'edit' ? this.isReadOnly = false : this.isReadOnly = true;
	}


	sendBack() {
		this.modalService.dismissAll('Cross click')
		this.router.navigate(['/'+this.commonService.getUrlSuffix(), RoutesConstants.VENTURELAYOUT, RoutesConstants.COMMISSIONDETAILS], { queryParams: { 'commissionStructure': true } });
	}
	open(content) {
		this.modalService.open(this.successMsg, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
		  this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
		  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	  }
	
	  private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
		  return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		  return 'by clicking on a backdrop';
		} else {
		  return `with: ${reason}`;
		}
	  }

	  onSubmit(commissionForm: any) {

		const userInfo = this.authService.getUserInfo();
		const { tenantId, marketerId } = userInfo;
		const venture = this.commonService.getVenture();
		if (this.commissionStructure.commissionType === 'perMeasure') {
			this.commissionStructure.commissionPerMeasure = this.commissionStructure.fieldValue;
		  } else if (this.commissionStructure.commissionType === 'percentage') {
			this.commissionStructure.commissionPercentage = this.commissionStructure.fieldValue;
		  } else if (this.commissionStructure.commissionType === 'fixed') {
			this.commissionStructure.commissionFixed = this.commissionStructure.fieldValue;
		  }

		this.commissionStructure = {
		  ...this.commissionStructure,
		  tenantId,
		  marketerId,
		  ventureId: venture?.ventureId,
		};
	
		if(this.isEditView){
			this.restService.updateCommissionDefinition(this.commissionStructure).subscribe(
			  (response: any) => {
				if (response != null) {
				  this.commissionStructure = response.data;
				}
			  }, (error) => {
				this.error = true;
				this.errorMessage = error.status;
				if (error.error && error.error.message) {
					alert(error.error.message);
				  } else {
					console.log("Unknown API Error");
				  }
			  })      
		  }
		  else{
			this.restService.addCommissionDefinition(this.commissionStructure).subscribe(
			  (response: any) => {
				if (response != null) {
				  this.commissionStructure = response.data;
				}
			  }, (error) => {
				this.error = true;
				this.errorMessage = error.status;
				if (error.error && error.error.message) {
					alert(error.error.message);
				  } else {
					console.log("Unknown API Error");
				  }
			  })
		  }
		  this.location.back()
	  }
}
