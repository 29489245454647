<ng-container>
    <div class="my-3">
      <div class="card">
        <div class="card-body">
          <form #details="ngForm">
            <div class="row">
              <div class="form-group col-md-4">
                <label>Ventures</label>
                <ng-multiselect-dropdown name="select" [placeholder]="'Select'" [data]="ventures" [settings]="dropdownSettings"
                  (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelect($event)"
                  (onDeSelectAll)="onUnSelectAll()">
                </ng-multiselect-dropdown>
              </div>
              <div class="form-group col-md-4" *ngIf="userInfo?.scopes.includes('MARKETER_SUPER_PRIVILEGE') || userInfo?.scopes.includes('MARKETER_BASIC_PRIVILEGE')">
                <label class="star">Salesrep</label>
                <select type="text" class="form-control" id="team" name="team" [(ngModel)]="filters.salesrepId">
                  <option *ngFor="let team of teamList" [value]="team.salesrepId">{{team.name}}</option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <label>Type</label>
                <select type="text" class="form-control" id="type" name="type" [(ngModel)]="filters.type">
                  <option [value]="'all'">All</option>
                  <option [value]="'direct'">Direct</option>
                  <option [value]="'indirect'">Indirect</option>
                </select>
              </div>
              <div class="form-group col-md-4" *ngIf="userInfo?.scopes.includes('SALESREP_PRIVILEGE')">&nbsp;</div>
              <div class="form-group col-md-4">
                  <label>From Date</label>
                  <input type="date" class="form-control" id="fromDate" name="fromDate" [(ngModel)]="filters.fromDate">
              </div>
              <div class="form-group col-md-4">
                <label>To Date</label>
                <input type="date" class="form-control" id="toDate" name="toDate" [(ngModel)]="filters.toDate">
              </div>
              <div class="col-md-2 mt-4 pt-1" style="font-size: medium;">
                <button class="btn-info py-1 px-3 border rounded" (click)="getPerformances()"
                  [disabled]=details.invalid><b>Go</b></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="my-3" *ngIf="salesrepCommissionsArray?.salesrepCommissionsList?.length > 0">
    <div class="card">
        <div class="card-body">
            <h6 class="card-title text-dark position-relative pb-2">Commissions</h6>
            <div class="container-fluid my-4">
                <div class="row">
                    <div class="col-3">Total Commission: <b>&#8377;{{salesrepCommissionsArray?.salesrepCommissionsSummary?.totalCommission?.toLocaleString('en-IN')}}</b></div>
                    <div class="col-3">Commission Earned: <b>&#8377;{{salesrepCommissionsArray?.salesrepCommissionsSummary?.commissionEarned?.toLocaleString('en-IN')}}</b></div>
                    <div class="col-3">Commission Paid: <b>&#8377;{{salesrepCommissionsArray?.salesrepCommissionsSummary?.commissionPaid?.toLocaleString('en-IN')}}</b></div>
                    <div class="col-3">Pending Payment: <b>&#8377;{{salesrepCommissionsArray?.salesrepCommissionsSummary?.commissionPending?.toLocaleString('en-IN')}}</b></div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div class="my-3">
    <div class="card" *ngIf="salesrepCommissionsArray?.salesrepCommissionsList?.length > 0">
        <div class="card-body">
            <div class="wrap">
                <table class="table table-striped table-bordered table-sm row-border hover">
                    <thead>
                        <tr>
                            <th>Salesrep</th>
                            <th>Venture Name</th>
                            <th>Plot Number</th>
                            <th>Plot Status</th>
                            <th>Total Commission (<i class="fas fa-rupee-sign"></i>)</th>
                            <th>Commission Earned (<i class="fas fa-rupee-sign"></i>)</th>
                            <th *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE] ">
                                Commission Paid (<i class="fas fa-rupee-sign"></i>)
                            </th>
                            <th *ngxPermissionsOnly="[userPrivileges.SALESREP_PRIVILEGE]">Commission Received (<i class="fas fa-rupee-sign"></i>)</th>
                            <th>Commission Pending (<i class="fas fa-rupee-sign"></i>)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let sale of salesrepCommissionsArray?.salesrepCommissionsList">
                            <td>{{sale.salesrepName}}</td>
                            <td>{{sale.ventureName}}</td>
                            <td>{{sale.plotNumber}}</td>
                            <td>{{sale.plotStatus | titlecase}}</td>
                            <td>{{sale.totalCommission?.toLocaleString('en-IN')}}</td>
                            <td>{{sale.commissionEarned?.toLocaleString('en-IN')}}</td>
                            <td>{{sale.commissionPaid?.toLocaleString('en-IN')}}</td>
                            <td>{{sale.commissionPending?.toLocaleString('en-IN')}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>