<div class=" my-3" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.TENANT_PRIVILEGE,userPrivileges.TENANT_BASIC_PRIVILEGE,userPrivileges.MARKETER_BASIC_PRIVILEGE]">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <h6 class="card-title text-dark position-relative pb-2 h6">Venture Layout</h6>
                </div>
            </div>
            <div class="card shadow  mb-3">
                <div class="card-body px-3 pb-4 pt-2">
                    <div *ngxPermissionsOnly="[userPrivileges.TENANT_PRIVILEGE]">
                        <div class="card mt-3" *ngIf="showUploadVenture" >
                            <div class="card-body">
                                <h6 class="card-title text-dark position-relative pb-2 mb-3">
                                   Upload File
                                </h6>
                                <div class="col-md-6">
                                    <div  >
                                        <p>Browse Files</p>
                                        <div class="d-flex justify-content-between">
                                            <input type="file" (change)="onLayoutFileSelected($event)" [(ngModel)]="layoutFile">
                                            <button (click)="uploadLayoutFile()" class="btn btn-info btn-sm" >Upload</button>        
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!showUploadVenture">
                        <img [src]="layoutUrl" alt="logo" class="img-fluid">
                    </div>                    
                </div>
            </div>

        </div>

    </div>

</div>