import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'ng2-charts';
import { Color, Label, ThemeService } from 'ng2-charts';
//import * as Highcharts from 'highcharts';
import * as Highcharts from 'highcharts/highstock';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { HighchartsService } from 'src/app/services/highcharts.service';


@Component({
  selector: 'app-marketerchart',
  templateUrl: './marketerchart.component.html',
  styleUrls: ['./marketerchart.component.scss']
})
export class MarketerchartComponent implements OnInit, OnChanges, AfterViewInit {
  
  @Input() sales: any;

  name = 'hello';

  @ViewChild('charts', {static: false}) public chartEl: ElementRef;
  info:any = [];

//highcharts2 = Highcharts;

  constructor(public themeService: ThemeService, 
    public authService: AuthServiceService,
    private highcharts: HighchartsService
    ) { }

    ngOnChanges() {
      if (this.sales) {
        this.myOptions.series =[
          {data: this.sales.map(a => parseInt(a.total)), name: 'TotalPlots'},
          {data: this.sales.map(a => parseInt(a.available)), name: 'AvailablePlots'},
          {data: this.sales.map(a => parseInt(a.booked)), name: 'BookedPlots'},
          {data: this.sales.map(a => parseInt(a.registered)), name: 'RegisteredPlots'},
        ];
        this.myOptions.xAxis.categories = this.sales.map(a => a.ventureName);
      }
      Highcharts.chart ( {// 'container',
        chart: {
          type: 'bar'
        },
        title: {
          text: 'Venzr Ventures'
        },
        subtitle: {
          text: ''
        },
        
        xAxis: {
          categories: 
          this.sales.map(a => a.ventureName),
          // [
          //   'SVG',
          //   'Vasavi'
          // ],
          crosshair: true
        },
        yAxis: {
          min: 0,
          title: {
            text: ''
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        credits: { enabled: false },
        series: 
        [
          // {data: this.sales.map(a => parseInt(a.total)), name: 'TotalPlots'},
          // {data: this.sales.map(a => parseInt(a.available)), name: 'AvailablePlots'},
          // {data: this.sales.map(a => parseInt(a.booked)), name: 'BookedPlots'},
          // {data: this.sales.map(a => parseInt(a.registered)), name: 'RegisteredPlots'},
        ]
        // [{
        //   name: 'Total Plots',
        //   data: [49.9, 71.5]
       
        // }, {
        //   name: 'Booked',
        //   data: [83.6, 78.8]
       
        // }, {
        //   name: 'Available',
        //   data: [48.9, 38.8]
       
        // }, {
        //   name: 'Sold',
        //   data: [42.4, 33.2]
       
        // }]
      });

    }

    ngOnInit() { 
        
    }    

    myOptions = {
      chart: {
        type: 'bar'
      },
      title: {
        text: 'Stacked bar chart'
      },
      xAxis: {
        categories: []
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Total plots consumption'
        }
      },
      credits: { enabled: false },
      legend: {
        reversed: true
      },
      plotOptions: {
        series: {
          stacking: 'normal'
        }
      },
      series: []
      
    };

    ngAfterViewInit() {
     this.highcharts.createChart(this.chartEl.nativeElement, this.info);
    }

  // public barChartOptions: ChartOptions = {
  //   responsive: true,
  //   scales: {
  //     xAxes: [{}], yAxes: [{
  //       ticks: {
  //       min : 0
  //       }
  //     }]
  //   },
  //   plugins: {
  //     datalabels: {
  //       anchor: 'end',
  //       align: 'end',
  //     }
  //   }
  // };

  // public barChartLabels: string[];
  
  // public barChartType: ChartType = 'bar';
  // public barChartLegend = true;
  // public barChartPlugins = [pluginDataLabels];

  // public barChartData : any[]; 


  
}

