import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'ng2-charts';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { ThemeService } from 'src/app/services/theme.service';
import { RestService } from 'src/app/services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RoutesConstants } from 'src/app/constants/RoutesConstants';

@Component({
  selector: 'app-marketerdashboard',
  templateUrl: './marketerdashboard.component.html',
  styleUrls: ['./marketerdashboard.component.scss']
})
export class MarketerdashboardComponent implements OnInit {
  @Output()
  displayEvent: EventEmitter<any> = new EventEmitter<any>();
  ventureSelected: any;
  sales: any = [];
  saleteam: any = [];
  revenue: any = [];
  marketer: any = [];
  venture: any = { displayName: "" };

  constructor(
    private restService: RestService,
    public authService: AuthServiceService,
    public themeService: ThemeService,
    private router: Router,
    private route: ActivatedRoute,
    public commonService: CommonServiceService
  ) { }


  launchDashboard() {
    this.router.navigate([RoutesConstants.VENTURELAYOUT, RoutesConstants.VENTUREDASHBOARD],
      { queryParams: { 'venture': this.ventureSelected.ventureId } });
  }

  selectVenture(venture: any) {
    this.ventureSelected = venture;
    this.commonService.setVenture(venture);

  }

  ngOnInit(): void {
    this.getDashboard();
    const path: string = 'marketer/getMarketerById';
    const userInfo: any = this.authService.getUserInfo();
    const data: any = {
      tenantId: userInfo.tenantId,
      marketerId: userInfo.marketerId
    };
    this.restService.submitPostRequest(path, data).subscribe(
      (response: any) => {
        if (response) {
          this.marketer = response;
          this.displayEvent.emit(this.marketer.displayName);
          this.commonService.setDisplayName(this.marketer.displayName);
        }
      });

  }


  getDashboard() {
    const path: string = 'marketer/marketerDashboard';
    const userInfo: any = this.authService.getUserInfo();
    const data: any = {
      tenantId: userInfo.tenantId,
      marketerId: userInfo.marketerId
    };
    this.restService.submitPostRequest(path, data).subscribe(
      (response: any) => {
        if (response) {
          this.sales = response.data;
          this.saleteam = this.sales.lablePlotlist;
          this.revenue = this.sales.marketerRevenue;
        }
      }, (error: any) => {
        // TODO error handling
      });
  }

  plots(top) {
    this.commonService.setVenture({ ventureId: top.ventureId, displayName: top.ventureName });
    this.router.navigate(['/'+this.commonService.getUrlSuffix(), RoutesConstants.VENTURELAYOUT, RoutesConstants.PLOTS], { queryParams: { 'ventureId': top.ventureId } });


  }
  sale(top) {
    this.commonService.setVenture({ ventureId: top.ventureId, displayName: top.ventureName });
    this.router.navigate(['/'+this.commonService.getUrlSuffix(), RoutesConstants.VENTURELAYOUT, RoutesConstants.VENTURESALES], { queryParams: { 'ventureId': top.ventureId } });
  }

  commissions(top) {
    this.commonService.setVenture({ ventureId: top.ventureId, displayName: top.ventureName });
    this.router.navigate(['/'+this.commonService.getUrlSuffix(), RoutesConstants.VENTURELAYOUT, RoutesConstants.COMMISSIONDETAILS], { queryParams: { 'ventureId': top.ventureId } });
  }
  onDisply(row) {
    this.router.navigate(['../alldetails', row.salesrepId], { relativeTo: this.route });
  }
}



