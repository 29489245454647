import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { ThemeService } from 'src/app/services/theme.service';
import { User } from 'src/app/models/user.model';
import { CommonServiceService } from 'src/app/services/common-service.service';

@Component({
  selector: 'app-venture-menu',
  templateUrl: './venture-menu.component.html',
  styleUrls: ['./venture-menu.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({ marginLeft: '0' })),
      state('false', style({ marginLeft: '-20rem' })),
      transition('false <=> true', animate(500))
    ])
  ],
})
export class VentureMenuComponent {

  status= true;
  profileSub = false;
  menutoggled = true;
  showVenture: boolean = true;
  userInfo: User = new User();
  
  constructor(
    public themeService: ThemeService,
    public commonService: CommonServiceService,
    public authService: AuthServiceService
  ) {
    this.userInfo = this.authService.getUserInfo();
  }

  menuToggle(){
    this.status = !this.status;       
  }

  toggleNavigation(){
    this.menutoggled = !this.menutoggled;
  }

  profileSubmenu(){
    this.profileSub = !this.profileSub;   
  }
  
}