<div class="my-3" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.TENANT_PRIVILEGE,userPrivileges.TENANT_BASIC_PRIVILEGE,userPrivileges.MARKETER_BASIC_PRIVILEGE]">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12 mb-2 text-right">
                    <button class="btn btn-sm mr-2 btn-info" *ngIf="!showCancelled" (click)="exportActiveSales()">Export to Excel</button>
                    <button class="btn btn-sm mr-2 btn-info" *ngIf="showCancelled" (click)="exportCancelledSales()">Export to Excel</button>
                    <button class="btn btn-sm btn-info" (click)="addSale()" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.ADD_SALE]">Add New Sale</button>
                </div>
            </div>
            <div class="row p-2 mx-0 mb-2 ">
                <form class="form-inline">
                    <div class="form-check px-3">
                        <label class="form-check-label">
                            <input type="radio" class="form-check-input" id="radio1" name="optradio" [checked]="true" (click)="fetchActiveSales()"> Active
                        </label>
                    </div>
                    <div class="form-check px-3">
                        <label class="form-check-label">
                            <input type="radio" class="form-check-input" (click)="fetchCancelledSales()" id="radio5" name="optradio"> Cancelled
                        </label>
                    </div>
                </form>
            </div>

            <ng-template #content let-modal>
                <div class="modal-header">
                    <h6 class="modal-title" id="modal-basic-title">Update Status</h6>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form #details="ngForm">
                        <div class="row">
                            <div class="form-group col-6">
                                <label class="star">Plot No</label>
                                <select  type="text" class="form-control" id="plotNumber" required disabled [(ngModel)]="this.plotdetails.plotNumber" name="plotNumber" #plotNumber="ngModel">
                                    <option value="{{this.plotdetails.plotNumber}}">{{this.plotdetails.plotNumber}}</option>
                                </select>
                            </div>
                            <div class="form-group col-6">
                                <label class="star">Customer</label>
                                <select  type="text" class="form-control" id="customerName" required disabled  [(ngModel)]="this.plotdetails.customerName" name="customerName" #customerName="ngModel">
                                    <option value="{{this.plotdetails.customerName}}">{{this.plotdetails.customerName}}</option>
                                </select>
                            </div>
                            <div class="form-group col-6" [ngClass]="{'invalid': plotStatus.invalid && (plotStatus.dirty || plotStatus.touched)}">
                                <label class="star">Plot Status</label>
                                <select type="text" class="form-control" id="plotStatus" required ngModel name="plotStatus" #plotStatus="ngModel">
                                    <option value="Registered">Registered</option>
                                </select>
                            </div>
                            <div class="form-group col-6">
                                <label class="star">Registration Number</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" id="registrationNum" required name="registrationNum" ngModel>
                                </div>
                            </div>
                            <div class="form-group col-6" [ngClass]="{'invalid': registrationDate.invalid && (registrationDate.dirty || registrationDate.touched)}">
                                <label class="star">Registration Date</label>
                                <input type="date" class="form-control" id="registrationDate" required ngModel name="registrationDate" #registrationDate="ngModel">
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-dark" [disabled]=details.invalid (click)="modal.close('Save click'); onSubmit(details.value);">Save</button>
                        </div>
                    </form>
                </div>
            </ng-template>

            <div *ngIf="!showCancelled" class="wrap" id="abcd">
                <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th>Plot #</th>
                            <th>Customer</th>
                            <th>Salesrep</th>
                            <th>Plot Status</th>
                            <th>Per {{commonService.getMeasure()}} Price (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                            <th>Sale Value (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                            <th>Received Amount (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                            <th>Balance Amount (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                            <th>Date</th>
                            <th *ngxPermissionsOnly="[userPrivileges.TENANT_PRIVILEGE,userPrivileges.TENANT_BASIC_PRIVILEGE]">Registered By</th>
                            <th *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.MARKETER_BASIC_PRIVILEGE]">Sold By</th>
                            <!-- <th>Lead</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="pointer" *ngFor="let sales of ventureSales; let i= index" (click)="editSale(sales)">
                            <td>{{sales.plotNumber}}</td>
                            <td>{{capitalFirst(sales.customerName)}}</td>
                            <td>{{capitalFirst(sales.name)}}</td>
                            <td>
                                <ng-template #popContent><a (click)="open(content, sales)">Change Status</a>
                                </ng-template>
                                <div type="button" [disabled]="popup" *ngIf="sales.plotStatus.toLowerCase() == 'sold' && popup == false" class="btn p-0 m-0" [ngbPopover]="popContent" style="font-size: 12px;">
                                    {{sales.plotStatus | titlecase}}
                                </div>
                                <div type="button" *ngIf="sales.plotStatus.toLowerCase() != 'sold' || popup == true " class="btn p-0 m-0" style="font-size: 12px;">
                                    {{sales.plotStatus | titlecase}}
                                </div>
                            </td>
                            <td>{{sales.perMeasurePrice.toLocaleString('en-IN')}}</td>
                            <td>{{sales.saleValue.toLocaleString('en-IN')}}</td>
                            <td>{{sales.amountReceived.toLocaleString('en-IN')}}</td>
                            <td>{{(sales.saleValue-sales.amountReceived).toLocaleString('en-IN')}}</td>
                            <td>{{sales.saleDate | date}}</td>
                            <td *ngxPermissionsOnly="[userPrivileges.TENANT_PRIVILEGE,userPrivileges.TENANT_BASIC_PRIVILEGE]">{{sales.registeredBy}}</td>
                            <td *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.MARKETER_BASIC_PRIVILEGE]">{{sales.soldBy}}</td>
                            <!-- <td>{{sales.leadName}}</td> -->
                        </tr>
                    </tbody>
                </table>
            </div>

            <div *ngIf="showCancelled" class="wrap">
                <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th>Plot #</th>   
                            <th>Customer</th>                         
                            <th>Salesrep</th>
                            <th>Per {{commonService.getMeasure()}} Price (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                            <th>Sale Value (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                            <th>Received Amount (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                            <th>Balance Amount (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                            <th>Sale Date</th>
                            <th>Cancelled Date</th>
                            <th *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.MARKETER_BASIC_PRIVILEGE]">Cancelled By</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="pointer" *ngFor="let sales of cancelledSales; let i= index" (click)="editSale(sales, 'cancelled')">
                            <td>{{sales.plotNumber}}</td>
                            <td>{{capitalFirst(sales.customerName)}}</td>
                            <td>{{capitalFirst(sales.salesrepName)}}</td>
                            <td>{{sales.perMeasurePrice.toLocaleString('en-IN')}}</td>
                            <td>{{sales.saleValue.toLocaleString('en-IN')}}</td>
                            <td>{{sales.saleAmountReceived.toLocaleString('en-IN')}}</td>
                            <td>{{(sales.saleValue-sales.saleAmountReceived).toLocaleString('en-IN')}}</td>
                            <td>{{sales.saleDate | date}}</td>
                            <td>{{sales.cancelDate | date}}</td>
                            <td *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.MARKETER_BASIC_PRIVILEGE]">{{sales.cancelledBy}}</td>
                            
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>