<form #details="ngForm" (ngSubmit)="onsubmit(details.value)">
    <div class="row mt-2">
        <div class="form-group col-md-6"
            [ngClass]="{'invalid': (ventureName.invalid && (ventureName.dirty || ventureName.touched))}">
            <label class="star">Venture Name</label>
            <input type="text" class="form-control" id="ventureName" required name="ventureName" maxlength="100"
                #ventureName="ngModel" ngModel>
            <div class="text-danger" *ngIf="ventureName.dirty && ventureName.invalid">Max length should be 100
                charecters</div>
        </div>

        <div class="form-group col-md-6"
            [ngClass]="{'invalid': (displayName.invalid && (displayName.dirty || displayName.touched))}">
            <label class="star">Display Name</label>
            <input type="text" class="form-control" id="displayName" name="displayName" #displayName="ngModel"
                maxlength="100" required ngModel>
        </div>
        <div class="form-group col-md-6"
            [ngClass]="{'invalid': (ventureType.invalid && (ventureType.dirty || ventureType.touched))}">
            <label class="star">Venture Type</label>
            <select type="text" class="form-control" id="ventureType" required #ventureType="ngModel" name="ventureType"
                ngModel>
                <option value="Open Plots">Open Plots</option>
                <option value="Plantation">Plantation</option>
                <option value="Farm Houses">Farm Houses</option>
            </select>
        </div>
        <div class="form-group col-md-6" [ngClass]="{'invalid': (size.invalid && (size.dirty || size.touched))}">
            <label class="star">Size</label>
            <input type="text" class="form-control" id="size" name="size" #size="ngModel" pattern="[0-9.]+"
                maxlength="6" required ngModel>
            <div class="text-danger" *ngIf="size.invalid && size.dirty">Size should be in number</div>
        </div>

        <div class="form-group col-md-6"
            [ngClass]="{'invalid': (numOfPlots.invalid && (numOfPlots.dirty || numOfPlots.touched))}">
            <label class="star">Plots</label>
            <input type="number" class="form-control" id="numOfPlots" name="numOfPlots" #numOfPlots="ngModel" required
                ngModel>
        </div>

        <div class="form-group col-md-6"
            [ngClass]="{'invalid': (locationAddress.invalid && (locationAddress.dirty || locationAddress.touched))}">
            <label class="star">Address</label>
            <input type="text" class="form-control" id="locationAddress" name="locationAddress"
                #locationAddress="ngModel" required ngModel>
        </div>

        <div class="form-group col-md-6"
            [ngClass]="{'invalid': (surveyNum.invalid && (surveyNum.dirty || surveyNum.touched))}">
            <label class="star">Survey Numbers</label>
            <input type="text" class="form-control" id="surveyNum" name="surveyNum" #surveyNum="ngModel" required
                ngModel>
        </div>
        <div class="form-group col-md-6"
            [ngClass]="{'invalid': (ventureStatus.invalid && (ventureStatus.dirty || ventureStatus.touched))}">
            <label class="star">Status</label>
            <select type="text" class="form-control" name="ventureStatus" id="ventureStatus" required
                #ventureStatus="ngModel" ngModel>
                <option value="open">Open</option>
                <option value="closed">Closed</option>
            </select>
        </div>
        <div class="form-group col-md-6"
            [ngClass]="{'invalid': (startDate.invalid && (startDate.dirty || startDate.touched))}">
            <label class="star">Start Date</label>
            <input type="date" class="form-control" id="startDate" required name="startDate" #startDate="ngModel"
                ngModel max="9999-12-31">
        </div>
        <div class="form-group col-md-6">
            <label class="star">End Date</label>
            <input type="date" class="form-control" id="endDate" name="endDate" ngModel>
        </div>
        <button type="submit" class="btn btn-info" [disabled]=details.invalid>Submit</button>
    </div>
</form>