<div *ngIf="alert" class="alert alert-success alert-dismissible fade show" role="alert">
    <strong>Success !</strong> You payment details have been saved successfully!
    <button type="button" (click)="closeAlert()" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<form (ngSubmit)="onSubmit(payment)" #myPayment="ngForm">
    <div class="row mt-2">
        <div class="form-group col-6" [ngClass]="{'invalid': amount.invalid && (amount.dirty || amount.touched || myPayment.submitted)}">
            <label class="star">Amount Received </label>
            <input type="text" class="form-control" id="amount" required [(ngModel)]="payment.amount" name="amount" #amount="ngModel">
        </div>

        <div class="form-group col-6" [ngClass]="{'invalid': paidFor.invalid && (paidFor.dirty || paidFor.touched || myPayment.submitted)}">
            <label class="star">Paid For</label>
            <input type="text" list="customers" class="form-control" id="paidFor" name="paidFor" required [(ngModel)]="payment.paidFor" (change)="paidEvent($event)" #paidFor="ngModel">
            <datalist id="customers">
                <option *ngFor="let c of Customers" [value]="c.name">{{c.name}}</option>
            </datalist>
        </div>

        <div class="form-group col-6" [ngClass]="{'invalid': paidBy.invalid && (paidBy.dirty || paidBy.touched || myPayment.submitted)}">
            <label class="star">Paid By</label>
            <input type="text" list="customers" class="form-control" id="paidBy" name="paidBy" required [(ngModel)]="payment.paidBy" (change)="paidEvent($event)" #paidBy="ngModel">
            <datalist id="customers">
                <option *ngFor="let c of Customers" [value]="c.name">{{c.name}}</option>
            </datalist>
        </div>

        <div class="form-group col-6">
            <label for="gender">Payment Mode</label>
            <select class="form-control" id="paymentMode" required [(ngModel)]="payment.paymentMode" name="paymentMode" #paymentMode="ngModel">
                <option *ngFor="let pay of payments" [value]="pay">
                    {{pay}}
                </option>
            </select>
        </div>

        <div class="form-group col-6" [ngClass]="{'invalid': receivedBy.invalid && (receivedBy.dirty || receivedBy.touched || myPayment.submitted)}">
            <label class="star">Received By</label>
            <input type="text" list="salesrep" class="form-control" id="receivedBy" name="receivedBy" required [(ngModel)]="payment.receivedBy" (change)="receivedEvent($event)" #receivedBy="ngModel">
            <datalist id="salesrep">
                <option *ngFor="let s of salesRep" [value]="s.name">{{s.name}}</option>
            </datalist>
        </div>

        <div class="form-group col-6" [ngClass]="{'invalid': comments.invalid && (comments.dirty || comments.touched || myPayment.submitted)}">
            <label class="star">Comments</label>
            <input type="text" class="form-control" id="comments" required [(ngModel)]="payment.comments" name="comments" #comments="ngModel">
        </div>
    </div>
</form>