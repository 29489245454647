import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as pluginDataLabels from 'ng2-charts';
import { ChartOptions, ChartType } from "chart.js";
import { RoutesConstants } from "src/app/constants/RoutesConstants";
import { ThemeService } from "ng2-charts";
import { AuthServiceService } from "src/app/services/auth-service.service";
import { CommonServiceService } from "src/app/services/common-service.service";
import { RestService } from "src/app/services/rest.service";

@Component({
  selector: "app-maindashboard",
  templateUrl: "./maindashboard.component.html",
  styleUrls: ["./maindashboard.component.scss"],
})
export class MaindashboardComponent implements OnInit {
  dashpathstatus = 85;
  classTab: any = [];
  setAdmin: boolean;
  ventureSelected: any;
  sales: any = [];
  saleteam: any = [];
  ventureLength: any;
  revenue: any = [];

  constructor(
    private restService: RestService,
    public authService: AuthServiceService,
    public themeService: ThemeService,
    public router: Router,
    public commonService: CommonServiceService,
    public route: ActivatedRoute
  ) { }

  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  public pieChartLabels: string[] = [];
  public pieChartData: number[];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
    },
  ];

  ngOnInit() {
    const path: string = 'tenant/tenantDashboard';
    const userInfo: any = this.authService.getUserInfo();
    const data: any = {
      tenantId: userInfo.tenantId,
    };
    this.restService.submitPostRequest(path, data).subscribe(
      (response: any) => {
        if (response) {
          this.sales = response.data;
          this.saleteam = this.sales.lablePlotlist;
          this.revenue = this.sales.marketerRevenue;
        }
      }, (error: any) => {
        // TODO error handling
      });
  }
  plots(top) {
    this.commonService.setVenture({ ventureId: top.ventureId, displayName: top.ventureName });
    this.router.navigate(['/'+this.commonService.getUrlSuffix(), RoutesConstants.VENTURELAYOUT, RoutesConstants.PLOTS], { queryParams: { 'ventureId': top.ventureId } });
  }
  sale(top) {
    this.commonService.setVenture({ ventureId: top.ventureId, displayName: top.ventureName });
    this.router.navigate(['/'+this.commonService.getUrlSuffix(), RoutesConstants.VENTURELAYOUT, RoutesConstants.VENTURESALES], { queryParams: { 'ventureId': top.ventureId } });
  }
  commissions(top) {
    this.commonService.setVenture({ ventureId: top.ventureId, displayName: top.ventureName });
    this.router.navigate(['/'+this.commonService.getUrlSuffix(), RoutesConstants.VENTURELAYOUT, RoutesConstants.COMMISSIONDETAILS], { queryParams: { 'ventureId': top.ventureId } });
  }
  onDisply(row) {
    this.router.navigate(['../alldetails', row.salesrepId], { relativeTo: this.route });
  }
  setLaunchedVenture(venture) {
    this.commonService.setVenture({ ventureId: venture.ventureId, displayName: venture.ventureName });
  }
}
