<div class="card mt-3">
  <div class="card-body">
    <div class="wrap">
    <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions"
      [dtTrigger]="dtTrigger">
      <thead>
        <tr>
          <th>Venture Name</th>
          <th>Payment Date</th>
          <th>Amount Received (<i class="fas fa-rupee-sign mr-1"></i>)</th>
          <th>Payment Mode</th>
          <!-- <th>Plot Number</th> -->
          <th>Description</th>
          <th>Last Updated By</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let payment of (PaymentsArray.myPayments ? PaymentsArray.myPayments : [])">
          <td>{{payment.ventureName}}</td>
          <td>{{payment.date | date}}</td>
          <td>{{payment.amountPaid.toLocaleString('en-IN')}}</td>
          <td>{{payment.mode}}</td>
          <!-- <td>{{payment.plotNum}}</td> -->
          <td>{{payment.description}}</td>
          <td>{{payment.lastUpdatedBy}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  </div>
</div>