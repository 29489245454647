import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UserRoleConstants } from 'src/app/constants/AppConstants';
import { RoutesConstants } from 'src/app/constants/RoutesConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetForm: FormGroup;
  forgotPasswordForm: FormGroup;
  userdata: any;
  submitted = false;
  resetSubmitted = false;
  errorMessage: any;
  error: boolean;
  isResetForm = false;
  isPasswordChanged=false;

  constructor(
    public authService: AuthServiceService,
    private formBuilder: FormBuilder,
    public commonService: CommonServiceService,
    public restService: RestService,
    private router: Router
  ) { }

  ngOnInit() {
    this.resetForm = this.formBuilder.group({
      otp: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
    this.forgotPasswordForm = this.formBuilder.group({
      username: ['', Validators.required]
    });
  }

    get r() {
      return this.resetForm.controls;
    }

    get f() {
      return this.forgotPasswordForm.controls;
    }

    onSubmitForgotPassword(isValid:boolean) {
      this.submitted = true;
      sessionStorage.clear();
      if(isValid){
      let data = this.forgotPasswordForm.get('username').value;
      this.authService
			.requestOtp(data)
			.pipe(
				tap((response) => {
          if(response.message == 'Request processed successfully') {
            this.isResetForm = true;
          }
				}),
				catchError((error) => {
					this.error = true;
					this.errorMessage = error.error.message;
					return of(error);
				})
			)
			.subscribe();
      }
    }

    onSubmit(isValid:boolean) {
      this.resetSubmitted = true;

      sessionStorage.clear();

      if(isValid){
      let data = {
        otp: this.resetForm.get('otp').value,
        newPassword: this.resetForm.get('newPassword').value
      }

      let uId = this.forgotPasswordForm.get('username').value;
  
      this.authService
			.resetPassword(uId, data)
			.pipe(
				tap((response) => {
					this.isPasswordChanged = true;
				}),
				catchError((error) => {
					this.error = true;
					this.errorMessage = error.error.message;
					return of(error);
				})
			)
			.subscribe();
      }
    }

    onResendOtp() {
      this.submitted = true;
      sessionStorage.clear();
      if(this.forgotPasswordForm.get('username').value) {
      let data = this.forgotPasswordForm.get('username').value;
      this.authService
			.resendOtp(data)
			.pipe(
				tap((response) => {
          if(response.message == 'Request processed successfully') {
            this.isResetForm = true;
          }
				}),
				catchError((error) => {
					this.error = true;
					this.errorMessage = error.error.message;
					return of(error);
				})
			)
			.subscribe();
      }
    }
}