import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import{Location} from '@angular/common'
import { RestService } from 'src/app/services/rest.service';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { UserPrivileges } from 'src/app/constants/AppConstants';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {
  customer: any = [];
  error: boolean;
  errorMessage: any;
  customerId: any;
  address: any =[];
  userPrivileges = UserPrivileges;
  isEdit: boolean = false;
  isVCPage = false;
  genders: any = ['Male', 'Female', 'Others', 'Prefer not to say'];
  @ViewChild('successMsg') successMsg: ElementRef<HTMLElement>;
  closeResult: string;
  message: any;

  constructor(private restService: RestService,
     private activatedRouter: ActivatedRoute,
     private authService: AuthServiceService,
      private location: Location,
      private modalService: NgbModal) {
        // venture
        this.activatedRouter.data.subscribe(data => {
          if(data?.pageType == "venture") {
            this.isVCPage = true;
          }
      })
        this.activatedRouter.params.subscribe(params => {
          this.customerId =params ['customerid']
        }) 
       }

  ngOnInit() {
    this.getCustomerData();
  }

  getCustomerData() {
    let data = {
      "tenantId": this.authService.getUserInfo().tenantId,
      "marketerId": this.authService.getUserInfo().marketerId,
      "personId": this.customerId,
    }
    this.restService.getVentureCustomer(data).subscribe(response => {
      if (data != null) {
        this.customer = response.data;
        this.address =response.data.primaryAddress;
      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      })
  }

  sendBack() {
    this.location.back();
  }

  editCustomer() {
    this.isEdit = true;
  }

  onSubmit(customer: any) {
    this.restService.updateCustomerData(this.customer).subscribe(response => {
      if(response.message === 'Request processed successfully') {
        this.message = "Details updated successfully";
        this.open(customer);
        this.isEdit = false;
      }
    },
    (error) => {
      this.error = true;
      this.errorMessage = error.status;
      if(error.error.message == "Customer update not permitted: Multiple sales linked to this customer.") {
        this.message = error.error.message;
        this.open(customer);
        this.isEdit = false;
      }
    });
  }

  open(content) {
		this.modalService.open(this.successMsg, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
		  this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
		  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

  closeDialog() {
    this.modalService.dismissAll('Cross click');
	}

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
