import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { UserPrivileges, UserRoleConstants } from 'src/app/constants/AppConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-direct-commission',
  templateUrl: './direct-commission.component.html',
  styleUrls: ['./direct-commission.component.scss']
})
export class DirectCommissionComponent implements OnInit {
  @Input() id;
  @Input() sales;
  directComm: any = [];
  error: boolean;
  errorMessage: any;
  userInfo: any;
  data: any;
  userPrivileges = UserPrivileges;

  constructor(private restService: RestService,
    private commonService: CommonServiceService,
    private router: Router,
    private authService: AuthServiceService,
    private route: ActivatedRoute) { }
  title = 'angulardatatables';
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      searching: false
    };
    if (this.sales) {
      this.getData();
    }
    else {
      this.onDisplay();
    }
  }
  onDisplay() {
    let directUrl = 'generic/salesrep/commissions?salesrepId=' + this.userInfo.salesrepId + '&type=direct';
    this.restService.getSales(directUrl).subscribe(response => {
      if (response.data != null && response.data != undefined) {
        this.directComm = response.data;
        this.dtTrigger.next();
      }
    },
    (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });
  }
  getData() {
    let directUrl;
    if (this.id) {
      directUrl = 'generic/salesrep/commissions?salesrepId=' + this.sales + '&ventureId=' + this.commonService.getVenture()?.ventureId + '&type=direct';
    } else {
      directUrl = 'generic/salesrep/commissions?salesrepId=' + this.sales + '&type=direct';
    }

    this.restService.getSales(directUrl).subscribe(response => {
      if (response.data != null && response.data != undefined) {
        this.directComm = response.data;
        this.dtTrigger.next();
      }
    },
    (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });
  }

  display(row) {
    //    this.router.navigate(['../salesrepplots', row.plotNum], { relativeTo: this.route });
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

}
