<div class="container-fluid my-3">
    <div class="card">
        <div class="card body p-4 ">
            <a class="position-absolute close-popup-link bg-white" (click)="sendBack()"><i class="fa fa-times"
                    aria-hidden="true"></i>
            </a>
            <div class="row mx-3">
                <div class="col-md-8">
                    <h6 class="card-title text-dark position-relative pb-2 h6 ">User Details</h6>
                </div>
                <div class="col-md-4">
                    <a class="text-right pointer" (click)="expand()" style="font-size: large; display: block;"><i
                            class="fas fa-expand-alt" aria-hidden="true"></i></a>
                </div>
            </div>
            <form #details="ngForm">
                <div class="row mx-3 mb-0">
                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (username.invalid && (username.dirty || username.touched))}">
                        <label class="star">User Name</label>
                        <input type="text" class="form-control" id="username" required #username="ngModel"
                            name="username" [(ngModel)]="userName" readonly>
                    </div>

                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (personType.invalid && (personType.dirty || personType.touched))}">
                        <label class="star">Person Type</label>
                        <input type="text" class="form-control" id="personType" required #personType="ngModel"
                            name="personType" [(ngModel)]="person.personType" readonly>
                    </div>
                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (firstName.invalid && (firstName.dirty || firstName.touched))}">
                        <label class="star">First Name</label>
                        <input type="text" class="form-control" id="firstName" required #firstName="ngModel"
                            name="firstName" [(ngModel)]="person.firstName" readonly>
                    </div>
                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (lastName.invalid && (lastName.dirty || lastName.touched))}">
                        <label class="star">Last Name</label>
                        <input type="text" class="form-control" id="lastName" required #lastName="ngModel"
                            name="lastName" [(ngModel)]="person.lastName" readonly>
                    </div>
                    <div class="form-group col-md-4" [ngClass]="{'invalid': (dob.invalid && (dob.dirty || dob.touched))}">
                        <label class="star">DOB</label>
                        <input type="date" class="form-control" id="dob" required #dob="ngModel" name="dob"
                            [(ngModel)]="person.dob" readonly>
                    </div>
                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (gender.invalid && (gender.dirty || gender.touched))}">
                        <label class="star">Gender</label>
                        <input type="text" class="form-control" id="gender" required #gender="ngModel" name="gender"
                            [(ngModel)]="person.gender" readonly>
                    </div>
                </div>
                <div class="row mt-0 pt-0 mx-3" [hidden]="display">
                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (bloodGroup.invalid && (bloodGroup.dirty || bloodGroup.touched))}">
                        <label class="star">Blood Group</label>
                        <input type="text" class="form-control" id="bloodGroup" required #bloodGroup="ngModel"
                            name="bloodGroup" [(ngModel)]="person.bloodGroup" readonly>
                    </div>
                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (nationality.invalid && (nationality.dirty || nationality.touched))}">
                        <label class="star">Nationality</label>
                        <input type="text" class="form-control" id="nationality" required #nationality="ngModel"
                            name="nationality" [(ngModel)]="person.nationality" readonly>
                    </div>
                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (panNum.invalid && (panNum.dirty || panNum.touched))}">
                        <label class="star">PAN Number</label>
                        <input type="text" class="form-control" id="panNum" required #panNum="ngModel" name="panNum"
                            [(ngModel)]="person.panNum" readonly>
                    </div>
                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (aadharNum.invalid && (aadharNum.dirty || aadharNum.touched))}">
                        <label class="star">Aadhar Number</label>
                        <input type="number" class="form-control" id="aadharNum" required #aadharNum="ngModel"
                            name="aadharNum" [(ngModel)]="person.aadharNum" readonly>
                    </div>

                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (contactNumber.invalid && (contactNumber.dirty || contactNumber.touched))}">
                        <label class="star">Contact Number</label>
                        <input type="tel" class="form-control" id="contactNumber" required #contactNumber="ngModel"
                            name="contactNumber" [(ngModel)]="person.contactNumber" readonly>
                    </div>
                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (alternateContact.invalid && (alternateContact.dirty || alternateContact.touched))}">
                        <label class="star">Alternate Contact Number</label>
                        <input type="tel" class="form-control" name="alternateContact" id="alternateContact" required
                            #alternateContact="ngModel" [(ngModel)]="person.alternateContact" readonly>
                    </div>
                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (email.invalid && (email.dirty || email.touched))}">
                        <label class="star">Email ID</label>
                        <input type="email" class="form-control" id="email" required name="email" #email="ngModel"
                            [(ngModel)]="person.email" readonly>
                    </div>
                    <div class="form-group col-md-4">
                        <label class="star">Alternate Email ID</label>
                        <input type="email" class="form-control" id="alternateEmail" name="alternateEmail"
                            [(ngModel)]="person.alternateEmail" readonly>
                    </div>
                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (facebookId.invalid && (facebookId.dirty || facebookId.touched))}">
                        <label class="star">Facebook Id</label>
                        <input type="text" class="form-control" id="facebookId" required name="facebookId"
                            #facebookId="ngModel" [(ngModel)]="person.facebookId" readonly>
                    </div>
                    <div class="form-group col-md-4">
                        <label>Comments</label>
                        <input type="text" class="form-control" id="comments" name="comments"
                            [(ngModel)]="person.comments" readonly>
                    </div>

                </div>
            </form>
            <ul class="list-unstyled ml-3 mb-0">
                <li class="form-group col-12">
                    <span class="d-flex"><input type="checkbox" class="form-control mr-2" name="checked"
                            [(ngModel)]="reset" value="checked">Reset Password</span>
                </li>
            </ul>
            <form #details="ngForm" (ngSubmit)="onSubmit(details.value)" *ngIf="reset == true">
                <div class="row m-3 ">
                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (newPassword.invalid && (newPassword.dirty || newPassword.touched))}">
                        <label class="star">New Password</label>
                        <input type="password" class="form-control" id="newPassword" required name="newPassword"
                            #newPassword="ngModel" ngModel>
                    </div>
                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (confirmPassword.invalid && (confirmPassword.value != newPassword.value) && (confirmPassword.dirty || confirmPassword.touched))}">
                        <label class="star">Confirm Password</label>
                        <input type="password" class="form-control" id="confirmPassword" required name="confirmPassword"
                            #confirmPassword="ngModel" ngModel>
                        <div class="text-danger"
                            *ngIf="confirmPassword.valid && confirmPassword.value != newPassword.value">password shold
                            match </div>
                    </div>
                    <div class="col-md-4 pt-2">
                        <button type="submit" class="btn btn-info m-3"
                            [disabled]="details.invalid || confirmPassword.value != newPassword.value">Reset</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>