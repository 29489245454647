export class User {
    id: any;
    age: number;
    email: string;
    firstName: string;
    lastName: string;
    personId: number;
    tenantId: number;
    marketerId: number;
    salesrepId: number;
    ventureId: number;
    username: string;
    accessToken: string;
    role: string;
    scopes: string[];
    resourceScopes: string[];
    lastLoggedIn: Date
    constructor() {
        this.id = 0;
        this.age = 0;
        this.email = '';
        this.firstName = '';
        this.lastName = '';
        this.personId = 0;
        this.tenantId = 0;
        this.marketerId = 0;
        this.salesrepId = 0;
        this.ventureId = 0;
        this.username = '';
        this.accessToken = '';
        this.role = '';
        this.scopes = [];
        this.resourceScopes = [];
        this.lastLoggedIn = null;
    }
}

export class UserKeys {

    personId: number;
    tenantId: number;
    marketerId: number;
    salesrepId: number;
    ventureId: number;

    constructor() {
        this.personId = 0;
        this.tenantId = 0;
        this.marketerId = 0;
        this.salesrepId = 0;
        this.ventureId = 0;
    }
}