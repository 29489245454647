import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesConstants } from 'src/app/constants/RoutesConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-no-permission-marketer',
  templateUrl: './no-permission-marketer.component.html',
  styleUrls: ['./no-permission-marketer.component.scss']
})
export class NoPermissionMarketerComponent implements OnInit {

  setAdmin: boolean;
  status = true;
  closeResult: string;
  constructor(
    public themeService: ThemeService,
    public authService: AuthServiceService,
    public router: Router
  ) { }

  ngOnInit(): void {
  }

  menuToggle() {
    this.themeService.menuToggle();
  }

  ngAfterContentInit() {
    this.themeService.getColorCookie();
  }

  ngAfterViewInit() {
    if (this.themeService.getThemeCookie()) {
      this.themeService.toggleField()
    }
  }
  closeAlert() {
    this.router.navigate([RoutesConstants.MARKETERLAYOUT, RoutesConstants.MARKETERDASHBOARD]);
  }
}
