import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { RoutesConstants } from 'src/app/constants/RoutesConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { UserPrivileges, UserRoleConstants } from 'src/app/constants/AppConstants';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'underscore';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgForm } from '@angular/forms';
import { usersForPlotBlockingDetails } from 'src/app/models/plots-details';

@Component({
  selector: 'app-plots',
  templateUrl: './plots.component.html',
  styleUrls: ['./plots.component.scss']
})
export class PlotsComponent implements OnInit, OnChanges {
  @Input() plotinfo: any;
  plots: any = [];
  error: boolean;
  errorMessage: any;
  role: any;
  mode: string;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  userInfo: any;
  ventureId: any;
  userPrivileges = UserPrivileges;
  plotDetails: any;
  roadFacings:any = [];
  plotTypes:any = [];
  plotStatuses:any = [];
  selectedRoadFacing: string = 'all';
  selectedPlotType: string = 'all';
  selectedPlotStatus: string = 'all';
  searchText: string = '';
  closeResult = '';
  blockingPlot: any;
  blockingComments: string = '';
  usersForPlotBlocking: any = [];
  usersForPlotBlockingDetails: usersForPlotBlockingDetails;
  keyword = 'name';

  constructor(
    private restService: RestService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthServiceService,
    public commonService: CommonServiceService,
    private modalService: NgbModal,
    private permissionsService: NgxPermissionsService
    ) { }

  ngOnInit() {

    this.userInfo = this.authService.getUserInfo();
    this.ventureId = this.commonService.getVenture()?.ventureId;

    if(this.userInfo?.role != "SalesRep"){
      this.getPlots();
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      bSort: false,
    };

    localStorage.getItem(this.role);
    this.mode = localStorage.getItem('role');
  }
  getPlots() {
    let data = {
      "tenantId": this.userInfo?.tenantId,
      "ventureId": this.ventureId
    }
  this.restService.getPlots(data).subscribe(response => {
      this.plots = response.data;
      this.plots.sort((a, b) => {
        if(!isNaN(a.plotNum)) {
          a.plotNum = +(a.plotNum);
        }
        if(!isNaN(b.plotNum)) {
          b.plotNum = +(b.plotNum);
        }
        if(typeof a.plotNum === "number" && typeof b.plotNum === "number") {
          return a.plotNum-b.plotNum;
        } else if(typeof a.plotNum === "string" && typeof b.plotNum === "number") {
          let tempA: any = a.plotNum;
          let x = a.plotNum.split('-',1);
          a.plotNum = tempA;
          return parseInt(x[0])-b.plotNum;          
        } else if(typeof a.plotNum === "number" && typeof b.plotNum === "string") {
          let tempB: any = b.plotNum;
          let y = b.plotNum.split('-',1);
          b.plotNum = tempB;
          return a.plotNum-parseInt(y[0]);
        } else if(typeof a.plotNum === "string" && typeof b.plotNum === "string") {
          let tempA: any = a.plotNum;
          let tempB: any = b.plotNum;
          let x = a.plotNum.split('-',1);
          let y = b.plotNum.split('-',1);
          a.plotNum = tempA;
          b.plotNum = tempB;
          return parseInt(x[0])-parseInt(y[0]);
        }
      });
      sessionStorage.setItem('plots', JSON.stringify(response.data));
      this.setDynamicData();
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  capitalFirst(type) {
    const mySentence = type;
    return mySentence.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  }

  capitalPlot(plot) {
    const str = plot;
    return str.toUpperCase();
  }

  blockPlot(detailsForm: NgForm): void {

    console.log(this.usersForPlotBlockingDetails);
    if (detailsForm.valid) {
      const plotDetail: any = {
        plotId: this.blockingPlot.plotId,
        blockingComments: this.blockingComments,
        blockedFor: this.usersForPlotBlockingDetails['personId']
      };

      let path = 'plot/block-plot';

      this.restService.submitPutRequest(path, plotDetail).subscribe((res) => {
        if (!_.isEmpty(res.data)) {
          this.getPlots();
          this.usersForPlotBlockingDetails = {personId: null, name:""};
          this.blockingComments = '';
        }
      });
    }
  }

  initiateSale(plot){
    this.commonService.setInitiateSalePlot(plot);
    console.log(plot.plotStatus);
    this.router.navigate(['/'+this.commonService.getUrlSuffix(), RoutesConstants.VENTURELAYOUT, RoutesConstants.ADDNEWSALE]);
  }

  onClick() {
    this.router.navigate([RoutesConstants.VENTURELAYOUT, RoutesConstants.ADDPLOT]);
  }

  goToPlotInfo(plotRec: any){
    this.router.navigate(['../plotbyid', plotRec.plotId], { relativeTo: this.route });
  }

  ngOnChanges() {
    if (this.userInfo?.role == "SalesRep") {
      this.plots = this.plotinfo
      sessionStorage.setItem('plots', JSON.stringify(this.plots));
      this.setDynamicData();
    } else {
      this.getPlots();
    }
  }

  setDynamicData(){
    let plotData: any = JSON.parse(sessionStorage.getItem('plots'));
    this.roadFacings = [...new Set(plotData.map(plot => this.toTitleCase(plot.roadFacing.trim())))];
    this.plotTypes = [...new Set(plotData.map(plot => this.toTitleCase(plot.plotType.trim())))];
    this.plotStatuses = [...new Set(plotData.map(plot => this.toTitleCase(plot.plotStatus.trim())))];
    this.plotStatuses = ["Available", "Blocked", "Sold", "Registered" , "Mortgage" , "M-sold", "C-blocked", "Landlord"];
  }

  toTitleCase(str: string): string {
      return str.toLowerCase().replace(/(?:^|\s|-)\w/g, (match) => {
      return match.toUpperCase();
    });
  }

  filterPlots(): void {
    let plotData: any = JSON.parse(sessionStorage.getItem('plots'));
    this.plots = plotData.filter(plot => {
      const facingMatch = this.selectedRoadFacing.toLowerCase() === 'all' || plot.roadFacing.toLowerCase() === this.selectedRoadFacing.toLowerCase();
      const typeMatch = this.selectedPlotType.toLowerCase() === 'all' || plot.plotType.toLowerCase() === this.selectedPlotType.toLowerCase();
      const statusMatch = this.selectedPlotStatus.toLowerCase() === 'all' || plot.plotStatus.toLowerCase() === this.selectedPlotStatus.toLowerCase();
      let searchMatch = true;
    if (this.searchText.trim() !== '') {
      searchMatch = plot.plotNum.toLowerCase().includes(this.searchText.toLowerCase());
    }
    return facingMatch && typeMatch && statusMatch && searchMatch;
    });
  }
  
  resetFilters(): void {
    this.selectedRoadFacing = 'all';
    this.selectedPlotType = 'all';
    this.selectedPlotStatus = 'all';
    this.searchText = '';
    this.plots = JSON.parse(sessionStorage.getItem('plots'));
  }

  open(content, plot) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.usersForPlotBlockingDetails = {personId: null, name:""};
      this.blockingComments = '';
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.blockingPlot = plot;

    let url = 'marketer/get-users-for-plot-blocking?ventureId=' + this.ventureId;

    this.restService.getUsersForPlotBlocking(url).subscribe(response => {
      if (response != null && response != undefined) {
        this.usersForPlotBlocking = response.data;

        let userInfoDetails = {
          name: this.capitalizeFirstLetter(this.userInfo.firstName) + ' ' + this.capitalizeFirstLetter(this.userInfo.lastName),
          personId: this.userInfo.personId
        };

        this.usersForPlotBlocking.push(userInfoDetails);
        
      }
    },
    (error) => {
      console.log(error.status);
    });

  }

  capitalizeFirstLetter(str: string): string {
    return str.split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  private getDismissReason(reason: any): string {

    return `with: ${reason}`;

  }

}