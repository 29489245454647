<div class=" my-3">
    <div class="card">
        <a class="position-absolute close-popup-link bg-white" (click)="sendBack()"><i class="fa fa-times"
                aria-hidden="true"></i>
        </a>
        <div class="card-body p-4">
            <div class="row">
                <div class="col-md-6 mb-0">
                    <h6 class="card-title text-dark position-relative pb-2 mb-4 ">Plot Info</h6>
                </div>
                <div class="col-md-6 text-right mb-0" *ngxPermissionsOnly="[userPrivileges.TENANT_PRIVILEGE]">
                    <button class="btn btn-sm btn-info" (click)="splitPlot()" *ngIf="!isSplit && plotdetails.plotStatus == 'Available'">Split Plot</button>
                </div>
            </div>

                <div class="row mt-2" *ngIf="!isSplit">
                    <div class="form-group col-md-4 " [ngClass]="{'invalid': (plotnumber.invalid && (plotnumber.dirty || plotnumber.touched))}">
                        <label class="star">Plot Number</label>
                        <input disabled type="text" class="form-control" id="plotnumber" [readonly]='edit' required [(ngModel)]="plotdetails.plotNum" name="plotnumber" #plotnumber="ngModel">
                    </div>
                    <div class="form-group col-md-4" [ngClass]="{'invalid': (measure.invalid && (measure.dirty || measure.touched))}">
                        <label class="star">Measure</label>
                        <input disabled type="text" class="form-control" id="measure" name="measure" [readonly]='edit' #measure="ngModel" required [(ngModel)]="plotdetails.measure">
                    </div>
                    <div class="form-group col-md-4" [ngClass]="{'invalid': (size.invalid && (size.dirty || size.touched))}">
                        <label class="star">Size</label>
                        <input disabled type="number" class="form-control" id="size" name="size" #size="ngModel" [readonly]='edit' required [(ngModel)]="plotdetails.size">
                    </div>
                    <div class="form-group col-md-4" [ngClass]="{'invalid': (eastLength.invalid && (eastLength.dirty || eastLength.touched))}">
                        <label class="star">East Length</label>
                        <input disabled type="number" class="form-control" id="eastLength" name="eastLength" [readonly]='edit' #eastLength="ngModel" required [(ngModel)]="plotdetails.eastLength">
                    </div>
                    <div class="form-group col-md-4" [ngClass]="{'invalid': (westlength.invalid && (westlength.dirty || westlength.touched))}">
                        <label class="star">West Length</label>
                        <input disabled type="text" class="form-control" id="westlength" name="westlength" #westlength="ngModel" [readonly]='edit' required [(ngModel)]="plotdetails.westLength">
                    </div>
                    <div class="form-group col-md-4" [ngClass]="{'invalid': (northlength.invalid && (northlength.dirty || northlength.touched))}">
                        <label class="star">North Length</label>
                        <input disabled type="text" class="form-control" id="northlength" name="northlength" [readonly]='edit' #northlength="ngModel" required [(ngModel)]="plotdetails.northLength">
                    </div>
                    <div class="form-group col-md-4" [ngClass]="{'invalid': (southlength.invalid && (southlength.dirty || southlength.touched))}">
                        <label class="star">South Length</label>
                        <input disabled type="text" class="form-control" id="southlength" required #southlength="ngModel" [readonly]='edit' name="southlength" [(ngModel)]="plotdetails.southLength">
                    </div>
                    <div class="form-group col-md-4" [ngClass]="{'invalid': (roadfacing.invalid && (roadfacing.dirty || roadfacing.touched))}">
                        <label class="star">Road Facing</label>
                        <input disabled type="text" class="form-control" name="roadfacing" id="roadfacing" required [readonly]='edit' #roadfacing="ngModel" [(ngModel)]="plotdetails.roadFacing">
                    </div>
                    <div class="form-group col-md-4" [ngClass]="{'invalid': (plottype.invalid && (plottype.dirty || plottype.touched))}">
                        <label class="star">Plot Type</label>
                        <input disabled type="text" class="form-control" id="plottype" required name="plottype" [readonly]='edit' #plottype="ngModel" [(ngModel)]="plotdetails.plotType">
                    </div>

                    <div class="form-group col-md-4" [ngClass]="{'invalid': (plotStatus.invalid && (plotStatus.dirty || plotStatus.touched))}">
                        <label class="star">Plot Status</label>
                        <input disabled type="text" class="form-control" id="plotStatus" required name="plotStatus" [readonly]='edit' #plotStatus="ngModel" [(ngModel)]="plotdetails.plotStatus">
                    </div>

                    <div class="form-group col-md-4" [ngClass]="{'invalid': (eastSideContent.invalid && (eastSideContent.dirty || eastSideContent.touched))}">
                        <label class="star">East Side</label>
                        <input disabled type="text" class="form-control" id="eastSideContent" required name="eastSideContent" [readonly]='edit' #eastSideContent="ngModel" [(ngModel)]="plotdetails.eastSideContent">
                    </div>

                    <div class="form-group col-md-4" [ngClass]="{'invalid': (westSideContent.invalid && (westSideContent.dirty || westSideContent.touched))}">
                        <label class="star">West Side</label>
                        <input disabled type="text" class="form-control" id="westSideContent" required name="westSideContent" [readonly]='edit' #westSideContent="ngModel" [(ngModel)]="plotdetails.westSideContent">
                    </div>
                    
                    <div class="form-group col-md-4" [ngClass]="{'invalid': (northSideContent.invalid && (northSideContent.dirty || northSideContent.touched))}">
                        <label class="star">North Side</label>
                        <input disabled type="text" class="form-control" id="northSideContent" required name="northSideContent" [readonly]='edit' #northSideContent="ngModel" [(ngModel)]="plotdetails.northSideContent">
                    </div>
                    
                    <div class="form-group col-md-4" [ngClass]="{'invalid': (southSideContent.invalid && (southSideContent.dirty || southSideContent.touched))}">
                        <label class="star">South Side</label>
                        <input disabled type="text" class="form-control" id="southSideContent" required name="southSideContent" [readonly]='edit' #southSideContent="ngModel" [(ngModel)]="plotdetails.southSideContent">
                    </div>
                    

                    <!-- <div class="form-group col-md-4" *ngIf='plotdetails.plotStatus == "available" || plotdetails.plotStatus == "Blocked"'>
                        <label class="star">Plot Status</label>
                        <select type="text" class="form-control" name="plotstatus" id="plotstatus" #plotstatus="ngModel" [(ngModel)]="plotdetails.plotStatus">
                            <option value="available">Available</option>
                            <option value="Blocked">Blocked</option>
                        </select>
                    </div>
                    <div class="form-group col-md-4" *ngIf='!(plotdetails.plotStatus == "available" || plotdetails.plotStatus == "Blocked")' [ngClass]="{'invalid': (updatedby.invalid && (updatedby.dirty || updatedby.touched))}">
                        <label class="star">Plot Status</label>
                        <input type="text" class="form-control" id="plotStatus" readonly name="plotStatus" [readonly]='edit' #plotStatus="ngModel" [(ngModel)]="plotdetails.plotStatus">
                    </div> -->
                    <!-- <div class="form-group col-md-4" [ngClass]="{'invalid': (updatedby.invalid && (updatedby.dirty || updatedby.touched))}">
                        <label class="star">Last Updated By</label>
                        <input disabled type="number" class="form-control" id="updatedby" name="updatedby" [readonly]='edit' #updatedby="ngModel" [(ngModel)]="plotdetails.lastUpdatedBy">
                    </div>
                    <div class="form-group col-md-4" [ngClass]="{'invalid': (updatedon.invalid && (updatedon.dirty || updatedon.touched))}">
                        <label class="star">Last Updated On</label>
                        <input disabled type="text" class="form-control" id="updatedon" name="updatedon" [readonly]='edit' #updatedon="ngModel" [(ngModel)]="plotdetails.lastUpdatedOn" max="9999-12-31">
                    </div> -->
                    <!-- <div class="col-md-6 text-right m-3">
                        <button type="submit" class="btn btn-sm btn-info" [disabled]=details.invalid>Submit</button>
                    </div> -->
                    
                </div>

                <div class="row mt-2" *ngIf="isSplit">
                    <div class="form-group col-md-4">
                        <label class="star">Plot Number</label>
                        <input type="text" class="form-control" id="plotnumber" [(ngModel)]="plotdetails.plotNum" name="plotnumber" disabled required>
                    </div>
                    <div class="form-group col-md-4">
                        <label class="star">Split Number</label>
                        <select class="form-control" name="splitCount" id="splitCount" [(ngModel)]="splitCount" required>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                        </select>
                    </div>
                    <div class="form-group col-md-4">
                        <label class="mb-2 pb-1 d-block">&nbsp;</label>
                        <button class="btn btn-sm btn-info" (click)="splitIntoPlots()">Go</button>
                    </div>
                </div>
                <ng-container *ngFor="let sPlot of modifiedPlots">
                    <hr>
                    <div class="row mt-2" *ngIf="isSplit">
                        <div class="form-group col-md-4 " [ngClass]="{'invalid': (plotnumber.invalid && (plotnumber.dirty || plotnumber.touched))}">
                            <label class="star">Plot Number</label>
                            <input disabled type="text" class="form-control" id="plotnumber" [readonly]='edit' required [(ngModel)]="sPlot.plotNum" name="plotnumber" #plotnumber="ngModel">
                        </div>
                        <div class="form-group col-md-4" [ngClass]="{'invalid': (size.invalid && (size.dirty || size.touched))}">
                            <label class="star">Size</label>
                            <input type="number" class="form-control" id="size" name="size" #size="ngModel" required [(ngModel)]="sPlot.size">
                        </div>
                        <div class="form-group col-md-4" [ngClass]="{'invalid': (eastLength.invalid && (eastLength.dirty || eastLength.touched))}">
                            <label class="star">East Length</label>
                            <input type="number" class="form-control" id="eastLength" name="eastLength" #eastLength="ngModel" required [(ngModel)]="sPlot.eastLength">
                        </div>
                        <div class="form-group col-md-4" [ngClass]="{'invalid': (westlength.invalid && (westlength.dirty || westlength.touched))}">
                            <label class="star">West Length</label>
                            <input type="text" class="form-control" id="westlength" name="westlength" #westlength="ngModel" required [(ngModel)]="sPlot.westLength">
                        </div>
                        <div class="form-group col-md-4" [ngClass]="{'invalid': (northlength.invalid && (northlength.dirty || northlength.touched))}">
                            <label class="star">North Length</label>
                            <input type="text" class="form-control" id="northlength" name="northlength" #northlength="ngModel" required [(ngModel)]="sPlot.northLength">
                        </div>
                        <div class="form-group col-md-4" [ngClass]="{'invalid': (southlength.invalid && (southlength.dirty || southlength.touched))}">
                            <label class="star">South Length</label>
                            <input type="text" class="form-control" id="southlength" required #southlength="ngModel" name="southlength" [(ngModel)]="sPlot.southLength">
                        </div>
                        <div class="form-group col-md-4" [ngClass]="{'invalid': (roadfacing.invalid && (roadfacing.dirty || roadfacing.touched))}">
                            <label class="star">Road Facing</label>
                            <select class="form-control" name="roadfacing" id="roadfacing" #roadfacing="ngModel" [(ngModel)]="sPlot.roadFacing" required>
                                <option *ngFor="let face of facings" [value]="face">{{face}}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-4" [ngClass]="{'invalid': (plottype.invalid && (plottype.dirty || plottype.touched))}">
                            <label class="star">Plot Type</label>
                            <select class="form-control" name="plottype" id="plottype" #plottype="ngModel" [(ngModel)]="sPlot.plotType" required>
                                <option *ngFor="let type of plotTypes" [value]="type">{{type}}</option>
                            </select>
                        </div>
    
                        <div class="form-group col-md-4" [ngClass]="{'invalid': (eastSideContent.invalid && (eastSideContent.dirty || eastSideContent.touched))}">
                            <label class="star">East Side</label>
                            <input type="text" class="form-control" id="eastSideContent" required name="eastSideContent" #eastSideContent="ngModel" [(ngModel)]="sPlot.eastSideContent">
                        </div>
    
                        <div class="form-group col-md-4" [ngClass]="{'invalid': (westSideContent.invalid && (westSideContent.dirty || westSideContent.touched))}">
                            <label class="star">West Side</label>
                            <input type="text" class="form-control" id="westSideContent" required name="westSideContent" #westSideContent="ngModel" [(ngModel)]="sPlot.westSideContent">
                        </div>
                        
                        <div class="form-group col-md-4" [ngClass]="{'invalid': (northSideContent.invalid && (northSideContent.dirty || northSideContent.touched))}">
                            <label class="star">North Side</label>
                            <input type="text" class="form-control" id="northSideContent" required name="northSideContent" #northSideContent="ngModel" [(ngModel)]="sPlot.northSideContent">
                        </div>
                        
                        <div class="form-group col-md-4" [ngClass]="{'invalid': (southSideContent.invalid && (southSideContent.dirty || southSideContent.touched))}">
                            <label class="star">South Side</label>
                            <input type="text" class="form-control" id="southSideContent" required name="southSideContent" #southSideContent="ngModel" [(ngModel)]="sPlot.southSideContent">
                        </div>                        
                    </div>
                </ng-container>
                <div class="row mt-2 pl-3" *ngIf="isSplit && modifiedPlots.length > 0">
                    <button class="btn btn-sm btn-info" (click)="submitsplitPlots()">Submit</button>
                </div>
        </div>

        <ng-template #successMsg let-modal>
            <div class="card">
                <div class="modal-header">
                    <button type="button" class="close" aria-label="Close" (click)="closeAlert()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="card-body modal-body">
                    <div class="text-center">
                        <p class="close-content">{{message}}</p>
                    </div>
                    <div class="row">
                        <div class="col-md-12 d-flex justify-content-center">
                            <div class="submit-btn px-2">
                                <button type="submit" class="btn btn-info" (click)="closeAlert()"
                                    #submitButton>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>