<ng-container>
  <div class="my-3">
    <div class="card">
      <div class="card-body">
        <form #details="ngForm">
          <div class="row">
            <div class="form-group col-md-4">
              <label>Ventures</label>
              <ng-multiselect-dropdown name="select" [placeholder]="'Select'" [data]="ventures" [settings]="dropdownSettings"
                (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelect($event)"
                (onDeSelectAll)="onUnSelectAll()">
              </ng-multiselect-dropdown>
            </div>
            <div class="form-group col-md-4">
              <label>Plot Status</label>
              <select type="text" class="form-control" id="status" name="status" [(ngModel)]="filters.plotStatus">
                <option [value]="'all'">All Plots</option>
                <option [value]="'sold'">Sold</option>
                <option [value]="'registered'">Registered</option>
                <option [value]="'m-sold'">M-sold</option>
              </select>
            </div>
            <div class="form-group col-md-4">&nbsp;</div>
            <div class="form-group col-md-4">
                <label>From Date</label>
                <input type="date" class="form-control" id="fromDate" name="fromDate" [(ngModel)]="filters.fromDate">
            </div>
            <div class="form-group col-md-4">
              <label>To Date</label>
              <input type="date" class="form-control" id="toDate" name="toDate" [(ngModel)]="filters.toDate">
            </div>
            <div class="col-md-2 mt-4 pt-1" style="font-size: medium;">
              <button class="btn-info py-1 px-3 border rounded" (click)="getPerformances()"
                [disabled]=details.invalid><b>Go</b></button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>
<div class="my-3">
  <div class="card">
    <div class="card-body">
      <div class="wrap" *ngIf="performanceArray.length > 0">
        <table class="table table-striped table-bordered table-sm row-border hover">
          <thead>
            <tr>
              <th>Sales Rep</th>
              <th>Plots Sold</th>
              <th>Sale Value (<i class="fas fa-rupee-sign mr-1"></i>)</th>
              <th>Amount Received (<i class="fas fa-rupee-sign mr-1"></i>)</th>
              <th>Amount Pending (<i class="fas fa-rupee-sign mr-1"></i>)</th>
              <th>Area Sold ({{commonService.getMeasure()}})</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let sale of performanceArray">
              <td>{{sale.salesrepName}}</td>
              <td>{{sale.plotsSold}}</td>
              <td>{{sale.saleValue?.toLocaleString('en-IN')}}</td>
              <td>{{sale.saleAmountReceived?.toLocaleString('en-IN')}}</td>
              <td>{{sale.saleAmountPending?.toLocaleString('en-IN')}}</td>
              <td>{{sale.areaSold.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>