<ul class=" list-unstyled list-group-flush mb-0 justify-content-center align-items-center">
    <ng-container>
        <li class="" *ngFor="let menuitem of menuitems">
            <div *ngIf="menuitem.id==logout">
            </div>
            <a class="list-group-item bg-transparent border-0 position-relative" 
            [routerLink]="['/'+commonService.getUrlSuffix() +'/'+ menuitem.route]"
                routerLinkActive="is-active" [routerLinkActiveOptions]="{ exact: true}" (click)="mobileNavToggle()">
                <i class="{{menuitem.icon}}"></i>
                <span class="sidebar-link-content">{{menuitem.adminlink}}</span>
            </a>
        </li>
    </ng-container>
    <div class=" " *ngIf="showVenture">
        <!-- <li class="list-group-item bg-transparent border-0 position-relative">
            <i class="fas fa-sign-out-alt mr-3"></i>
            <span class="sidebar-link-content pointer"></span>
        </li> -->
        <li class="list-group-item bg-transparent border-1 d-flex pointer">
            <span class="sidebar-link-content" (click)="dashboard()"><img src="assets/images/venzr-icon.png"
                    class="mr-3 ml-2" width="18" /> {{ventureName }}</span>
            <a class="ml-2 text-white position-relative" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
                aria-controls="collapseExample"><i class="far fa-chevron-square-down"></i></a>
        </li>
    </div>
    <ul class=" list-unstyled " #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <ng-container *ngIf="showVenture">
            <li class="" *ngFor="let menuitem of ventureMenus">
                <div *ngIf="menuitem.id==logout">
                </div>
                <a class="list-group-item bg-transparent border-0 position-relative ml-4 pl-4"
                [routerLink]= "['/'+commonService.getUrlSuffix() +'/'+ menuitem.route]" routerLinkActive="is-active"
                    [routerLinkActiveOptions]="{ exact: true}" (click)="mobileNavToggle()">
                    <i class="{{menuitem.icon}}"></i>
                    <span class="sidebar-link-content">{{menuitem.adminlink}}</span>
                </a>
            </li>
        </ng-container>
    </ul>
</ul>