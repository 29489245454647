import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesConstants } from '../../constants/RoutesConstants';
import { RestService } from '../../services/rest.service';
import { CommonServiceService } from 'src/app/services/common-service.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {

  check_strength;
  passwordChanged: boolean = false;

  constructor(
    private restService: RestService,
    private commonService: CommonServiceService,
    private router: Router
    ) { }

  ngOnInit(): void { }

  onSubmit(password: any){
    const requestBody: any = {
      oldPassword: password.currentPassword,
      newPassword: password.newPassword,
      confirmPassword: password.confirmPassword
    };
    const path: string = 'user/change-password';
    this.restService.submitPostRequest(path, requestBody).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.passwordChanged = true;
        }
      }, (error: any) => {
        // TODO error
      }
    );
  }

  doLogout() {
    this.router.navigate(['/'+this.commonService.getUrlSuffix(), RoutesConstants.LOGOUT]);
  }

  Check(data) {
    let check_strength = document.getElementById("same");
    if (data.length == 0) {
      check_strength.innerHTML = "";
      return;
    }
  
    let regex = new Array();
    regex.push("[A-Z]"); 
    regex.push("[a-z]");
    regex.push("[0-9]"); 
    regex.push("[$@$!%*#?&]"); 
  
    let add = 0;
  
    for (let i = 0; i < regex.length; i++) {
      if (new RegExp(regex[i]).test(data)) {
        add++;
      }
    }
    let strength = "";
    switch (add) {
      case 0:
      case 1:
      case 2:
        strength = "<div class='progress-bar bg-danger' style='width: 40%'>weak</div>";
        break;
      case 3:
        strength = "<div class='progress-bar bg-warning' style='width: 60%'>Medium</div>";
        break;
      case 4:
        strength = "<div class='progress-bar bg-success' style='width: 100%'>Strong</div>";
        break;
  
    }
    check_strength.innerHTML = strength;
  }
  
}
