import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UserPrivileges } from 'src/app/constants/AppConstants';

@Component({
  selector: 'app-new-customer',
  templateUrl: './new-customer.component.html',
  styleUrls: ['./new-customer.component.scss']
})
export class NewCustomerComponent implements OnInit {

  @Input() customerData: any;
  newCustomer: any = [];
  submit=false;
  userPrivileges = UserPrivileges;
  constructor() { }

  ngOnInit() {
    this.newCustomer = this.customerData;
  }

  onSubmit() { this.submit = true; }
}
