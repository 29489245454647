import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-addventure',
  templateUrl: './addventure.component.html',
  styleUrls: ['./addventure.component.scss']
})
export class AddventureComponent implements OnInit {

  @Output()
  emitObj: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onsubmit(ventureObj: any){
    this.emitObj.emit(ventureObj);
  }

}
