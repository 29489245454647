<div class=" mt-3">
    <div class="card ">
        <div class="card-body">
            <div class="row p-2 mx-0 mb-0 ">                
                  <div class="form-group col-md-2">
                    <label>Facing</label>
                    <select class="form-control" [(ngModel)]="selectedRoadFacing" (change)="filterPlots()">
                      <option value="all">All</option>
                      <option *ngFor="let roadFacing of roadFacings" [value]="roadFacing">{{ roadFacing }}</option>
                    </select>
                  </div>                  
                  <div class="form-group col-md-2">
                    <label>Type</label>
                    <select class="form-control" [(ngModel)]="selectedPlotType" (change)="filterPlots()">
                      <option value="all">All</option>
                      <option *ngFor="let plotType of plotTypes" [value]="plotType">{{ plotType }}</option>
                    </select>
                  </div>

                  <div class="form-group col-md-2">
                    <label>Status</label>
                    <select class="form-control" [(ngModel)]="selectedPlotStatus" (change)="filterPlots()">
                      <option value="all">All</option>
                      <option *ngFor="let plotStatus of plotStatuses" [value]="plotStatus">{{ plotStatus }}</option>
                    </select>
                  </div>                              
                  <div class="form-group col-md-2 ml-2 mt-4">
                    <input type="text" class=" form-control" placeholder="Search By Plot No..." [(ngModel)]="searchText" (keyup)="filterPlots()">
                  </div>
                  
                  <div class="form-group col-md-2 mt-4">
                    <button class="btn-info  border rounded form-control" (click)="resetFilters()">Reset</button>
                  </div>
            </div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Block Plot</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #details="ngForm" (ngSubmit)="blockPlot(details)">
      <div class="row">
        <div class="form-group col-6">
          <label class="star">Plot No</label>
          <select type="text" class="form-control" id="plotNum" required disabled [(ngModel)]="this.blockingPlot.plotNum" name="plotNum" #plotNum="ngModel">
            <option value="{{this.blockingPlot.plotNum}}">{{this.blockingPlot.plotNum}}</option>
          </select>
        </div>

        <div class="form-group col-6">
          <label class="star">Blocked For</label>
          <ng-autocomplete [data]="usersForPlotBlocking" [focusFirst]=true [(ngModel)]="usersForPlotBlockingDetails" [ngModelOptions]="{standalone: true}" [searchKeyword]="keyword" placeholder="Enter Name" [itemTemplate]="blockedForUserTemplate" [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>

          <ng-template #blockedForUserTemplate let-item>
              <div class="item">
                  <span>{{ item.name }}</span>
              </div>
          </ng-template>

          <ng-template #notFoundTemplate let-notFound>
              <div style="font-size: large;">Not Found</div>
          </ng-template>
      </div>

        <div class="form-group col-md-12">
          <label class="star">Comments</label>
          <textarea class="form-control" id="blockingComments" required [(ngModel)]="blockingComments" name="blockingComments"></textarea>
        </div>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-outline-dark" [disabled]="details.invalid" (click)="modal.close('Save click');">Save</button>
      </div>
    </form>
  </div>
</ng-template>


            <div class="wrap">
                <table class="table table-striped table-bordered table-sm row-border hover" datatable
                    [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th>Plot #</th>
                            <th>Status</th>
                            <th>Size ({{commonService.getMeasure()}})</th>
                            <th>East (dim)</th>
                            <th>West (dim)</th>
                            <th>North (dim)</th>
                            <th>South (dim)</th>
                            <th>Facing</th>
                            <th>Block</th>
                            <!-- <th>East</th>
                            <th>West</th>
                            <th>North</th>
                            <th>South</th> -->
                            <th>Type</th>
                            <th *ngIf="userInfo.role == 'Marketer'"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let plot of plots" >
                            <td class="pointer" (click)="goToPlotInfo(plot)"><a class="plot">{{plot.plotNum}}</a></td>
                            <!-- <td *ngIf="userInfo.role == 'SalesRep'"><a class="plot">{{plot.plotNum}}</a></td> -->
                            <td>{{capitalFirst(plot.plotStatus)}}</td>
                            <td>{{plot.size.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                            <td>{{plot.eastLength.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                            <td>{{plot.westLength.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                            <td>{{plot.northLength.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                            <td>{{plot.southLength.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                            <td>{{plot.roadFacing | titlecase}}</td>
                            <td>{{plot.blockName | titlecase}}</td>
                            <!-- <td>{{plot.eastSideContent | titlecase}}</td>
                            <td>{{plot.westSideContent | titlecase}}</td>
                            <td>{{plot.northSideContent | titlecase}}</td>
                            <td>{{plot.southSideContent | titlecase}}</td> -->
                            <td>{{plot.plotType | titlecase}}</td>
                            <td *ngIf="userInfo.role == 'Marketer'">
                                <ng-template #popContent>
                                    <div class="pt-1" *ngIf="plot.plotStatus === 'available'">
                                        <a class="text-body pointer"  (click)="open(content, plot)">Block</a>
                                    </div>
                                    <div class="py-1" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.ADD_SALE]">
                                        <a class="text-body pointer"  *ngIf="plot.plotStatus === 'available' || plot.plotStatus === 'mortgage'" (click)="initiateSale(plot)">Initiate Sale</a>
                                    </div>
                                </ng-template>
                                <div *ngIf="plot.plotStatus === 'available' || plot.plotStatus === 'mortgage'" [ngbPopover]="popContent" class="text-center">
                                  <i class="fa fa-ellipsis-v"></i>
                                </div>
                              </td>                              
                        </tr>
                    </tbody>
                </table>
            </div>
            <section class="card-view" ng-class="cardView ? 'show' : 'hide'">
              <div class="card p-3 mobile-view" ng-repeat="card in cards">
                <div *ngFor="let plot of plots" class="info">
                  <h5>{{plot.plotNum}}</h5>
                  <p>Status: {{plot.plotStatus}}</p>
                  <p>Size: {{plot.size}}</p>
                  <p>Dimensions: {{plot.eastLength}} (E), {{plot.westLength}} (W), {{plot.northLength}} (N), {{plot.southLength}}
                    (S)</p>
                  <p>Facing: {{plot.roadFacing}}</p>
                  <p>Type: {{plot.plotType}}</p>
                  <hr>
                </div>
                <button class="card-button" ng-click="viewMore()">View Info</button>
              </div>
            </section>
        </div>
    </div>
</div>