<div class="container-fluid my-3">
    <div class="card">
        <a class="position-absolute close-popup-link bg-white" (click)="sendBack()"><i class="fa fa-times"
                aria-hidden="true"></i>
        </a>
        <div class="card-body p-4" >
            <h6 class="card-title text-dark position-relative pb-2 mb-4 ">New Plot</h6>
            <form #details="ngForm" (ngSubmit)="onSubmit(details.value)">
                <div class="row mt-2" >
                    <div class="form-group col-md-4 "
                        [ngClass]="{'invalid': (plotNum.invalid && (plotNum.dirty || plotNum.touched))}">
                        <label class="star">Plot Number</label>
                        <input type="text" class="form-control" id="plotNum" required name="plotNum"
                            #plotNum="ngModel" ngModel>
                    </div>

                    <div class="form-group col-md-4" [ngClass]="{'invalid': (measure.invalid && (measure.dirty || measure.touched))}">
                        <label class="star">Measure</label>
                        <select class="form-control" id="measure" name="measure" #measure="ngModel" required ngModel>
                            <option value="Square Yards">Square Yards</option>
                            <option value="Feet">Feet</option>
                        </select>
                    </div>
                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (size.invalid && (size.dirty || size.touched))}">
                        <label class="star">Size</label>
                        <input type="number" class="form-control" id="size" name="size"
                        #size="ngModel" required ngModel>
                    </div>

                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (eastLength.invalid && (eastLength.dirty || eastLength.touched))}">
                        <label class="star">East (Sq.Ft)</label>
                        <input type="number" class="form-control" id="eastLength" name="eastLength"
                            #eastLength="ngModel" required ngModel>
                    </div>

                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (westLength.invalid && (westLength.dirty || westLength.touched))}">
                        <label class="star">West (Sq.Ft)</label>
                        <input type="text" class="form-control" id="westLength" name="westLength" #westLength="ngModel"
                            required ngModel>
                    </div>

                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (northLength.invalid && (northLength.dirty || northLength.touched))}">
                        <label class="star">North (Sq.Ft)</label>
                        <input type="text" class="form-control" id="northLength" name="northLength"
                            #northLength="ngModel" required ngModel>

                    </div>

                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (southLength.invalid && (southLength.dirty || southLength.touched))}">
                        <label class="star">South (Sq.Ft)</label>
                        <input type="text" class="form-control" id="southLength" required #southLength="ngModel"
                            name="southLength" ngModel>
                    </div>
                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (roadFacing.invalid && (roadFacing.dirty || roadFacing.touched))}">
                        <label class="star">Road Facing</label>
                        <select class="form-control" name="roadFacing" id="roadFacing" required
                            #roadFacing="ngModel" ngModel>
                            <option *ngFor = "let road of roadfacing" [value]="road">{{road}}</option>
                            </select>
                    </div>
                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (coordinates.invalid && (coordinates.dirty || coordinates.touched))}">
                        <label class="star">Coordinates</label>
                        <input type="text" class="form-control" id="coordinates"  name="coordinates"
                            #coordinates="ngModel" ngModel>
                    </div>
                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (plotType.invalid && (plotType.dirty || plotType.touched))}">
                        <label class="star">Plot Type</label>
                        <select class="form-control" id="plotType" required name="plotType"
                            #plotType="ngModel" ngModel>
                            <option *ngFor = "let type of plottype" value={{type}}>{{type}}</option>
                            </select>
                    </div>
                    <div class="form-group col-md-4">
                        <label class="star">Plot Status</label>
                        <select type="text" class="form-control" name="plotStatus" id="plotStatus"
                            #plotStatus="ngModel" ngModel>
                            <option value="open">Open</option>
                            <option value="blocked">Blocked</option>
                            <option value="sold">Sold</option>
                            <option value="registered">Registered</option>
                        </select>
                    </div>
                    <div class="form-group col-md-4">
                        <label class="star">Comments</label>
                        <input type="text" class="form-control" id="comments"  name="comments"
                            #comments="ngModel" ngModel>
                    </div>
                    
                    <button type="submit" class="btn btn-info m-3" [disabled]=details.invalid>Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>