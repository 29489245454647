<div class="container-fluid mt-2">
    <div class="card shadow right-tabs clearfix  mt-3 h-100 mb-2">
        <div class="card-header d-flex justify-content-between align-items-center">
            <div>
                <h6>{{ isCancelledSale ? 'Cancelled ' : '' }}Sale ({{ ventureName }}) - {{ plots.plotNum }}</h6>
            </div>
            <div class="pr-3">
                <div ngbDropdown>
                    <button class="btn btn-outline-info btn-sm" id="actionsDropdown" ngbDropdownToggle>Actions</button>
                    <div ngbDropdownMenu aria-labelledby="actionsDropdown">
                        <button *ngIf="userPrivileges.MARKETER_SUPER_PRIVILEGE"
                            (click)="openChangePlotModal(changePlotModal)" class="dropdown-item btn btn-sm btn-info">
                            <i class="fas fa-edit mr-1"></i>Transfer
                        </button>
                        <button
                            *ngIf="!isCancelledSale && plotsales.saleStatus != 'registered' && userPrivileges.MARKETER_SUPER_PRIVILEGE"
                            (click)="cancelSaleRequest(cancelled)" class="dropdown-item  btn btn-danger btn-sm">
                            <i class="fas fa-ban mr-1"></i>{{ isCancelRequested ? 'Cancel Sale' : 'Request cancellation'
                            }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <a class="pointer position-absolute close-popup-link bg-white" (click)="goBack()">
            <i class="fa fa-times close-btn" aria-hidden="true"></i>
        </a>
        <!-- Change Plot Modal -->
        <ng-template #changePlotModal let-modal>
            <div class="modal-header">
                <h6 class="modal-title">Change Plot Number</h6>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label for="newPlotNumber">Select New Plot Number</label>
                    <select class="form-control" id="newPlotNumber" [(ngModel)]="selectedNewPlot">
                        <option *ngFor="let plot of availablePlots" [value]="plot.plotNum">
                            {{plot.plotNum}}
                        </option>
                    </select>
                </div>
                <div class="row" *ngIf="selectedNewPlot">
                    <div class="col-md-6">
                        <i class="lable-icon px-1 py-2 fas fa-map-marked"></i>New Plot Number:
                        <b class="margin">{{selectedNewPlot}}</b>
                    </div>
                    <div class="col-md-6">
                        <i class="lable-icon px-1 py-2 fas fa-calendar-alt"></i>Size:
                        <b class="margin">{{getSelectedPlotDetails().size}} </b>
                    </div>
                    <div class="col-md-6">
                        <i class="lable-icon px-1 py-2 fas fa-calendar-alt"></i>Facing:
                        <b class="margin">{{getSelectedPlotDetails().roadFacing}} </b>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark"
                    (click)="modal.dismiss('cancel click')">Cancel</button>
                <button type="button" class="btn btn-outline-info" (click)="saveNewPlotNumber(transferModal)">Save</button>
            </div>
        </ng-template>

        <!-- Transfer plot success modal -->
        <ng-template #transferModal let-modal>
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Success</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');goBack()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                Congrats! Plot has been transferred successfully!
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" (click)="goBack()" (click)="modal.close('Save click')">Ok</button>
            </div>
        </ng-template>

        <!-- Cancel Plot Modal -->
        <ng-template #cancelled let-modal>
            <div class="modal-header">
                <h6 class="modal-title" id="modal-basic-title">Cancel Sale ?</h6>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <ngb-alert [dismissible]="false" type="danger">
                    <div class="d-flex align-items-baseline">
                        <i class="fa fa-exclamation-triangle"></i>
                        <h6 class="ml-2">Are you sure you want to cancel this sale ? </h6>
                    </div>
                    <p class="pl-4">This is a one time action and cannot be
                        reversed!</p>
                </ngb-alert>

                <div class="row mt-2">
                    <div class="form-group col-md-12">
                        <label for="cancelledReason">Remarks</label>
                        <textarea class="form-control" id="cancelledReason" name="cancelledReason"
                            [(ngModel)]="cancelledReason" required></textarea>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="float-left btn btn-outline-danger" (click)="cancelSale()"
                    [disabled]="!cancelledReason">Yes, Cancel Sale</button>
                <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('cancel click')">No</button>
            </div>
        </ng-template>
        <div class="card-body pb-2">
            <div class="row">
                <div class="col-md-12">
                    <div *ngIf="cancelalert" class="alert alert-success alert-dismissible fade show" role="alert">
                        <strong>Success !</strong> Sale cancelled successfully!
                        <button type="button" (click)="closeAlert()" class="close" data-dismiss="alert"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <p *ngIf="!isCanceled && isCancelRequested" class="alert-danger p-2">Cancel Request has been
                        initiated for this sale.</p>
                    <div class="row">
                        <div [ngClass]="isCancelRequested ? 'col-md-10' : 'col-md-12'">
                            <div class="row">
                                <div class="col-md-4">
                                    <i class="lable-icon px-1 py-2 fas fa-map-marked"></i>Sale Id:
                                    <b class="margin">{{plotsales.saleId}}</b>
                                </div>
                                <div class="col-md-4">
                                    <i class="lable-icon px-1 py-2 fas fa-calendar-alt"></i>Sale Date:
                                    <b class="margin">{{plotsales.saleDate | date}}</b>
                                </div>
                                <div class="col-md-4">
                                    <i class="lable-icon px-1 py-2 fas fa-map-marked"></i>Plot Number:
                                    <b class="margin">{{plots.plotNum}}</b>
                                </div>
                                <div class="col-md-4">
                                    <i class="lable-icon px-1 py-2 fas fa-ruler-combined"></i>Plot Size:
                                    <b class="margin">{{plots && plots.size.toLocaleString('en-IN', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2 })}}</b>
                                </div>
                                <div [ngClass]="!isCancelRequested ? 'col-md-4' : 'col-md-4'">
                                    <div class="text-dark position-relative">
                                        <i class="lable-icon px-1 py-2 fas fa-hourglass-half"></i>Sale Status:
                                        <b class="margin">{{plotsales.saleStatus | titlecase}}</b>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <i class="lable-icon px-1 py-2 fas fa-compass"></i>Plot Type:
                                    <b class="margin">{{plots.plotType}}</b>
                                </div>
                                <div *ngIf="plotsales.saleStatus == 'registered'" class="col-md-4">
                                    <i class="lable-icon px-1 py-2 fas fa-compass"></i>Registration Number:
                                    <b class="margin">{{plotSaleDetails.plotSales.registrationNum}}</b>
                                </div>
                                <div *ngIf="plotsales.saleStatus == 'registered'" class="col-md-4">
                                    <i class="lable-icon px-1 py-2 fas fa-compass"></i>Registration Date:
                                    <b class="margin">{{plotSaleDetails.plotSales.registrationDate | date}}</b>
                                </div>
                                <div class="col-md-4">
                                    <i class="lable-icon px-1 py-2 fa fa-money-bill"></i>Sale Value:
                                    <b class="margin"><i class="fas fa-rupee-sign "></i>{{plotsales &&
                                        plotsales.saleValue.toLocaleString('en-IN')}}</b>
                                </div>
                                <div class="col-md-4">
                                    <i class="lable-icon px-1 py-2 fa fa-money-bill"></i>Per Measure Value:
                                    <b class="margin"><i
                                            class="fas fa-rupee-sign "></i>{{perMeasurePrice.toLocaleString('en-IN')}}</b>
                                </div>
                                <div class="col-md-4">
                                    <i class="lable-icon px-1 py-2 fa fa-money-check"></i>Amount Paid:
                                    <b class="margin"><i
                                            class="fas fa-rupee-sign "></i>{{plotsales.saleAmountReceived.toLocaleString('en-IN')}}</b>
                                </div>
                                <div *ngIf="isCancelledSale" class="col-md-4">
                                    <i class="lable-icon px-1 py-2 fas fa-info-circle"></i>Reason for Cancellation:
                                    <b class="margin">{{ cancelledReason }}</b>
                                </div>
                                <div *ngIf="saleDetails.comments" class="col-md-8">
                                    <i class="lable-icon px-1 py-2 fas fa-map-marked"></i>Comments:
                                    <b class="margin">{{saleDetails.comments}}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
                <div class="col-md-7">
                    <div class="border mb-4 mt-2">

                        <div class="font-weight-bold border-bottom text-dark position-relative pl-3 py-2">
                            Customer Details
                        </div>
                        <div class="row p-2">
                            <div class="col-md-6">
                                <i class="lable-icon px-1 py-2 fas fa-user"></i>Customer Name:
                                <b class="margin">{{customerName}}</b>
                            </div>
                            <div class="col-md-6">
                                <i class="lable-icon px-1 py-2 fa fa-phone"></i>Contact Number:
                                <b class="margin">{{contactNumber}}</b>
                            </div>
                            <div class="col-md-6">
                                <i class="lable-icon px-1 py-2 fa fa-male"></i>Father/Husband Name:
                                <b class="margin">{{customerdata.fatherOrHusbandName}}</b>
                            </div>
                            <div class="col-md-6">
                                <i class="lable-icon px-1 py-2 fa fa-calendar-alt"></i>DOB:
                                <b class="margin">{{customerdata.dob}}</b>
                            </div>
                            <div class="col-md-6">
                                <i class="lable-icon px-1 py-2 fa fa-envelope"></i>Email:
                                <b class="margin">{{customerdata.email}}</b>
                            </div>
                            <div class="col-md-6">
                                <i class="lable-icon px-1 py-2 fa fa-id-card"></i>PAN Number:
                                <b class="margin">{{customerdata.panNum}}</b>
                            </div>
                            <div class="col-md-6">
                                <i class="lable-icon px-1 py-2 fa fa-id-card"></i>Aadhar Number:
                                <b class="margin">{{customerdata.aadharNum}}</b>
                            </div>
                            <div class="col-md-6">
                                <i class="lable-icon px-1 py-2 fa fa-city"></i>City:
                                <b class="margin">{{customerCity}}</b>
                            </div>
                        </div>

                    </div>

                    <div class="font-weight-bold card-title text-dark position-relative pb-2">
                        Commission Details
                    </div>
                    <!-- <h6 class="card-title text-dark position-relative pb-2 h6">
                    </h6> -->
                    <table
                        class="table commission-for-sale table-striped table-bordered table-sm row-border hover mt-2 mb-4">
                        <thead>
                            <tr>
                                <th>Designation</th>
                                <th>Level</th>
                                <th>Name</th>
                                <th>Total Commission<!-- (<i class="fas fa-rupee-sign "></i>) --></th>
                                <th>Commission Earned<!-- (<i class="fas fa-rupee-sign "></i>) --></th>
                                <th>Commission Paid<!-- (<i class="fas fa-rupee-sign "></i>) --></th>
                                <th>Commission Pending<!-- (<i class="fas fa-rupee-sign "></i>) --></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let sales of salesLevel; let i = index">
                                <td>{{sales.salesrepDesignation}}</td>
                                <td>{{sales.salesrepCommissionLevel+1}}</td>
                                <td>{{sales.salesrepName}}</td>
                                <td>{{sales.totalCommission.toLocaleString('en-IN')}}</td>
                                <td>{{sales.commissionEarned.toLocaleString('en-IN')}}</td>
                                <td>{{sales.commissionPaid.toLocaleString('en-IN')}}</td>
                                <td>{{(sales.commissionEarned - sales.commissionPaid).toLocaleString('en-IN')}}
                                    <i *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.ADD_COMMISSION_PAYMENT]"
                                        (click)="commissionPayment(sales.salesrepId)"
                                        class="text-primary float-right fa fa-external-link"
                                        [ngClass]="isCancelledSale?'not-allowed':'pointer'"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="showPayment" class="card-title font-weight-bold text-dark position-relative">
                        Payments History
                    </div>

                    <table *ngIf="showPayment"
                        class="table table-striped table-bordered table-sm row-border hover my-4">
                        <thead>
                            <tr>
                                <th>Amount Received (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                                <th>Payment Mode</th>
                                <th>Payment Date</th>
                                <!-- <th>Last Updated On</th> -->
                                <th>Comments</th>
                                <th>Last Updated By</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let payment of paymentHistory">
                                <td>{{payment.amountReceived.toLocaleString('en-IN')}}</td>
                                <td>{{payment.paymentMode}}</td>
                                <td>{{payment.paidDate | date}}</td>
                                <!-- <td>{{payment.lastUpdatedOn | date}}</td> -->
                                <td>{{payment.comments}}</td>
                                <td>{{payment.lastUpdatedBy}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-md-5">

                    <div *ngIf="alert" class="alert alert-success alert-dismissible fade show" role="alert">
                        <strong>Success !</strong> Your payment details have been saved successfully!
                        <button type="button" (click)="closeAlert()" class="close" data-dismiss="alert"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div *ngIf="!showPayment && !isCancelledSale"
                        class="card-title font-weight-bold text-dark position-relative pb-2">
                        <i class="p-2 fas fa-credit-card"></i>Payments History
                        <button (click)="addPayment()" class="float-right btn btn-info btn-sm" [hidden]="show"
                            *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.ADD_CUSTOMER_PAYMENT]">
                            Add Payment
                        </button>
                    </div>
                    <table *ngIf="!showPayment"
                        class="table table-striped table-bordered table-sm row-border hover mt-2 mb-4">
                        <thead>
                            <tr>
                                <th>Amount Received (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                                <th>Payment Mode</th>
                                <th>Payment Date</th>
                                <!-- <th>Last Updated On</th> -->
                                <th>Comments</th>
                                <th>Last Updated By</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let payment of paymentHistory">
                                <td>{{payment.amountReceived.toLocaleString('en-IN')}}</td>
                                <td>{{payment.paymentMode}}</td>
                                <td>{{payment.paidDate | date}}</td>
                                <!-- <td>{{payment.lastUpdatedOn | date}}</td> -->
                                <td>{{payment.comments}}</td>
                                <td>{{payment.lastUpdatedBy}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="showPayment">
                        <div class="card p-3">
                            <form (ngSubmit)="onSubmit(myPayment)" #myPayment="ngForm">
                                <div class="row">
                                    <div class="form-group col-6"
                                        [ngClass]="{'invalid': amount.invalid && (amount.dirty || amount.touched || myPayment.submitted)}">
                                        <label class="star">Amount Received (<i
                                                class="fas fa-rupee-sign mr-1"></i>)</label>
                                        <input type="number" class="form-control" id="amount" required
                                            [(ngModel)]="payment.amount" name="amount" #amount="ngModel">
                                    </div>

                                    <div class="form-group col-6"
                                        [ngClass]="{'invalid': paidFor.invalid && (paidFor.dirty || paidFor.touched || myPayment.submitted)}">
                                        <label class="star">Paid For</label>
                                        <input type="text" class="form-control" id="paidFor" name="paidFor" readonly
                                            [(ngModel)]="ModelObject.payFor" #paidFor="ngModel">
                                    </div>

                                    <div class="form-group col-6"
                                        [ngClass]="{'invalid': paidBy.invalid && (paidBy.dirty || paidBy.touched || myPayment.submitted)}">
                                        <label class="star">Paid By</label>
                                        <input type="text" class="form-control" id="paidBy" name="paidBy" readonly
                                            [(ngModel)]="ModelObject.payBy" #paidBy="ngModel">
                                    </div>

                                    <div class="form-group col-6"
                                        [ngClass]="{'invalid': paymentMode.invalid && (paymentMode.dirty || paymentMode.touched || myPayment.submitted)}">
                                        <label class="star" for="gender">Payment Mode</label>
                                        <select class="form-control" id="paymentMode" required
                                            [(ngModel)]="payment.paymentMode" name="paymentMode" #paymentMode="ngModel">
                                            <option *ngFor="let pay of payments" [value]="pay">
                                                {{pay}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group col-6"
                                        [ngClass]="{'invalid': paidDate.invalid && (paidDate.dirty || paidDate.touched || myPayment.submitted)}">
                                        <label class="star">Paid Date</label>
                                        <input type="date" class="form-control" id="paidDate" name="paidDate"
                                            [(ngModel)]="payment.paidDate" #paidDate="ngModel" required>
                                    </div>
                                    <div class="form-group col-6">
                                        <label>Comments</label>
                                        <input type="text" class="form-control" id="comments"
                                            [(ngModel)]="payment.comments" name="comments" #comments="ngModel">
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-info btn-sm" *ngIf="!isCancelledSale"
                                    [disabled]="!myPayment.form.valid">Submit
                                </button>
                            </form>

                        </div>
                    </div>

                    <div class="col-md-8">
                        <div class="card-title font-weight-bold text-dark position-relative pb-2">
                            Files
                        </div>
                        <div *ngIf="!isCancelledSale">
                            <p>Browse Files</p>
                            <div class="d-flex justify-content-between">
                                <input type="file" (change)="onFileSelected($event)" [(ngModel)]="selectedFile">
                                <button (click)="uploadFile()" class="btn btn-info btn-sm">Upload</button>
                            </div>
                        </div>
                        <div>
                            <table class="table table-striped table-bordered table-sm row-border hover mt-2 mb-4">
                                <tr>
                                    <th>S.No</th>
                                    <th>File Name</th>
                                </tr>
                                <ng-container *ngIf="saleFiles.length > 0; else noFiles">
                                    <ng-container *ngFor="let fileUrl of saleFiles; let i = index">
                                        <tr>
                                            <td>{{ i + 1 }}</td>
                                            <td>
                                                <a [href]="fileUrl" download>{{ extractFileNameFromUrl(fileUrl) }}</a>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                                <ng-template #noFiles>
                                    <tr>
                                        <td colspan="2">No files exist.</td>
                                    </tr>
                                </ng-template>
                            </table>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    </div>
</div>