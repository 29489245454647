import { Component, OnInit } from '@angular/core';
import { UserPrivileges } from 'src/app/constants/AppConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { RestService } from 'src/app/services/rest.service';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-salesrep-commissions-analytics',
  templateUrl: './salesrep-commissions-analytics.component.html',
  styleUrls: ['./salesrep-commissions-analytics.component.scss']
})
export class SalesrepCommissionsAnalyticsComponent implements OnInit {
  salesrepCommissionsArray: any = {
    salesrepCommissionsList: [],
    salesrepCommissionsSummary: {}
  };
  userInfo: any;
  filters = {
    ventureId: 'all',
    salesrepId: null,
    type: 'all',
    fromDate: null,
    toDate: null
  };
  ventures: any = [];
  teamList: any = [];
  userPrivileges = UserPrivileges;
  dropdownSettings:IDropdownSettings={};
  selectedItems = [];

  constructor(
    private restService: RestService,
    private authService: AuthServiceService
  ) { }

  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    let path:any;
    if(this.userInfo.scopes.includes('SALESREP_PRIVILEGE')) {
      path = 'salesrep/assigned-ventures/' + this.userInfo.salesrepId;
      this.dropdownSettings = {
        idField: 'ventureId',
        textField: 'ventureName',
      };  
    } else if(this.userInfo.scopes.includes('MARKETER_SUPER_PRIVILEGE') || this.userInfo.scopes.includes('MARKETER_BASIC_PRIVILEGE')) {
      path = 'marketer/venture/getVenturesList';
      let teamUrl = 'marketer/get-markerter-teams';
      let data = {
        'tenantId': this.userInfo.tenantId,
        'marketerId': this.userInfo.marketerId
      };
      this.restService.submitPostRequest(teamUrl,data).subscribe(response => {
        this.teamList = response.teams;
      });
      this.dropdownSettings = {
        idField: 'ventureId',
        textField: 'displayName',
      };  
    }
    this.restService.submitGetRequest(path).subscribe(response => {
      this.ventures = response.data;
    });
  }

  getPerformances() {
    let url;
    this.filters.ventureId = this.selectedItems.toString();
    let venture = (this.filters.ventureId != 'all' && this.filters.ventureId != null) ? '&ventureIdList='+this.filters.ventureId :'';
    let type = this.filters.type?'&type='+this.filters.type:'';
    let fromDate = this.filters.fromDate?'&fromDate='+this.filters.fromDate:'';
    let toDate = this.filters.toDate?'&toDate='+this.filters.toDate:'';
    let hasSalesrepId = false;
    if(this.userInfo.scopes.includes('SALESREP_PRIVILEGE')) {      
      hasSalesrepId = true;
      url = 'generic/salesrep/commissions/analytics?' + 'salesrepId='+this.userInfo.salesrepId+venture+type+fromDate+toDate;
    } else if(this.userInfo.scopes.includes('MARKETER_SUPER_PRIVILEGE') || this.userInfo.scopes.includes('MARKETER_BASIC_PRIVILEGE')) {
      if(this.filters.salesrepId) {
        hasSalesrepId = true;
        url = 'generic/salesrep/commissions/analytics?' + 'salesrepId='+this.filters.salesrepId+venture+type+fromDate+toDate;
      } else {
        hasSalesrepId = false;
      }
    }
    if(hasSalesrepId) {
      this.restService.getSales(url).subscribe(response => {
        if (response.data != null && response.data != undefined) {
          this.salesrepCommissionsArray = response.data;
        }
      },
      (error) => {
        console.log(error.status);
      });
    } else {
      alert("please select salesrep");
    }
  }

  onItemSelect(item: any) {
    this.selectedItems.push(item.ventureId)
  }

  onItemDeSelect(item: any) {
    this.selectedItems = this.selectedItems.filter(ele => {
      return ele != item.ventureId
    });
  }

  onSelectAll(items: any) {
    this.selectedItems = [];
    this.ventures.forEach(element => {
      this.selectedItems.push(element.ventureId)
    });
  }

  onUnSelectAll() {
    this.selectedItems = []
  }
}
