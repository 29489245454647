<div class="container-fluid my-3">
    <div class="card">
        <div class="card body">
            <form #details="ngForm" (ngSubmit)="onSubmit(details.value)" *ngIf="!passwordChanged">
                <div class=" mt-2 m-4">
                    <div class="form-group col-md-6 d-block" [ngClass]="{'invalid': (currentPassword.invalid && (currentPassword.dirty || currentPassword.touched))}">
                        <label class="star">Current Password</label>
                        <input type="password" class="form-control" id="currentPassword" required name="currentPassword" #currentPassword="ngModel"  ngModel>
                    </div>
                    <div class="form-group col-md-6 d-block" [ngClass]="{'invalid': (newPassword.invalid && (newPassword.dirty || newPassword.touched))}">
                        <label class="star">New Password</label>
                        <input type="password" class="form-control" id="newPassword" required name="newPassword" #newPassword="ngModel"  ngModel (keyup)="Check(newPassword.value)">
                    </div>
                    <div class="strength ml-3 my-2">
                        <div class="progress-bar" id="same"
                             aria-valuemin="0" aria-valuemax="100">
                        </div>
                      </div>
                    <div class="form-group col-md-6 d-block" [ngClass]="{'invalid': (confirmPassword.invalid && (confirmPassword.value != newPassword.value) && (confirmPassword.dirty || confirmPassword.touched))}">
                        <label class="star">Confirm Password</label>
                        <input type="password" class="form-control" id="confirmPassword" required name="confirmPassword" #confirmPassword="ngModel"  ngModel>
                        <div class="text-danger" *ngIf= "confirmPassword.valid && confirmPassword.value != newPassword.value">password shold match </div>
                    </div>
                    <button type="submit" class="btn btn-info ml-3" [disabled]= "details.invalid || confirmPassword.value != newPassword.value">Submit</button>
                </div>
            </form>
            <form #details2="ngForm" (ngSubmit)="onLogout()" *ngIf="passwordChanged">
                <div class=" mt-2 m-4">
                    <div class="form-group col-md-6 d-block" >
                        <label>Your password has been changed successfully. <a [routerLink]="null" (click)="doLogout()">Re-Login</a></label>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>