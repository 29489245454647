import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { RoutesConstants } from 'src/app/constants/RoutesConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { UserPrivileges, UserRoleConstants } from 'src/app/constants/AppConstants';
import { RestService } from 'src/app/services/rest.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsService } from 'ngx-permissions';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-venturesales',
  templateUrl: './venturesales.component.html',
  styleUrls: ['./venturesales.component.scss']
})
export class VenturesalesComponent implements OnInit {
  ventureSales: any = [];
  error: boolean;
  errorMessage: any;
  closeResult = '';
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  salesRepId: any;
  dispaly = true;
  plotId: any;
  popup = true;
  userPrivileges = UserPrivileges;
  modalShow: boolean = false;
  modelStyle: any = {
    display: 'none'
  };
  soldPlots: any = [];
  plotdetails: any = [];
  status: any = {};
  plotStatus: any = [];
  showCancelled = false;
  cancelledSales: any =[];

  constructor(
    private restService: RestService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private authService: AuthServiceService,
    public commonService: CommonServiceService,
    private modalService: NgbModal,
    private permissionsService: NgxPermissionsService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.fetchActiveSales();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      bSort: false,
      searching: false,
    };
  }

  fetchActiveSales() {
    this.showCancelled = false;
    const userInfo = this.authService.getUserInfo();
    let data = {
      "tenantId": userInfo.tenantId,
      "marketerId": userInfo.marketerId,
      "ventureId": this.commonService.getVenture()?.ventureId
    };
    const path: string = userInfo.role.toLowerCase() + '/get-sales';
    this.restService.submitPostRequest(path, data).subscribe(response => {
      if (response) {
        this.ventureSales = response.sales;
        this.soldPlots = this.ventureSales.filter(a => a.plotStatus == 'Sold');
        this.dtTrigger.next();
        if (this.authService.hasRole(UserRoleConstants.TENANT)) {
          this.permissionsService.hasPermission(UserPrivileges.TENANT_PRIVILEGE).then(hasTenantPrivilege => {
            if (hasTenantPrivilege) {
              this.popup = false;      
            }
          });
          this.permissionsService.hasPermission(UserPrivileges.REGISTER_PLOT).then(hasRegisterPlotPrivilege => {
            if (hasRegisterPlotPrivilege) {
              this.popup = false;       
            }
          });
        }
      }
    }, (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  // Fetch cancelled sale
  fetchCancelledSales() {
    this.showCancelled = true;
    const userInfo = this.authService.getUserInfo();
    const ventureId = this.commonService.getVenture()?.ventureId;    
    let path = '';
    path = (userInfo.role === UserRoleConstants.MARKETER ? '/marketer' : '/tenant') + '/getCancelledSales';
    this.restService.fetchCancelledSales(ventureId, userInfo.tenantId, path, userInfo.marketerId).subscribe(response => {
      this.cancelledSales = response.data;
      this.dtTrigger.next();
    });
  }

  addSale() {
    this.router.navigate(['/'+this.commonService.getUrlSuffix(), RoutesConstants.VENTURELAYOUT, RoutesConstants.ADDNEWSALE]);
  }
  editSale(id, status?) {
    if (this.authService.hasRole(UserRoleConstants.MARKETER)) {
      this.router.navigate(['/'+this.commonService.getUrlSuffix(), RoutesConstants.VENTURELAYOUT, RoutesConstants.EDITSALE], { queryParams: { 'salesRepId': id.salesrepId, 'plotId': id.plotId, 'saleId': id.saleId, status } });
    }
  }
  changeStatus(id) {
    if (this.authService.hasRole(UserRoleConstants.TENANT) && (id.plotStatus == "Sold")) {
      this.router.navigate(['/'+this.commonService.getUrlSuffix(), RoutesConstants.VENTURELAYOUT, RoutesConstants.PLOTSTATUS], { queryParams: { 'plotNum': id.plotNumber, 'plotId': id.plotId } });
    }
  }
  capitalFirst(type) {
    const mySentence = type;
    return mySentence.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  }
  capitalPlot(plot) {
    const str = plot;
    return str.toUpperCase();
  }

  open(content, sales) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.plotdetails = sales;
  }
  close(reason) {
    this.getDismissReason(reason);
  }
  onSubmit(plot: any) {
    const userInfo = this.authService.getUserInfo();
    const path: string = "tenant/update-registration-status";
    plot.tenantId = userInfo.tenantId;
    plot.ventureId = this.commonService.getVenture()?.ventureId;
    plot.plotId = this.plotdetails.plotId;
    plot.plotNum = this.plotdetails.plotNumber;
    this.restService.submitPostRequest(path, plot).subscribe(response => {
      this.plotStatus = response;
      window.location.reload();
    });
  }
  private getDismissReason(reason: any): string {

    return `with: ${reason}`;

  }


  exportActiveSales(): void {
    const formattedData = this.ventureSales.map(sale => ({
      "Plot": sale.plotNumber, 
      "Customer": sale.customerName,
      "Salesrep": sale.name,
      "Plot Status": sale.plotStatus,
      "perMeasurePrice": sale.perMeasurePrice,
      "Sale Value": sale.saleValue,
      "Received Amount": sale.amountReceived,
      "Balance Amount": sale.saleValue-sale.amountReceived,
      "Sale Date": this.datePipe.transform(sale.saleDate, 'dd-MM-yyyy'),
    }));
    this.commonService.exportToExcel(formattedData, 'SalesData', 'sales_data');
  }

  exportCancelledSales(): void {
    const formattedData = this.cancelledSales.map(cancelldSale => ({
      "Plot": cancelldSale.plotNumber, 
      "Customer": cancelldSale.customerName,
      "Salesrep": cancelldSale.salesrepName,
      "Sale Status": cancelldSale.saleStatus,
      "perMeasurePrice": cancelldSale.perMeasurePrice,
      "Sale Value": cancelldSale.saleValue,
      "Received Amount": cancelldSale.saleAmountReceived,
      "Balance Amount": cancelldSale.saleValue-cancelldSale.saleAmountReceived,
      "Sale Date": this.datePipe.transform(cancelldSale.saleDate, 'dd-MM-yyyy'),
      "Cancel Date": this.datePipe.transform(cancelldSale.cancelDate, 'dd-MM-yyyy'),
    }));
    this.commonService.exportToExcel(formattedData, 'CancelSalesData', 'cancel_sales_data');
  }
  

  
}