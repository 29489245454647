<div class="container-fluid full-page-wrapper  login-block bg-white">
  <div class="row h-100">
    <div class="col-lg-7 login-bg d-lg-flex flex-row d-none pattern p-0">
      <img [src]="bannerUrl" alt="logo" class="img-fluid">
    </div>
    <div class="col-lg-5 d-flex align-items-center login-bg-color  justify-content-center">
      <div class="login-bg-color_img"></div>
      <div class="row  text-left p-3 w-100">
        <div class="col-md-8 offset-md-2">
          <div class="brand-logo text-center mb-3 ">
            <img [src]="logoUrl" alt="logo" class="img-fluid">
          </div>
          <form *ngIf="validUrlSuffix || pwaAccess" [formGroup]="loginForm" class="pt-3 my-3" (ngSubmit)="onSubmit(loginForm.valid)">
            <div *ngIf="error" class="text-danger text-center">
              <div>{{errorMessage}}Unauthorized! Username or Password Invalid</div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text border-0">
                    <i class="far fa-user"></i>
                  </span>
                </div>
                <input type="text" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                  formControlName="username" class="form-control border-left-0" id="exampleInputEmail"
                  placeholder="Username">
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                  <div *ngIf="f.username.errors.required">Username is required</div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text border-0">
                    <i class="fas fa-unlock-alt"></i>
                  </span>
                </div>
                <input type="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                  formControlName="password" class="form-control border-left-0" id="exampleInputPassword"
                  placeholder="Password">
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">Password is required</div>
                </div>
              </div>
            </div>
            <div style="line-height: 0.5;"><a [routerLink]= "['/'+commonService.getUrlSuffix() +'/reset-password']"  class="text-white">Forgot password?</a></div>
            <div class="my-3">
              <button type="submit" value="Tenant" class="btn btn-sm btn-primary w-100">
                Login</button>
            </div>
          </form>
          <div *ngIf="!validUrlSuffix && !pwaAccess">
            <div class="alert alert-danger">
              <strong>Warning -> Invalid Url, Please check and try with Correct Url.</strong>
            </div>
          </div>
          <!-- <a routerLink='/register'>New user? Register</a> -->
        </div>
      </div>
      <p class="font-weight-medium text-center position-absolute align-self-end login-copyright pb-2">
        <span class="text-center w-50 d-flex justify-content-center align-items-center mr-auto ml-auto mb-2">
          <img src="assets/images/student-logo.png" alt="" class="d-block text-center img-fluid w-75" />
        </span>

        <small class="text-white"> Copyright <i class="far fa-copyright"></i>
          Vanna Infotech Pvt Ltd. All Rights Reserved.</small>
      </p>
    </div>
  </div>
  <!-- content-wrapper ends -->
</div>