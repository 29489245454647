<div class="container-fluid my-3">

        <div class="row gutters-sm">
            <div class="col-md-4 mb-3">
                <div class="card bg-white">
                    <div class="card-body" >
                        <div class="d-flex flex-column align-items-center text-center">
                            <img src="assets/images/user.png" alt="Admin" class="rounded-circle" width="150">
                            <div class="mt-3">
                                <h4>{{person.firstName}} {{person.lastName}}</h4>
                                <div class="row mt-4 justify-content-center">
                                    <a class="p-2" href= "https://twitter.com/{{person.twitter}}" target="_blank"> 
                                        <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-twitter mr-2 icon-inline text-info">
                                        <path
                                            d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z">
                                        </path>
                                    </svg></h6></a>
                                    <a class="p-2" href= "https://www.instagram.com/{{person.instagram}}" target="_blank">
                                        <p class="mb-0" ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-instagram mr-2 icon-inline text-danger">
                                            <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                                            <path d="M16 11.37A4 4 0 1 1 12.63 8  4 4 0 0 1 16 11.37z"></path>
                                            <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                                        </svg></p>
                                    </a>
                                    <a class="p-2" href= "https://www.facebook.com/{{person.facebookId}}/" target="_blank">
                                        <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-facebook mr-2 icon-inline text-primary">
                                            <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                                        </svg></h6>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mt-3">
                    <div class="card-body">
                        <h6 class="card-title text-dark position-relative pb-2 mb-5">
                            Customize Theme
                        </h6>
                        <div class="py-3 bg-white text-center">
                            <h6 class="text-dark font-weight-bold">Themes</h6>
                            <nav ngbNav #nav="ngbNav"
                                class="nav-tabs border-0 d-flex justify-content-center align-items-center">
                                <ng-container ngbNavItem>
                                    <a ngbNavLink class="p-1" (click)="themeColorChange('primary')">
                                        <i class="fas fa-circle fa-2x  text-primary"></i>
                                    </a>
                                </ng-container>
    
                                <ng-container ngbNavItem>
                                    <a ngbNavLink class="p-1" (click)="themeColorChange('danger')">
                                        <i class="fas fa-circle fa-2x  text-danger"></i>
                                    </a>
                                </ng-container>
    
                                <ng-container ngbNavItem>
                                    <a ngbNavLink class="p-1" (click)="themeColorChange('success')">
                                        <i class="fas fa-circle fa-2x  text-success"></i>
                                    </a>
                                </ng-container>
    
                                <ng-container ngbNavItem>
                                    <a ngbNavLink class="p-1" (click)="themeColorChange('info')">
                                        <i class="fas fa-circle fa-2x  text-info"></i>
                                    </a>
                                </ng-container>
    
                                <ng-container ngbNavItem>
                                    <a ngbNavLink class="p-1" (click)="themeColorChange('dark')">
                                        <i class="fas fa-circle fa-2x  text-dark"></i>
                                    </a>
                                </ng-container>
    
                            </nav>
    
                            <div class="dropdown-divider"></div>
    
    
                            <ul class="list-inline">
                                <li class=" d-md-block d-lg-block d-none">
                                    <h6 class="text-dark font-weight-bold">Header</h6>
                                </li>
                                <li class="list-inline-item d-md-block d-lg-block d-none">
                                    <a class="p-1 d-flex justify-content-center align-content-centers">
                                        <label class="font-weight-bold text-dark">Left</label>
                                        <label class="switch mx-2" for="checkbox">
                                            <input type="checkbox" id="checkbox" [ngModel]="checked"
                                                (change)="horizontalHeader($event)" />
                                            <div class="slider round bg-light"></div>
                                        </label>
                                        <label class="font-weight-bold text-dark">Top</label>
                                    </a>
    
                                </li>
                                <li class=" d-md-block d-lg-block d-none">
                                    <div class="dropdown-divider"></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card mt-3" *ngxPermissionsOnly="[userPrivileges.TENANT_PRIVILEGE]">
                    <div class="card-body">
                        <h6 class="card-title text-dark position-relative pb-2 mb-5">
                            Files
                        </h6>
                        <div class="col-md-10">
                            <div class="card-title font-weight-bold text-dark position-relative pb-2">
                                Logo  
                            </div>
                            <div  >
                                <p>Browse Files</p>
                                <div class="d-flex justify-content-between">
                                    <input type="file" (change)="onLogoFileSelected($event)" [(ngModel)]="logoFile">
                                    <button (click)="uploadLogoFile()" class="btn btn-info btn-sm" >Upload</button>        
                                </div>
                            </div>
                            <div>
                                <table class="table table-striped table-bordered table-sm row-border hover mt-2 mb-4">
                                  <ng-container *ngIf="logoUrl != null; else noFiles">
                                      <tr>
                                        <td>
                                          <a [href]="logoUrl" download>{{ extractFileNameFromUrl(logoUrl) }}</a>
                                        </td>
                                      </tr>
                                  </ng-container>
                                  <ng-template #noFiles>
                                    <tr>
                                      <td colspan="2">No files exist.</td>
                                    </tr>
                                  </ng-template>
                                </table>
                              </div>                          
                        </div>

                        <div class="col-md-10">
                            <div class="card-title font-weight-bold text-dark position-relative pb-2">
                                Banner  
                            </div>
                            <div  >
                                <p>Browse Files</p>
                                <div class="d-flex justify-content-between">
                                    <input type="file" (change)="onBannerFileSelected($event)" [(ngModel)]="bannerFile">
                                    <button (click)="uploadBannerFile()" class="btn btn-info btn-sm" >Upload</button>        
                                </div>
                            </div>
                            <div>
                                <table class="table table-striped table-bordered table-sm row-border hover mt-2 mb-4">
                                  <ng-container *ngIf="bannerUrl != null; else noFiles">
                                      <tr>
                                        <td>
                                          <a [href]="bannerUrl" download>{{ extractFileNameFromUrl(bannerUrl) }}</a>
                                        </td>
                                      </tr>
                                  </ng-container>
                                  <ng-template #noFiles>
                                    <tr>
                                      <td colspan="2">No files exist.</td>
                                    </tr>
                                  </ng-template>
                                </table>
                              </div>                          
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                    <div class="card">
                    <div class="card-body p-3">
                        <h6 class="card-title text-dark position-relative pb-2 h6">Personal Details</h6>
                        <form #myProfile="ngForm" autocomplete="off"
                            >
                            <section class="container-fluid mt-3">
                                <div class="row form-group">
                                    <div class="form-group col-3"
                                        [ngClass]="{'invalid': firstName.invalid && (firstName.dirty || firstName.touched || myProfile.submitted)}">
                                        <label class="">First Name </label>
                                        <input type="text" class="form-control bg-white form-control-sm" readonly name="firstName"
                                            [(ngModel)]="personalDetails.firstName" #firstName="ngModel"
                                            (change)="onChangeSave(personalDetails,myProfile)" readonly required>
                                    </div>

                                    <div class="form-group col-3"
                                        [ngClass]="{'invalid': lastName.invalid && (lastName.dirty || lastName.touched || myProfile.submitted)}">
                                        <label class="">Last Name </label>
                                        <input type="text" class="form-control form-control-sm" readonly name="lastName"
                                            [(ngModel)]="personalDetails.lastName" #lastName=ngModel
                                            (change)="onChangeSave(personalDetails,myProfile)" required>
                                    </div>

                                    <div class="form-group col-3"
                                        [ngClass]="{'invalid': dob.invalid && (dob.dirty || dob.touched || myProfile.submitted)}">
                                        <label class="">DOB </label>
                                        <input type="date" class="form-control form-control-sm" readonly name="dob"
                                            [(ngModel)]="personalDetails.dob" #dob=ngModel
                                            (change)="onChangeSave(personalDetails,myProfile)" required>
                                    </div>

                                    <div class="form-group col-3"
                                        [ngClass]="{'invalid': gender.invalid && (gender.dirty || gender.touched || myProfile.submitted)}">
                                        <label class="">Gender </label>
                                        <input type="text" class="form-control form-control-sm" readonly name="gender"
                                            [(ngModel)]="personalDetails.gender" #gender=ngModel
                                            (change)="onChangeSave(personalDetails,myProfile)" required>
                                    </div>

                                    <div class="form-group col-3"
                                        [ngClass]="{'invalid': bloodGroup.invalid && (bloodGroup.dirty || bloodGroup.touched || myProfile.submitted)}">
                                        <label class="">Blood Group </label>
                                        <input type="text" class="form-control form-control-sm" readonly name="bloodGroup"
                                            [(ngModel)]="personalDetails.bloodGroup" #bloodGroup=ngModel
                                            (change)="onChangeSave(personalDetails,myProfile)" required>
                                    </div>

                                    <div class="form-group col-3"
                                        [ngClass]="{'invalid': nationality.invalid && (nationality.dirty || nationality.touched || myProfile.submitted)}">
                                        <label class="">Nationality </label>
                                        <input type="text" class="form-control form-control-sm" readonly name="nationality"
                                            [(ngModel)]="personalDetails.nationality" #nationality=ngModel
                                            (change)="onChangeSave(personalDetails,myProfile)" required>
                                    </div>

                                    <div class="form-group col-3"
                                        [ngClass]="{'invalid': panNum.invalid && (panNum.dirty || panNum.touched || myProfile.submitted)}">
                                        <label class="">PAN Number </label>
                                        <input type="text" class="form-control form-control-sm" readonly name="panNum"
                                            [(ngModel)]="personalDetails.panNum" #panNum=ngModel
                                            (change)="onChangeSave(personalDetails,myProfile)" required>
                                    </div>

                                    <div class="form-group col-3"
                                        [ngClass]="{'invalid': aadharNum.invalid && (aadharNum.dirty || aadharNum.touched || myProfile.submitted)}">
                                        <label class="">Aadhaar Number </label>
                                        <input type="number" class="form-control form-control-sm" readonly name="aadharNum"
                                            [(ngModel)]="personalDetails.aadharNum" #aadharNum=ngModel
                                            (change)="onChangeSave(personalDetails,myProfile)" required>
                                    </div>

                                    <div class="form-group col-3"
                                        [ngClass]="{'invalid': contactNumber.invalid && (contactNumber.dirty || contactNumber.touched || myProfile.submitted)}">
                                        <label class="">Contact Number </label>
                                        <input type="number" class="form-control form-control-sm" readonly name="contactNumber"
                                            [(ngModel)]="personalDetails.contactNumber" #contactNumber=ngModel
                                            (change)="onChangeSave(personalDetails,myProfile)" required>
                                    </div>

                                    <div class="form-group col-3"
                                        [ngClass]="{'invalid': alternateContact.invalid && (alternateContact.dirty || alternateContact.touched || myProfile.submitted)}">
                                        <label class="">Alternate Contact </label>
                                        <input type="number" class="form-control form-control-sm"
                                            name="alternateContact" readonly [(ngModel)]="personalDetails.alternateContact"
                                            #alternateContact=ngModel (change)="onChangeSave(personalDetails,myProfile)"
                                            required>
                                    </div>

                                    <div class="form-group col-3">
                                        <label class="">Email ID</label>
                                        <input type="text" class="form-control form-control-sm" readonly name="email"
                                            [(ngModel)]="personalDetails.email" #email=ngModel
                                            (change)="onChangeSave(personalDetails,myProfile)" required>
                                    </div>

                                    <div class="form-group col-3"
                                        [ngClass]="{'invalid': alternateEmail.invalid && (alternateEmail.dirty || alternateEmail.touched || myProfile.submitted)}">
                                        <label class="">Alternate Email ID</label>
                                        <input type="text" class="form-control form-control-sm" readonly name="alternateEmail"
                                            [(ngModel)]="personalDetails.alternateEmail" #alternateEmail=ngModel
                                            (change)="onChangeSave(personalDetails,myProfile)" required>
                                    </div>
                                    <div class="form-group col-4">
                                    <label class="">Twitter URL</label>
                                    <input type="text" class="form-control form-control-sm" readonly
                                        name="twitter" [(ngModel)]="personalDetails.facebookId"
                                        #alternateContact=ngModel (change)="onChangeSave(personalDetails,myProfile)"
                                        required>
                                </div>

                                <div class="form-group col-4"
                                    [ngClass]="{'invalid': email.invalid && (email.dirty || email.touched || myProfile.submitted)}">
                                    <label class="">Instagram URL</label>
                                    <input type="text" class="form-control form-control-sm" readonly name="email"
                                        [(ngModel)]="personalDetails.facebookId" #email=ngModel
                                        (change)="onChangeSave(personalDetails,myProfile)" required>
                                </div>

                                <div class="form-group col-4"
                                    [ngClass]="{'invalid': alternateEmail.invalid && (alternateEmail.dirty || alternateEmail.touched || myProfile.submitted)}">
                                    <label class="">Facebook URL</label>
                                    <input type="text" class="form-control form-control-sm" readonly name="alternateEmail"
                                        [(ngModel)]="personalDetails.facebookId" #alternateEmail=ngModel
                                        (change)="onChangeSave(personalDetails,myProfile)" required>
                                </div>

                                </div>
                            </section>
                        </form>
                    </div>

                </div>
                 <div class="card pt-0 mt-3">
                    <div class="card-body p-3">
                        <h6 class="card-title text-dark position-relative pb-2 h6">Permanent Address</h6>
                        <form #myProfile="ngForm" class="mt-3" autocomplete="off">
                           <div>
                            <section class="container-fluid mt-3">
                                <div class="row form-group">
                                    
                                    <div class="form-group col-3"
                                        [ngClass]="{'invalid': addressCatogery.invalid && (addressCatogery.dirty || addressCatogery.touched || myProfile.submitted)}">
                                        <label class="">Category </label>
                                        <input type="text" class="form-control form-control-sm" readonly name="addressCatogery"
                                            [(ngModel)]="address.addressCatogery" #addressCatogery=ngModel
                                            (change)="onChangeSave(personalDetails,myProfile)" required>
                                    </div>

                                    <div class="form-group col-3"
                                        [ngClass]="{'invalid': addressLine1.invalid && (addressLine1.dirty || addressLine1.touched || myProfile.submitted)}">
                                        <label class="">Address Line 1 </label>
                                        <input type="text" class="form-control form-control-sm" readonly name="addressLine1"
                                            [(ngModel)]="address.addressLine1" #addressLine1=ngModel
                                            (change)="onChangeSave(personalDetails,myProfile)" required>
                                    </div>

                                    <div class="form-group col-3"
                                        [ngClass]="{'invalid': addressLine2.invalid && (addressLine2.dirty || addressLine2.touched || myProfile.submitted)}">
                                        <label class="">Address Line 2 </label>
                                        <input type="text" class="form-control form-control-sm" readonly name="addressLine2"
                                            [(ngModel)]="address.addressLine2" #addressLine2=ngModel
                                            (change)="onChangeSave(personalDetails,myProfile)" required>
                                    </div>

                                    <div class="form-group col-3"
                                        [ngClass]="{'invalid': landmark.invalid && (landmark.dirty || landmark.touched || myProfile.submitted)}">
                                        <label class="">Landmark </label>
                                        <input type="text" class="form-control form-control-sm" readonly name="landmark"
                                            [(ngModel)]="address.landmark" #landmark=ngModel
                                            (change)="onChangeSave(personalDetails,myProfile)" required>
                                    </div>

                                    <div class="form-group col-3"
                                        [ngClass]="{'invalid': city.invalid && (city.dirty || city.touched || myProfile.submitted)}">
                                        <label class="">City </label>
                                        <input type="text" class="form-control form-control-sm" readonly name="city"
                                            [(ngModel)]="address.city" #city=ngModel
                                            (change)="onChangeSave(personalDetails,myProfile)" required>
                                    </div>

                                    <div class="form-group col-3"
                                        [ngClass]="{'invalid': state.invalid && (state.dirty || state.touched || myProfile.submitted)}">
                                        <label class="">State </label>
                                        <input type="text" class="form-control form-control-sm" readonly name="state"
                                            [(ngModel)]="address.state" #state=ngModel
                                            (change)="onChangeSave(personalDetails,myProfile)" required>
                                    </div>
                                    <div class="form-group col-3"
                                    [ngClass]="{'invalid': country.invalid && (country.dirty || country.touched || myProfile.submitted)}">
                                    <label class="">Country</label>
                                    <input type="text" class="form-control form-control-sm" readonly name="country"
                                        [(ngModel)]="address.country" #country=ngModel
                                        (change)="onChangeSave(personalDetails,myProfile)" required>
                                </div>
                                    <div class="form-group col-3"
                                        [ngClass]="{'invalid': pin.invalid && (pin.dirty || pin.touched || myProfile.submitted)}">
                                        <label class="">PIN Code </label>
                                        <input type="text" class="form-control form-control-sm" readonly name="pin"
                                            [(ngModel)]="address.pin" #pin=ngModel
                                            (change)="onChangeSave(personalDetails,myProfile)" required>
                                    </div>

                                </div>
                            </section>
                        </div>
                        </form>
                    </div>
                </div>
                     <div class="card pt-0 mt-3" *ngIf = "commAddress != null">
                        <div class="card-body p-3">
                            <h6 class="card-title text-dark position-relative pb-2 h6">Present Address</h6>
                            <form #myProfile="ngForm" class="mt-3" autocomplete="off">
                               <div >
                                <section class="container-fluid mt-3">
                                    <div class="row form-group">
                                        
                                        <div class="form-group col-3"
                                            [ngClass]="{'invalid': addressCatogery.invalid && (addressCatogery.dirty || addressCatogery.touched || myProfile.submitted)}">
                                            <label class="">Category </label>
                                            <input type="text" class="form-control form-control-sm" readonly name="addressCatogery"
                                                [(ngModel)]="commAddress.addressCatogery" #addressCatogery=ngModel
                                                (change)="onChangeSave(personalDetails,myProfile)" required>
                                        </div>
    
                                        <div class="form-group col-3"
                                            [ngClass]="{'invalid': addressLine1.invalid && (addressLine1.dirty || addressLine1.touched || myProfile.submitted)}">
                                            <label class="">Address Line 1 </label>
                                            <input type="text" class="form-control form-control-sm" readonly name="addressLine1"
                                                [(ngModel)]="commAddress.addressLine1" #addressLine1=ngModel
                                                (change)="onChangeSave(personalDetails,myProfile)" required>
                                        </div>
    
                                        <div class="form-group col-3"
                                            [ngClass]="{'invalid': addressLine2.invalid && (addressLine2.dirty || addressLine2.touched || myProfile.submitted)}">
                                            <label class="">Address Line 2 </label>
                                            <input type="text" class="form-control form-control-sm" readonly name="addressLine2"
                                                [(ngModel)]="commAddress.addressLine2" #addressLine2=ngModel
                                                (change)="onChangeSave(personalDetails,myProfile)" required>
                                        </div>
    
                                        <div class="form-group col-3"
                                            [ngClass]="{'invalid': landmark.invalid && (landmark.dirty || landmark.touched || myProfile.submitted)}">
                                            <label class="">Landmark </label>
                                            <input type="text" class="form-control form-control-sm" readonly name="landmark"
                                                [(ngModel)]="commAddress.landmark" #landmark=ngModel
                                                (change)="onChangeSave(personalDetails,myProfile)" required>
                                        </div>
    
                                        <div class="form-group col-3"
                                            [ngClass]="{'invalid': city.invalid && (city.dirty || city.touched || myProfile.submitted)}">
                                            <label class="">City </label>
                                            <input type="text" class="form-control form-control-sm" readonly name="city"
                                                [(ngModel)]="commAddress.city" #city=ngModel
                                                (change)="onChangeSave(personalDetails,myProfile)" required>
                                        </div>
    
                                        <div class="form-group col-3"
                                            [ngClass]="{'invalid': state.invalid && (state.dirty || state.touched || myProfile.submitted)}">
                                            <label class="">State </label>
                                            <input type="text" class="form-control form-control-sm"  readonly name="state"
                                                [(ngModel)]="commAddress.state" #state=ngModel
                                                (change)="onChangeSave(personalDetails,myProfile)" required>
                                        </div>
    
                                        <div class="form-group col-3"
                                            [ngClass]="{'invalid': pin.invalid && (pin.dirty || pin.touched || myProfile.submitted)}">
                                            <label class="">Pin Code </label>
                                            <input type="number" class="form-control form-control-sm" readonly name="pin"
                                                [(ngModel)]="commAddress.pin" #pin=ngModel
                                                (change)="onChangeSave(personalDetails,myProfile)" required>
                                        </div>
    
                                        <div class="form-group col-3"
                                            [ngClass]="{'invalid': country.invalid && (country.dirty || country.touched || myProfile.submitted)}">
                                            <label class="">Country</label>
                                            <input type="text" class="form-control form-control-sm" readonly name="country"
                                                [(ngModel)]="commAddress.country" #country=ngModel
                                                (change)="onChangeSave(personalDetails,myProfile)" required>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            </form>
                        </div>
    

                </div> 
                <div class="card mt-3">
                    <div class="card-body p-3" >
                        <h6 class="card-title col-md-12 text-dark position-relative pb-2 h6">
                            Bank Details
                        </h6>
                        <div class="col-md-12">
                            <button class="btn btn-info" (click) = "addbank()" *ngIf= "bankArray ==null && !isshow">Add BankDetails</button>
                        </div>
                        <div class="col-md-12 text-right">
                            <button class="btn btn-info" (click) = "goBack()" [hidden]= "!isshow">Go Back</button>
                        </div>
                        <div [hidden] = "!isshow">
                        <app-addbankdetails></app-addbankdetails>
                    </div>
                        <form *ngIf= "bankArray !=null" >
                            <div class="row mb-2 p-3" >
                                
                                <div class="form-group my-2"
                                    [ngClass]="{'invalid': bankName.invalid && (bankName.dirty || bankName.touched)}">
                                    <label class="star mr-sm-2">Bank Name: </label>
                                    <input type="text" class="form-control form-control-sm" name="bankName"
                                        [(ngModel)]="bankArray.bankName" #bankName=ngModel
                                        (change)="onChangeSave(personalDetails)" required>
                                </div>

                                <div class="form-group my-2"
                                    [ngClass]="{'invalid': ifscCode.invalid && (ifscCode.dirty || ifscCode.touched)}">
                                    <label class="star mr-sm-2">IFSC Code: </label>
                                    <input type="text" class="form-control form-control-sm" name="ifscCode"
                                        [(ngModel)]="bankArray.ifscCode" #ifscCode=ngModel
                                        (change)="onChangeSave(personalDetails)" required>
                                </div>

                                <div class="form-group my-2"
                                    [ngClass]="{'invalid': accountNumber.invalid && (accountNumber.dirty || accountNumber.touched )}">
                                    <label class=" mr-sm-2">Account Number: </label>
                                    <input type="number" class="form-control form-control-sm" name="accountNumber"
                                        [(ngModel)]="bankArray.accountNumber" #accountNumber=ngModel
                                        (change)="onChangeSave(personalDetails)" required>
                                </div>

                                <div class="form-group my-2"
                                    [ngClass]="{'invalid': accountName.invalid && (accountName.dirty || accountName.touched )}">
                                    <label class=" mr-sm-2">Account Holder: </label>
                                    <input type="text" class="form-control form-control-sm" name="accountName"
                                        [(ngModel)]="bankArray.accountName" #accountName=ngModel
                                        (change)="onChangeSave(personalDetails)" required>
                                </div>

                                <div class="form-group my-2"
                                    [ngClass]="{'invalid': branchName.invalid && (branchName.dirty || branchName.touched )}">
                                    <label class=" mr-sm-2">Branch Name: </label>
                                    <input type="text" class="form-control form-control-sm" name="branchName"
                                        [(ngModel)]="bankArray.branchName" #branchName=ngModel
                                        (change)="onChangeSave(personalDetails)" required>
                                </div>

                                <div class="form-group my-2"
                                    [ngClass]="{'invalid': branchAddress.invalid && (branchAddress.dirty || branchAddress.touched )}">
                                    <label class=" mr-sm-2">Branch Address: </label>
                                    <input type="text" class="form-control form-control-sm" name="branchName"
                                        [(ngModel)]="bankArray.branchAddress" #branchAddress=ngModel
                                        (change)="onChangeSave(personalDetails)" required>
                                </div>
                            </div>
                        </form>
                    </div>
                </div> 

            </div>
        </div>
</div>