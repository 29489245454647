<div [hidden]="submitExisting">
  <div class="combobox">
    <input type="text" placeholder="Select one..." 
    class="combobox-input" [(ngModel)]="inputItem"
    (ngModelChange)="getFilteredList()" (keyup)="onKeyPress($event)"
    (blur)="toggleListDisplay(0)" (focus)="toggleListDisplay(1)"
    [ngClass]="{'error': showError}">
    <i *ngIf="showError" class="error-text">Invalid Selection.</i>
    <div class="combobox-options" *ngIf="!listHidden">
        <list-item *ngFor="let item of filteredList;let i = index"
        [ngClass]="{'selected': i===selectedIndex}"
        (click)="selectItem(i)" (click)="onExisting()">
            {{item}}
        </list-item>
    </div>
  </div>
</div>
<div [hidden]="!submitExisting">
  <app-existing-customer [selectedCustomer]="inputItem"></app-existing-customer>
</div>