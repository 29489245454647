<div class="container-fluid my-3 text-dark">
    <div class="card shadow p-4">
        <div class="card-body">
            <h6 class="card-title text-dark position-relative pb-2 ">Update Marketer</h6>
            <div id="stepper1" class="bs-stepper">
                <div class="bs-stepper-header">
                    <div class="step" data-target="#personaldetails">
                        <button class="step-trigger">
                            <span class="bs-stepper-circle">1</span>
                            <span class="bs-stepper-label">Marketer Info.</span>
                        </button>
                    </div>
                    <div class="line"></div>
                    <div class="step" data-target="#permanentAddress">
                        <button class="step-trigger">
                            <span class="bs-stepper-circle">2</span>
                            <span class="bs-stepper-label">Perm. Address</span>
                        </button>
                    </div>
                    <div class="line"></div>
                    <div class="step" data-target="#communicateAddress">
                        <button class="step-trigger">
                            <span class="bs-stepper-circle">3</span>
                            <span class="bs-stepper-label">Comm. Address</span>
                        </button>
                    </div>
                </div>
                <div class="bs-stepper-content">
                    <form #details="ngForm">
                        <div id="personaldetails" class="content">
                            <div class="row">
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (displayName.invalid && (displayName.dirty || displayName.touched))}">
                                    <label class="star">Display Name</label>
                                    <input type="text" class="form-control" id="displayName" name="displayName"
                                        #displayName="ngModel" required [(ngModel)]="marketerList.displayName">
                                </div>
                            </div>
                            <div class="row" *ngIf="marketerList.marketerType == 'company'">
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (companyCategory.invalid && (companyCategory.dirty || companyCategory.touched))}">
                                    <label class="star">Company Category</label>
                                    <input type="text" class="form-control" id="companyCategory" name="companyCategory"
                                        #companyCategory="ngModel" [(ngModel)]="primaryCompany.companyCategory">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (companyName.invalid && (companyName.dirty || companyName.touched))}">
                                    <label class="star">Company Name</label>
                                    <input type="text" class="form-control" id="companyName" name="companyName"
                                        #companyName="ngModel" [(ngModel)]="primaryCompany.companyName">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (companyType.invalid && (companyType.dirty || companyType.touched))}">
                                    <label class="star">Company Type</label>
                                    <input type="text" class="form-control" id="companyType" #companyType="ngModel"
                                        name="companyType" [(ngModel)]="primaryCompany.companyType">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (registrationNumber.invalid && (registrationNumber.dirty || registrationNumber.touched))}">
                                    <label class="star">Registration Number</label>
                                    <input type="text" class="form-control" id="registrationNumber"
                                        #registrationNumber="ngModel" name="registrationNumber"
                                        [(ngModel)]="primaryCompany.registrationNumber">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (tanNumber.invalid && (tanNumber.dirty || tanNumber.touched))}">
                                    <label class="star">TAN Number</label>
                                    <input type="text" class="form-control" id="tanNumber" #tanNumber="ngModel"
                                        name="tanNumber" [(ngModel)]="primaryCompany.tanNumber">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (gstNumber.invalid && (gstNumber.dirty || gstNumber.touched))}">
                                    <label class="star">GST Number</label>
                                    <input type="text" class="form-control" id="gstNumber" #gstNumber="ngModel"
                                        name="gstNumber" [(ngModel)]="primaryCompany.gstNumber">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (contactNumber.invalid && (contactNumber.dirty || contactNumber.touched))}">
                                    <label class="star">Contact Number</label>
                                    <input type="tel" class="form-control" id="contactNumber" #contactNumber="ngModel"
                                        name="contactNumber" [(ngModel)]="primaryCompany.contactNumber">
                                </div>
                                <div class="form-group col-md-4">
                                    <label class="">Alternate Contact Number</label>
                                    <input type="tel" class="form-control" name="alternateContact" id="alternateContact"
                                        #alternateContact="ngModel" [(ngModel)]="primaryCompany.alternateContact">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (email.invalid && (email.dirty || email.touched))}">
                                    <label class="star">Email ID</label>
                                    <input type="email" class="form-control" id="email" name="email" #email="ngModel"
                                        [(ngModel)]="primaryCompany.email">
                                </div>
                                <div class="form-group col-md-4">
                                    <label class="">Alternate Email ID</label>
                                    <input type="email" class="form-control" id="alternateEmail" name="alternateEmail"
                                        [(ngModel)]="primaryCompany.alternateEmail">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Description</label>
                                    <input type="text" class="form-control" id="description" name="description"
                                        [(ngModel)]="primaryCompany.description">
                                </div>
                                <div class="row mx-1">
                                    <div class="col-md-12">
                                        <h6 class="card-title text-dark position-relative pb-2 h6 ">Managing Director
                                        </h6>
                                    </div>
                                    <div class="form-group col-md-4"
                                        [ngClass]="{'invalid': (firstName.invalid && (firstName.dirty || firstName.touched))}">
                                        <label class="star">First Name</label>
                                        <input type="text" class="form-control" id="firstName" required
                                            #firstName="ngModel" name="firstName"
                                            [(ngModel)]="personaldetails.firstName">
                                    </div>
                                    <div class="form-group col-md-4"
                                        [ngClass]="{'invalid': (lastName.invalid && (lastName.dirty || lastName.touched))}">
                                        <label class="star">Last Name</label>
                                        <input type="text" class="form-control" id="lastName" required
                                            #lastName="ngModel" name="lastName" [(ngModel)]="personaldetails.lastName">
                                    </div>
                                    <div class="form-group col-md-4"
                                        [ngClass]="{'invalid': (dob.invalid && (dob.dirty || dob.touched))}">
                                        <label class="star">DOB</label>
                                        <input type="date" class="form-control" id="dob" required #dob="ngModel"
                                            name="dob" [(ngModel)]="personaldetails.dob">
                                    </div>
                                    <div class="form-group col-md-4"
                                        [ngClass]="{'invalid': (gender.invalid && (gender.dirty || gender.touched))}">
                                        <label class="star">Gender</label>
                                        <input type="text" class="form-control" id="gender" required #gender="ngModel"
                                            name="gender" [(ngModel)]="personaldetails.gender">
                                    </div>
                                    <div class="form-group col-md-4"
                                        [ngClass]="{'invalid': (bloodGroup.invalid && (bloodGroup.dirty || bloodGroup.touched))}">
                                        <label class="star">Blood Group</label>
                                        <input type="text" class="form-control" id="bloodGroup" required
                                            #bloodGroup="ngModel" name="bloodGroup"
                                            [(ngModel)]="personaldetails.bloodGroup">
                                    </div>
                                    <div class="form-group col-md-4"
                                        [ngClass]="{'invalid': (nationality.invalid && (nationality.dirty || nationality.touched))}">
                                        <label class="star">Nationality</label>
                                        <input type="text" class="form-control" id="nationality" required
                                            #nationality="ngModel" name="nationality"
                                            [(ngModel)]="personaldetails.nationality">
                                    </div>
                                    <div class="form-group col-md-4"
                                        [ngClass]="{'invalid': (panNum.invalid && (panNum.dirty || panNum.touched))}">
                                        <label class="star">PAN Number</label>
                                        <input type="text" class="form-control" id="panNum" required #panNum="ngModel"
                                            name="panNum" [(ngModel)]="personaldetails.panNum">
                                    </div>
                                    <div class="form-group col-md-4"
                                        [ngClass]="{'invalid': (aadharNum.invalid && (aadharNum.dirty || aadharNum.touched))}">
                                        <label class="star">Aadhar Number</label>
                                        <input type="number" class="form-control" id="aadharNum" required
                                            #aadharNum="ngModel" name="aadharNum"
                                            [(ngModel)]="personaldetails.aadharNum">
                                    </div>
                                    <div class="form-group col-md-4"
                                        [ngClass]="{'invalid': (contactNumber.invalid && (contactNumber.dirty || contactNumber.touched))}">
                                        <label class="star">Contact Number</label>
                                        <input type="tel" class="form-control" id="contactNumber" required
                                            #contactNumber="ngModel" name="contactNumber"
                                            [(ngModel)]="personaldetails.contactNumber">
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="">Alternate Contact Number</label>
                                        <input type="tel" class="form-control" name="alternateContact"
                                            id="alternateContact" #alternateContact="ngModel"
                                            [(ngModel)]="personaldetails.alternateContact">
                                    </div>
                                    <div class="form-group col-md-4"
                                        [ngClass]="{'invalid': (email.invalid && (email.dirty || email.touched))}">
                                        <label class="star">Email ID</label>
                                        <input type="email" class="form-control" id="email" required name="email"
                                            #email="ngModel" [(ngModel)]="personaldetails.email">
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="">Alternate Email ID</label>
                                        <input type="email" class="form-control" id="alternateEmail"
                                            name="alternateEmail" [(ngModel)]="personaldetails.alternateEmail">
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label>Facebook Id</label>
                                        <input type="text" class="form-control" id="facebookId"
                                            name="facebookId" #facebookId="ngModel"
                                            [(ngModel)]="personaldetails.facebookId">
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label>Comments</label>
                                        <input type="text" class="form-control" id="comments" name="comments"
                                            [(ngModel)]="personaldetails.comments">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <button type="submit" class="btn btn-sm btn-info" (click)="next()">Next</button>
                                </div>
                            </div>
                            <div class="row" *ngIf="marketerList.marketerType == 'person'">
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (firstName.invalid && (firstName.dirty || firstName.touched))}">
                                    <label class="star">First Name</label>
                                    <input type="text" class="form-control" id="firstName" required #firstName="ngModel"
                                        name="firstName" [(ngModel)]="personaldetails.firstName">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (lastName.invalid && (lastName.dirty || lastName.touched))}">
                                    <label class="star">Last Name</label>
                                    <input type="text" class="form-control" id="lastName" required #lastName="ngModel"
                                        name="lastName" [(ngModel)]="personaldetails.lastName">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (dob.invalid && (dob.dirty || dob.touched))}">
                                    <label class="star">DOB</label>
                                    <input type="date" class="form-control" id="dob" required #dob="ngModel" name="dob"
                                        [(ngModel)]="personaldetails.dob">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (gender.invalid && (gender.dirty || gender.touched))}">
                                    <label class="star">Gender</label>
                                    <input type="text" class="form-control" id="gender" required #gender="ngModel"
                                        name="gender" [(ngModel)]="personaldetails.gender">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (bloodGroup.invalid && (bloodGroup.dirty || bloodGroup.touched))}">
                                    <label class="star">Blood Group</label>
                                    <input type="text" class="form-control" id="bloodGroup" required
                                        #bloodGroup="ngModel" name="bloodGroup"
                                        [(ngModel)]="personaldetails.bloodGroup">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (nationality.invalid && (nationality.dirty || nationality.touched))}">
                                    <label class="star">Nationality</label>
                                    <input type="text" class="form-control" id="nationality" required
                                        #nationality="ngModel" name="nationality"
                                        [(ngModel)]="personaldetails.nationality">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (panNum.invalid && (panNum.dirty || panNum.touched))}">
                                    <label class="star">PAN Number</label>
                                    <input type="text" class="form-control" id="panNum" required #panNum="ngModel"
                                        name="panNum" [(ngModel)]="personaldetails.panNum">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (aadharNum.invalid && (aadharNum.dirty || aadharNum.touched))}">
                                    <label class="star">Aadhar Number</label>
                                    <input type="number" class="form-control" id="aadharNum" required
                                        #aadharNum="ngModel" name="aadharNum" [(ngModel)]="personaldetails.aadharNum">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (contactNumber.invalid && (contactNumber.dirty || contactNumber.touched))}">
                                    <label class="star">Contact Number</label>
                                    <input type="tel" class="form-control" id="contactNumber" required
                                        #contactNumber="ngModel" name="contactNumber"
                                        [(ngModel)]="personaldetails.contactNumber">
                                </div>
                                <div class="form-group col-md-4">
                                    <label class="">Alternate Contact Number</label>
                                    <input type="tel" class="form-control" name="alternateContact" id="alternateContact"
                                         #alternateContact="ngModel"
                                        [(ngModel)]="personaldetails.alternateContact">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (email.invalid && (email.dirty || email.touched))}">
                                    <label class="star">Email ID</label>
                                    <input type="email" class="form-control" id="email" required name="email"
                                        #email="ngModel" [(ngModel)]="personaldetails.email">
                                </div>
                                <div class="form-group col-md-4">
                                    <label class="">Alternate Email ID</label>
                                    <input type="email" class="form-control" id="alternateEmail" name="alternateEmail"
                                        [(ngModel)]="personaldetails.alternateEmail">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Facebook Id</label>
                                    <input type="text" class="form-control" id="facebookId" name="facebookId"
                                        #facebookId="ngModel" [(ngModel)]="personaldetails.facebookId">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Comments</label>
                                    <input type="text" class="form-control" id="comments" name="comments"
                                        [(ngModel)]="personaldetails.comments">
                                </div>
                                <div class="col-6">
                                    <button type="submit" class="btn btn-sm btn-info" (click)="next()">Next</button>
                                </div>
                            </div>
                        </div>
                        <div id="permanentAddress" class="content">
                            <div class="row">
                                <div class="form-group col-md-3">
                                    <label class="star">Address Line 1</label>
                                    <input type="text" class="form-control" id="addressLine1" required
                                        [(ngModel)]="permAddress.addressLine1" name="addressLine1">
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Address Line 2</label>
                                    <input type="text" class="form-control" id="addressLine2"
                                        [(ngModel)]="permAddress.addressLine2" name="addressLine2"
                                        #addressLine2="ngModel">
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="star">LandMark</label>
                                    <input type="text" class="form-control" id="landmark" required
                                        [(ngModel)]="permAddress.landmark" name="landmark" #landmark="ngModel">
                                </div>
                                <div class="form-group col-md-3"
                                    [ngClass]="{'invalid': city.invalid && (city.dirty || city.touched)}">
                                    <label class="star">City</label>
                                    <input type="text" class="form-control" id="city" required
                                        [(ngModel)]="permAddress.city" name="city" #city="ngModel">
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="star">Mandal</label>
                                    <input type="text" class="form-control" id="mandal" required
                                        [(ngModel)]="permAddress.mandal" name="mandal" #mandal="ngModel">
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="star">District</label>
                                    <input type="text" class="form-control" id="district" required
                                        [(ngModel)]="permAddress.district" name="district" #district="ngModel">
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="star">State</label>
                                    <input type="text" class="form-control" id="state" required
                                        [(ngModel)]="permAddress.state" name="state" #state="ngModel">
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="star">PIN</label>
                                    <input type="number" class="form-control" id="pin" required
                                        [(ngModel)]="permAddress.pin" name="pin" #pin="ngModel">
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="star">Country</label>
                                    <input type="text" class="form-control" id="country" required
                                        [(ngModel)]="permAddress.country" name="country">
                                </div>
                            </div>
                            <button type="submit" class="btn btn-sm btn-info" (click)="next()">Next</button>
                        </div>
                        <div id="communicateAddress" class="content">
                            <div class="row">
                                <div class="form-group col-md-3">
                                    <label class="star">Address Line 1</label>
                                    <input type="text" class="form-control" id="address1Line1"
                                        [(ngModel)]="commAddress.addressLine1" name="address1Line1">
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Address Line 2</label>
                                    <input type="text" class="form-control" id="address1Line2"
                                        [(ngModel)]="commAddress.addressLine2" name="address1Line2">
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="star">LandMark</label>
                                    <input type="text" class="form-control" id="landmark1"
                                        [(ngModel)]="commAddress.landmark" name="landmark1">
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="star">City</label>
                                    <input type="text" class="form-control" id="city1" [(ngModel)]="commAddress.city"
                                        name="city1">
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="star">Mandal</label>
                                    <input type="text" class="form-control" id="mandal1"
                                        [(ngModel)]="commAddress.mandal" name="mandal1">
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="star">District</label>
                                    <input type="text" class="form-control" id="district1"
                                        [(ngModel)]="commAddress.district" name="district1">
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="star">State</label>
                                    <input type="text" class="form-control" id="state1" [(ngModel)]="commAddress.state"
                                        name="state1">
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="star">PIN</label>
                                    <input type="number" class="form-control" id="pin1" [(ngModel)]="commAddress.pin"
                                        name="pin1">
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="star">Country</label>
                                    <input type="text" class="form-control" id="country1"
                                        [(ngModel)]="commAddress.country" name="country1">
                                </div>
                            </div>
                            <button type="submit" class="btn btn-info" [disabled]=details.invalid
                                (click)="onsubmit(details.value)">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>