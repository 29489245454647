import { Component, OnInit } from '@angular/core';
import { UserRoleConstants } from 'src/app/constants/AppConstants';
import { User } from 'src/app/models/user.model';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-sidebar-marketer',
  templateUrl: './sidebar-marketer.component.html',
  styleUrls: ['./sidebar-marketer.component.scss']
})
export class SidebarMarketerComponent implements OnInit {

  status= true;
  profileSub = false;
  menutoggled = true;
  marketerUser: boolean = false;
  userInfo: User = new User();
  lastLogged: any;

  constructor(
    public themeService: ThemeService,
    public commonService: CommonServiceService,
    public authService: AuthServiceService
  ) {}

  ngOnInit() {
    this.marketerUser = this.authService.hasRole(UserRoleConstants.TENANT);
    this.userInfo = this.authService.getUserInfo();
    this.lastLogged = this.authService.getUserInfo().lastLoggedIn
  }

  menuToggle(){
    this.status = !this.status;       
  }

  toggleNavigation(){
    this.menutoggled = !this.menutoggled;
  }

  profileSubmenu(){
    this.profileSub = !this.profileSub;   
  }
  
}