import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { UserPrivileges } from 'src/app/constants/AppConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-marketerventure',
  templateUrl: './marketerventure.component.html',
  styleUrls: ['./marketerventure.component.scss']
})
export class MarketerventureComponent implements OnInit {
  @Input() id;
  submit: boolean;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  userInfo: any;
  marketerventureList: any = [];
  marketer: any;
  userPrivileges = UserPrivileges;

  constructor(
    private authService: AuthServiceService,
    private restService: RestService
  ) { }

  ngOnInit(): void {
    this.marketer = this.id
    this.userInfo = this.authService.getUserInfo();
    let data = {
      "tenantId": this.userInfo.tenantId,
      "marketerId": this.id
    };
    const path: string = '/venture-marketing/get-venture-marketing';
    this.restService.submitPostRequest(path, data).subscribe(response => {
      this.marketerventureList = response.data;
    });
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      searching: false
    };
  }
  onSubmit() {
    this.submit = true;
  }
  onShow() {
    this.submit = false;
  }

}
