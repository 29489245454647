<div class="container-fluid my-3">
  <div class="card bg-white col-md-8">
    <div class="card-body">
      <h6 class="card-title text-dark position-relative pb-2">Sale Report</h6>
      <div>
        <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
          [plugins]="barChartPlugins" [legend]="barChartLegend" [colors]="barChartColors" [chartType]="barChartType">
        </canvas>
      </div>
    </div>
  </div>

</div>