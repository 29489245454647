import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NgxPermissionsGuard } from "ngx-permissions";
import { ChangepasswordComponent } from "./components/changepassword/changepassword.component";
import { ReportDetailsComponent } from "./components/report-details/report-details.component";
import { ResetpasswordComponent } from "./components/resetpassword/resetpassword.component";
import { UserPrivileges } from "./constants/AppConstants";
import { RoutesConstants } from "./constants/RoutesConstants";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { MaindashboardComponent } from "./layouts/admin-layout/maindashboard/maindashboard.component";
import { MainLayoutComponent } from "./layouts/main-layout/main-layout.component";
import { SalesrepLayoutComponent } from "./layouts/salesrep-layout/salesrep-layout.component";
import { VenturelayoutComponent } from "./layouts/venturelayout/venturelayout.component";
import { AnalyticsComponent } from "./pages/analytics/analytics.component";
import { CustomerDetailsComponent } from "./pages/customer-details/customer-details.component";
import { CustomersComponent } from "./pages/customers/customers.component";
import { AddcommissionpaymentsComponent } from "./pages/forms/addcommissionpayments/addcommissionpayments.component";
import { AddcommissionstructureComponent } from "./pages/forms/addcommissionstructure/addcommissionstructure.component";
import { AddingmarketerventureComponent } from "./pages/forms/addingmarketerventure/addingmarketerventure.component";
import { AddplotComponent } from "./pages/forms/addplot/addplot.component";
import { AddplotpricingComponent } from "./pages/forms/addplotpricing/addplotpricing.component";
import { AddtenantComponent } from "./pages/forms/addtenant/addtenant.component";
import { AdduserComponent } from "./pages/forms/adduser/adduser.component";
import { LoginComponent } from "./pages/login/login.component";
import { AddmarketerComponent } from "./pages/marketer/addmarketer/addmarketer.component";
import { CommissiondetailsComponent } from "./pages/marketer/commissions/commissiondetails/commissiondetails.component";
import { CommissionsComponent } from "./pages/marketer/commissions/commissions/commissions.component";
import { MycommissionsComponent } from "./pages/marketer/commissions/mycommissions/mycommissions.component";
import { EditmarketerComponent } from "./pages/marketer/editmarketer/editmarketer.component";
import { MarketerLayoutComponent } from "./pages/marketer/marketer-layout/marketer-layout.component";
import { MarketerListComponent } from "./pages/marketer/marketer-list/marketer-list.component";
import { MarketerdashboardComponent } from "./pages/marketer/marketerdashboard/marketerdashboard.component";
import { NoPermissionMarketerComponent } from "./pages/marketer/no-permission-marketer/no-permission-marketer.component";
import { AddnewsaleComponent } from "./pages/marketer/sales/addnewsale/addnewsale.component";
import { EditSaleComponent } from "./pages/marketer/sales/editSale/editSale.component";
import { MyprofileComponent } from "./pages/marketer/sales/myprofile/myprofile.component";
import { MysalesComponent } from "./pages/marketer/sales/mysales/mysales.component";
import { MyteamsalesComponent } from "./pages/marketer/sales/myteamsales/myteamsales.component";
import { NewSaleComponent } from "./pages/marketer/sales/new-sale/new-sale.component";
import { SaleschallengesComponent } from "./pages/marketer/sales/saleschallenges/saleschallenges.component";
import { SalesdashboardComponent } from "./pages/marketer/sales/salesdashboard/salesdashboard.component";
import { SalesSummaryComponent } from "./pages/marketer/sales/salesSummary/salesSummary.component";
import { TeamsComponent } from "./pages/marketer/teams/teams.component";
import { MypaymentsComponent } from "./pages/mypayments/mypayments.component";
import { MyteamComponent } from "./pages/myteam/myteam.component";
import { MyteamdetailsComponent } from "./pages/myteamdetails/myteamdetails.component";
import { NoPermissionsComponent } from "./pages/no-permissions/no-permissions.component";
import { PaymentsComponent } from "./pages/payments/payments.component";
import { PlotbyidComponent } from "./pages/plots/plotbyid/plotbyid.component";
import { PlotdetailsComponent } from "./pages/plots/plotdetails/plotdetails.component";
import { PlotsSalesrepComponent } from "./pages/plots/plots-salesrep/plots-salesrep.component";
import { PlotsaleComponent } from "./pages/plots/plotsale/plotsale.component";
import { PlotspricingComponent } from "./pages/plots/plotspricing/plotspricing.component";
import { PlotstatusComponent } from "./pages/plots/plotstatus/plotstatus.component";
import { PlotsummaryComponent } from "./pages/plots/plotsummary/plotsummary.component";
import { PromotionsComponent } from "./pages/promotions/promotions.component";
import { ReceivablesComponent } from "./pages/receivables/receivables.component";
import { ReportsComponent } from "./pages/reports/reports.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { SalesrepCommissionComponent } from "./pages/sales/salesrep-commission/salesrep-commission.component";
import { SalesrepplotsComponent } from "./pages/sales/salesrepplots/salesrepplots.component";
import { SalesummaryComponent } from "./pages/sales/salesummary/salesummary.component";
import { NoPermissionTenantComponent } from "./pages/tenant/no-permission-tenant/no-permission-tenant.component";
import { TenantComponent } from "./pages/tenant/tenant/tenant.component";
import { UsersComponent } from "./pages/users/users.component";
import { VentureComponent } from "./pages/ventures/venture/venture.component";
import { VenturedashboardComponent } from "./pages/ventures/venturedashboard/venturedashboard.component";
import { VenturesaledetailsComponent } from "./pages/ventures/venturesaledetails/venturesaledetails.component";
import { VentureteamComponent } from "./pages/ventures/ventureteam/ventureteam.component";
import { PromosComponent } from "./pages/promos/promos.component";
import { PromoDetailsComponent } from "./pages/promo-details/promo-details.component";
import { AddpromoComponent } from "./pages/forms/addpromo/addpromo.component";
import { VentureLayoutDetailsComponent } from "./pages/venture-layout-details/venture-layout-details.component";
import { MortgageMarkingComponent } from "./pages/forms/mortgage-marking/mortgage-marking.component";
import { CompanyBlockedMarkingComponent } from "./pages/forms/company-blocked-marking/company-blocked-marking.component";
import { LandlordMarkingComponent } from "./pages/forms/landlord-marking/landlord-marking.component";

import { LayoutDesignComponent } from "./layout-design/layout-design.component";


let urlSuffix = localStorage.getItem('urlSuffix');

const routes: Routes = [

		//Tenant Based Urls
		{
			path: ":urlSuffix",
			redirectTo: ":urlSuffix/login",
			pathMatch: "full",
		},
		{
			path: ":urlSuffix/login",
			component: LoginComponent
		},
		{
			path: ":urlSuffix/layout/:code",
			component: LayoutDesignComponent
		},
		{
			path: "pwa/login",
			component: LoginComponent
		},

	// Admin Layout
	{ path: ':urlSuffix', children: [
	{
		path: RoutesConstants.ADMIN_LAYOUT,
		component: MainLayoutComponent,
		canActivate: [NgxPermissionsGuard],
		data: {
			permissions: {
				only: [UserPrivileges.ADMIN_PRIVILEGE],
				redirectTo: RoutesConstants.TENANT_LAYOUT,
			},
		},
		children: [
			{
				path: RoutesConstants.TENANT_DASHBOARD,
				component: MaindashboardComponent,
			},
			{
				path: RoutesConstants.TENANT,
				component: TenantComponent,
			},
			{
				path: RoutesConstants.SALESSUMMARY,
				component: SalesSummaryComponent,
			},
			{
				path: RoutesConstants.USER,
				component: UsersComponent,
			},
			{
				path: RoutesConstants.ADD_USER,
				component: AdduserComponent,
			},
			{
				path: RoutesConstants.USERDETAILS,
				component: ResetpasswordComponent,
			},
			{
				path: RoutesConstants.ADDTENANT,
				component: AddtenantComponent,
			},
			{
				path: RoutesConstants.PLOTBYID + "/:plotId",
				component: PlotbyidComponent,
			},
			{
				path: RoutesConstants.MARKETER,
				component: MarketerListComponent,
			},
			{
				path: RoutesConstants.NEWSALE,
				component: NewSaleComponent,
			},
		],
	}
]},

	// Tenant Layout
	{ path: ':urlSuffix', children: [
	{
		path: RoutesConstants.TENANT_LAYOUT,
		component: AdminLayoutComponent,
		canActivate: [NgxPermissionsGuard],
		data: {
			permissions: {
				only: [
					UserPrivileges.TENANT_PRIVILEGE,
					UserPrivileges.TENANT_BASIC_PRIVILEGE,
				],
				redirectTo: '/'+urlSuffix+'/'+RoutesConstants.MARKETERLAYOUT,
			},
		},
		children: [
			{
				path: RoutesConstants.TENANT_DASHBOARD,
				component: MaindashboardComponent,
			},
			{
				path: RoutesConstants.ADD_USER,
				component: AdduserComponent,
			},
			{
				path: RoutesConstants.EDIT_USER,
				component: AdduserComponent,
			},
			{
				path: RoutesConstants.MARKETERDASHBOARD,
				component: MarketerdashboardComponent,
			},
			{
				path: RoutesConstants.MYPROFILE,
				component: MyprofileComponent,
			},
			{
				path: RoutesConstants.ALLDETAILS,
				component: MyteamdetailsComponent,
			},
			{
				path: RoutesConstants.REPORTS,
				component: ReportsComponent,
			},
			{
				path: RoutesConstants.REPORT,
				component: ReportDetailsComponent,
			},
			{
				path: RoutesConstants.CUSTOMERS,
				component: CustomersComponent,
				canActivate: [NgxPermissionsGuard],
				data: {
					permissions: {
						only: [
							UserPrivileges.TENANT_PRIVILEGE,
							UserPrivileges.TENANT_BASIC_PRIVILEGE,
						],
						redirectTo: RoutesConstants.NOTENANTPERMISSIONS,
					},
				},
			},
			{
				path: RoutesConstants.ADDPLOT,
				component: AddplotComponent,
			},
			{
				path: RoutesConstants.ADDMARKETER,
				component: AddmarketerComponent,
			},
			{
				path: RoutesConstants.EDITMARKETER,
				component: EditmarketerComponent,
			},
			{
				path: RoutesConstants.ADDMARKETERVENTURE,
				component: AddingmarketerventureComponent,
			},
			{
				path: RoutesConstants.ADDPLOTPRICING,
				component: AddplotpricingComponent,
			},
			{
				path: RoutesConstants.PLOTS,
				component: PlotdetailsComponent,
			},
			{
				path: RoutesConstants.CUSTOMERS_DETAILS,
				component: CustomerDetailsComponent,
			},
			{
				path: RoutesConstants.VENTURE,
				component: VentureComponent,
			},
			{
				path: RoutesConstants.ANALYTICS,
				component: AnalyticsComponent
			},
			{
				path: RoutesConstants.SALESSUMMARY,
				component: SalesSummaryComponent,
			},
			{
				path: RoutesConstants.USER,
				component: UsersComponent,
				canActivate: [NgxPermissionsGuard],
				data: {
					permissions: {
						only: [
							UserPrivileges.TENANT_PRIVILEGE,
						],
						redirectTo: RoutesConstants.NOTENANTPERMISSIONS,
					},
				},
			},
			{
				path: RoutesConstants.USERDETAILS,
				component: ResetpasswordComponent,
			},
			{
				path: RoutesConstants.PLOTBYID + "/:plotId",
				component: PlotbyidComponent,
			},

			{
				path: RoutesConstants.MARKETER,
				component: MarketerListComponent,
			},
			{
				path: RoutesConstants.NEWSALE,
				component: NewSaleComponent,
			},
		],
	}
]},

	// Marketer layout
	{ path: ':urlSuffix', children: [
	{
		path: RoutesConstants.MARKETERLAYOUT,
		component: MarketerLayoutComponent,
		canActivate: [NgxPermissionsGuard],
		data: {
			permissions: {
				only: [
					UserPrivileges.MARKETER_SUPER_PRIVILEGE,
					UserPrivileges.MARKETER_BASIC_PRIVILEGE,
				],
				redirectTo: '/'+urlSuffix+'/'+RoutesConstants.SALESREPLAYOUT,
			},
		},
		children: [
			{
				path: RoutesConstants.MARKETERDASHBOARD,
				component: MarketerdashboardComponent,
			},
			{
				path: RoutesConstants.SALEDETAILS,
				component: PlotsaleComponent,
			},
			{
				path: RoutesConstants.ADD_USER,
				component: AdduserComponent,
			},
			{
				path: RoutesConstants.EDIT_USER,
				component: AdduserComponent,
			},
			{
				path: RoutesConstants.USER,
				component: UsersComponent,
				canActivate: [NgxPermissionsGuard],
				data: {
					permissions: {
						only: [
							UserPrivileges.MARKETER_SUPER_PRIVILEGE,
						],
						redirectTo: RoutesConstants.NOMARKETERPERMISSIONS,
					},
				},
			},
			{
				path: RoutesConstants.PLOTSUMMARY + "/:ventureId",
				component: PlotsummaryComponent,
			},
			{
				path: RoutesConstants.SALESUMMARY + "/:ventureId",
				component: SalesummaryComponent,
			},
			{
				path: RoutesConstants.USERDETAILS,
				component: ResetpasswordComponent,
			},
			{
				path: RoutesConstants.REPORT,
				component: ReportDetailsComponent,
			},
			{
				path: RoutesConstants.ALLDETAILS,
				component: MyteamdetailsComponent,
			},
			{
				path: RoutesConstants.REPORTS,
				component: ReportsComponent,
			},
			{
				path: RoutesConstants.NOPERMISSIONS,
				component: NoPermissionsComponent,
			},
			{
				path: RoutesConstants.COMMISSION_PAYMENTS,
				component: AddcommissionpaymentsComponent,
			},
			{
				path: RoutesConstants.TEAMS,
				component: TeamsComponent,
				canActivate: [NgxPermissionsGuard],
				data: {
					permissions: {
						only: [
							UserPrivileges.MARKETER_SUPER_PRIVILEGE,
							UserPrivileges.MARKETER_BASIC_PRIVILEGE,
						],
						redirectTo: RoutesConstants.NOMARKETERPERMISSIONS,
					},
				},
			},
			{
				path: RoutesConstants.MYPROFILE,
				component: MyprofileComponent,
			},
			{
				path: RoutesConstants.CUSTOMERS,
				component: CustomersComponent,
				canActivate: [NgxPermissionsGuard],
				data: {
					permissions: {
						only: [
							UserPrivileges.MARKETER_SUPER_PRIVILEGE,
							UserPrivileges.MARKETER_BASIC_PRIVILEGE,
						],
						redirectTo: RoutesConstants.NOMARKETERPERMISSIONS,
					},
				},
			},
			{
				path: RoutesConstants.ANALYTICS,
				component: AnalyticsComponent
			},
			{
				path: RoutesConstants.CUSTOMERS_DETAILS,
				component: CustomerDetailsComponent,
			},
			{
				path: RoutesConstants.PROMOS,
				component: PromosComponent,
			},
			{
				path: RoutesConstants.ADDPROMO,
				component: AddpromoComponent,
			},
		],
	}
]},

	// Venture Layout
	{ path: ':urlSuffix', children: [
	{
		path: RoutesConstants.VENTURELAYOUT,
		component: VenturelayoutComponent,
		canActivate: [NgxPermissionsGuard],
		data: {
			permissions: {
				only: [UserPrivileges.READ_PRIVILEGE],
				redirectTo: '/'+urlSuffix+'/'+RoutesConstants.CHANGE_PASSWORD,
			},
		},
		children: [
			{
				path: RoutesConstants.VENTUREDASHBOARD,
				component: VenturedashboardComponent,
			},
			{
				path: RoutesConstants.SALEDETAILS,
				component: PlotsaleComponent,
			},
			{
				path: RoutesConstants.ALLDETAILS,
				component: MyteamdetailsComponent,
			},
			{
				path: RoutesConstants.PLOTS,
				component: PlotdetailsComponent,
				canActivate: [NgxPermissionsGuard],
				data: {
					permissions: {
						only: [
							UserPrivileges.MARKETER_SUPER_PRIVILEGE,
							UserPrivileges.MARKETER_BASIC_PRIVILEGE,
							UserPrivileges.TENANT_PRIVILEGE,
							UserPrivileges.TENANT_BASIC_PRIVILEGE,
						],
						redirectTo: RoutesConstants.NOPERMISSIONS,
					},
				},
			},
			{
				path: RoutesConstants.PLOTSUMMARY + "/:ventureId",
				component: PlotsummaryComponent,
			},
			{
				path: RoutesConstants.PLOTSPRICING,
				component: PlotspricingComponent,
				canActivate: [NgxPermissionsGuard],
				data: {
					permissions: {
						only: [
							UserPrivileges.MARKETER_SUPER_PRIVILEGE,
							UserPrivileges.TENANT_PRIVILEGE,
						],
						redirectTo: RoutesConstants.NOPERMISSIONS,
					},
				},
			},
			{
				path: RoutesConstants.MYPROFILE,
				component: MyprofileComponent,
			},
			{
				path: RoutesConstants.COMMISSIONDETAILS,
				component: CommissiondetailsComponent,
				canActivate: [NgxPermissionsGuard],
				data: {
					permissions: {
						only: [
							UserPrivileges.MARKETER_SUPER_PRIVILEGE,
							UserPrivileges.MARKETER_BASIC_PRIVILEGE,
							UserPrivileges.TENANT_PRIVILEGE,
							UserPrivileges.TENANT_BASIC_PRIVILEGE,
						],
						redirectTo: RoutesConstants.NOPERMISSIONS,
					},
				},
			},
			{
				path: RoutesConstants.ADDPLOTPRICING,
				component: AddplotpricingComponent,
				canActivate: [NgxPermissionsGuard],
				data: {
					permissions: {
						only: [
							UserPrivileges.TENANT_PRIVILEGE,
						],
						redirectTo: RoutesConstants.NOPERMISSIONS,
					},
				},
			},
			{
				path: RoutesConstants.EDITPLOTPRICING,
				component: AddplotpricingComponent,
				canActivate: [NgxPermissionsGuard],
				data: {
					permissions: {
						only: [
							UserPrivileges.TENANT_PRIVILEGE,
						],
						redirectTo: RoutesConstants.NOPERMISSIONS,
					},
				},
			},
			{
				path: RoutesConstants.VENTURETEAM,
				component: VentureteamComponent,
				canActivate: [NgxPermissionsGuard],
				data: {
					permissions: {
						only: [
							UserPrivileges.MARKETER_SUPER_PRIVILEGE,
							UserPrivileges.MARKETER_BASIC_PRIVILEGE,
							UserPrivileges.TENANT_PRIVILEGE,
							UserPrivileges.TENANT_BASIC_PRIVILEGE,
						],
						redirectTo: RoutesConstants.NOPERMISSIONS,
					},
				},
			},
			{
				path: RoutesConstants.PLOTBYID + "/:plotId",
				component: PlotbyidComponent,
				canActivate: [NgxPermissionsGuard],
				data: {
					permissions: {
						only: [
							UserPrivileges.MARKETER_SUPER_PRIVILEGE,
							UserPrivileges.MARKETER_BASIC_PRIVILEGE,
							UserPrivileges.TENANT_PRIVILEGE,
							UserPrivileges.TENANT_BASIC_PRIVILEGE,
						],
						redirectTo: RoutesConstants.NOPERMISSIONS,
					},
				},
			},
			{
				path: RoutesConstants.VENTURESALES,
				component: VenturesaledetailsComponent,
				canActivate: [NgxPermissionsGuard],
				data: {
					permissions: {
						only: [
							UserPrivileges.MARKETER_SUPER_PRIVILEGE,
							UserPrivileges.MARKETER_BASIC_PRIVILEGE,
							UserPrivileges.TENANT_PRIVILEGE,
							UserPrivileges.TENANT_BASIC_PRIVILEGE,
						],
						redirectTo: RoutesConstants.NOPERMISSIONS,
					},
				},
			},
			{
				path: RoutesConstants.MORTGAGEMARKING,
				component: MortgageMarkingComponent,
				canActivate: [NgxPermissionsGuard],
				data: {
					permissions: {
						only: [
							UserPrivileges.TENANT_PRIVILEGE,
						],
						redirectTo: RoutesConstants.NOPERMISSIONS,
					},
				},
			},
			{
				path: RoutesConstants.COMPANYBLOCKEDMARKING,
				component: CompanyBlockedMarkingComponent,
				canActivate: [NgxPermissionsGuard],
				data: {
					permissions: {
						only: [
							UserPrivileges.TENANT_PRIVILEGE,
						],
						redirectTo: RoutesConstants.NOPERMISSIONS,
					},
				},
			},
			{
				path: RoutesConstants.LANDLORDMARKING,
				component: LandlordMarkingComponent,
				canActivate: [NgxPermissionsGuard],
				data: {
					permissions: {
						only: [
							UserPrivileges.TENANT_PRIVILEGE,
						],
						redirectTo: RoutesConstants.NOPERMISSIONS,
					},
				},
			},
			{
				path: RoutesConstants.COMMISSIONS,
				component: CommissionsComponent,
			},
			{
				path: RoutesConstants.PAYMENTS,
				component: PaymentsComponent,
			},
			{
				path: RoutesConstants.RECEIVABLES,
				component: ReceivablesComponent,
			},
			{
				path: RoutesConstants.PROMOTIONS,
				component: PromotionsComponent,
			},
			{
				path: RoutesConstants.SALESCHALLENGES,
				component: SaleschallengesComponent,
			},
			{
				path: RoutesConstants.ADDNEWSALE,
				component: AddnewsaleComponent,
			},
			{
				path: RoutesConstants.ADDPLOT,
				component: AddplotComponent,
			},
			{
				path: RoutesConstants.ADDCOMMISSIONSTRUCTURE,
				component: AddcommissionstructureComponent,
			},
			{
				path: RoutesConstants.EDITCOMMISSIONSTRUCTURE,
				component: AddcommissionstructureComponent,
			},
			{
				path: RoutesConstants.EDITSALE,
				component: EditSaleComponent,
			},
			{
				path: RoutesConstants.PLOTSTATUS,
				component: PlotstatusComponent,
			},
			{
				path: RoutesConstants.CUSTOMERS,
				component: CustomersComponent,
				canActivate: [NgxPermissionsGuard],
				data: {
					permissions: {
						only: [
							UserPrivileges.MARKETER_SUPER_PRIVILEGE,
							UserPrivileges.MARKETER_BASIC_PRIVILEGE,
							UserPrivileges.TENANT_PRIVILEGE,
							UserPrivileges.TENANT_BASIC_PRIVILEGE,
						],
						redirectTo: RoutesConstants.NOMARKETERPERMISSIONS,
					},
				},
			},
			{
				path: RoutesConstants.CUSTOMERS_DETAILS,
				component: CustomerDetailsComponent,
				data: {
					pageType: 'venture'
				}
			},
			{
				path: RoutesConstants.VENTURELAYOUTDETAILS,
				component: VentureLayoutDetailsComponent,
				canActivate: [NgxPermissionsGuard],
				data: {
					permissions: {
						only: [
							UserPrivileges.MARKETER_SUPER_PRIVILEGE,
							UserPrivileges.MARKETER_BASIC_PRIVILEGE,
							UserPrivileges.TENANT_PRIVILEGE,
							UserPrivileges.TENANT_BASIC_PRIVILEGE,
						],
						redirectTo: RoutesConstants.NOMARKETERPERMISSIONS,
					},
				},
			},
			{
				path: RoutesConstants.VENTURELAYOUTDESIGN,
				component: LayoutDesignComponent,
				canActivate: [NgxPermissionsGuard],
				data: {
					permissions: {
						only: [
							UserPrivileges.MARKETER_SUPER_PRIVILEGE,
							UserPrivileges.TENANT_PRIVILEGE,
						],
						redirectTo: RoutesConstants.NOMARKETERPERMISSIONS,
					},
				},
			},						
		],
	}
]},

	// Salesrep Layout
	{ path: ':urlSuffix', children: [
	{
		path: RoutesConstants.SALESREPLAYOUT,
		component: SalesrepLayoutComponent,
		canActivate: [NgxPermissionsGuard],
		data: {
			permissions: {
				only: [UserPrivileges.SALESREP_PRIVILEGE],
				redirectTo: '/'+urlSuffix+'/'+RoutesConstants.VENTURELAYOUT,
			},
		},
		children: [
			{
				path: RoutesConstants.SALESREPDASHBOARD,
				component: SalesdashboardComponent,
			},
			{
				path: RoutesConstants.VENTUREDESIGN,
				component: LayoutDesignComponent
			},
			{
				path: RoutesConstants.PLOTS,
				component: PlotsSalesrepComponent,
			},
			{
				path: RoutesConstants.PLOTBYID + "/:plotId",
				component: PlotbyidComponent,
			},
			{
				path: RoutesConstants.EDITSALE,
				component: EditSaleComponent,
			},
			{
				path: RoutesConstants.ALLDETAILS,
				component: MyteamdetailsComponent,
			},
			{
				path: RoutesConstants.MYPROFILE,
				component: MyprofileComponent,
			},
			{
				path: RoutesConstants.MYTEAM,
				component: MyteamComponent,
			},
			{
				path: RoutesConstants.MYSALES,
				component: MysalesComponent,
			},
			{
				path: RoutesConstants.MYTEAMSALES,
				component: MyteamsalesComponent,
			},
			{
				path: RoutesConstants.SEPARATECOMMISSIONS,
				component: MycommissionsComponent,
			},
			{
				path: RoutesConstants.SALEDETAILS,
				component: PlotsaleComponent,
			},
			{
				path: RoutesConstants.SALESREPPLOTS,
				component: SalesrepplotsComponent,
			},
			{
				path: RoutesConstants.MYCOMMISSIONS,
				component: SalesrepCommissionComponent,
			},
			{
				path: RoutesConstants.MYPAYMENTS,
				component: MypaymentsComponent,
			},
			{
				path: RoutesConstants.ANALYTICS,
				component: AnalyticsComponent
			},
			{
				path: RoutesConstants.PROMOS,
				component: PromoDetailsComponent,
			},
		],
	}
]},

	// Generic routes
	{
		path: ":urlSuffix/"+RoutesConstants.LOGOUT,
		component: LoginComponent,
	},
	{
		path: ":urlSuffix/"+RoutesConstants.RESETPASSWORD,
		component: ResetPasswordComponent,
	},
	{
		path: ":urlSuffix/"+RoutesConstants.NOPERMISSIONS,
		component: NoPermissionsComponent,
	},
	{
		path: ":urlSuffix/"+RoutesConstants.NOMARKETERPERMISSIONS,
		component: NoPermissionMarketerComponent,
	},
	{
		path: ":urlSuffix/"+RoutesConstants.NOTENANTPERMISSIONS,
		component: NoPermissionTenantComponent,
	},
	{
		path: ":urlSuffix/"+RoutesConstants.CHANGE_PASSWORD,
		component: ChangepasswordComponent,
		canActivate: [NgxPermissionsGuard],
		data: {
			permissions: {
				only: [UserPrivileges.CHANGE_PASSWORD_PRIVILEGE],
				redirectTo: ":urlSuffix/"+RoutesConstants.LOGOUT,
			},
		},
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
