<section class="my-3">
    <div class="row">
        <div class="col-lg-12 col-md-12" style="font-size: 13px;">
            <div class="mb-3">
                <a class="position-absolute close-popup-link bg-white" (click)= "sendBack()"><i class="fa fa-times" aria-hidden="true"></i>
                </a>
                <div class="card shadow mb-2" *ngIf="report == reportTypes.TOTALSALE">
                    <h6 class="card-title text-white position-relative pb-2 p-3 w-100"
                        >Total Sale</h6>
                    <div class="card-body pb-4 mx-0 pt-2">
                        <div  *ngIf= "barChartData">
                            <canvas  #mycanvas  baseChart height="75" width="100" 
                                [datasets]="barChartData"
                                [labels]="barChartLabels" [options]="barChartOptions" [legend]="barChartLegend"
                                [chartType]="barChartType" [plugins]="barChartPlugins"
                                [colors]="barChartColors"></canvas>
                        </div>
                    </div>
                    <!--<div>
                        <div class="chart " *ngIf="pieChartData">
                            <canvas baseChart height="20" width="50" 
                            [data]="pieChartData" 
                            [labels]="pieChartLabels"
                            [chartType]="pieChartType" 
                            [options]="pieChartOptions" 
                            [plugins]="pieChartPlugins"
                            [colors]="pieChartColors" 
                            [legend]="pieChartLegend">
                            </canvas>
                        </div>
                    </div>-->
                </div>
                <div class="card shadow mb-2" *ngIf="report == reportTypes.SALEVENTURE">
                    <h6 class="card-title text-white position-relative pb-2 p-3 w-100"
                        style="background-color: rgb(43,45,65);">Venture Sale</h6>
                    <div class="card-body pb-4 mx-0 pt-2">
                        <div class="row">
                            <div class="col-lg-8">
                                <div style="color: black;">
                                    <canvas #mycanvas baseChart height="75" width="100" [datasets]="vSalebarChartData"
                                        [labels]="vSalebarChartLabels" [options]="vSalebarChartOptions"
                                        [legend]="vSalebarChartLegend" [chartType]="vSalebarChartType"
                                        [plugins]="vSalebarChartPlugins" [colors]="vSalebarChartColors"></canvas>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <ul class="p-5" style="font-size: 14px;">
                                    <li class="m-2">
                                        <p>Schon: Total plots sold by the marketer Team in Schon => 38</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Vasavi Sandle county: Total plots sold by the marketer team in Vasavi Sandle
                                            county => 50 </p>
                                    </li>
                                    <li class="m-2">
                                        <p>Sweet Home: Total plots sold by the marketer team in Sweet Home => 68</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Villas 1: Total plots sold by the marketer team in Villas 1 => 44</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow mb-2" *ngIf="report == reportTypes.TOTALREVENUE">
                    <h6 class="card-title text-white position-relative pb-2 p-3 w-100"
                        >Total Revenue</h6>
                    <div class="card-body pb-4 mx-0 pt-2">
                        <div class="row">
                            <div class="col-lg-8">
                                <div>
                                    <canvas #mycanvas baseChart height="75" width="100" [datasets]="vSalebarChartData"
                                        [labels]="vSalebarChartLabels" [options]="vSalebarChartOptions"
                                        [legend]="vSalebarChartLegend" [chartType]="vSalebarChartType"
                                        [plugins]="vSalebarChartPlugins" [colors]="vSalebarChartColors"></canvas>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <ul class="p-5">
                                    <li class="m-2">
                                        <p>Schon: Total plots sold by the marketer Team in Schon => 38</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Vasavi Sandle county: Total plots sold by the marketer team in Vasavi Sandle
                                            county => 50 </p>
                                    </li>
                                    <li class="m-2">
                                        <p>Sweet Home: Total plots sold by the marketer team in Sweet Home => 68</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Villas 1: Total plots sold by the marketer team in Villas 1 => 44</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow mb-2" *ngIf="report == reportTypes.REVENUEMARKETER">
                    <h6 class="card-title text-white position-relative pb-2 p-3 w-100"
                        >Marketer Revenue</h6>
                    <div class="card-body pb-4 mx-0 pt-2">
                        <div class="row">
                            <div class="col-lg-8">
                                <div>
                                    <canvas #mycanvas baseChart height="75" width="100" [datasets]="vSalebarChartData"
                                        [labels]="vSalebarChartLabels" [options]="vSalebarChartOptions"
                                        [legend]="vSalebarChartLegend" [chartType]="vSalebarChartType"
                                        [plugins]="vSalebarChartPlugins" [colors]="vSalebarChartColors"></canvas>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <ul class="p-5">
                                    <li class="m-2">
                                        <p>Schon: Total plots sold by the marketer Team in Schon => 38</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Vasavi Sandle county: Total plots sold by the marketer team in Vasavi Sandle
                                            county => 50 </p>
                                    </li>
                                    <li class="m-2">
                                        <p>Sweet Home: Total plots sold by the marketer team in Sweet Home => 68</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Villas 1: Total plots sold by the marketer team in Villas 1 => 44</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow mb-2" *ngIf="report == reportTypes.REVENUEVENTURE">
                    <h6 class="card-title text-white position-relative pb-2 p-3 w-100"
                        >Venture Revenue</h6>
                    <div class="card-body pb-4 mx-0 pt-2">
                        <div class="row">
                            <div class="col-lg-8">
                                <div>
                                    <canvas #mycanvas baseChart height="75" width="100" [datasets]="vSalebarChartData"
                                        [labels]="vSalebarChartLabels" [options]="vSalebarChartOptions"
                                        [legend]="vSalebarChartLegend" [chartType]="vSalebarChartType"
                                        [plugins]="vSalebarChartPlugins" [colors]="vSalebarChartColors"></canvas>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <ul class="p-5">
                                    <li class="m-2">
                                        <p>Schon: Total plots sold by the marketer Team in Schon => 38</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Vasavi Sandle county: Total plots sold by the marketer team in Vasavi Sandle
                                            county => 50 </p>
                                    </li>
                                    <li class="m-2">
                                        <p>Sweet Home: Total plots sold by the marketer team in Sweet Home => 68</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Villas 1: Total plots sold by the marketer team in Villas 1 => 44</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow mb-2" *ngIf="report == reportTypes.COMMISSION">
                    <h6 class="card-title text-white position-relative pb-2 p-3 w-100"
                        >Total Commission</h6>
                    <div class="card-body pb-4 mx-0 pt-2">
                        <div class="row">
                            <div class="col-lg-8">
                                <div>
                                    <canvas #mycanvas baseChart height="75" width="100" [datasets]="vSalebarChartData"
                                        [labels]="vSalebarChartLabels" [options]="vSalebarChartOptions"
                                        [legend]="vSalebarChartLegend" [chartType]="vSalebarChartType"
                                        [plugins]="vSalebarChartPlugins" [colors]="vSalebarChartColors"></canvas>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <ul class="p-5">
                                    <li class="m-2">
                                        <p>Schon: Total plots sold by the marketer Team in Schon => 38</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Vasavi Sandle county: Total plots sold by the marketer team in Vasavi Sandle
                                            county => 50 </p>
                                    </li>
                                    <li class="m-2">
                                        <p>Sweet Home: Total plots sold by the marketer team in Sweet Home => 68</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Villas 1: Total plots sold by the marketer team in Villas 1 => 44</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow mb-2" *ngIf="report == reportTypes.SALESREPCOMMISSION">
                    <h6 class="card-title text-white position-relative pb-2 p-3 w-100"
                        >SalesRep Commission</h6>
                    <div class="card-body pb-4 mx-0 pt-2">
                        <div class="row">
                            <div class="col-lg-8">
                                <div>
                                    <canvas #mycanvas baseChart height="75" width="100" [datasets]="vSalebarChartData"
                                        [labels]="vSalebarChartLabels" [options]="vSalebarChartOptions"
                                        [legend]="vSalebarChartLegend" [chartType]="vSalebarChartType"
                                        [plugins]="vSalebarChartPlugins" [colors]="vSalebarChartColors"></canvas>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <ul class="p-5">
                                    <li class="m-2">
                                        <p>Schon: Total plots sold by the marketer Team in Schon => 38</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Vasavi Sandle county: Total plots sold by the marketer team in Vasavi Sandle
                                            county => 50 </p>
                                    </li>
                                    <li class="m-2">
                                        <p>Sweet Home: Total plots sold by the marketer team in Sweet Home => 68</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Villas 1: Total plots sold by the marketer team in Villas 1 => 44</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow mb-2" *ngIf="report == reportTypes.VENTURECOMMISSION">
                    <h6 class="card-title text-white position-relative pb-2 p-3 w-100"
                        >Venture wise Commission</h6>
                    <div class="card-body pb-4 mx-0 pt-2">
                        <div class="row">
                            <div class="col-lg-8">
                                <div>
                                    <canvas #mycanvas baseChart height="75" width="100" [datasets]="vSalebarChartData"
                                        [labels]="vSalebarChartLabels" [options]="vSalebarChartOptions"
                                        [legend]="vSalebarChartLegend" [chartType]="vSalebarChartType"
                                        [plugins]="vSalebarChartPlugins" [colors]="vSalebarChartColors"></canvas>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <ul class="p-5">
                                    <li class="m-2">
                                        <p>Schon: Total plots sold by the marketer Team in Schon => 38</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Vasavi Sandle county: Total plots sold by the marketer team in Vasavi Sandle
                                            county => 50 </p>
                                    </li>
                                    <li class="m-2">
                                        <p>Sweet Home: Total plots sold by the marketer team in Sweet Home => 68</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Villas 1: Total plots sold by the marketer team in Villas 1 => 44</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow mb-2" *ngIf="report == reportTypes.PROFIT">
                    <h6 class="card-title text-white position-relative pb-2 p-3 w-100"
                        >Total Profit</h6>
                    <div class="card-body pb-4 mx-0 pt-2">
                        <div class="row">
                            <div class="col-lg-8">
                                <div >
                                    <canvas #mycanvas baseChart height="75" width="100" [datasets]="vSalebarChartData"
                                        [labels]="vSalebarChartLabels" [options]="vSalebarChartOptions"
                                        [legend]="vSalebarChartLegend" [chartType]="vSalebarChartType"
                                        [plugins]="vSalebarChartPlugins" [colors]="vSalebarChartColors"></canvas>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <ul class="p-5">
                                    <li class="m-2">
                                        <p>Schon: Total plots sold by the marketer Team in Schon => 38</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Vasavi Sandle county: Total plots sold by the marketer team in Vasavi Sandle
                                            county => 50 </p>
                                    </li>
                                    <li class="m-2">
                                        <p>Sweet Home: Total plots sold by the marketer team in Sweet Home => 68</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Villas 1: Total plots sold by the marketer team in Villas 1 => 44</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow mb-2" *ngIf="report == reportTypes.PROFITVENTURE">
                    <h6 class="card-title text-white position-relative pb-2 p-3 w-100"
                        >Venture Profit</h6>
                    <div class="card-body pb-4 mx-0 pt-2">
                        <div class="row">
                            <div class="col-lg-8">
                                <div>
                                    <canvas #mycanvas baseChart height="75" width="100" [datasets]="vSalebarChartData"
                                        [labels]="vSalebarChartLabels" [options]="vSalebarChartOptions"
                                        [legend]="vSalebarChartLegend" [chartType]="vSalebarChartType"
                                        [plugins]="vSalebarChartPlugins" [colors]="vSalebarChartColors"></canvas>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <ul class="p-5">
                                    <li class="m-2">
                                        <p>Schon: Total plots sold by the marketer Team in Schon => 38</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Vasavi Sandle county: Total plots sold by the marketer team in Vasavi Sandle
                                            county => 50 </p>
                                    </li>
                                    <li class="m-2">
                                        <p>Sweet Home: Total plots sold by the marketer team in Sweet Home => 68</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Villas 1: Total plots sold by the marketer team in Villas 1 => 44</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow mb-2" *ngIf="report == reportTypes.TEAM">
                    <h6 class="card-title text-white position-relative pb-2 p-3 w-100"
                        >Marketer Team</h6>
                    <div class="card-body pb-4 mx-0 pt-2">
                        <div class="row">
                            <div class="col-lg-8">
                                <div>
                                    <canvas #mycanvas baseChart height="75" width="100" [datasets]="vSalebarChartData"
                                        [labels]="vSalebarChartLabels" [options]="vSalebarChartOptions"
                                        [legend]="vSalebarChartLegend" [chartType]="vSalebarChartType"
                                        [plugins]="vSalebarChartPlugins" [colors]="vSalebarChartColors"></canvas>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <ul class="p-5">
                                    <li class="m-2">
                                        <p>Schon: Total plots sold by the marketer Team in Schon => 38</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Vasavi Sandle county: Total plots sold by the marketer team in Vasavi Sandle
                                            county => 50 </p>
                                    </li>
                                    <li class="m-2">
                                        <p>Sweet Home: Total plots sold by the marketer team in Sweet Home => 68</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Villas 1: Total plots sold by the marketer team in Villas 1 => 44</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow mb-2" *ngIf="report == reportTypes.TEAMVENTURE">
                    <h6 class="card-title text-white position-relative pb-2 p-3 w-100"
                        >Venture Team</h6>
                    <div class="card-body pb-4 mx-0 pt-2">
                        <div class="row">
                            <div class="col-lg-8">
                                <div>
                                    <canvas #mycanvas baseChart height="75" width="100" [datasets]="vSalebarChartData"
                                        [labels]="vSalebarChartLabels" [options]="vSalebarChartOptions"
                                        [legend]="vSalebarChartLegend" [chartType]="vSalebarChartType"
                                        [plugins]="vSalebarChartPlugins" [colors]="vSalebarChartColors"></canvas>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <ul class="p-5">
                                    <li class="m-2">
                                        <p>Schon: Total plots sold by the marketer Team in Schon => 38</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Vasavi Sandle county: Total plots sold by the marketer team in Vasavi Sandle
                                            county => 50 </p>
                                    </li>
                                    <li class="m-2">
                                        <p>Sweet Home: Total plots sold by the marketer team in Sweet Home => 68</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Villas 1: Total plots sold by the marketer team in Villas 1 => 44</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow mb-2" *ngIf="report == reportTypes.CUSTOMERPAYMENT">
                    <h6 class="card-title text-white position-relative pb-2 p-3 w-100"
                        >Customer Payment</h6>
                    <div class="card-body pb-4 mx-0 pt-2">
                        <div class="row">
                            <div class="col-lg-8">
                                <div>
                                    <canvas #mycanvas baseChart height="75" width="100" [datasets]="vSalebarChartData"
                                        [labels]="vSalebarChartLabels" [options]="vSalebarChartOptions"
                                        [legend]="vSalebarChartLegend" [chartType]="vSalebarChartType"
                                        [plugins]="vSalebarChartPlugins" [colors]="vSalebarChartColors"></canvas>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <ul class="p-5">
                                    <li class="m-2">
                                        <p>Schon: Total plots sold by the marketer Team in Schon => 38</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Vasavi Sandle county: Total plots sold by the marketer team in Vasavi Sandle
                                            county => 50 </p>
                                    </li>
                                    <li class="m-2">
                                        <p>Sweet Home: Total plots sold by the marketer team in Sweet Home => 68</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Villas 1: Total plots sold by the marketer team in Villas 1 => 44</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow mb-2" *ngIf="report == reportTypes.PENDINGPAYMENT">
                    <h6 class="card-title text-white position-relative pb-2 p-3 w-100"
                        >Pending Payment</h6>
                    <div class="card-body pb-4 mx-0 pt-2">
                        <div class="row">
                            <div class="col-lg-8">
                                <div>
                                    <canvas #mycanvas baseChart height="75" width="100" [datasets]="vSalebarChartData"
                                        [labels]="vSalebarChartLabels" [options]="vSalebarChartOptions"
                                        [legend]="vSalebarChartLegend" [chartType]="vSalebarChartType"
                                        [plugins]="vSalebarChartPlugins" [colors]="vSalebarChartColors"></canvas>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <ul class="p-5">
                                    <li class="m-2">
                                        <p>Schon: Total plots sold by the marketer Team in Schon => 38</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Vasavi Sandle county: Total plots sold by the marketer team in Vasavi Sandle
                                            county => 50 </p>
                                    </li>
                                    <li class="m-2">
                                        <p>Sweet Home: Total plots sold by the marketer team in Sweet Home => 68</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Villas 1: Total plots sold by the marketer team in Villas 1 => 44</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow mb-2" *ngIf="report == reportTypes.PAYMENTVENTURE">
                    <h6 class="card-title text-white position-relative pb-2 p-3 w-100"
                        >Venture Payment</h6>
                    <div class="card-body pb-4 mx-0 pt-2">
                        <div class="row">
                            <div class="col-lg-8">
                                <div>
                                    <canvas #mycanvas baseChart height="75" width="100" [datasets]="vSalebarChartData"
                                        [labels]="vSalebarChartLabels" [options]="vSalebarChartOptions"
                                        [legend]="vSalebarChartLegend" [chartType]="vSalebarChartType"
                                        [plugins]="vSalebarChartPlugins" [colors]="vSalebarChartColors"></canvas>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <ul class="p-5">
                                    <li class="m-2">
                                        <p>Schon: Total plots sold by the marketer Team in Schon => 38</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Vasavi Sandle county: Total plots sold by the marketer team in Vasavi Sandle
                                            county => 50 </p>
                                    </li>
                                    <li class="m-2">
                                        <p>Sweet Home: Total plots sold by the marketer team in Sweet Home => 68</p>
                                    </li>
                                    <li class="m-2">
                                        <p>Villas 1: Total plots sold by the marketer team in Villas 1 => 44</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>