import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { AuthServiceService } from '../../../services/auth-service.service';
import { RestService } from '../../../services/rest.service';
import { ActivatedRoute } from '@angular/router';
import { CommonServiceService } from '../../../services/common-service.service';
import _ from 'underscore';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.scss']
})
export class AdduserComponent implements OnInit {

  userDetails: any = {};
  person: any = {};
  userData: any = [];
  userId = null;
  scopes: any;
  resourceScopes: any;
  basicUserVenturesList: any;
  readOnly = false;
  disableSubmit: boolean;
  selectedUser: any;
  isLoading = false;

  constructor(private authService: AuthServiceService,
    private restService: RestService,
    private route: ActivatedRoute,
    private location: Location) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (!_.isEmpty(params)) {
        this.userId = params['id'];
        if (this.userId) {
          this.isLoading = true;
          this.disableSubmit = true;
          this.readOnly = true;
          this.restService.fetchUser(this.userId).subscribe((response: any) => {
            if (response) {
              this.selectedUser = response.data;
              this.person = this.selectedUser.person;
              this.resourceScopes = this.selectedUser.resourceScopes;
              this.basicUserVenturesList = this.selectedUser.basicUserVenturesList;
              this.isLoading = false;
            }
          }, 
          (error) => {
            this.isLoading = false;
          });
        }
      }
    });
  }

  onSubmit(user: NgForm) {
    const userInfo = this.authService.getUserInfo();
    const path: string = userInfo.role.toLowerCase() + '/addUser';
    if (this.userId) {
      this.userDetails.username = this.selectedUser.username;
      this.restService.submitPutRequest(userInfo.role.toLowerCase() + '/resourceScopes', this.userDetails).subscribe((response: any) => {
        if (response) {
          this.userData.push(response);
          this.location.back();
        }
      });
    } else {
      this.userDetails.tenantId = userInfo.tenantId;
      this.userDetails.marketerId = userInfo.marketerId;
      this.userDetails.firstName = this.person.firstName;
      this.userDetails.lastName = this.person.lastName;
      this.userDetails.email = this.person.email;
      this.userDetails.person = this.person;
      this.userDetails.person.tenantId = userInfo.tenantId;
      this.userDetails.person.marketerId = userInfo.marketerId;
      this.restService.submitPostRequest(path, this.userDetails).subscribe((response: any) => {
        if (response) {
          this.userData.push(response);
        }
        user.resetForm();
        this.location.back();
      });
    }
  }
  onChecking(event: any) {

    const selectedVentures = event.ventures;
    const resourceScopes = event.resourceScopes;

    this.userDetails.resourceScopes = resourceScopes;
    this.userDetails.basicUserVenturesList = selectedVentures;
    this.disableSubmit = !selectedVentures || selectedVentures.length === 0;

  }



}
