<div *ngIf="alert" class="alert alert-success alert-dismissible fade show" role="alert">
    <strong>Success !</strong> Your details have been saved successfully!
    <button type="button" (click)="closeAlert()" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="wrap">
    <div id="stepper1" class="bs-stepper">
        <div class="bs-stepper-header">
            <div class="step" data-target="#personaldetails">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">1</span>
                    <span class="bs-stepper-label">Personal Info.</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#permanentAddress">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">2</span>
                    <span class="bs-stepper-label">Perm. Address</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#communicateAddress">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">3</span>
                    <span class="bs-stepper-label">Comm. Address</span>
                </button>
            </div>
        </div>
        <div class="bs-stepper-content">
            <form #newSalesrep="ngForm">
                <div id="personaldetails" class="content">
                    <!-- <div class="form-group" *ngIf="!isMarketerTeams" style="display: flex;">
                        <input type="checkbox" class="" id="useCustomCommissions"
                            [(ngModel)]="salesRepDetails.useCustomCommissions" name="useCustomCommissions"
                            #useCustomCommissions="ngModel">
                        <label class="mt-1 ml-2 ">Use Custom Commissions </label>
                    </div> -->
                    <div class="row">
                        <div class=" col-md-3">
                            <label>Reporting Manager</label>
                            <ng-autocomplete [data]="salesRep" name="salesrepName" [focusFirst]=true
                                [(ngModel)]="salesRepData" [searchKeyword]="keyword" [itemTemplate]="salesRepTemplate"
                                [notFoundTemplate]="notFoundTemplate">
                            </ng-autocomplete>
                            <ng-template #salesRepTemplate let-item>
                                <div class="item">
                                    <span>{{ item.name }}</span>
                                </div>
                            </ng-template>
                            <ng-template #notFoundTemplate let-notFound>
                                <div>notFound</div>
                            </ng-template>
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': firstName.invalid && (firstName.dirty || firstName.touched || newSalesrep.submitted)}">
                            <label class="star">First Name</label>
                            <input type="text" class="form-control" id="firstName" required
                                [(ngModel)]="salesRepDetails.firstName" name="firstName" #firstName="ngModel">
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': lastName.invalid && (lastName.dirty || lastName.touched || newSalesrep.submitted)}">
                            <label class="star">Last Name</label>
                            <input type="text" class="form-control" id="lastName" required
                                [(ngModel)]="salesRepDetails.lastName" name="lastName" #lastName="ngModel">
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': dob.invalid && (dob.dirty || dob.touched || newSalesrep.submitted)}">
                            <label class="star">DOB</label>
                            <input type="date" class="form-control" id="dob" required [(ngModel)]="salesRepDetails.dob"
                                name="dob" #dob="ngModel" max="9999-12-31">
                        </div>
                        <div class="form-group col-md-3">
                            <label for="star">Gender</label>
                            <select class="form-control" id="gender" required [(ngModel)]="salesRepDetails.gender"
                                name="gender" #gender="ngModel">
                                <option *ngFor="let gen of genders" [value]="gen">
                                    {{gen}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': bloodGroup.invalid && (bloodGroup.dirty || bloodGroup.touched || newSalesrep.submitted)}">
                            <label>Blood Group</label>
                            <select type="text" class="form-control" id="bloodGroup" [(ngModel)]="salesRepDetails.bloodGroup" name="bloodGroup" #bloodGroup="ngModel">
                                <option value="A+">A+</option>
                                <option value="A-">A-</option>
                                <option value="B+">B+</option>
                                <option value="B-">B-</option>
                                <option value="O+">O+</option>
                                <option value="O-">O-</option>
                                <option value="AB+">AB+</option>
                                <option value="AB-">AB-</option>
                            </select>
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': joiningDate.invalid && (joiningDate.dirty || joiningDate.touched || newSalesrep.submitted)}">
                            <label class="star">Joining Date</label>
                            <input type="date" class="form-control" id="joiningDate" required
                                [(ngModel)]="salesRepDetails.joiningDate" name="joiningDate" #joiningDate="ngModel"
                                max="9999-12-31">
                        </div>
                        <!-- <div class="form-group col-md-3"
                            [ngClass]="{'invalid': designation.invalid && (designation.dirty || designation.touched || newSalesrep.submitted)}">
                            <label class="star">Designation</label>
                            <input type="text" class="form-control" id="designation" required
                                [(ngModel)]="salesRepDetails.designation" name="designation" #designation="ngModel">
                        </div> -->
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': nationality.invalid && (nationality.dirty || nationality.touched || newSalesrep.submitted)}">
                            <label>Nationality</label>
                            <input type="text" class="form-control" id="nationality" [(ngModel)]="salesRepDetails.nationality" name="nationality" #nationality="ngModel">
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': panNum.invalid && (panNum.dirty || panNum.touched || newSalesrep.submitted)}">
                            <label>PAN Number</label>
                            <input type="text" class="form-control" id="panNum" maxlength="10" minlength="10"
                                [(ngModel)]="salesRepDetails.panNum" name="panNum" #panNum="ngModel">
                            <div class="text-danger" *ngIf="panNum.dirty && panNum.invalid">Pan number should be 10
                                digit</div>
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': aadharNum.invalid && (aadharNum.dirty || aadharNum.touched || newSalesrep.submitted)}">
                            <label>Aadhaar</label>
                            <input type="text" class="form-control" id="aadharNum" maxlength="12"
                                minlength="12" [(ngModel)]="salesRepDetails.aadharNum" name="aadharNum"
                                #aadharNum="ngModel">
                            <div class="text-danger" *ngIf="aadharNum.dirty && aadharNum.invalid">Aadhar number should
                                be 12 digit</div>
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': contactNumber.invalid && (contactNumber.dirty || contactNumber.touched || newSalesrep.submitted)}">
                            <label class="star">Contact Number</label>
                            <input type="text" class="form-control" id="contactNumber" required
                                [(ngModel)]="salesRepDetails.contactNumber" name="contactNumber"
                                #contactNumber="ngModel">
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': alternateContact.invalid && (alternateContact.dirty || alternateContact.touched || newSalesrep.submitted)}">
                            <label>Alternate Contact Number</label>
                            <input type="text" class="form-control" id="alternateContact"
                                [(ngModel)]="salesRepDetails.alternateContact" name="alternateContact"
                                #alternateContact="ngModel">
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': email.invalid && (email.dirty || email.touched || newSalesrep.submitted)}">
                            <label>Email</label>
                            <input type="text" class="form-control" id="email" [(ngModel)]="salesRepDetails.email" name="email" #email="ngModel">
                        </div>
                        <div class="form-group col-md-3">
                            <label for="alternateContact">Alternate Email</label>
                            <input type="text" class="form-control" id="alternateEmail"
                                [(ngModel)]="salesRepDetails.alternateEmail" name="alternateEmail"
                                #alternateEmail="ngModel">
                        </div>
                        <div class="form-group col-md-3">
                            <label class="">Facebook Id</label>
                            <input type="text" class="form-control" id="facebookId" name="facebookId"
                                #facebookId="ngModel" [(ngModel)]="salesRepDetails.facebookId">
                        </div>
                    </div>
                    <button type="submit" class="btn btn-sm btn-info"
                        [disabled]="!salesRepDetails.firstName || !salesRepDetails.lastName || !salesRepDetails.dob || !salesRepDetails.contactNumber"
                        (click)="next()">Next</button>
                </div>
                <div id="permanentAddress" class="content">
                    <div class="row">
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': addressLine1.invalid && (addressLine1.dirty || addressLine1.touched)}">
                            <label class="star">Address Line 1</label>
                            <input type="text" class="form-control" id="addressLine1" required
                                [(ngModel)]="permAddress.addressLine1" name="addressLine1" #addressLine1="ngModel">
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': addressLine2.invalid && (addressLine2.dirty || addressLine2.touched)}">
                            <label class="">Address Line 2</label>
                            <input type="text" class="form-control" id="addressLine2"
                                [(ngModel)]="permAddress.addressLine2" name="addressLine2" #addressLine2="ngModel">
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': landmark.invalid && (landmark.dirty || landmark.touched)}">
                            <label class="star">Landmark</label>
                            <input type="text" class="form-control" id="landmark" required
                                [(ngModel)]="permAddress.landmark" name="landmark" #landmark="ngModel">
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': city.invalid && (city.dirty || city.touched)}">
                            <label class="star">City</label>
                            <input type="text" class="form-control" id="city" required [(ngModel)]="permAddress.city"
                                name="city" #city="ngModel">
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': mandal.invalid && (mandal.dirty || mandal.touched)}">
                            <label class="star">Mandal</label>
                            <input type="text" class="form-control" id="mandal" required
                                [(ngModel)]="permAddress.mandal" name="mandal" #mandal="ngModel">
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': district.invalid && (district.dirty || district.touched)}">
                            <label class="star">District</label>
                            <input type="text" class="form-control" id="district" required
                                [(ngModel)]="permAddress.district" name="district" #district="ngModel">
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': state.invalid && (state.dirty || state.touched)}">
                            <label class="star">State</label>
                            <input type="text" class="form-control" id="state" required [(ngModel)]="permAddress.state"
                                name="state" #state="ngModel">
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': country.invalid && (country.dirty || country.touched)}">
                            <label class="star">Country</label>
                            <input type="text" class="form-control" id="country" required
                                [(ngModel)]="permAddress.country" name="country" #country="ngModel">
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': pin.invalid && (pin.dirty || pin.touched)}">
                            <label class="star">PIN Code</label>
                            <input type="text" class="form-control" id="pin" required maxlength="6" minlength="6"
                                [(ngModel)]="permAddress.pin" name="pin" #pin="ngModel">
                            <div class="text-danger" *ngIf="pin.dirty && pin.invalid">PIN number should be 6 digit</div>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-sm btn-info"
                        [disabled]="!permAddress.addressLine1 || !permAddress.landmark || !permAddress.city || !permAddress.mandal || !permAddress.state || !permAddress.district || !permAddress.country || !permAddress.pin"
                        (click)="next()">Next</button>
                </div>
                <div id="communicateAddress" class="content">
                    <div class="row">
                        <ul class="list-unstyled mb-0">
                            <li class="form-group col-12">
                                <span class="d-flex"><input type="checkbox" class="form-control mr-2" name="checked"
                                        [(ngModel)]="address2" value="checked">Communication Address same as
                                    permanentAddress</span>
                            </li>
                        </ul>
                    </div>
                    <div class="row" *ngIf="address2== false">
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': addressLine1.invalid && (addressLine1.dirty || addressLine1.touched)}">
                            <label class="star">Address Line 1</label>
                            <input type="text" class="form-control" id="addressLine1" required
                                [(ngModel)]="commAddress.addressLine1" name="addressLine1" #addressLine1="ngModel">
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': addressLine2.invalid && (addressLine2.dirty || addressLine2.touched)}">
                            <label class="">Address Line 2</label>
                            <input type="text" class="form-control" id="addressLine2"
                                [(ngModel)]="commAddress.addressLine2" name="addressLine2" #addressLine2="ngModel">
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': landmark.invalid && (landmark.dirty || landmark.touched)}">
                            <label class="star">Landmark</label>
                            <input type="text" class="form-control" id="landmark" required
                                [(ngModel)]="commAddress.landmark" name="landmark" #landmark="ngModel">
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': city.invalid && (city.dirty || city.touched)}">
                            <label class="star">City</label>
                            <input type="text" class="form-control" id="city" required [(ngModel)]="commAddress.city"
                                name="city" #city="ngModel">
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': mandal.invalid && (mandal.dirty || mandal.touched)}">
                            <label class="star">Mandal</label>
                            <input type="text" class="form-control" id="mandal" required
                                [(ngModel)]="commAddress.mandal" name="mandal" #mandal="ngModel">
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': district.invalid && (district.dirty || district.touched)}">
                            <label class="star">District</label>
                            <input type="text" class="form-control" id="district" required
                                [(ngModel)]="commAddress.district" name="district" #district="ngModel">
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': state.invalid && (state.dirty || state.touched)}">
                            <label class="star">State</label>
                            <input type="text" class="form-control" id="state" required [(ngModel)]="commAddress.state"
                                name="state" #state="ngModel">
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': country.invalid && (country.dirty || country.touched)}">
                            <label class="star">Country</label>
                            <input type="text" class="form-control" id="country" required
                                [(ngModel)]="commAddress.country" name="country" #country="ngModel">
                        </div>
                        <div class="form-group col-md-3"
                            [ngClass]="{'invalid': pin.invalid && (pin.dirty || pin.touched)}">
                            <label class="star">PIN Code</label>
                            <input type="text" class="form-control" id="pin" required maxlength="6" minlength="6"
                                [(ngModel)]="commAddress.pin" name="pin" #pin="ngModel">
                            <div class="text-danger" *ngIf="pin.dirty && pin.invalid">PIN number should be 6 digit</div>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-sm btn-info" (click)="onSubmit(newSalesrep)"
                        [disabled]="!newSalesrep.form.valid">Submit</button>
                </div>
            </form>
            <ng-template #successMsg let-modal>
                <div class="card">
                    <div class="modal-header">
                        <button type="button" class="close" aria-label="Close" (click)="closeAlert()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="card-body modal-body">
                        <div class="text-center">
                            <p class="close-content">{{message}}</p>
                        </div>
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-center">
                                <div class="submit-btn px-2">
                                    <button type="submit" class="btn btn-info" (click)="closeAlert()"
                                        #submitButton>Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>