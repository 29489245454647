import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserPrivileges } from '../constants/AppConstants';
import { RestService } from '../services/rest.service';
import { AuthServiceService } from '../services/auth-service.service';
import { CommonServiceService } from '../services/common-service.service';
import { data } from 'jquery';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-layout-design',
  templateUrl: './layout-design.component.html',
  styleUrls: ['./layout-design.component.scss']
})
export class LayoutDesignComponent implements OnInit {
  @ViewChild('canvas', { static: false }) canvas: ElementRef<HTMLCanvasElement>;

  showUploadVenture = false;
  userPrivileges = UserPrivileges;
  layoutFile: File;
  userInfo: any;
  ventureName: any;
  layoutUrl: any;
  public dataSource?: object[];
  public shapeData?: object;
  public shapePropertyPath?: string;
  public shapeDataPath?: string;
  public shapeSettings?: object;
  public legendSettings?: object;
  coords: any;
  title: any;
  plotType: any;
  plotDetails: any;
  scale: number;
  urlSuffix: any;
  constructor(
    private restService: RestService,
    private authService: AuthServiceService,
    private commonService: CommonServiceService,
    private route: ActivatedRoute,
  ) {
    this.userInfo = this.authService.getUserInfo();
    this.route.params.subscribe((params: Params) => {
      this.urlSuffix = params['urlSuffix'];
      if(this.urlSuffix){
        localStorage.setItem('urlSuffix', this.urlSuffix);
      }
    });
    this.getFIlesUrls();
  }

  ngOnInit(): void {
    this.legendSettings = {
      visible: true
    }
  }

  onLayoutFileSelected(event: any) {
    this.layoutFile = event.target.files[0];
  }

  uploadLayoutFile() {
    if (this.layoutFile) {
      const formData = new FormData();
      formData.append('file', this.layoutFile);
      formData.append('tenantId', this.userInfo.tenantId);
      formData.append('ventureId', this.commonService.getVenture()?.ventureId);
      if (this.layoutFile && this.layoutFile.type.startsWith('image/')) {
        this.restService.uploadLayoutFile(formData).subscribe(response => {
          if (response) {
            this.layoutFile = null;
            this.ngOnInit();
            window.location.reload();
          }
        });
      } else {
        alert('Invalid file type. Please select an image file.');
      }
    } else {
      console.log('No file selected');
    }
  }

  getFIlesUrls() {
    let url1;
    if (this.commonService.getVenture()?.ventureId || this.route.snapshot.paramMap.get('ventureId')) {
      let ventureId = this.commonService.getVenture() ? this.commonService.getVenture().ventureId : this.route.snapshot.paramMap.get('ventureId')
      url1 = '/generic/venture-layout-details?' + 'ventureId=' + ventureId + '&tenantId=' + this.userInfo.tenantId;
      this.restService.getFilesUrls(url1).subscribe(response => {
        if (response != null && response != undefined) {
          console.log(response.data);
          if (response.data === null) {
            this.showUploadVenture = true;
          }
          this.layoutUrl = response.data.ventureLayoutData.layoutUrl;
          this.plotDetails = response.data;
          // this.restService.getplotDetails().subscribe(
          //   (data) => {
          //     this.plotDetails = data.data;
          //   })
          this.imageUpdate()
        }
      });
    } else {
      let layoutId = this.route.snapshot.paramMap.get('code');
      url1 = 'public/venture-layout-details?' + 'urlSuffix=' + this.commonService.getUrlSuffix() + '&ventureLayoutCode=' + layoutId;
      this.restService.getFilesUrls(url1).subscribe(response => {
        if (response != null && response != undefined) {
          this.layoutUrl = response.data.ventureLayoutData.layoutUrl;
          this.plotDetails = response.data;
          // this.restService.getplotDetails().subscribe(
          //   (data) => {
          //     this.plotDetails = data.data;
          //   })
          this.imageUpdate()
        }
      });

    }

  }
  imageUpdate() {
    setTimeout(() => {
      if (this.canvas.nativeElement.getContext) {
        const ctx = this.canvas.nativeElement.getContext("2d");
        const img = new Image();
        img.src = this.layoutUrl;
        img.onload = () => {
          this.canvas.nativeElement.width = img.width;
          this.canvas.nativeElement.height = img.height;
          ctx.drawImage(img, 0, 0);

          if (this.plotDetails) {
            this.plotDetails.plotCoordinatesDataList.forEach((element, index) => {
              const status = element.plotStatusCategory ? 'Not Available' : element.plotStatus;
              const dispalyValue = this.capitalizeFirstLetter(status);
              const tooltip = `
          Plot Id: ${element.plotNum},
          Plot Type: ${element.plotType},
          Plot Status: ${dispalyValue},
          Road Facing: ${element.roadFacing}
        `;
              this.drawShape(ctx, element, tooltip);
            });
          }

          this.canvas.nativeElement.addEventListener('mousemove', (event) => {
            this.handleMouseMove(event, ctx);
          });

          this.canvas.nativeElement.addEventListener('mouseout', () => {
            this.hideTooltip();
          });
        };
      }
    }, 1000);
  }
  capitalizeFirstLetter(str: string): string {
    if (!str) {
      return str;
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  drawShape(ctx: CanvasRenderingContext2D, plotData: any, tooltip: any) {
    // Set the fill color
    if (plotData.plotStatusCategory) {
      ctx.fillStyle = 'gray';
    } else {
      if (plotData.plotStatus.toLowerCase() == "available") {
        ctx.fillStyle = 'green';
      } else if (plotData.plotStatus.toLowerCase() == "blocked") {
        ctx.fillStyle = 'yellow';
      } else if (plotData.plotStatus.toLowerCase() == "sold") {
        ctx.fillStyle = 'orange';
      } else if (plotData.plotStatus.toLowerCase() == "mortgage") {
        ctx.fillStyle = 'aqua';
      } else if (plotData.plotStatus.toLowerCase() == "m-sold") {
        ctx.fillStyle = 'blue';
      } else if (plotData.plotStatus.toLowerCase() == "registered") {
        ctx.fillStyle = 'red';
      }

    }
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 1;

    // Begin the path
    ctx.beginPath();
    // Move to the first point
    if (plotData.coordinatesList.length == 4) {
      const x1 = plotData.coordinatesList[0];
      const y1 = plotData.coordinatesList[1];
      const x2 = plotData.coordinatesList[2];
      const y2 = plotData.coordinatesList[3];
      ctx.fillRect(x1, y1, x2 - x1, y2 - y1);
      ctx.strokeRect(x1, y1, x2 - x1, y2 - y1);
    } else {
      ctx.moveTo(plotData.coordinatesList[0], plotData.coordinatesList[1]);

      for (let i = 2; i < plotData.coordinatesList.length; i += 2) {
        ctx.lineTo(plotData.coordinatesList[i], plotData.coordinatesList[i + 1]);
      }

      if (plotData.coordinatesList.length % 2 === 0) {
        ctx.closePath();
      }

      ctx.fill();
      ctx.stroke();
    }
    const centerX = plotData.coordinatesList.reduce((acc, val, index) => index % 2 === 0 ? acc + val : acc, 0) / (plotData.coordinatesList.length / 2);
    const centerY = plotData.coordinatesList.reduce((acc, val, index) => index % 2 !== 0 ? acc + val : acc, 0) / (plotData.coordinatesList.length / 2);
    const text = plotData.plotNum;
    ctx.fillStyle = 'white';
    ctx.font = '16px Arial';
    ctx.textAlign = 'center';
    ctx.fillText(text, centerX, centerY);
    (ctx.canvas as any).tooltips = (ctx.canvas as any).tooltips || [];
    (ctx.canvas as any).tooltips.push({ x: centerX, y: centerY, text: tooltip });
  }
  handleMouseMove(event: MouseEvent, ctx: CanvasRenderingContext2D) {
    const tooltips = ((ctx.canvas as any).tooltips || []) as { x: number, y: number, text: string }[];
    const rect = ctx.canvas.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const mouseY = event.clientY - rect.top;
    tooltips.forEach(tooltip => {
      if (mouseX >= tooltip.x - 20 && mouseX <= tooltip.x + 20 &&
        mouseY >= tooltip.y - 20 && mouseY <= tooltip.y + 20) {
        this.showTooltip(tooltip.x, tooltip.y, tooltip.text);
      }
    });
  }

  showTooltip(x: number, y: number, text: string) {
    const tooltip = document.getElementById('tooltip');
    tooltip.style.left = (x + 10) + 'px';
    tooltip.style.top = (y - 20) + 'px';
    tooltip.innerHTML = text;
    tooltip.style.display = 'block';
  }

  hideTooltip() {
    const tooltip = document.getElementById('tooltip');
    tooltip.style.display = 'none';
  }

  handleZoom(event: WheelEvent) {
    if (event.deltaY < 0) {
      this.scale *= 1.1;
    } else {
      this.scale *= 0.9;
    }
    event.preventDefault();
  }

}
