import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subject } from 'rxjs';
import { UserPrivileges } from 'src/app/constants/AppConstants';
import { RoutesConstants } from 'src/app/constants/RoutesConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-commissionstructure',
  templateUrl: './commissionstructure.component.html',
  styleUrls: ['./commissionstructure.component.scss']
})
export class CommissionstructureComponent implements OnInit {
  @Input() salesrepId: number;

  measure: any = "yard";
  commissionStructure: any = [];
  error: boolean;
  errorMessage: any;
  userInfo: any;
  ventureId: any;
  ventureTeam: any;
  userPrivileges = UserPrivileges;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  dtTrigger1: Subject<any> = new Subject<any>();
  customCommission: any = [];
  showHistory = false;
  commissionStructureHistory: any = [];

  constructor(
    private restService: RestService,
    private router: Router,
    private authService: AuthServiceService,
    private commonService: CommonServiceService,
    private permissionsService: NgxPermissionsService
  ) { }

  ngOnInit(): void {

    this.userInfo = this.authService.getUserInfo();
    this.ventureId = this.commonService.getVenture()?.ventureId

    this.fetchActiveCommissionStructure();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      bSort: false,
      searching: false
    };
  }

  editCommissionStructure(commission) {
    this.permissionsService.hasPermission(UserPrivileges.MARKETER_SUPER_PRIVILEGE).then(hasMarketerSuperPrivilege => {
      if (hasMarketerSuperPrivilege) {
        this.commonService.updateCommissionStructure(commission);
        this.router.navigate(['/'+this.commonService.getUrlSuffix(), RoutesConstants.VENTURELAYOUT, RoutesConstants.EDITCOMMISSIONSTRUCTURE], { state: { commission } });
      }
    });
  }

  addCStructure() {
    this.router.navigate(['/'+this.commonService.getUrlSuffix(), RoutesConstants.VENTURELAYOUT, RoutesConstants.ADDCOMMISSIONSTRUCTURE]);
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  fetchActiveCommissionStructure(){

    this.showHistory = false;

    let url = 'marketer/get-commission-structure?ventureId=' + this.ventureId;

    this.restService.getCommissionStructureHistory(url).subscribe(response => {
      if (response != null && response != undefined) {
        this.commissionStructure = response.data;
        this.dtTrigger.next();
      }
    },
    (error) => {
      console.log(error.status);
    });

  }


  fetchCommissionStructureHistory(){
    this.showHistory = true;
    let url = 'marketer/get-commission-structure-history?ventureId=' + this.ventureId;

    this.restService.getCommissionStructureHistory(url).subscribe(response => {
      if (response != null && response != undefined) {
        this.commissionStructureHistory = response.data;
        this.dtTrigger.next();
      }
    },
    (error) => {
      console.log(error.status);
    });

  }

}
