import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { UserPrivileges, UserRoleConstants } from 'src/app/constants/AppConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-ventureteam',
  templateUrl: './ventureteam.component.html',
  styleUrls: ['./ventureteam.component.scss']
})
export class VentureteamComponent implements OnInit {
  @ViewChild('changeDesignation', { static: false }) changeDesignation: ElementRef;
  ventureTeam: any = [];
  error: boolean;
  errorMessage: any;
  submit = false;
  salesrepvalue: any;
  userInfo: any;
  ventureId: any;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  ventureName: any;
  userPrivileges = UserPrivileges;
  radioSelected: any;
  salesRep: any = [];
  salesRepData: any;
  keyword = 'name';
  addSalesrep: any = {};
  ventureTeamdata: any = [];
  team: any = [];
  allowedDesignationsList: any = [];
  selectedDesignation: any;
  updatedSalesrepDesignationObject: any;
  updatedSalesrepDesignation: String;
  closeResult : String;

  constructor(private restService: RestService,
    private router: Router,
    private authService: AuthServiceService,
    private commonService: CommonServiceService,
    private modalService: NgbModal,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    this.ventureId = this.commonService.getVenture()?.ventureId;
    this.ventureName = this.commonService.getVenture()?.displayName;
    this.commonService.clearVentureTeam();
    this.getventureTeam();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      searching: false
    };
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  getventureTeam(): void {
    const data = {
      "tenantId": this.userInfo.tenantId,
      "marketerId": this.userInfo.marketerId,
      "ventureId": this.ventureId
    };

    const path: string = this.authService.hasRole(UserRoleConstants.MARKETER) ? 'venture-team/get-venture-team' : 'tenant/get-venture-team';

    this.restService.submitPostRequest(path, data)
      .subscribe(
        (response: any) => {
          if (response) {
            this.ventureTeam = response.data;
            this.team = this.ventureTeam.teams;
            // this.dtTrigger.next();
            if (this.team != null) {
              this.getMarketerTeam();
            }
          }
        },
        (error: any) => {
          this.handleError(error);
        }
      );
  }

  private handleError(error: any): void {
    this.error = true;
    this.errorMessage = error.status;
  }

  getMarketerTeam() {
    let data = {
      "tenantId": this.userInfo.tenantId,
      "marketerId": this.userInfo.marketerId,
    }
    const path: string = 'marketer/get-markerter-teams';
    this.restService.submitPostRequest(path, data).subscribe(response => {
      if (response) {
        this.salesRep = response.teams.filter(a => a.salesrepId != this.team.salesrepId);
      }
    }, (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });
  }
  onSubmit() { this.submit = true; }
  saveVentureTeam(salesRep: any): void {

    const ventureTeam = {
      "tenantId": this.userInfo.tenantId,
      "marketerId": this.userInfo.marketerId,
      "ventureId": this.ventureId,
      "salesrepId": this.salesRepData.salesrepId,
      "salesrepDesignation": this.selectedDesignation
    };

    const path: string = 'venture-team/add-venture-team';

    this.restService.submitPostRequest(path, ventureTeam)
      .subscribe(response => {
        if (response) {
          this.ventureTeamdata = response;
          this.refreshTheComponenet();
          this.getventureTeam();
        }
      });
    this.submit = false;
  }
  onShow() {
    this.submit = false;
    this.refreshTheComponenet();
    this.getventureTeam();
  }
  onDisplay(ventureTeam: any) {
    this.commonService.setVentureTeam(ventureTeam);
    this.router.navigate(['../alldetails', ventureTeam.salesrepId], { relativeTo: this.route });
    this.salesrepvalue = ventureTeam.salesrepId;
  }

  getSalesrepAllowedDesignationsList(item){

    let url = 'venture-team/get-salesrep-allowed-designations?salesrepId=' + item.salesrepId + '&ventureId=' + this.ventureId;

    this.restService.getSalesrepAllowedDesignations(url).subscribe(response => {
      if (response != null && response != undefined) {
        this.allowedDesignationsList = response.data;
        this.dtTrigger.next();
      }
    },
    (error) => {
      console.log(error.status);
    });
  }

  showChangeSalesrepDesignation(e:Event,salesrepId,salesrepName,salesrepDesignation) {
    e.stopPropagation();
    this.updatedSalesrepDesignationObject = {"salesrepId" :salesrepId, "salesrepName" : salesrepName,"salesrepDesignation" : salesrepDesignation};

    let url = 'venture-team/get-salesrep-allowed-designations?salesrepId=' + this.updatedSalesrepDesignationObject.salesrepId + '&ventureId=' + this.ventureId;

    this.restService.getSalesrepAllowedDesignations(url).subscribe(response => {
      if (response != null && response != undefined) {
        this.allowedDesignationsList = response.data;
        this.dtTrigger.next();
      }
    },
    (error) => {
      console.log(error.status);
    });

    this.open(this.updatedSalesrepDesignationObject);
  }

  open(content) {
		this.modalService.open(this.changeDesignation, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

  private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

  changeSalesrepDesignation(){
    let reqBody = this.updatedSalesrepDesignationObject;
    reqBody.salesrepDesignation = this.updatedSalesrepDesignation;
    reqBody.ventureId = this.ventureId;
    this.restService.updateSalesrepDesignation(reqBody).subscribe(
     (response: any) => {
       if (response != null) {
         this.getventureTeam();
         this.updatedSalesrepDesignation="";
       }
     }, (error) => {
       this.error = true;
       this.errorMessage = error.status;
       if (error.error && error.error.message) {
        alert(error.error.message);
        } else {
        console.log("Unknown API Error");
        }
     })
  }

  refreshTheComponenet(){
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }


}

