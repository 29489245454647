import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServiceService } from '../../services/auth-service.service';
import { PersonalDetails, Person } from 'src/app/models/personal-details';
import { RestService } from '../../services/rest.service';
import { ThemeService } from '../../services/theme.service';
import { UserRoleConstants } from '../../constants/AppConstants';

@Component({
  selector: 'app-newprofile',
  templateUrl: './newprofile.component.html',
  styleUrls: ['./newprofile.component.scss']
})
export class NewprofileComponent implements OnInit {

  @Input() sales;
  setAdmin: boolean;
  isNavSolid: boolean;
  checked = false;
  navMode: any;
  mode: string;
  layout: string;
  themeLayout: string;
  myDetails: any = [];
  person: any = [];
  bankArray: any = [];
  error: boolean;
  errorMessage: any;
  bankDeatils: any;
  userInfo: any;
  address: any =[];
  submit = false;

  constructor(public themeService: ThemeService,
    private authService: AuthServiceService, 
    private restService: RestService , 
    private router: Router, 
    private route: ActivatedRoute) { 
      this.address =[];

    }
  value: string;
  name: any;
  vals: any;
  bgPrimary: any;
  allcookies: any;
  cookiearray: any;
  salesrepId;

  setColorCookie(name: string, val: string) {
    this.value = val;
    document.cookie = name + "=" + this.value + ";  path=/";
  }


  sidebarToggleNewTenant(e) {
    this.checked = e.target.checked;
    if (this.checked) {
      this.navMode = "solid";
      document.cookie = this.mode + "=" + this.navMode + "; path=/";
    }
    this.themeService.toggleSidebarNew();
    if (!this.checked) {
      this.navMode = "image"
      document.cookie = this.mode + "=" + this.navMode + "; path=/";
    }
  }


  horizontalHeader(e) {
    this.checked = e.target.checked;
    if (this.checked) {
      document.cookie = 'layout' + "=" + 'horizontal' + "; path=/";
    }
    if (!this.checked) {
      document.cookie = 'layout' + "=" + 'left' + "; path=/";
    }
    this.themeService.toggleField();
  }


  themeColorChange(value) {
    if (value === 'primary') {
      this.setColorCookie("color", "primary");
      this.themeService.setActiveTheme("primary")
    }
    else if (value === 'danger') {
      this.setColorCookie("color", "danger");
      this.themeService.setActiveTheme("danger")
    }
    else if (value === 'success') {
      this.setColorCookie("color", "success");
      this.themeService.setActiveTheme("success")
    }
    else if (value === 'info') {
      this.setColorCookie("color", "info");
      this.themeService.setActiveTheme("info")
    }
    else if (value === 'dark') {
      this.setColorCookie("color", "dark");
      this.themeService.setActiveTheme("dark")
    }
  }

  ngOnInit() {
this.salesrepId = this.sales;

    this.userInfo= this.authService.getUserInfo();

    if (this.sales) {
      this.personal();
      this.bank()
        }
   else{
    this.personalDetails();
    this.bankDetails();
     }
    
    this.setAdmin = this.authService.hasRole(UserRoleConstants.ADMIN);
    localStorage.setItem("mode", "image");

  }
  ngAfterContentInit() {
    if (this.themeService.themLayoutTop == "horizontal") {
      this.checked = true;
    }
  }

  onChangeSave(personalDetails: Person, myProfile) {
    if (myProfile.valid) {
      this.saveProgramData();
    }
  }
  saveProgramData() {
  }

  bankDetails() {
    let bankData = {
      "tenantId": this.userInfo.tenantId,
      "bankAccountId": this.userInfo.salesrepId,
    }
    this.restService.getbankDetails(bankData).subscribe(response => {
      if (bankData != null )
        this.bankArray.push(response)
        this.bankArray.map((value)=>{
          this.bankDeatils = value;
        })
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      });

  }
  personalDetails() {
    const path: string = (this.authService.hasRole(UserRoleConstants.MARKETER))? 'marketer/getMarketerById':
    '/venture-team/salesrep/get-salesrep';
    let data = {
      "salesrepId": this.userInfo.salesrepId,
      "tenantId": this.userInfo.tenantId,
      "marketerId": this.userInfo.marketerId
    }
    this.restService.submitPostRequest(path,data).subscribe(response => {
      if (response.data != null) {
        this.myDetails = response.data;
        this.person.push(this.myDetails);
        this.address.push(this.myDetails.primaryAddress);

      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      });
  }
  bank() {
    let bankData = {
      "tenantId": this.userInfo.tenantId,
      "bankAccountId": this.sales
    }
    this.restService.getbankDetails(bankData).subscribe(response => {
      if (bankData != null )
        this.bankArray.push(response)
        this.bankArray.map((value)=>{
          this.bankDeatils = value;
        })
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      });

  }
  personal() {
    let data = {
      "salesrepId": this.sales,
      "tenantId": this.userInfo.tenantId,
      "marketerId": this.userInfo.marketerId
    }
    this.restService.getmyDetails(data).subscribe(response => {
      if (response.data != null) {
        this.myDetails = response.data;
        this.person.push(this.myDetails);
        this.address.push(this.myDetails.primaryAddress);
      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      });
  }
sendName(){
  
}
onSubmit() { this.submit = true; }

  onShow() {
    this.submit = false;
  }
  

}


