import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-existing-customer',
  templateUrl: './existing-customer.component.html',
  styleUrls: ['./existing-customer.component.scss']
})
export class ExistingCustomerComponent implements OnInit {
  @Input() selectedCustomer: any;
  constructor() { }

  ngOnInit() {}
}
