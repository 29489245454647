<section class="my-3">
  <div class="row">
    <div class="col-lg-6 col-md-12" style="font-size: 13px;">
      <div class="mb-3">
        <div class="card shadow mb-2">
          <h6 class="card-title text-white position-relative pb-2 p-3 w-100" style="background-color: rgb(43,45,65);">
            Venture Wise Revenue</h6>
          <div class="card-body pb-4 mx-0 pt-2">
            <div style="color: black;">
              <canvas #mycanvas baseChart height="220" width="200" [datasets]="barChartData" [labels]="barChartLabels"
                [options]="barChartOptions" [legend]="barChartLegend" [chartType]="barChartType"
                [plugins]="barChartPlugins" [colors]="barChartColors"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12" style="font-size: 13px;">
      <div class="card shadow mb-3">
        <div class="card-body p-3">
          <h6 class="card-title text-dark position-relative pb-2 h6">Revenue Percentage</h6>
          <div>
            <div class="chart " *ngIf="pieChartData">
              <canvas baseChart height="20" width="50" [data]="pieChartData" [labels]="pieChartLabels"
                [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins"
                [colors]="pieChartColors" [legend]="pieChartLegend">
              </canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="card shadow  h-50 mb-2">
        <div class="d-flex" style="background-color: rgb(43,45,65);">
          <h6 class="card-title text-white position-relative pb-2 p-3 w-100">Sales</h6>
          <select class="custom-select custom-select-sm select-month mr-2 mt-3">
            <option value="0" selected>Jan</option>
            <option value="1">Feb</option>
            <option value="2">Mar</option>
          </select>
        </div>
        <div class="card-body pb-4 mx-0 pt-2 ">
          <div class="pb-4" style="color: black;" *ngIf="lineChartLabels.length > 0">
            <canvas #mycanvas baseChart height="150" [datasets]="lineChartData" [labels]="lineChartLabels"
              [options]="lineChartOptions" [legend]="lineChartLegend" [chartType]="lineChartType"
              [plugins]="lineChartPlugins"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12 h-100" style="font-size: 13px;">
      <div class="card shadow  mb-3">
        <div class="card-body px-3 pt-2 pt-0">
          <h6 class="card-title text-dark position-relative pb-2 h6">Top 5 performing Sales rep</h6>
          <table class="table table-striped table-bordered table-sm row-border hover">
            <thead>
              <tr>
                <th>Sales rep</th>
                <th>Plots Sold</th>
                <th>Yards Sold</th>
                <th>Revenue</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let top of Performance.bestperformance">
                <td>{{top.salesRep}}</td>
                <td>{{top.plotsSold}}</td>
                <td>{{top.yardsSold}}</td>
                <td>{{top.revenue}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>