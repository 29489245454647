import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { UserPrivileges } from 'src/app/constants/AppConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

  Payments: any = [];
  userPrivileges = UserPrivileges;
  error: boolean;
  errorMessage: any;
  submit = false;
  userInfo: any;
  ventureId: any;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private restService: RestService,
    private authService: AuthServiceService,
    private commonService: CommonServiceService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.userInfo= this.authService.getUserInfo();
    const path: string =  this.userInfo.role.toLowerCase() + '/get-payments';
    this.ventureId = this.commonService.getVenture()?.ventureId;
    let data = {
      "tenantId": this.userInfo.tenantId,
      "marketerId": this.userInfo.marketerId,
      "ventureId": this.ventureId
    }
    this.restService.submitPostRequest(path,data).subscribe(response => {
      if (data != null) {
        this.Payments = response
        this.dtTrigger.next()
      }
    })
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      bSort: false,
      searching: false
    };
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  onSubmit() { this.submit = true; }

  onShow() { this.submit = false; }

  exportPayments(){
    const formattedData = this.Payments.payments.map(commissionPayment => ({
      "Name": commissionPayment.name,
      "Amount Paid": commissionPayment.amountPaid,
      "Payment Mode": commissionPayment.modeOfPayment,
      "Comments": commissionPayment.comments,
      "Payment Date": this.datePipe.transform(commissionPayment.paymentDate, 'dd-MM-yyyy'),
    }));
    this.commonService.exportToExcel(formattedData, 'CommissionPayments', 'commission_payments');
  }
}
