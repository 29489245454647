<div class="">
  <div class=" mt-3 position-relative right-tabs clearfix mb-0 card">
    <a class="position-absolute close-popup-link bg-white" (click)="sendBack()" *ngIf="show"><i
        class="fa fa-times" aria-hidden="true"></i>
    </a>
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="justify-content-end pr-3">
      <li class="mr-auto pt-3 px-4 mb-0">
        <h6>Sale Details ({{ventureName}})</h6>
      </li>
      <li [ngbNavItem]="1">
        <a ngbNavLink>Sales</a>
        <ng-template ngbNavContent>
          <app-venturesales></app-venturesales>
        </ng-template>
      </li>
      <li [ngbNavItem]="4">
        <a ngbNavLink>Sale Summary</a>
        <ng-template ngbNavContent>
          <app-salesummary></app-salesummary>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>By Sales Rep</a>
        <ng-template ngbNavContent>
          <app-salesbysalesrep></app-salesbysalesrep>
        </ng-template>
      </li>
      <!-- <li [ngbNavItem]="3">
        <a ngbNavLink>By Month</a>
        <ng-template ngbNavContent>
          <app-salesbymonth></app-salesbymonth>
        </ng-template>
      </li> -->
      <!-- <li [ngbNavItem]="2">
            <a ngbNavLink>Payments </a>
            <ng-template ngbNavContent>
              <app-venturesalepayments></app-venturesalepayments>
            </ng-template>
          </li> -->
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>
<router-outlet></router-outlet>