<div class="my-2">
  <div class="card">
    <div class="card-body">
      <h6 class="card-title text-dark position-relative pb-2 h6">Sales Summary</h6>
      <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions"
        [dtTrigger]="dtTrigger">
        <thead>
          <tr>
            <th>Sales rep id</th>
            <th>Venture</th>
            <th>Total Sales</th>
            <th>Sale in Progress</th>
            <th>Total Commission</th>
            <th>Comission earned</th>
            <th>Comission pending</th>
            <th>Comission paid</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let sales of salesData" (click)="editDetails()">
            <td>{{sales.salesrepId}}</td>
            <td>{{sales.venture}}</td>
            <td>{{sales.totalSale}}</td>
            <td>{{sales.saleInProgress}}</td>
            <td>{{sales.totalCommission}}</td>
            <td>{{sales.commissionEarned}}</td>
            <td>{{sales.commissionPending}}</td>
            <td>{{sales.commissionPaid}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>