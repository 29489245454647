import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { UserRoleConstants } from 'src/app/constants/AppConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-salesbymonth',
  templateUrl: './salesbymonth.component.html',
  styleUrls: ['./salesbymonth.component.scss']
})
export class SalesbymonthComponent implements OnInit, OnDestroy {

  performance: any = [];
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private authService: AuthServiceService,
    public commService: CommonServiceService,
    private restService: RestService) { }

  ngOnInit(): void {
    const path: string = (this.authService.hasRole(UserRoleConstants.MARKETER)) ? 'marketer/marketerVentureDashboard' :
      'tenant/tenantVentureDashboard';
    const userInfo: any = this.authService.getUserInfo();
    const data: any = {
      tenantId: userInfo.tenantId,
      marketerId: userInfo.marketerId,
      ventureId: this.commService.getVenture()?.ventureId,
    };
    this.restService.submitPostRequest(path, data).subscribe(response => {
      this.performance = response.data;
      this.dtTrigger.next();
    });
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      searching: false
    };
  }
  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }
}
