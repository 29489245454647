<div class=" my-3">
    <div class="card">
        <a class="position-absolute close-popup-link bg-white" (click)="sendBack()"><i class="fa fa-times"
                aria-hidden="true"></i>
        </a>
        <div class="card-body">
            <h6 class="card-title text-dark position-relative pb-2 ">{{ isEditView ? 'Update Plot Pricing' : 'New Plot Pricing' }}</h6>
            <form #details="ngForm" (ngSubmit)="onSubmit(details.value)">
                <div class="row mt-2">
                    <div class="form-group col-md-6"
                        [ngClass]="{'invalid': (plotType.invalid && (plotType.dirty || plotType.touched))}">
                        <label class="star">Plot Type</label>
                        <select class="form-control" id="plotType" [value]="plotPricing?.plotType" [(ngModel)]="plotPricing.plotType" [disabled]="isEditView"  required name="plotType" #plotType="ngModel" ngModel>
                            <option *ngFor="let type of plottype" value={{type}}>{{type}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-6"
                        [ngClass]="{'invalid': (measure.invalid && (measure.dirty || measure.touched))}">
                        <label class="star">Measure</label>
                        <select class="form-control" id="measure" [value]="plotPricing?.measure" [(ngModel)]="plotPricing.measure" [disabled]="isEditView" name="measure" #measure="ngModel" required ngModel>
                            <option [value]="'Square ' + measureValue">Square {{commonService.getMeasure()}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-6"
                        [ngClass]="{'invalid': (maxSellingPrice.invalid && (maxSellingPrice.dirty || maxSellingPrice.touched))}">
                        <label class="star">Max Selling Price</label>
                        <input type="number" class="form-control" id="maxSellingPrice" name="maxSellingPrice"
                            #maxSellingPrice="ngModel" required ngModel [value]="plotPricing?.maxSellingPrice" [(ngModel)]="plotPricing.maxSellingPrice">
                    </div>

                    <div class="form-group col-md-6"
                        [ngClass]="{'invalid': (minSellingPrice.invalid && (minSellingPrice.dirty || minSellingPrice.touched))}">
                        <label class="star">Min Selling Price</label>
                        <input type="number" class="form-control" id="minSellingPrice" name="minSellingPrice"
                            #minSellingPrice="ngModel" required ngModel [value]="plotPricing?.minSellingPrice" [(ngModel)]="plotPricing.minSellingPrice">
                    </div>
                    <div class="form-group col-md-6"
                        [ngClass]="{'invalid': (maxDiscountAllowed.invalid && (maxDiscountAllowed.dirty || maxDiscountAllowed.touched))}">
                        <label class="star">Max Discount Allowed</label>
                        <input type="number" class="form-control" id="maxDiscountAllowed" name="maxDiscountAllowed"
                            #maxDiscountAllowed="ngModel" required ngModel [value]="plotPricing?.maxDiscountAllowed" [(ngModel)]="plotPricing.maxDiscountAllowed">
                    </div>
                    <div class="form-group col-md-6"
                        [ngClass]="{'invalid': (priceForMarketer.invalid && (priceForMarketer.dirty || priceForMarketer.touched))}">
                        <label class="star">Price For Marketer</label>
                        <input type="number" class="form-control" id="priceForMarketer" name="priceForMarketer"
                            #priceForMarketer="ngModel" required ngModel [value]="plotPricing?.priceForMarketer" [(ngModel)]="plotPricing.priceForMarketer">
                    </div>
                    <button type="submit" class="btn btn-info" [disabled]="details.invalid">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>