import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { RoutesConstants } from 'src/app/constants/RoutesConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-plotstatus',
  templateUrl: './plotstatus.component.html',
  styleUrls: ['./plotstatus.component.scss']
})
export class PlotstatusComponent implements OnInit {
@Input() plot;
  status: any = {}
  plotNum: any;
  plotId: any;
  plotStatus: any = [];

  constructor(
    private activatedRouter: ActivatedRoute,
    private authService: AuthServiceService,
    private location: Location,
    private router: Router,
    private restService: RestService,
    private commonService: CommonServiceService
  ) {
    this.activatedRouter.queryParams.subscribe(
      (params: any) => {
        this.plotNum = params['plotNum'];
        this.plotId = params['plotId'];
      });
  }

  ngOnInit(): void { 
    console.log(this.plot)
  }

  sendBack() {
    this.location.back()
  }

  onSubmit(plot: any) {
    const userInfo = this.authService.getUserInfo();
    const path: string = "tenant/update-registration-status";
    this.status.tenantId = userInfo.tenantId;
    this.status.ventureId = this.commonService.getVenture()?.ventureId;
    this.status.plotId = this.plot.plotId;
    this.status.plotNum = this.plot.plotNumber;
    this.restService.submitPostRequest(path, this.status).subscribe(response => {
      this.plotStatus = response;
    });
   // this.location.back();
   this.router.navigate([RoutesConstants.VENTURELAYOUT, RoutesConstants.VENTURESALES]);
   close();
  }

}
