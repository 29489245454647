<div class=" mt-3 position-relative right-tabs clearfix mb-0 card">
    <a class="position-absolute close-popup-link bg-white" (click)="sendBack()" *ngIf="show"><i class="fa fa-times" aria-hidden="true"></i>
</a>
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="justify-content-end pr-3">
        <li class="mr-auto pt-3 px-4 mb-0">
            <h6>Commission Details ({{ventureName}})</h6>
        </li>
        <!-- <li [ngbNavItem]="4">
            <a ngbNavLink>Commission Summary </a>
            <ng-template ngbNavContent>
                <app-commissionsummary></app-commissionsummary>
            </ng-template>
        </li> -->
        <li [ngbNavItem]="1">
            <a ngbNavLink>Commissions</a>
            <ng-template ngbNavContent>
                <app-commissions></app-commissions>
            </ng-template>
        </li>
        <li [ngbNavItem]="2">
            <a ngbNavLink>Payments </a>
            <ng-template ngbNavContent>
                <app-payments></app-payments>
            </ng-template>
        </li>
        <li [ngbNavItem]="3">
            <a ngbNavLink>Advances </a>
            <ng-template ngbNavContent>
                <app-all-salesrep-advances></app-all-salesrep-advances>
            </ng-template>
        </li>
        <li [ngbNavItem]="4" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.MARKETER_BASIC_PRIVILEGE]">

            <a ngbNavLink>Commission Structure</a>
            <ng-template ngbNavContent>
                <app-commissionstructure></app-commissionstructure>

            </ng-template>

        </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

<router-outlet></router-outlet>