<div class=" my-3 bg-white">
    <div class="row mx-3 my-3">
        <div class="col-md-4">
            <div class="card  mb-3">
                <div class="card-body px-3 pb-4 pt-2">
                    <ul class="list-unstyled my-4">
                        <li *ngFor="let plot of marketerSale">
                            <div class="row">
                                <div class="col-6">Total Commission:</div>
                                <div class="col-6"><b>&#8377;{{plot.totalCommission.toLocaleString('en-IN')}}</b></div>
                                <div class="col-6">Commission Earned:</div>
                                <div class="col-6"><b>&#8377;{{plot.commissionEarned.toLocaleString('en-IN')}}</b></div>
                                <div class="col-6">Commission Paid:</div>
                                <div class="col-6"><b>&#8377;{{plot.commissionPaid.toLocaleString('en-IN')}}</b>
                                </div>
                                <div class="col-6">Pending Payment:</div>
                                <div class="col-6"><b>&#8377;{{plot.commissionPending.toLocaleString('en-IN')}}</b>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="card mb-3">
                <div class="card-body">
                    <div>
                        <div *ngIf="lineChartData">
                            <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels"
                                [options]="lineChartOptions" [plugins]="lineChartPlugins" [legend]="lineChartLegend"
                                [colors]="lineChartColors" [chartType]="lineChartType">
                            </canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>