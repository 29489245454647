import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { data } from 'jquery';
import { Subject, Subscription } from 'rxjs';
import { UserPrivileges, UserRoleConstants } from '../../constants/AppConstants';
import { AuthServiceService } from '../../services/auth-service.service';
import { CommonServiceService } from '../../services/common-service.service';
import { RestService } from '../../services/rest.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  submit = false;
  userDetails: any = [];
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  userPrivileges = UserPrivileges;
  constructor(private authService: AuthServiceService,
    private restService: RestService,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonServiceService) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      searching: false,
    };
    const path: string = (this.authService.hasRole(UserRoleConstants.MARKETER)) ? 'marketer/getMarketerUsers' :
      'tenant/getTenantUsers';

    let data = {
      tenantId: this.authService.getUserInfo().tenantId,
      marketerId: this.authService.getUserInfo().marketerId,
    }
    this.restService.submitPostRequest(path, data).subscribe(response => {
      if (response) {
        this.userDetails = response.data;
        this.dtTrigger.next();
      }
    });

  }

  onSubmit() {
    this.submit = true;
  }
  onShow() {
    this.submit = false;
  }

  addUser() {
    this.router.navigate(['../user/add'], { relativeTo: this.route });
  }

  onDisplay(row) {
    this.router.navigate(['../user', row?.person?.personId], { relativeTo: this.route });
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }
}
