<div class="my-2" *ngIf="commissionStructure">
    <div class="card">
        <div class="card-body">
            <div class="row">
                 <div class="col-md-12 text-right" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE]">
                    <button class=" btn btn-info mb-3" (click)="addCStructure()">Add Commission Structure</button>
                </div> 
            </div>

            <div class="row p-2 mx-0 mb-2 ">
                <form class="form-inline">
                    <div class="form-check px-3">
                        <label class="form-check-label">
                            <input type="radio" class="form-check-input" id="radio1" name="optradio" [checked]="true" (click)="fetchActiveCommissionStructure()"> Active
                        </label>
                    </div>
                    <div class="form-check px-3">
                        <label class="form-check-label">
                            <input type="radio" class="form-check-input" (click)="fetchCommissionStructureHistory()" id="radio5" name="optradio"> History
                        </label>
                    </div>
                </form>
            </div>

            <div *ngIf="!showHistory" class="wrap">
                <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th>Salesrep Designation</th>
                            <th>Effective From</th>
                            <th>Commission Type</th>
                            <th>Commission Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let commission of commissionStructure" (click)="editCommissionStructure(commission)">
                            <td>{{commission.salesrepDesignation}}</td>
                            <td>{{commission.effectiveDate | date}}</td>
                            <td *ngIf="commission.commissionType === 'perMeasure'">Per Measure</td>
                            <td *ngIf="commission.commissionType === 'percentage'">Percentage</td>
                            <td *ngIf="commission.commissionType === 'fixed'">Fixed</td>                              
                            <td *ngIf="commission.commissionType === 'perMeasure'">{{commission.commissionPerMeasure}}</td>
                            <td *ngIf="commission.commissionType === 'percentage'">{{commission.commissionPercentage}}</td>
                            <td *ngIf="commission.commissionType === 'fixed'">{{commission.commissionFixed}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>


            <div *ngIf="showHistory" class="wrap">
                <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th>Salesrep Designation</th>
                            <th>Effective From</th>
                            <th>Effective To</th>
                            <th>Commission Type</th>
                            <th>Commission Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let commission of commissionStructureHistory">
                            <td>{{commission.salesrepDesignation}}</td>
                            <td>{{commission.effectiveDate | date}}</td>
                            <td>{{commission.effectiveEndDate | date}}</td>
                            <td *ngIf="commission.commissionType === 'perMeasure'">Per Measure</td>
                            <td *ngIf="commission.commissionType === 'percentage'">Percentage</td>
                            <td *ngIf="commission.commissionType === 'fixed'">Fixed</td>
                              
                            <td *ngIf="commission.commissionType === 'perMeasure'">{{commission.commissionPerMeasure}}</td>
                            <td *ngIf="commission.commissionType === 'percentage'">{{commission.commissionPercentage}}</td>
                            <td *ngIf="commission.commissionType === 'fixed'">{{commission.commissionFixed}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>


        </div>
    </div>
</div>