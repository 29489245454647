<div class=" mt-3 position-relative right-tabs clearfix mb-0 card">

    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="justify-content-end pt-0 pr-3">
        <li class="mr-auto pt-3 px-4 mb-0" *ngFor="let personalDetails of (person ? person : [])">
            <h6>{{personalDetails.firstName}} {{personalDetails.lastName}}</h6>
        </li>

        <li [ngbNavItem]="1" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.MARKETER_BASIC_PRIVILEGE, userPrivileges.TENANT_PRIVILEGE,userPrivileges.TENANT_BASIC_PRIVILEGE]">
            <a ngbNavLink>Sales</a>
            <ng-template ngbNavContent>
                <div class="mx-4 mb-4">
                    <app-mysales [sales]="salesrepId" [id]="team"></app-mysales>
                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="2" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.MARKETER_BASIC_PRIVILEGE, userPrivileges.TENANT_PRIVILEGE,userPrivileges.TENANT_BASIC_PRIVILEGE]">
            <a ngbNavLink>Commission</a>
            <ng-template ngbNavContent>
                <div class="mx-4 mb-4">
                    <app-mycommissions [sales]="salesrepId" [id]="team"></app-mycommissions>
                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="3">
            <a ngbNavLink>Payments</a>
            <ng-template ngbNavContent>
                <app-mypayments [sales]="salesrepId" [id]="team"></app-mypayments>
            </ng-template>
        </li>
        <li [ngbNavItem]="4" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.MARKETER_BASIC_PRIVILEGE, userPrivileges.TENANT_PRIVILEGE,userPrivileges.TENANT_BASIC_PRIVILEGE]">
            <a ngbNavLink>Advances</a>
            <ng-template ngbNavContent>
                <div class="mx-4 mb-4">
                    <app-advances [sales]="salesrepId" [id]="team"></app-advances>
                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="5" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.MARKETER_BASIC_PRIVILEGE, userPrivileges.SALESREP_PRIVILEGE]">
            <a ngbNavLink>Team</a>
            <ng-template ngbNavContent>
                <app-myteam [sales]="salesrepId" [id]="team"></app-myteam>
            </ng-template>
        </li>
        <!-- <li [ngbNavItem]="7" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE]">
            <ng-container *ngIf="team != null">
                <a ngbNavLink>Commission Structure</a>
                <ng-template ngbNavContent>
                    <app-commissionstructure [salesrepId]="salesrepId"></app-commissionstructure>
                </ng-template>
            </ng-container>
        </li> -->
        <li [ngbNavItem]="6">
            <a ngbNavLink>Profile</a>
            <ng-template ngbNavContent>
                <app-editprofile [sales]="salesrepId"></app-editprofile>
            </ng-template>
        </li>
        <li [ngbNavItem]="8" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.MARKETER_BASIC_PRIVILEGE]">
            <a ngbNavLink>Bank Details</a>
            <ng-template ngbNavContent>
                <app-addbankdetails [sales]="salesrepId"></app-addbankdetails>
            </ng-template>
        </li>
    </ul>
    <a class="position-absolute close-popup-link bg-white" (click)="sendBack()"><i class="fa fa-times" aria-hidden="true"></i>
  </a>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
<router-outlet></router-outlet>