<div class=" my-3">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <h6 class="card-title text-dark position-relative pb-2 h6">{{ventureName}}</h6>
                </div>
                <div [hidden]="submit" (click)="onSubmit()" class="col-md-6 text-right" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE]">
                    <button class="btn btn-sm btn-info mb-2">Attach SalesRep</button>
                </div>
            </div>
            <div [hidden]="submit" class="wrap">
                <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Contact Number</th>
                            <!-- <th>Email</th> -->
                            <th>Lead</th>
                            <th>Designation</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let member of ventureTeam.teams">
                            <td class="pointer" (click)="onDisplay(member)">{{member.name}}</td>
                            <td>{{member.contactNumber}}</td>
                            <!-- <td>{{member.email}}</td> -->
                            <td>{{member.leadName?member.leadName: ''}}</td>
                            <td>{{member.salesrepDesignation}}
                                <span *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE]" class="text-primary float-right" [ngClass]="'pointer'" (click)="showChangeSalesrepDesignation($event, member.salesrepId,member.name,member.salesrepDesignation)">Change Designation <i class="fa fa-external-link"></i></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div [hidden]="!submit">
                <a class="position-absolute close-popup-link bg-white" (click)="onShow()"><i class="fa fa-times"
                        aria-hidden="true"></i>
                </a>
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <h6 class="card-title text-dark position-relative pb-2 h6">Attach SalesRep</h6>
                            </div>
                            <div class="col-md-6 text-right">
                            </div>
                        </div>

                        <div class="container-fluid my-3 text-dark">
                            <form #details="ngForm">
                                <div id="ventureList" class="content">
                                    <div class="row">
                                        <div class="ml-3 ">
                                            <label>Select SalesRep</label>
                                            <ng-autocomplete [data]="salesRep" (selected)="getSalesrepAllowedDesignationsList($event)" name="salesrepName" [focusFirst]=true [(ngModel)]="salesRepData" [searchKeyword]="keyword" [itemTemplate]="salesRepTemplate" [notFoundTemplate]="notFoundTemplate">
                                            </ng-autocomplete>
                                            <ng-template #salesRepTemplate let-item>
                                                <div class="item">
                                                    <span>{{ item.name }}</span>
                                                </div>
                                            </ng-template>
                                            <ng-template #notFoundTemplate let-notFound>
                                                <div>notFound</div>
                                            </ng-template>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label >Designation</label>
                                            <select class="form-control" name="selectedDesignation" [(ngModel)]="selectedDesignation" >
                                                <option *ngFor="let designation of allowedDesignationsList" [value]="designation">{{designation}}</option>
                                              </select>
                                          </div>
                                        <div class="col-md-12">
                                            <button type="submit" class="btn btn-info" [disabled]=details.invalid
                                                (click)="saveVentureTeam(details.value)">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #changeDesignation let-modal>
        <div class="modal-header">
            <h6 class="card-title text-dark position-relative pb-2 ">Change Designation</h6>
            <button type="button"  class="position-absolute close-popup-link bg-white change-lead"  (click)="modal.dismiss('Cross click')"><i class="fa fa-times"
                aria-hidden="true"></i></button>
        </div>
        <div class="modal-body">
            <form>
                <div class="container-fluid">
                    <div class="row mt-2">
                    <div class="form-group col-md-6">
                        <label for="salesrepName">Salesrep</label>
                        <input type="text" class="form-control" id="salesrepName" name="salesrepName" readonly [(ngModel)]="updatedSalesrepDesignationObject.salesrepName">
                      </div>
                      <div class="form-group col-md-6">
                        <label >Designation</label>
                        <select class="form-control" name="updatedSalesrepDesignation" [(ngModel)]="updatedSalesrepDesignation" >
                            <option value=''>Select</option>
                            <option *ngFor="let designation of allowedDesignationsList" [value]="designation">{{designation}}</option>
                          </select>
                      </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-info" (click)="modal.close('Save click');changeSalesrepDesignation()" >Update</button>
        </div>
    </ng-template>

</div>