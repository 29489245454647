<div class=" mt-3 position-relative right-tabs clearfix mb-0 card">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="justify-content-end pr-3">
    <li class="mr-auto pt-3 px-4 mb-0">
      <h6>Promos</h6>
    </li>
    <li [ngbNavItem]="1">
      <a ngbNavLink>Active Promos</a>
      <ng-template ngbNavContent>
        <div class="mx-4 mb-4">
          <app-promo-details></app-promo-details>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="4">
      <a ngbNavLink>Closed Promos</a>
      <ng-template ngbNavContent>
        <div class="mx-4 mb-4">
          <app-promo-details [closed]="closed"></app-promo-details>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>