
export class AddMarketer {
        tenantId: Number;
        ventureId: Number;
        displayName: string;
        marketerType: string;
        managingDirector: Person;
        primaryAddress: any;
        secondaryAddress: any;
        primaryCompany: Company;
        constructor() {
            this.tenantId = null;
            this.ventureId = null;
            this.displayName = '';
            this.marketerType = '';
            this.managingDirector = null;
            this.primaryAddress = {};
            this.secondaryAddress = {};
            this.primaryCompany = null;
        }
}

export interface Person {
         firstName: String,
         lastName: String,
         dob: Date,
         gender: String,
         bloodGroup: String,
         nationality: String,
         panNum: String,
         aadharNum: String,
         contactNumber: Number,
         alternateContact: Number,
         email: String,
         alternateEmail: String,
         facebookId: String,
         pictureLink: String,
         themeOption: String,
         comments: String,
}
export interface Company {
    tenantId: number,
    companyCategory: String,
	companyName: String,
	companyType: String,
	registrationNumber: String,
	tanNumber: String,
	gstNumber: String,
	contactNumber: Number,
	alternateContact: Number,
	email: String,
	alternateEmail: String,
	description: String
}
export interface Address {
    permAddress: {
        //tenantId: Number,
        sequenceNum: Number,
        addressCatogery: String,
        addressLine1: String,
        addressLine2: String,
        landmark: String,
        city: String,
        mandal: String,
        district: String,
        state: String,
        pin: number,
        country: String,
    }
    commAddress: {
       // tenantId: Number,
        sequenceNum: Number,
        addressCatogery: String,
        addressLine1: String,
        addressLine2: String,
        landmark: String,
        city: String,
        mandal: String,
        district: String,
        state: String,
        pin: number,
        country: String,
    }

}
