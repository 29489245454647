<div class=" mb-2" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.TENANT_PRIVILEGE,userPrivileges.TENANT_BASIC_PRIVILEGE,userPrivileges.MARKETER_BASIC_PRIVILEGE]">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div (click)="onClick()" class="col-md-12 justify-item-end  text-right pb-2" *ngxPermissionsOnly="[userPrivileges.TENANT_PRIVILEGE]">
                    <button class="btn btn-sm btn-info">Add Plot Pricing</button>
                </div>
            </div>

            <div class="row p-2 mx-0 mb-2 ">
                <form class="form-inline">
                    <div class="form-check px-3">
                        <label class="form-check-label">
                            <input type="radio" class="form-check-input" id="radio1" name="optradio" [checked]="true" (click)="getPlotPricing()"> Active
                        </label>
                    </div>
                    <div class="form-check px-3">
                        <label class="form-check-label">
                            <input type="radio" class="form-check-input" (click)="getPlotPricingHistory()" id="radio5" name="optradio"> History
                        </label>
                    </div>
                </form>
            </div>

            <div *ngIf="!showHistory" class="pb-2 wrap">
                <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Effective Date</th>
                            <th>Max Price/{{commonService.getMeasure()}} (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                            <th>Min Price/{{commonService.getMeasure()}} (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                            <th>Max Discount (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                            <th>Price For Marketer (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let price of plotPricing" (click)="onEdit(price)">
                            <td>{{price.plotType | titlecase}}</td>
                            <td>{{price.effectiveDate | date}}</td>
                            <td>{{price.maxSellingPrice.toLocaleString('en-IN')}}</td>
                            <td>{{price.minSellingPrice.toLocaleString('en-IN')}}</td>
                            <td>{{price.maxDiscountAllowed.toLocaleString('en-IN')}}</td>
                            <td>{{price.priceForMarketer.toLocaleString('en-IN')}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div *ngIf="showHistory" class="pb-2 wrap">
                <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Effective Date</th>
                            <th>Effective End Date</th>
                            <th>Max Price/{{commonService.getMeasure()}} (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                            <th>Min Price/{{commonService.getMeasure()}} (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                            <th>Max Discount (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                            <th>Price For Marketer (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let price of plotPricingHistory" >
                            <td>{{price.plotType | titlecase}}</td>
                            <td>{{price.effectiveDate | date}}</td>
                            <td>{{price.effectiveEnddate | date}}</td>
                            <td>{{price.maxSellingPrice.toLocaleString('en-IN')}}</td>
                            <td>{{price.minSellingPrice.toLocaleString('en-IN')}}</td>
                            <td>{{price.maxDiscountAllowed.toLocaleString('en-IN')}}</td>
                            <td>{{price.priceForMarketer.toLocaleString('en-IN')}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</div>