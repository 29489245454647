import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataTablesModule } from 'angular-datatables';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartModule } from 'angular-highcharts';
import { ChartsModule } from 'ng2-charts';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AccesscontrolComponent } from './components/accesscontrol/accesscontrol.component';
import { AttachSalesrepComponent } from './components/attach-salesrep/attach-salesrep.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ChangepasswordComponent } from './components/changepassword/changepassword.component';
import { ComboBoxComponent } from './components/combo-box/combo-box.component';
import { DirectCommissionComponent } from './components/direct-commission/direct-commission.component';
import { EditprofileComponent } from './components/editprofile/editprofile.component';
import { ErrorAlertComponent } from './components/error-alert/error-alert.component';
import { ExistingCustomerComponent } from './components/existing-customer/existing-customer.component';
import { IndirectCommissionComponent } from './components/indirect-commission/indirect-commission.component';
import { MenuComponent } from './components/menu/menu.component';
import { NewCustomerComponent } from './components/new-customer/new-customer.component';
import { NewprofileComponent } from './components/newprofile/newprofile.component';
import { PathStatusComponent } from './components/path-status/path-status.component';
import { PayNowComponent } from './components/pay-now/pay-now.component';
import { ReportDetailsComponent } from './components/report-details/report-details.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { SearchComponent } from './components/search/search.component';
import { SidebarAdminComponent } from './components/sidebar-admin/sidebar-admin.component';
import { SidebarMarketerComponent } from './components/sidebar-marketer/sidebar-marketer.component';
import { SidebarSalesrepComponent } from './components/sidebar-salesrep/sidebar-salesrep.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { VentureGraphComponent } from './components/venture-graph/venture-graph.component';
import { VentureListComponent } from './components/venture-list/venture-list.component';
import { VentureMenuComponent } from './components/venture-menu/venture-menu.component';
import { CredentialsInterceptor } from './interceptors/credentials.interceptor';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { MaindashboardComponent } from './layouts/admin-layout/maindashboard/maindashboard.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { SalesrepLayoutComponent } from './layouts/salesrep-layout/salesrep-layout.component';
import { VenturelayoutComponent } from './layouts/venturelayout/venturelayout.component';
import { CustomerDetailsComponent } from './pages/customer-details/customer-details.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { AddbankdetailsComponent } from './pages/forms/addbankdetails/addbankdetails.component';
import { AddcommissionpaymentsComponent } from './pages/forms/addcommissionpayments/addcommissionpayments.component';
import { AddcommissionstructureComponent } from './pages/forms/addcommissionstructure/addcommissionstructure.component';
import { AddingmarketerventureComponent } from './pages/forms/addingmarketerventure/addingmarketerventure.component';
import { AddplotComponent } from './pages/forms/addplot/addplot.component';
import { AddplotpricingComponent } from './pages/forms/addplotpricing/addplotpricing.component';
import { AddtenantComponent } from './pages/forms/addtenant/addtenant.component';
import { AdduserComponent } from './pages/forms/adduser/adduser.component';
import { AddventureComponent } from './pages/forms/addventure/addventure.component';
import { LoginComponent } from './pages/login/login.component';
import { AddmarketerComponent } from './pages/marketer/addmarketer/addmarketer.component';
import { CommissionPaymentsComponent } from './pages/marketer/commissions/commission-payments/commission-payments.component';
import { CommissiondetailsComponent } from './pages/marketer/commissions/commissiondetails/commissiondetails.component';
import { CommissionsComponent } from './pages/marketer/commissions/commissions/commissions.component';
import { CommissionstructureComponent } from './pages/marketer/commissions/commissionstructure/commissionstructure.component';
import { CommissionsummaryComponent } from './pages/marketer/commissions/commissionsummary/commissionsummary.component';
import { MycommissionsComponent } from './pages/marketer/commissions/mycommissions/mycommissions.component';
import { EditmarketerComponent } from './pages/marketer/editmarketer/editmarketer.component';
import { MarketerLayoutComponent } from './pages/marketer/marketer-layout/marketer-layout.component';
import { MarketerListComponent } from './pages/marketer/marketer-list/marketer-list.component';
import { MarketerSaleComponent } from './pages/marketer/marketer-sale/marketer-sale.component';
import { MarketerchartComponent } from './pages/marketer/marketerchart/marketerchart.component';
import { MarketerdashboardComponent } from './pages/marketer/marketerdashboard/marketerdashboard.component';
import { MarketerteamComponent } from './pages/marketer/marketerteam/marketerteam.component';
import { MarketerventureComponent } from './pages/marketer/marketerventure/marketerventure.component';
import { NoPermissionMarketerComponent } from './pages/marketer/no-permission-marketer/no-permission-marketer.component';
import { AddnewsaleComponent } from './pages/marketer/sales/addnewsale/addnewsale.component';
import { EditSaleComponent } from './pages/marketer/sales/editSale/editSale.component';
import { MyprofileComponent } from './pages/marketer/sales/myprofile/myprofile.component';
import { MysalesComponent } from './pages/marketer/sales/mysales/mysales.component';
import { MyteamsalesComponent } from './pages/marketer/sales/myteamsales/myteamsales.component';
import { NewSaleComponent } from './pages/marketer/sales/new-sale/new-sale.component';
import { SaleschallengesComponent } from './pages/marketer/sales/saleschallenges/saleschallenges.component';
import { SalesdashboardComponent } from './pages/marketer/sales/salesdashboard/salesdashboard.component';
import { SalesSummaryComponent } from './pages/marketer/sales/salesSummary/salesSummary.component';
import { TeamsComponent } from './pages/marketer/teams/teams.component';
import { UpdatemarketerComponent } from './pages/marketer/updatemarketer/updatemarketer.component';
import { MypaymentsComponent } from './pages/mypayments/mypayments.component';
import { MyteamComponent } from './pages/myteam/myteam.component';
import { MyteamdetailsComponent } from './pages/myteamdetails/myteamdetails.component';
import { NoPermissionsComponent } from './pages/no-permissions/no-permissions.component';
import { PaymentsComponent } from './pages/payments/payments.component';
import { PlotsComponent } from './pages/plots/plot/plots.component';
import { PlotbyidComponent } from './pages/plots/plotbyid/plotbyid.component';
import { PlotdetailsComponent } from './pages/plots/plotdetails/plotdetails.component';
import { PlotsSalesrepComponent } from './pages/plots/plots-salesrep/plots-salesrep.component';
import { PlotsaleComponent } from './pages/plots/plotsale/plotsale.component';
import { PlotspricingComponent } from './pages/plots/plotspricing/plotspricing.component';
import { PlotstatusComponent } from './pages/plots/plotstatus/plotstatus.component';
import { PlotsummaryComponent } from './pages/plots/plotsummary/plotsummary.component';
import { PromotionsComponent } from './pages/promotions/promotions.component';
import { ReceivablesComponent } from './pages/receivables/receivables.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { SalesbymonthComponent } from './pages/sales/salesbymonth/salesbymonth.component';
import { SalesbysalesrepComponent } from './pages/sales/salesbysalesrep/salesbysalesrep.component';
import { SalesrepCommissionComponent } from './pages/sales/salesrep-commission/salesrep-commission.component';
import { SalesrepplotsComponent } from './pages/sales/salesrepplots/salesrepplots.component';
import { SalesummaryComponent } from './pages/sales/salesummary/salesummary.component';
import { NoPermissionTenantComponent } from './pages/tenant/no-permission-tenant/no-permission-tenant.component';
import { TenantComponent } from './pages/tenant/tenant/tenant.component';
import { UsersComponent } from './pages/users/users.component';
import { VentureComponent } from './pages/ventures/venture/venture.component';
import { VenturedashboardComponent } from './pages/ventures/venturedashboard/venturedashboard.component';
import { VenturesaledetailsComponent } from './pages/ventures/venturesaledetails/venturesaledetails.component';
import { VenturesalesComponent } from './pages/ventures/venturesales/venturesales.component';
import { VentureteamComponent } from './pages/ventures/ventureteam/ventureteam.component';
import { configFactory, AuthServiceService } from './services/auth-service.service';
import { HighchartsService } from './services/highcharts.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AdvancesComponent } from './pages/marketer/commissions/advance/advances.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { PerformanceComponent } from './pages/performance/performance.component';
import { SalesAnalyticsComponent } from './pages/sales-analytics/sales-analytics.component';
import { SalesrepSalesAnalyticsComponent } from './pages/salesrep-sales-analytics/salesrep-sales-analytics.component';
import { SalesrepCommissionsAnalyticsComponent } from './pages/salesrep-commissions-analytics/salesrep-commissions-analytics.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PromosComponent } from './pages/promos/promos.component';
import { PromoDetailsComponent } from './pages/promo-details/promo-details.component';
import { AddpromoComponent } from './pages/forms/addpromo/addpromo.component';
import { LoaderComponent } from './loader/loader.component';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { VentureLayoutDetailsComponent } from './pages/venture-layout-details/venture-layout-details.component';
import { AllSalesrepAdvancesComponent } from './pages/marketer/commissions/all-salesrep-advances/all-salesrep-advances.component';
import { DatePipe } from '@angular/common';
import { PlotBlockingComponent } from './pages/plots/plot-blocking/plot-blocking.component';
import { CBlockedPlotsComponent } from './pages/plots/plots-by-status/c-blocked-plots/c-blocked-plots.component';
import { LandlordPlotsComponent } from './pages/plots/plots-by-status/landlord-plots/landlord-plots.component';
import { MSoldPlotsComponent } from './pages/plots/plots-by-status/m-sold-plots/m-sold-plots.component';
import { MortgagePlotsComponent } from './pages/plots/plots-by-status/mortgage-plots/mortgage-plots.component';
import { BlockedPlotsComponent } from './pages/plots/plots-by-status/blocked-plots/blocked-plots.component';
import { MortgageMarkingComponent } from './pages/forms/mortgage-marking/mortgage-marking.component';
import { CompanyBlockedMarkingComponent } from './pages/forms/company-blocked-marking/company-blocked-marking.component';
import { LandlordMarkingComponent } from './pages/forms/landlord-marking/landlord-marking.component';

import { LayoutDesignComponent } from './layout-design/layout-design.component';
@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    LoginComponent,
    AdminLayoutComponent,
    PathStatusComponent,
    MaindashboardComponent,
    SearchComponent,
    BreadcrumbComponent,
    SidebarSalesrepComponent,
    MarketerLayoutComponent,
    ErrorAlertComponent,
    MenuComponent,
    SpinnerComponent,
    PlotsComponent,
    VentureComponent,
    SalesSummaryComponent,
    MarketerListComponent,
    NewSaleComponent,
    ReportsComponent,
    SalesrepLayoutComponent,
    SalesdashboardComponent,
    MyprofileComponent,
    MyteamComponent,
    MysalesComponent,
    MyteamsalesComponent,
    MycommissionsComponent,
    AdvancesComponent,
    MypaymentsComponent,
    SidebarMarketerComponent,
    DirectCommissionComponent,
    IndirectCommissionComponent,
    MarketerdashboardComponent,
    VenturedashboardComponent,
    VentureListComponent,
    PaymentsComponent,
    VentureteamComponent,
    PlotspricingComponent,
    CommissionstructureComponent,
    ReceivablesComponent,
    PromotionsComponent,
    SaleschallengesComponent,
    VentureMenuComponent,
    VenturelayoutComponent,
    VenturesalesComponent,
    CommissionsComponent,
    CustomersComponent,
    VentureGraphComponent,
    AddnewsaleComponent,
    ComboBoxComponent,
    ExistingCustomerComponent,
    PayNowComponent,
    NewCustomerComponent,
    AttachSalesrepComponent,
    TeamsComponent,
    MyteamdetailsComponent,
    PlotdetailsComponent,
    CommissiondetailsComponent,
    EditSaleComponent,
    CustomerDetailsComponent,
    PlotsaleComponent,
    SalesrepCommissionComponent,
    SalesrepplotsComponent,
    NewprofileComponent,
    MarketerchartComponent,
    MarketerSaleComponent,
    ReportDetailsComponent,
    AddventureComponent,
    AddplotComponent,
    AddplotpricingComponent,
    AddmarketerComponent,
    PlotbyidComponent,
    UpdatemarketerComponent,
    VenturesaledetailsComponent,
    MarketerteamComponent,
    AddcommissionstructureComponent,
    CommissionPaymentsComponent,
    AddingmarketerventureComponent,
    EditmarketerComponent,
    MarketerventureComponent,
    EditprofileComponent,
    SalesbysalesrepComponent,
    SalesbymonthComponent,
    AddcommissionpaymentsComponent,
    AddbankdetailsComponent,
    PlotstatusComponent,
    ChangepasswordComponent,
    AdduserComponent,
    UsersComponent,
    ResetpasswordComponent,
    MainLayoutComponent,
    SidebarAdminComponent,
    TenantComponent,
    AddtenantComponent,
    PlotsummaryComponent,
    SalesummaryComponent,
    CommissionsummaryComponent,
    AccesscontrolComponent,
    PlotsSalesrepComponent,
    NoPermissionsComponent,
    NoPermissionTenantComponent,
    NoPermissionMarketerComponent,
    AnalyticsComponent,
    PerformanceComponent,
    SalesAnalyticsComponent,
    SalesrepSalesAnalyticsComponent,
    SalesrepCommissionsAnalyticsComponent,
    ResetPasswordComponent,
    PromosComponent,
    PromoDetailsComponent,
    AddpromoComponent,
    LoaderComponent,
    VentureLayoutDetailsComponent,
    AllSalesrepAdvancesComponent,
    LayoutDesignComponent,
    PlotBlockingComponent,
    CBlockedPlotsComponent,
    LandlordPlotsComponent,
    MSoldPlotsComponent,
    MortgagePlotsComponent,
    BlockedPlotsComponent,
    MortgageMarkingComponent,
    CompanyBlockedMarkingComponent,
    LandlordMarkingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    ChartModule,
    DataTablesModule.forRoot(),
    AutocompleteLibModule,
    NgxPermissionsModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    HttpClientModule,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CredentialsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
   },
    HighchartsService,
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [AuthServiceService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
