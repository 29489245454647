<div class="my-2" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.TENANT_PRIVILEGE,userPrivileges.TENANT_BASIC_PRIVILEGE,userPrivileges.MARKETER_BASIC_PRIVILEGE]">
    <div class="card">
        <div class="card-body table-responsive">
            <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Advance (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                        <th>Total Passive Advance (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let salesrepAdvance of salesrepAdvancesList">
                        <td (click)="checkSalesrepAdvance(salesrepAdvance)" style="cursor: pointer;">{{salesrepAdvance.salesrepName}}</td>
                        <td>{{salesrepAdvance.advanceAmount.toLocaleString('en-IN')}}</td>
                        <td>{{salesrepAdvance.totalPassiveAdvanceAmount.toLocaleString('en-IN')}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>