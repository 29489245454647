import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import * as pluginDataLabels from 'ng2-charts';
import { ActivatedRoute } from '@angular/router';
import { RestService } from '../../services/rest.service';
import{Location} from '@angular/common'
import { PlotsaleComponent } from '../../pages/plots/plotsale/plotsale.component';
import { ReportTypes } from '../../constants/AppConstants';


@Component({
  selector: 'app-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.scss']
})
export class ReportDetailsComponent implements OnInit {

  [x: string]: any;
  Performance: any = [];
  reportsData: any = [];
  saleData: any = [];
  report: any;
  totalsale: any= [];
  result: any =[];
  mmode;
  reportTypes = ReportTypes;

  constructor(
    private restService: RestService,
    private route: ActivatedRoute,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      let id = params['reporttype'];
      this.report = id;
    });
    this.restService.getReportDetails().subscribe(data => {
      const response = data
      this.totalsale = response.totalSale
      /** this.totalsale.map(a => 
         {
           this.result.push(a.plots)
         })
       this.barChartData = [{
       data: [this.result], label: 'plots'
        } ]**/
      this.barChartData = [
        { data: this.totalsale.map(a => a.plots), label: 'plots' },
      ];
      this.barChartLabels =
        this.totalsale.map(a => a.label);

    });
    

  }

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{
      ticks: {
        min: 0,
        max: 300
      }
    }] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };

  public barChartLabels: string[] =[];
  //= ['Total','Sold', 'Booked', 'Registered'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];
  public barChartColors: Color[] = [
    { 
      backgroundColor: ['rgba(70, 86, 197, 0.8)', 'rgba(70, 86, 197, 0.8)', 'rgba(70, 86, 197, 0.8)', 'rgba(70, 86, 197, 0.8)']
    }
]

  public barChartData : any[] = []
 /** = [
    { data: [200, 50, 68, 82, 0, 300], label: 'plots' },

  ]; */
  /**public pieChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    legend: {
      position: 'right',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  public pieChartLabels: Label[] = ['Sold', 'Booked', 'Registered'];
  public pieChartLegend = true;
  public pieChartType: ChartType = 'pie';
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
    },
  ];
  public pieChartData : number[] 
  = [
   50, 68, 82

  ];**/

  public vSalebarChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{
    }] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public vSalebarChartLabels: Label[] = [' Schon', ' Vasavi Sandal County ', ' Sweet Home', ' villas 1'];
  public vSalebarChartType: ChartType = 'bar';
  public vSalebarChartLegend = true;
  public vSalebarChartPlugins = [pluginDataLabels];
  public vSalebarChartColors: Color[] = [
    { 
      backgroundColor: ['rgba(70, 86, 197, 0.8)', 'rgba(70, 86, 197, 0.8)', 'rgba(70, 86, 197, 0.8)', 'rgba(70, 86, 197, 0.8)']
    }
]

  public vSalebarChartData : ChartDataSets[] 
  = [
    { data: [38, 50, 68, 44, 0, 100], label: 'sold' },

  ];
  sendBack(){
    this.location.back()
  }

}

