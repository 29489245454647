 <!-- <div>
    <div style= "color: black;" *ngIf="barChartData">
        <canvas #mycanvas baseChart height="100"
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [legend]="barChartLegend"
        [chartType]="barChartType"
        [plugins]="barChartPlugins"
        ></canvas>
    </div>
  </div>  -->
  <!-- <div #charts *ngIf = "myOptions.series"></div> -->
  <div id="container"></div>

 