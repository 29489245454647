import { Component, OnInit } from '@angular/core';
import { UserPrivileges } from 'src/app/constants/AppConstants';
import { UserKeys } from 'src/app/models/user.model';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-venture-layout-details',
  templateUrl: './venture-layout-details.component.html',
  styleUrls: ['./venture-layout-details.component.scss']
})
export class VentureLayoutDetailsComponent implements OnInit {

  showUploadVenture = false;
  userPrivileges = UserPrivileges;
  layoutFile: File;
  userInfo: any;
  ventureName: any;
  layoutUrl: any;

  constructor(
    private restService: RestService,
    private authService: AuthServiceService,
    private commonService: CommonServiceService
  ) { }

  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    this.getFIlesUrls();
  }

  onLayoutFileSelected(event: any) {
    this.layoutFile = event.target.files[0];
  }
  
  uploadLayoutFile(){
    if (this.layoutFile) {
      const formData = new FormData();
      formData.append('file', this.layoutFile);
      formData.append('tenantId',this.userInfo.tenantId);
      formData.append('ventureId',this.commonService.getVenture()?.ventureId);
      if (this.layoutFile && this.layoutFile.type.startsWith('image/')) {      
      this.restService.uploadLayoutFile(formData).subscribe(response => {
        if (response) {
          this.layoutFile = null;
          this.ngOnInit();
          window.location.reload();
        }
      });
      } else {
        alert('Invalid file type. Please select an image file.');
      }
    } else {
      console.log('No file selected');
    }
  }

  getFIlesUrls(){
    let url1;
    url1 = '/generic/ventureLayout?' +'ventureId='+this.commonService.getVenture()?.ventureId+'&tenantId='+this.userInfo.tenantId;
    this.restService.getFilesUrls(url1).subscribe(response => {
      if (response != null && response != undefined) {
        console.log(response.data);
        if( response.data === null){
          this.showUploadVenture=true;
        }
        this.layoutUrl = response.data;   
      }
    });
  }

}
