<div class="wrap">
    <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
        <thead>
            <tr>
                <th>Venture Name</th>
                <th>Plot Number</th>
                <th>Plot Status</th>
                <th>Total Commission(<i class="fas fa-rupee-sign"></i>)</th>
                <th>Commission Earned(<i class="fas fa-rupee-sign"></i>)</th>
                <th *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE, userPrivileges.TENANT_PRIVILEGE] ">Commission Paid(
                    <i class="fas fa-rupee-sign"></i>)</th>
                <th *ngxPermissionsOnly="[userPrivileges.SALESREP_PRIVILEGE]">Commission Received(<i class="fas fa-rupee-sign"></i>)</th>
                <th>Commission Pending(<i class="fas fa-rupee-sign"></i>)</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let commission of directComm">
                <td>{{commission.ventureName}}</td>
                <td (click)="display(commission)">{{commission.plotNumber}}</td>
                <td>{{commission.plotStatus}}</td>
                <td>{{commission.totalCommission?.toLocaleString('en-IN')}}</td>
                <td>{{commission.commissionEarned?.toLocaleString('en-IN')}}</td>
                <td>{{commission.commissionPaid?.toLocaleString('en-IN')}}</td>
                <td>{{commission.commissionPending?.toLocaleString('en-IN')}}</td>
            </tr>
        </tbody>
    </table>
</div>