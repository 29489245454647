import { AfterContentInit, Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { UserPrivileges, UserRoleConstants } from 'src/app/constants/AppConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-indirect-commission',
  templateUrl: './indirect-commission.component.html',
  styleUrls: ['./indirect-commission.component.scss']
})
export class IndirectCommissionComponent implements OnInit {
  @Input() id;
  @Input() sales;
  indirectComm: any = [];
  commissionOnPlots: any = [];
  value;
  error: boolean;
  errorMessage: any;
  show = true;
  number: any;
  expandContent = true;
  expanded = false;
  userInfo: any;
  data: any;
  userPrivileges = UserPrivileges;

  constructor(private restService: RestService,
    private authService: AuthServiceService,
    private commonService: CommonServiceService) { }
  title = 'angulardatatables';
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  isDtInitialized: boolean = false

  ngOnInit() {
    this.userInfo = this.authService.getUserInfo();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      bSort: false,
      searching: false
    };
    if (!this.sales) {
      this.onclick();
    }
    else {
      this.oncheck();
    }
    if (this.expanded) {
      this.display(this.sales);
    }
  }
  onclick() {
    let indirectUrl = 'generic/salesrep/commissions?salesrepId=' + this.userInfo.salesrepId + '&type=indirect';
    this.restService.getSales(indirectUrl).subscribe(response => {
      if (response.data != null && response.data != undefined) {
        this.indirectComm = response.data;
        this.dtTrigger.next();
      }
    },
    (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });
  }

  oncheck() {
    let indirectUrl;
    if (this.id) {
      indirectUrl = 'generic/salesrep/commissions?salesrepId=' + this.sales + '&ventureId=' + this.commonService.getVenture()?.ventureId + '&type=indirect';
    } else {
      indirectUrl = 'generic/salesrep/commissions?salesrepId=' + this.sales + '&type=indirect';
    }

    this.restService.getSales(indirectUrl).subscribe(response => {
      if (response.data != null && response.data != undefined) {
        this.indirectComm = response.data;
        this.dtTrigger.next();
      }
    },
    (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });
  }

  display(id) {
    let data = {
      "tenantId": this.userInfo.tenantId,
      "marketerId": this.userInfo.marketerId,
      "salesrepId": this.userInfo.salesrepId
    }
    this.restService.getinDirectCommissionPlots(data).subscribe(response => {
      if (data != null) {
        this.commissionOnPlots = response.data
      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      })
    this.number = id;
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

}