<div class="my-3"
    *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.TENANT_PRIVILEGE]">
    <div class="card">
        <div class="card-body">
            <div [hidden]="submit" class="row">
                <div class="col-md-6 mb-0">
                    <h6 class="card-title text-dark position-relative pb-2 h6">Users</h6>
                </div>
                <div (click)="addUser()" class="col-md-6 text-right mb-0"
                    *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.TENANT_PRIVILEGE,userPrivileges.USERS_ADD]">
                    <button class="btn btn-sm btn-info">Add</button>
                </div>
            </div>
            <div [hidden]="submit" class="wrap">
                <table class="table table-striped table-bordered table-sm row-border hover" datatable
                    [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th>User ID</th>
                            <th>Name</th>
                            <th>Contact Number</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let user of userDetails">
                            <td>{{user.person?.personId}}</td>
                            <td (click)="onDisplay(user)"><a class="user">{{user.person.firstName}}
                                    {{user.person.lastName}}</a></td>
                            <td>{{user.person.contactNumber}}</td>
                            <td>{{user.person.email}}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <div [hidden]="!submit">
                <a class="position-absolute close-popup-link bg-white" (click)="onShow()"><i class="fa fa-times"
                        aria-hidden="true"></i>
                </a>
                <div class="row">
                    <div class="col-md-6">
                        <h6 class="card-title text-dark position-relative pb-2 h6">New User</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>