<section class="my-3">
    <div class="row">
        <div class="col-lg-8 col-md-12" style="font-size: 13px;">
            <!-- <div class="row">
                <div class="col-md-12 mb-3">
                  <div class="card shadow  h-100 mb-2">
                    <div class="card-body pb-5 px-3 pt-2">
                      <h6 class="card-title text-dark position-relative pb-2">Notifications</h6>
                    </div>
                  </div>
                </div>
              </div> -->
            <div class="row">
                <div class="col-md-12">
                    <div class="card shadow mb-3">
                        <div class="card-body">
                            <h6 class="card-title text-dark position-relative pb-2 w-100">Plots</h6>
                            <div class="wrap">
                                <table class="table table-striped table-bordered table-sm row-border hover">
                                    <thead>
                                        <tr>
                                            <th>Venture</th>
                                            <th>Registered</th>
                                            <th>Sold</th>
                                            <th>Blocked</th>
                                            <th>Available</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="pointer" *ngFor="let top of sales.ventureSales" (click)="plots(top)">
                                            <td>{{top.ventureName}}</td>
                                            <td>{{top.registered ? top.registered: 0}}</td>
                                            <td>{{top.sold ? top.sold: 0}}</td>
                                            <td>{{top.blocked ? top.blocked: 0}}</td>
                                            <td>{{top.available}}</td>
                                            <td>{{top.total}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="card mb-3">
                        <div class="card-body">
                            <h6 class="card-title text-dark position-relative pb-2 w-100">Sales</h6>
                            <div class="wrap">
                                <table class="table table-striped table-bordered table-sm row-border hover">
                                    <thead>
                                        <tr>
                                            <th style="width: 25%;">Venture</th>
                                            <th>Plots Sold</th>
                                            <th>{{commonService.getMeasure()}} Sold</th>
                                            <th>Sale Value (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                                            <th>Received (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                                            <th>Pending (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="p-5 pointer" *ngFor="let top of sales.marketerSaleInfoList" (click)="sale(top)">
                                            <td class="pb-3">{{top.ventureName}}</td>
                                            <td>{{top.plotsSold}}</td>
                                            <td>{{top.yardsSold.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                                            <td>{{top.saleValue.toLocaleString('en-IN')}}</td>
                                            <td>{{top.amountReceived.toLocaleString('en-IN')}}</td>
                                            <td>{{top.amountPending.toLocaleString('en-IN')}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="card mb-3">
                        <div class="card-body">
                            <h6 class="card-title text-dark position-relative pb-2 w-100">Commissions</h6>
                            <div class="wrap">
                                <table class="table table-striped table-bordered table-sm row-border hover">
                                    <thead>
                                        <tr>
                                            <th>Venture</th>
                                            <th>Total (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                                            <th>Earned (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                                            <th>Paid (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                                            <th>Pending (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="pointer" *ngFor="let commission of sales.marketerCommissionList" (click)="commissions(commission)">
                                            <td>{{commission.ventureName}}</td>
                                            <td>{{commission.totalCommission.toLocaleString('en-IN')}}</td>
                                            <td>{{commission.commissionEarned.toLocaleString('en-IN')}}</td>
                                            <td>{{commission.commissionPaid.toLocaleString('en-IN')}}</td>
                                            <td>{{commission.commissionPending.toLocaleString('en-IN')}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="card shadow  mb-3">
                        <div class="card-body px-3 pb-4 pt-2">
                            <div class="row">
                                <div class="col-md-6">
                                    <h6 class="card-title text-dark position-relative pb-2 h6">All Time Top 5 Sales Rep</h6>
                                </div>
                            </div>
                            <div class="wrap">
                                <table class="table table-striped table-bordered table-sm row-border hover">
                                    <thead>
                                        <tr>
                                            <th>Sales rep</th>
                                            <th>Plots Sold</th>
                                            <th>{{commonService.getMeasure()}} Sold</th>
                                            <th>Revenue (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let top of sales.performancelist; let i= index">
                                            <ng-container *ngIf="i < 5 ">
                                                <td class="pointer" (click)="onDisply(top)">{{top.salesRep}}</td>
                                                <td>{{top.plotsSold}}</td>
                                                <td>{{top.yardsSold.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                                                <td>{{top.revenue.toLocaleString('en-IN')}}</td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-12">
            <div class="card shadow  mb-3">
                <div class="card-body px-3 pb-4 pt-2">
                    <h6 class="card-title text-dark position-relative pb-2 w-100">Ventures</h6>
                    <app-venture-list (ventureEvent)="setLaunchedVenture($event)"></app-venture-list>
                </div>
            </div>
            <div class="card   my-3 team">
                <div class="card-body px-3 pt-3">
                    <h6 class="card-title position-relative pb-2">Status: All Plots (Total Plots : {{sales?.marketerSale?.totalPlots}})</h6>
                    <app-marketerteam [team]="saleteam"></app-marketerteam>
                </div>
            </div>
            <div class="card shadow  mb-3">
                <div class="card-body px-3 pb-4 pt-2">
                    <h6 class="card-title text-dark position-relative pb-2 h6">Top 5 ({{sales?.lastMonth}})</h6>
                    <div class="wrap">
                        <table class="table table-striped table-bordered table-sm row-border hover">
                            <thead>
                                <tr>
                                    <th>Sales rep</th>
                                    <th>Plots Sold</th>
                                    <th>{{commonService.getMeasure()}} Sold</th>
                                    <th>Revenue (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let top of sales.bestPerformancelist; let i= index">
                                    <ng-container *ngIf="i < 5 ">
                                        <td class="pointer" (click)="onDisply(top)">{{top.salesRep}}</td>
                                        <td>{{top.plotsSold}}</td>
                                        <td>{{top.yardsSold.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                                        <td>{{top.revenue.toLocaleString('en-IN')}}</td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>