import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common'
import { CommonServiceService } from 'src/app/services/common-service.service';

@Component({
  selector: 'app-plotdetails',
  templateUrl: './plotdetails.component.html',
  styleUrls: ['./plotdetails.component.scss']
})
export class PlotdetailsComponent implements OnInit {
  active = 1;
  ventureId: any;
  ventureName: any;
  show: any = false;
  constructor(private route: ActivatedRoute,
    private commonService: CommonServiceService,
    private location: Location) {
    this.route.queryParams.subscribe(params => {
      this.ventureId = params['ventureId'];
    });
  }

  ngOnInit(): void {
    this.ventureName = this.commonService.getVenture()?.displayName
    if (this.ventureId) {
      this.show = true;
      this.active = 3;
    }
  }
  sendBack() {
    this.location.back();
  }
}
