<div [hidden]="submit" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE]">
    <ul class="list-unstyled my-4">
        <li *ngFor="let customer of newCustomer">
            <div class="row">
                <div class="col-4">Name:</div>
                <div class="col-8"><b>{{customer.firstName}} {{customer.lastName}}</b></div>
                <div class="col-4">DOB:</div>
                <div class="col-8"><b>{{customer.dob | date}}</b></div>
                <div class="col-4">Gender:</div>
                <div class="col-8"><b>{{customer.gender}}</b></div>
                <div class="col-4">Blood Group:</div>
                <div class="col-8"><b>{{customer.bloodGroup}}</b></div>
                <div class="col-4">Nationality:</div>
                <div class="col-8"><b>{{customer.nationality}}</b></div>
                <div class="col-4">PAN:</div>
                <div class="col-8"><b>{{customer.panNum}}</b></div>
                <div class="col-4">Aadhaar:</div>
                <div class="col-8"><b>{{customer.aadharNum}}</b></div>
                <div class="col-4">Mobile:</div>
                <div class="col-8"><b>{{customer.contactNumber}}</b></div>
                <div class="col-4">Alternate Contact:</div>
                <div class="col-8"><b>{{customer.alternateContact}}</b></div>
                <div class="col-4">Email:</div>
                <div class="col-8"><b>{{customer.email}}</b></div>
                <div class="col-4">Alternate Email:</div>
                <div class="col-8"><b>{{customer.alternateEmail}}</b></div>
            </div>
        </li>

    </ul>
</div>