import { Component, Input, OnChanges, OnInit } from '@angular/core';
import * as pluginDataLabels from 'ng2-charts';
import { ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'app-marketerteam',
  templateUrl: './marketerteam.component.html',
  styleUrls: ['./marketerteam.component.scss']
})
export class MarketerteamComponent implements OnInit, OnChanges {
  @Input() team;
  sales: any;
  saleteam: any;
  teamData: any = [];
  constructor() { }

	ngOnChanges() {
		if (this.team) {
			this.teamData = this.team.filter((a) => a.lable != 'totalPlots');
			this.teamData.map((a) => {
				this.pieChartLabels.push(a.lable.replace(/\b\w/g, (l) => l.toUpperCase()));
			});
			this.pieChartData = [this.teamData.map((a) => a.plots)];
		}
	}
	ngOnInit(): void {}

  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  public pieChartLabels: string[] = [];
  public pieChartData: number[];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
    },
  ];
}
