<div class="container-fluid my-2">
    <div class="card">
      <div class="card-body">
  
        <div class="row">
          <div class="col-md-6">
            <h6 class="card-title text-dark position-relative pb-2 ">Tenents</h6>
          </div>
          <div (click)="onCheck()" class="col-md-3 text-right">
            <button class="btn btn-sm btn-info mb-2">Add Tenants</button>
          </div>
        </div>
        <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
          <thead>
            <tr>
              <th>Tenant Type</th>
              <th>Display Name</th>
              <th>Director</th>
              <th>Email</th>
              <th>Contact Number</th>
              <th>Location</th>
  
            </tr>
          </thead>
          <tbody>
            <tr class="pointer" *ngFor="let marketer of tenantList">
  
              <td>{{marketer.tenantType}}</td>
              <td>{{marketer.displayName}}</td>
              <td>{{marketer.managingDirector?.firstName}}</td>
  
              <td>{{marketer.managingDirector?.email}}</td>
              <td>{{marketer.managingDirector?.contactNumber}}</td>
              <td>{{marketer.primaryAddress?.city}}</td>
  
            </tr>
          </tbody>
        </table>
      </div>
  
    </div>
  
  </div>