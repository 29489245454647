import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthServiceService } from '../../services/auth-service.service';
import{Location} from '@angular/common'
import { RestService } from '../../services/rest.service';
import { UserPrivileges } from '../../constants/AppConstants';
import { CommonServiceService } from '../../services/common-service.service';

@Component({
  selector: 'app-myteamdetails',
  templateUrl: './myteamdetails.component.html',
  styleUrls: ['./myteamdetails.component.scss']
})
export class MyteamdetailsComponent implements OnInit {

  salesrepId: any;
  active = 1;
  name: string;
  details: any = [];
  mode: any;
  myDetails: any = [];
  person: any = [];
  error: boolean;
  errorMessage: any;
  userPrivileges = UserPrivileges;
  team: any;

  constructor(
    private authservice: AuthServiceService,
    private restService: RestService,
    private location: Location,
    private route: ActivatedRoute,
    private commonService: CommonServiceService,
    private router: Router
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.route.params.subscribe(
        (params: Params) => {
          this.salesrepId = params['salesrepid'];
        }
      );
    });
    let activeTab = +(sessionStorage.getItem('activeTab'));
    if(activeTab) {
      this.active = activeTab;
      sessionStorage.removeItem('activeTab');
    }
  }


  ngOnInit() {
    this.getSalesRepInfo();
    // TODO need to get the VentureTeam info
        this.team  =this.commonService.getVentureTeam()?.salesrepId;
  }

  onCall(salesRepName) {
    this.details.push(salesRepName)
  }

  sendBack(): void {
    this.location.back()
  }

  getSalesRepInfo() {
    const userInfo = this.authservice.getUserInfo();
    let data = {
      "salesrepId": this.salesrepId,
      "tenantId": userInfo.tenantId,
      "marketerId": userInfo.marketerId
    }

    this.restService.getmyDetails(data).subscribe(response => {
      if (response.data != null) {
        this.myDetails = response.data;
        this.person.push(this.myDetails);
      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      });
  }
  
}


