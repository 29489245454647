<div class="container mt-4 card p-4">
    <a class="position-absolute close-popup-link bg-white" (click)="sendBack()"><i class="fa fa-times"
            aria-hidden="true"></i>
    </a>
    <div class="row">
        <div class="col-md-6 mb-0">
            <h6 class="card-title text-dark position-relative pb-2">Customer Details</h6>
        </div>
        <div class="col-md-6 text-right mb-0" *ngIf="!isEdit && isVCPage">
            <button  class="btn btn-sm btn-info" (click)="editCustomer()" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE, userPrivileges.UPDATE_CUSTOMER_DETAILS]">Edit Customer</button>
        </div>
    </div>
    <div class="row" *ngIf="!isEdit">
        <div class="col-md-12 mt-3 ">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <p class="m-b-10 f-w-600">First Name</p>
                            <h6 class="f-w-400">{{customer.firstName}}</h6>
                        </div>
                        <div class="col-md-4">
                            <p class="m-b-10 f-w-600">Last Name</p>
                            <h6 class="f-w-400">{{customer.lastName}}</h6>
                        </div>
                        <div class="col-md-4">
                            <p class="m-b-10 f-w-600">Father/Husband Name</p>
                            <h6 class="f-w-400">{{customer.fatherOrHusbandName}}</h6>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <p class="m-b-10 f-w-600">DOB</p>
                            <h6 class="f-w-400">{{customer.dob | date: 'dd/MM/yyyy'}}</h6>
                        </div>
                        <div class="col-md-4">
                            <p class="m-b-10 f-w-600">Gender</p>
                            <h6 class="f-w-400">{{customer.gender}}</h6>
                        </div>
                        <div class="col-md-4">
                            <p class="m-b-10 f-w-600">Blood Group</p>
                            <h6 class="f-w-400">{{customer.bloodGroup}}</h6>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <p class="m-b-10 f-w-600">Nationality</p>
                            <h6 class="f-w-400">{{customer.nationality}}</h6>
                        </div>
                        <div class="col-md-4">
                            <p class="m-b-10 f-w-600">PAN</p>
                            <h6 class="f-w-400">{{customer.panNum}}</h6>
                        </div>
                        <div class="col-md-4">
                            <p class="m-b-10 f-w-600">Aadhaar</p>
                            <h6 class="f-w-400">{{customer.aadharNum}}</h6>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <p class="m-b-10 f-w-600">Contact Number</p>
                            <h6 class="f-w-400">{{customer.contactNumber}}</h6>
                        </div>
                        <div class="col-md-4">
                            <p class="m-b-10 f-w-600">Email</p>
                            <h6 class="f-w-400">{{customer.email}}</h6>
                        </div>
                        <div class="col-md-4">
                            <p class="m-b-10 f-w-600">Alternate Contact Number</p>
                            <h6 class="f-w-400">{{customer.alternateContact}}</h6>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <p class="m-b-10 f-w-600">Alternate Email</p>
                            <h6 class="f-w-400">{{customer.alternateEmail}}</h6>
                        </div>
                        <div class="col-md-4">
                            <p class="m-b-10 f-w-600">Address</p>
                            <h6 class="f-w-400">{{address.addressLine1}}
                                <span *ngIf="address.addressLine1 && address.landmark">,</span>
                                {{address.landmark}}
                            </h6>
                            <h6 class="f-w-400">{{address.city}}
                                <span *ngIf="address.city && address.district">,</span>
                                {{address.district}}
                            </h6>
                            <h6 class="f-w-400">{{address.state}}
                                <span *ngIf="address.state && address.pin">,</span>
                                {{address.pin}}
                            </h6>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="isEdit">
        <div class="col-md-12">
            <form #myProfile="ngForm" (ngSubmit)="onSubmit(myProfile.value)">
                <div class="row mt-2">
                    <div class="form-group col-md-4" [ngClass]="{'invalid': firstName.invalid && (firstName.dirty || firstName.touched || myProfile.submitted)}">
                        <label class="star">First Name </label>
                        <input type="text" class="form-control" id="firstName" [(ngModel)]="customer.firstName" name="firstName" #firstName="ngModel" required>
                    </div>

                    <div class="form-group col-md-4" [ngClass]="{'invalid': lastName.invalid && (lastName.dirty || lastName.touched || myProfile.submitted)}">
                        <label class="star">Last Name</label>
                        <input type="text" class="form-control" id="lastName" [(ngModel)]="customer.lastName" name="lastName" #lastName="ngModel" required>
                    </div>

                    <div class="form-group col-md-4" [ngClass]="{'invalid': fatherOrHusbandName.invalid && (fatherOrHusbandName.dirty || fatherOrHusbandName.touched || myProfile.submitted)}">
                        <label class="star">Father/Husband Name</label>
                        <input type="text" class="form-control" id="fatherOrHusbandName" [(ngModel)]="customer.fatherOrHusbandName" name="fatherOrHusbandName" #fatherOrHusbandName="ngModel" required>
                    </div>

                    <div class="form-group col-md-4" [ngClass]="{'invalid': dob.invalid && (dob.dirty || dob.touched || myProfile.submitted)}">
                        <label >DOB</label>
                        <input type="date" class="form-control" id="dob" [(ngModel)]="customer.dob" name="dob" #dob="ngModel" max="9999-12-31">
                    </div>

                    <div class="form-group col-md-4">
                        <label for="gender">Gender</label>
                        <select class="form-control" id="gender" [(ngModel)]="customer.gender" name="gender" #gender="ngModel">
                            <option *ngFor="let gen of genders" [value]="gen">
                                {{gen}}
                            </option>
                        </select>
                    </div>

                    <div class="form-group col-md-4" [ngClass]="{'invalid': bloodGroup.invalid && (bloodGroup.dirty || bloodGroup.touched || myProfile.submitted)}">
                        <label>Blood Group</label>
                        <input type="text" class="form-control" id="bloodGroup" [(ngModel)]="customer.bloodGroup" name="bloodGroup" #bloodGroup="ngModel">
                    </div>

                    <div class="form-group col-md-4" [ngClass]="{'invalid': nationality.invalid && (nationality.dirty || nationality.touched || myProfile.submitted)}">
                        <label>Nationality</label>
                        <input type="text" class="form-control" id="nationality" [(ngModel)]="customer.nationality" name="nationality" #nationality="ngModel">
                    </div>

                    <div class="form-group col-md-4" [ngClass]="{'invalid': panNum.invalid && (panNum.dirty || panNum.touched || myProfile.submitted)}">
                        <label>PAN Number</label>
                        <input type="text" class="form-control" id="panNum" [(ngModel)]="customer.panNum" name="panNum" #panNum="ngModel">
                    </div>

                    <div class="form-group col-md-4" [ngClass]="{'invalid': aadharNum.invalid && (aadharNum.dirty || aadharNum.touched || myProfile.submitted)}">
                        <label>Aadhaar</label>
                        <input type="text" class="form-control" id="aadharNum" [(ngModel)]="customer.aadharNum" name="aadharNum" #aadharNum="ngModel">
                    </div>

                    <div class="form-group col-md-4" [ngClass]="{'invalid': contactNumber.invalid && (contactNumber.dirty || contactNumber.touched || myProfile.submitted)}">
                        <label class="star">Contact Number</label>
                        <input type="text" class="form-control" id="contactNumber" required [(ngModel)]="customer.contactNumber" name="contactNumber" #contactNumber="ngModel">
                    </div>

                    <div class="form-group col-md-4" [ngClass]="{'invalid': alternateContact.invalid && (alternateContact.dirty || alternateContact.touched || myProfile.submitted)}">
                        <label>Alternate Number</label>
                        <input type="text" class="form-control" id="alternateContact" [(ngModel)]="customer.alternateContact" name="alternateContact" #alternateContact="ngModel">
                    </div>

                    <div class="form-group col-md-4" [ngClass]="{'invalid': email.invalid && (email.dirty || email.touched || myProfile.submitted)}">
                        <label>Email</label>
                        <input type="text" class="form-control" id="email" [(ngModel)]="customer.email" name="email" #email="ngModel">
                    </div>

                    <div class="form-group col-md-4">
                        <label>Alternate Email</label>
                        <input type="text" class="form-control" id="alternateEmail" [(ngModel)]="customer.alternateEmail" name="alternateEmail" #alternateEmail="ngModel">
                    </div>

                    <div class="form-group col-md-4" [ngClass]="{'invalid': addressLine1.invalid && (addressLine1.dirty || addressLine1.touched)}">
                        <label>Address Line 1</label>
                        <input type="text" class="form-control" id="addressLine1" [(ngModel)]="customer.primaryAddress.addressLine1" name="addressLine1" #addressLine1="ngModel">
                    </div>

                    <div class="form-group col-md-4" [ngClass]="{'invalid': landmark.invalid && (landmark.dirty || landmark.touched)}">
                        <label>LandMark</label>
                        <input type="text" class="form-control" id="landmark" [(ngModel)]="customer.primaryAddress.landmark" name="landmark" #landmark="ngModel">
                    </div>

                    <div class="form-group col-md-4" [ngClass]="{'invalid': city.invalid && (city.dirty || city.touched)}">
                        <label>City</label>
                        <input type="text" class="form-control" id="city" [(ngModel)]="customer.primaryAddress.city" name="city" #city="ngModel">
                    </div>

                    <div class="form-group col-md-4" [ngClass]="{'invalid': mandal.invalid && (mandal.dirty || mandal.touched)}">
                        <label>Mandal</label>
                        <input type="text" class="form-control" id="mandal" [(ngModel)]="customer.primaryAddress.mandal" name="mandal" #mandal="ngModel">
                    </div>

                    <div class="form-group col-md-4" [ngClass]="{'invalid': district.invalid && (district.dirty || district.touched)}">
                        <label>District</label>
                        <input type="text" class="form-control" id="district" [(ngModel)]="customer.primaryAddress.district" name="district" #district="ngModel">
                    </div>

                    <div class="form-group col-md-4" [ngClass]="{'invalid': state.invalid && (state.dirty || state.touched)}">
                        <label>State</label>
                        <input type="text" class="form-control" id="state" [(ngModel)]="customer.primaryAddress.state" name="state" #state="ngModel">
                    </div>

                    <div class="form-group col-md-4" [ngClass]="{'invalid': pin.invalid && (pin.dirty || pin.touched)}">
                        <label>Pin Code</label>
                        <input type="text" class="form-control" id="pin" [(ngModel)]="customer.primaryAddress.pin" name="pin" #pin="ngModel">
                    </div>

                </div>

                <button type="submit" class="btn btn-sm btn-info" [disabled]="!myProfile.form.valid">Submit</button>
            </form>
            <ng-template #successMsg let-modal>
                <div class="card">
                    <div class="modal-header">
                        <button type="button" class="close" aria-label="Close" (click)="sendBack()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="card-body modal-body">
                        <div class="text-center">
                            <p class="close-content">{{message}}</p>
                        </div>
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-center">
                                <div class="submit-btn px-2">
                                    <button type="submit" class="btn btn-info" (click)="closeDialog()"
                                        #submitButton>Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>

</div>