<ng-container *ngIf="userInfo?.scopes.includes('SALESREP_PRIVILEGE')">
  <div class="my-3">
    <div class="card">
      <div class="card-body">
        <form #details="ngForm">
          <div class="row">
            <div class="form-group col-md-4">
              <label class="star">Ventures</label>
              <select type="text" class="form-control" id="ventures" name="ventures" required
                [(ngModel)]="ventureSelected">
                <option [value]="'all'">All Ventures</option>
                <option *ngFor="let venture of ventures" [value]="venture.ventureId">{{venture.ventureName}}</option>
              </select>
            </div>
            <div class="col-md-2 mt-4" style="font-size: medium;">
              <button class="btn-info py-1 px-3 border rounded" (click)="getCommissionsByVenture()"
                [disabled]=details.invalid><b>Go</b></button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>


<div class="my-2" *ngIf="!ventureId">
  <div class="card">
    <div class="card-body">
      <div *ngIf="ventureAdvancesInfo && ventureAdvancesInfo.length > 0">
        <div class="table-responsive mt-3">
          <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger">
            <thead>
              <tr>
                <th>Venture Name</th>
                <th>Advance (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                <th>Total Passive Advance (<i class="fas fa-rupee-sign mr-1"></i>)</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="ventureAdvancesInfo">
                <tr *ngFor="let venture of ventureAdvancesInfo">
                  <td>{{ venture.ventureName }}</td>
                  <td>{{ venture.advanceAmount?.toLocaleString('en-IN') }}</td>
                  <td>{{ venture.totalPassiveAdvanceAmount?.toLocaleString('en-IN') }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>

      <div *ngIf="!ventureAdvancesInfo || ventureAdvancesInfo.length === 0">
        <h5 class="text-center m-0 py-2">No advances exists</h5>
      </div>

    </div>
  </div>
</div>



<div class="my-2" *ngIf="ventureId">
  <div class="card">
    <div class="card-body">

      <div *ngIf="!passiveAdvanceInfo?.advance">
        <h5 class="text-center m-0 py-2">No advance exists</h5>
      </div>
      
      <div class="d-flex justify-content-between">




        <div *ngIf="passiveAdvanceInfo?.advance">
          <div>
            <i></i> Advance:
            <b class="margin"><i
                class="fas fa-rupee-sign "></i>{{passiveAdvanceInfo.advance.advanceAmount?.toLocaleString('en-IN') ||
              'N/A'}}</b>
          </div>
          <div>
            <i></i> Total Passive Advance:
            <b class="margin"><i
                class="fas fa-rupee-sign "></i>{{passiveAdvanceInfo.advance.totalPassiveAdvanceAmount?.toLocaleString('en-IN')
              || 'N/A'}}</b>
          </div>
        </div>


       



        <div *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.ADD_PASSIVE_ADVANCE]">
          <div *ngIf="passiveAdvanceInfo?.advance" class="col-md-12 text-right">
            <button class="btn btn-sm btn-info mb-3 "
              (click)="showMakePassiveAdvance($event, passiveAdvanceInfo?.advance?.salesrepId)">Create Passive
              Advance</button>
          </div>
        </div>




      </div>

      <div class="table-responsive mt-3" *ngIf="passiveAdvanceInfo?.advance">
        <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions"
          [dtTrigger]="dtTrigger">
          <thead>
            <tr>
              <th>Passive Advances (<i class="fas fa-rupee-sign mr-1"></i>)</th>
              <th>Date</th>
              <th>Comments</th>
              <th>Last Updated By</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="passiveAdvanceInfo?.passiveAdvanceList">
              <tr *ngFor="let passiveAdvance of passiveAdvanceInfo.passiveAdvanceList">
                <td>{{passiveAdvance.passiveAdvanceAmount?.toLocaleString('en-IN')}}</td>
                <td>{{passiveAdvance.createdOn | date}}</td>
                <td>{{passiveAdvance.comments}}</td>
                <td>{{passiveAdvance.lastUpdatedBy}}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>




  <!-- <ng-template #makePassiveAdvance let-modal>
    <div class="modal-header">
      <h6 class="card-title text-dark position-relative pb-2 ">Create Passive Advance</h6>
      <button type="button" class="position-absolute close-popup-link bg-white change-lead"
        (click)="modal.dismiss('Cross click')"><i class="fa fa-times" aria-hidden="true"></i></button>
    </div>
    <div class="modal-body">
      <form>
        <div class="container-fluid">
          <div class="row mt-2">
            <div class="form-group col-md-6">
              <label for="salesrepName">Salesrep</label>
              <input type="text" class="form-control" id="salesrepName" name="salesrepName" readonly
                [(ngModel)]="salesrep.salesrepId">
            </div>
            <div class="form-group col-md-6">
              <label for="passiveAdvanceEntry">Passive Advance</label>
              <input type="number" min="1" class="form-control" id="passiveAdvanceEntry" name="passiveAdvanceEntry" [(ngModel)]="passiveAdvanceEntry" required>
            </div>
          </div>
          <div class="row mt-2">
            <div class="form-group col-md-12">
              <label for="commentsEntry">Comments</label>
              <textarea class="form-control" id="commentsEntry" name="commentsEntry" [(ngModel)]="commentsEntry" required></textarea>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-info" (click)="modal.close('Save click');createPassiveAdvance()"
        >Update</button>
    </div>
  </ng-template> -->



  <ng-template #makePassiveAdvance let-modal>
    <div class="modal-header">
      <h6 class="card-title text-dark position-relative pb-2 ">Create Passive Advance</h6>
      <button type="button" class="position-absolute close-popup-link bg-white change-lead"
        (click)="modal.dismiss('Cross click')"><i class="fa fa-times" aria-hidden="true"></i></button>
    </div>
    <div class="modal-body">
      <form #passiveAdvanceForm="ngForm" (ngSubmit)="createPassiveAdvance()" novalidate>
        <div class="container-fluid">
          <div class="row mt-2">
            <div class="form-group col-md-6">
              <label for="salesrepName">Salesrep</label>
              <input type="text" class="form-control" id="salesrepName" name="salesrepName" readonly
                [(ngModel)]="salesrep.salesrepName">
            </div>
            <div class="form-group col-md-6">
              <label for="passiveAdvanceEntry">Passive Advance</label>
              <input type="number" class="form-control" id="passiveAdvanceEntry" name="passiveAdvanceEntry"
                [(ngModel)]="passiveAdvanceEntry" required>
              <div
                *ngIf="passiveAdvanceForm.controls['passiveAdvanceEntry'].invalid && passiveAdvanceForm.controls['passiveAdvanceEntry'].touched"
                class="text-danger">Passive Advance is required.
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="form-group col-md-12">
              <label for="commentsEntry">Comments</label>
              <textarea class="form-control" id="commentsEntry" name="commentsEntry" [(ngModel)]="commentsEntry"
                required></textarea>
              <div
                *ngIf="passiveAdvanceForm.controls['commentsEntry'].invalid && passiveAdvanceForm.controls['commentsEntry'].touched"
                class="text-danger">Comments are required.
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-info" [disabled]="passiveAdvanceForm.invalid"
            (click)="modal.close('Save click');createPassiveAdvance()">Submit</button>
        </div>
      </form>
    </div>
  </ng-template>








</div>