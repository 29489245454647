<div *ngIf="alert" class="alert alert-success alert-dismissible fade show" role="alert">
    <strong>Success !</strong> You payment details have been saved successfully!
    <button type="button" (click)="closeAlert()" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form (ngSubmit)="onSubmit()" #myPayment="ngForm">
    <div class="row mt-2">
        <div class="form-group col-md-6" [ngClass]="{'invalid': amountPaid.invalid && (amountPaid.dirty || amountPaid.touched)}">
            <label class="star">Amount Paid (<i class="fas fa-rupee-sign mr-1"></i>)</label>
            <input type="number" class="form-control" id="amountPaid" required [(ngModel)]="payment.amountPaid" name="amountPaid" #amountPaid="ngModel">
        </div>

        <ng-template #advance let-modal>
            <div class="modal-body">
                <ngb-alert [dismissible]="false" type="danger">
                    <div class="d-flex align-items-baseline">
                        <i class="fa fa-exclamation-triangle"></i>
                        <h6 class="ml-2">Excess Commission!</h6>
                    </div>
                    <p class="pl-4">You are paying more than pending amount for this salesrep.</p>                    
                    <p class="pl-4" *ngIf="plotId === null || plotId === undefined">Do you want to proceed and add the additional amount to Advance Commission Payments ?</p>
                </ngb-alert>
            </div>
            <div class="modal-footer">
                <button *ngIf="plotId === null || plotId === undefined" type="button" class="float-left btn btn-outline-danger" (click)="modal.close('Save click'); saveCommission()">Proceed</button>
                <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('cancel click')">Cancel</button>
            </div>
        </ng-template>

        <div class="form-group col-md-6">
            <label for="gender">Payment Mode</label>
            <select class="form-control" id="paymentMode" required [(ngModel)]="payment.paymentMode" name="paymentMode" #paymentMode="ngModel">
                <option *ngFor="let pay of payments" [value]="pay">
                    {{pay}}
                </option>
            </select>
        </div>

        <div class="form-group col-md-6">
            <label class="star">Received By</label>
            <ng-autocomplete [data]="salesRep" name="salesrepName" [focusFirst]=true [(ngModel)]="salesRepData" [searchKeyword]="keyword" placeholder="Enter salesRep Name" [itemTemplate]="salesRepTemplate" [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>

            <ng-template #salesRepTemplate let-item>
                <div class="item">
                    <a [innerHTML]="item.name"></a>
                </div>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
                <div>notFound</div>
            </ng-template>
        </div>
        <div class="form-group col-md-6" [ngClass]="{'invalid': datePaid .invalid && (datePaid .dirty || datePaid .touched)}">
            <label class="star">Paid Date</label>
            <input type="date" class="form-control" id="datePaid " name="datePaid " required [(ngModel)]="payment.datePaid" #datePaid="ngModel">
        </div>
        <div class="form-group col-md-6">
            <label class="">Comments</label>
            <input type="text" class="form-control" id="comments" [(ngModel)]="payment.comments" name="comments" #comments="ngModel">
        </div>
        <div class="form-group col-12">
            <button type="submit" class="btn btn-info" [disabled]=myPayment.invalid>Submit</button>
        </div>
    </div>
</form>