import { Component, OnInit } from '@angular/core';
import { AuthServiceService } from 'src/app/services/auth-service.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  userInfo: any;
  active = 1;

  constructor(
    private authService: AuthServiceService
  ) { }

  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    if(this.userInfo?.scopes.includes('SALESREP_PRIVILEGE')) {
      this.active = 3;
    }
  }
}
