import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { UserPrivileges, UserRoleConstants } from 'src/app/constants/AppConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-plotsummary',
  templateUrl: './plotsummary.component.html',
  styleUrls: ['./plotsummary.component.scss']
})
export class PlotsummaryComponent implements OnInit {
  summary: any = [];
  performance: any = [];
  list: any = [];
  plots: any = [];
  ventureId: any;
  plotData: any = [];
  graphdata: any;
  userPrivileges = UserPrivileges;

  constructor(private restService: RestService,
    private authService: AuthServiceService,
    private route: ActivatedRoute,
    private location: Location,
    private commonService: CommonServiceService) { }

  ngOnInit(): void {
    const userInfo: any = this.authService.getUserInfo();
    const pathF: string = (this.authService.hasRole(UserRoleConstants.MARKETER)) ? 'marketer/plotSummary' : 'tenant/plotSummary';
    const data: any = {
      tenantId: userInfo.tenantId,
      marketerId: userInfo.marketerId,
      ventureId: this.commonService.getVenture()?.ventureId,
    };
    this.restService.submitPostRequest(pathF, data).subscribe(response => {
      this.summary = response.data.plotTypeslist;
      this.graphdata = response.data.plotSaleslist;
      this.lineChartData = [
        { data: this.graphdata.map(a => a.sold), label: 'Sold' },
        { data: this.graphdata.map(a => a.registered), label: 'Registered' },
      ]
      this.lineChartLabels = this.graphdata.map(a => a.month);
    });
    const path: string = (this.authService.hasRole(UserRoleConstants.MARKETER)) ? 'marketer/marketerVentureDashboard' :
      'tenant/tenantVentureDashboard';
    this.restService.submitPostRequest(path, data).subscribe(response => {
      this.performance = response.data.lablePlotlist;
    });
  }
  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: string[];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false
    },
    scales: {
      xAxes: [{}], yAxes: [{
        //  display: false,
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    },
  };
  public lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];
}
