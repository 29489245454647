import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserRoleConstants } from 'src/app/constants/AppConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';
import { Location } from '@angular/common';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-addcommissionpayments',
  templateUrl: './addcommissionpayments.component.html',
  styleUrls: ['./addcommissionpayments.component.scss']
})
export class AddcommissionpaymentsComponent implements OnInit {
  @ViewChild('advance', { static: false }) advance: ElementRef;

  alert = false;
  payments: any = ['Online', 'Wire Transfer', 'Cash', 'Cheque'];
  salesRepData: any;
  salesRep: any;
  payment: any = {};
  keyword = 'name';
  commissionPayment: any = [];
  mysalesArray: any = [];
  plotData: any;
  data: { tenantId: number; marketerId: number; ventureId: any; salesrepId: any; };
  error: boolean;
  errorMessage: any;
  closeResult: string;
  salesrepId: any;
  plotId = null;
  currentSalesrep: boolean;

  constructor(
    private restService: RestService,
    private authService: AuthServiceService,
    private location: Location,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    public commonService: CommonServiceService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.salesrepId = params['salesrepId'];
      this.plotId = params['plotId'];
    });
    this.getsalesRep();
    this.getSale();
  }

  onSubmit() {
    const userInfo = this.authService.getUserInfo();
    const path: string = 'marketer/add-commission-payment';
    this.payment.tenantId = userInfo.tenantId;
    this.payment.marketerId = userInfo.marketerId;
    this.payment.advanceEnabled = false;
    this.payment.ventureId = this.commonService.getVenture()?.ventureId;
    this.payment.salesrepId = this.salesRepData.salesrepId;
    this.payment.amountPaid = Math.round(this.payment.amountPaid);
    if (this.plotData) {
      this.payment.plotId = this.plotData.plotId;
    }

    if(this.salesrepId!==null && this.plotId!==null){
      this.payment.plotId = this.plotId;
    }

    this.restService.submitPostRequest(path, this.payment).subscribe(
      (response: any) => {
        this.commissionPayment = response;
        this.payment = {};
        if(this.plotId === null || this.plotId === undefined){
          window.location.reload();
        }
        else{
          this.location.back()
        }

      }, (error: any) => {
        if (error.error.message === 'Excess Commission') {
          this.open(this.payment);
        }
        this.error = true;
        this.errorMessage = error.status;
      });
  }

  saveCommission() {
    const path: string = 'marketer/add-commission-payment';
    this.payment.advanceEnabled = true;
    this.restService.submitPostRequest(path, this.payment).subscribe(
      (res: any) => {
        console.log(res);
        window.location.reload();
      },
      (error: any) => {
        this.error = true;
        this.errorMessage = error.status;
      }
    )
  }


  closeAlert() {
    this.alert = false;
  }

  open(data) {
    this.modalService.open(this.advance, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      console.log(result);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log(`Dismissed: ${reason}`);
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getsalesRep() {
    const user = this.authService.getUserInfo();

    let data = {
      'tenantId': user.tenantId,
      'marketerId': user.marketerId,
      'ventureId': this.commonService.getVenture()?.ventureId
    }

    const path: string = this.authService.hasRole(UserRoleConstants.TENANT) ? 'tenant/get-venture-team' :
      'venture-team/get-venture-team';
    this.restService.submitPostRequest(path, data).subscribe(response => {
      if (response != null) {
        if(this.salesrepId !== null && this.salesrepId !== undefined) {
        this.salesRep = response.data.teams.filter(salesrep => {
          if (salesrep.salesrepId == this.salesrepId) {
            this.currentSalesrep = salesrep;
            return this.currentSalesrep;
          }
        });
        } else {
          this.salesRep = response.data.teams.filter(a => a.name != null);
        }
      }
    });
  }

  getSale() {
    const path: string = this.authService.getUserInfo().role.toLowerCase() + '/get-sales';

    let data = {
      "tenantId": this.authService.getUserInfo().tenantId,
      "marketerId": this.authService.getUserInfo().marketerId,
      "ventureId": this.commonService.getVenture()?.ventureId
    }

    this.restService.submitPostRequest(path, data).subscribe(response => {
      if (response) {
        this.mysalesArray = response.sales;
      }
    })
  }

}
