export class UserRoleConstants {
    public static ADMIN = 'Admin';
    public static TENANT = 'Tenant';
    public static MARKETER = 'Marketer';
    public static SALESREP = 'SalesRep';
}

export class ReportTypes {
    public static TOTALSALE = 'totalSale';
    public static SALEVENTURE = 'saleVenture';
    public static TOTALREVENUE = 'totalRevenue';
    public static REVENUEMARKETER = 'revenueMarketer';
    public static REVENUEVENTURE = 'revenueVenture';
    public static COMMISSION = 'commission';
    public static SALESREPCOMMISSION = 'salesrepCommission';
    public static VENTURECOMMISSION = 'ventureCommission';
    public static PROFIT = 'Profit';
    public static PROFITVENTURE = 'ProfitVenture';
    public static TEAM = 'team';
    public static TEAMVENTURE = 'teamVenture';
    public static CUSTOMERPAYMENT = 'customerPayment';
    public static PENDINGPAYMENT = 'pendingPayment';
    public static PAYMENTVENTURE = 'paymentVenture';
}

export class UserPrivileges {
    public static ADMIN_PRIVILEGE = 'ADMIN_PRIVILEGE';
    public static TENANT_PRIVILEGE = 'TENANT_PRIVILEGE';
    public static TENANT_BASIC_PRIVILEGE = 'TENANT_BASIC_PRIVILEGE';

    public static MARKETER_SUPER_PRIVILEGE = 'MARKETER_SUPER_PRIVILEGE';
    public static MARKETER_BASIC_PRIVILEGE = 'MARKETER_BASIC_PRIVILEGE';

    public static SALESREP_PRIVILEGE = 'SALESREP_PRIVILEGE';

    public static CHANGE_PASSWORD_PRIVILEGE = 'CHANGE_PASSWORD_PRIVILEGE';
    public static READ_PRIVILEGE = 'READ_PRIVILEGE';

    public static REGISTER_PLOT = 'REGISTER_PLOT';

    public static ADD_SALE = 'ADD_SALE';

    public static ADD_CUSTOMER_PAYMENT = 'ADD_CUSTOMER_PAYMENT';

    public static ADD_COMMISSION_PAYMENT = 'ADD_COMMISSION_PAYMENT';

    public static ADD_PASSIVE_ADVANCE = 'ADD_PASSIVE_ADVANCE';
    
    public static UPDATE_CUSTOMER_DETAILS = 'UPDATE_CUSTOMER_DETAILS';
}