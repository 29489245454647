<section class="my-3">
    <div class="card mb-3">
        <div class="card-body rounded" style="background-image: url(assets/images/header-bg.jpg);">
            <h5 class="text-center">{{ventureName}}</h5>
        </div>
    </div>
    <div class="row">
        <div class="col-md-8">
            <!-- <div class="row">
        <div class="col-md-12 mb-3">
          <div class="card shadow  h-100 mb-2">
            <div class="card-body pb-5 px-3 pt-2">
              <h6 class="card-title text-dark position-relative pb-2">Notifications</h6>
            </div>
          </div>
        </div>
      </div> -->
            <div class="card shadow  mb-3">
                <div class="card-body px-3 pb-4 pt-2">
                    <div class="row">
                        <div class="col-md-6">
                            <h6 class="card-title text-dark position-relative pb-2 h6">All Time Top 5 Sales Rep</h6>
                        </div>
                        <div class="col-md-6">
                            <a class="text-right mr-1 pointer" (click)="expand(performance)" style="font-size: large; display: block;">
                                <i class="fas fa-expand text-dark"></i> </a>
                        </div>
                    </div>
                    <div class="wrap">
                        <table class="table table-striped table-bordered table-sm row-border hover">
                            <thead>
                                <tr>
                                    <th>Sales rep</th>
                                    <th>Plots Sold</th>
                                    <th>{{commService.getMeasure()}} Sold</th>
                                    <th>Revenue (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let top of performance.performancelist; let i= index">
                                    <ng-container *ngIf="i < 5 ">
                                        <td class="pointer" (click)="onDisply(top)">{{top.salesRep}}</td>
                                        <td>{{top.plotsSold}}</td>
                                        <td>{{top.yardsSold.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                                        <td>{{top.revenue.toLocaleString('en-IN')}}</td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card shadow  mb-3">
                <div class="card-body px-3 pb-4 pt-2">
                    <h6 class="card-title text-dark position-relative pb-2 h6">Top 5 Sales Rep ({{performance.lastMonth}})</h6>
                    <div class="wrap">
                        <table class="table table-striped table-bordered table-sm row-border hover">
                            <thead>
                                <tr>
                                    <th>Sales rep</th>
                                    <th>Plots Sold</th>
                                    <th>{{commService.getMeasure()}} Sold</th>
                                    <th>Revenue (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let top of performance.bestPerformancelist; let i= index">
                                    <ng-container *ngIf="i < 5 ">
                                        <td class="pointer" (click)="onDisply(top)">{{top.salesRep}}</td>
                                        <td>{{top.plotsSold}}</td>
                                        <td>{{top.yardsSold.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                                        <td>{{top.revenue.toLocaleString('en-IN')}}</td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card shadow  mb-3">
                <div class="card-body px-3 pb-4 pt-2">
                    <h6 class="card-title text-dark position-relative pb-2">Plots(Total Plots : {{performance?.marketerPlot?.totalPlots}})
                    </h6>
                    <app-venture-graph [plots]="performance.lablePlotlist"></app-venture-graph>
                </div>
            </div>
            <div class="card shadow  mb-3">
                <div class="card-body px-3 pb-4 pt-2">
                    <div class="row mb-0">
                        <div class="col-md-6">
                            <h6 class="card-title text-dark position-relative pb-2">Sales</h6>
                        </div>
                        <div class="col-md-6">
                            <a class="text-right mr-3 pointer" (click)="expandsale(performance)" style="font-size: large; display: block;">
                                <i class="fas fa-expand text-dark"></i> </a>
                        </div>
                    </div>
                    <ul class="list-unstyled mb-4 mt-2">
                        <li *ngFor="let plot of marketerSale">
                            <div class="row">
                                <div class="col-6">Total Plots Sold:</div>
                                <div class="col-6"><b>{{plot.plotsSold}}</b></div>
                                <div class="col-6">Total Sale Value:</div>
                                <div class="col-6"><b>&#8377;{{plot.saleValue.toLocaleString('en-IN')}}</b></div>
                                <div class="col-6">Amount Received:</div>
                                <div class="col-6"><b>&#8377;{{plot.amountReceived.toLocaleString('en-IN')}}</b></div>
                                <div class="col-6">Amount Pending:</div>
                                <div class="col-6"><b>&#8377;{{plot.amountPending.toLocaleString('en-IN')}}</b></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card shadow  mb-3">
                <div class="card-body px-3 pb-4 pt-2">
                    <div class="row mb-0">
                        <div class="col-md-6">
                            <h6 class="card-title text-dark position-relative pb-2">Commission</h6>
                        </div>
                        <div class="col-md-6">
                            <a class="text-right mr-3 pointer" (click)="expandcommission(performance)" style="font-size: large; display: block;">
                                <i class="fas fa-expand text-dark"></i> </a>
                        </div>
                    </div>
                    <ul class="list-unstyled mb-4 mt-2">
                        <li *ngFor="let plot of marketerCommission">
                            <div class="row">
                                <div class="col-6">Total Commission:</div>
                                <div class="col-6"><b>&#8377;{{plot.totalCommission.toLocaleString('en-IN')}}</b></div>
                                <div class="col-6">Commission Earned:</div>
                                <div class="col-6"><b>&#8377;{{plot.commissionEarned.toLocaleString('en-IN')}}</b></div>
                                <!-- <br><br> -->
                                <div class="col-6">Commission Paid:</div>
                                <div class="col-6"><b>&#8377;{{plot.commissionPaid.toLocaleString('en-IN')}}</b></div>
                                <div class="col-6">Pending Payment:</div>
                                <div class="col-6"><b>&#8377;{{plot.commissionPending.toLocaleString('en-IN')}}</b></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>