import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RoutesConstants } from 'src/app/constants/RoutesConstants';
import { ThemeService } from 'src/app/services/theme.service';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  //from admin
  @Input()
  userSearch: any;
  @Input()
  catalogueSearch: any;

  //local 
  data: any;
  userJson: any;
  userObject: any;
  catalogueJson: any;
  catalogueObject: any;
  statsRoute: string = RoutesConstants.TENANT_LAYOUT + '/' + RoutesConstants.TENANT_STATS;

  constructor(
    public themeService: ThemeService
  ) { }

  ngOnInit() {
    if (this.catalogueSearch) {
      this.data = [        
        {
          name: '1B',
          value: 'Sai builders'
        },
        {
          name: '1C',
          value: 'Gunti Ventures'
        },
        {
          name: '2B',
          value: 'Tadpatri builders'
        }
      ]
      
      
      this.catalogueJson = JSON.stringify(this.data);
      this.catalogueObject = JSON.parse(this.catalogueJson);
    }
    
    if (this.userSearch) {
      this.data = [
        
        {
          name: 'bsanders ',
          value: 'Beth Sanders '
        },
        {
          name: 'jsmith ',
          value: 'Beth Sanders '
        },
      ]
      this.userJson = JSON.stringify(this.data);
      this.userObject = JSON.parse(this.userJson);
    }
  }

}