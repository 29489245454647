export var DATA = [{
	"table": "tenant",
	"rows":
		[
			{
				"tenant_id": 10001,
				"managing_director_id": 100010000002,
				"primary_company_id": 100010001,
				"display_name": "Suvarnabhoomi Developers Private Limited",
				"primary_contact_id": 100010000003,
				"secondary_contact_id": null,
				"primary_address_id": 100010000003,
				"secondary_address_id": null,
				"contact_number": 7889977678,
				"alternate_contact": 12378947,
				"email": "suvarna@gmail.com",
				"alternate_email": "suvarna1@gmail.com",
				"comments": "Welcome",
				"last_updated_by": null,
				"last_updated_on": null
			}
		]
}
	,
{
	"table": "venture",
	"rows":
		[
			{
				"venture_id": 10001001,
				"tenant_id": 10001,
				"name": "Sweet Home",
				"type": "input",
				"display_name": "Sweet Home",
				"size": 20,
				"num_of_plots": 25,
				"locaton_address": "10-5, Hyd, 500001",
				"survey_num": "UN123533",
				"layout_map": "https//maps.12",
				"location_map": "https//maps.2",
				"venture_type": "Limited partnership",
				"approval_details": "Approved",
				"documents_folder": "http//data",
				"venture_status": "Active",
				"start_date": "2021-02-05",
				"end_date": "2021-03-01",
				"comments": "Hello!!!",
				"last_updated_by": 10001,
				"last_updated_on": "2021-02-05"
			}
		]
}
	,
{
	"table": "plots",
	"rows":
		[
			{
				"plot_id": 100010010001,
				"tenant_id": 10001,
				"venture_id": 10001001,
				"plot_num": 101,
				"measure": "feet",
				"size": 5,
				"east_length": 10,
				"west_length": 25,
				"north_length": 33,
				"south_length": 56,
				"road_facing": "Yes",
				"coordinates": "31.5,42.2",
				"plot_type": "plains",
				"plot_status": "booked",
				"comments": "Hello!!!",
				"last_updated_by": 10001,
				"last_updated_on": "2010-01-12"
			}
		]
}
	,
{
	"table": "plots_pricing",
	"rows":
		[
			{
				"venture_id": 10001001,
				"effective_date": "2010-01-12",
				"tenant_id": 10001,
				"plot_status": "open",
				"plot_type": "plains",
				"measure": "feet",
				"max_selling_price": 1000000,
				"min_selling_price": 99999,
				"max_discount_allowed": 999,
				"price_for_marketer": 100000,
				"effective_enddate": "2021-02-05",
				"comments": "Hello!!",
				"last_updated_by": 10001,
				"last_updated_on": "2021-02-05"
			}
		]
},

	{
		"table": "receivables",
		"rows":
		[
			{
				"marketer_id": 10001501,
				"venture_id": 10001001,
				"transaction_date": "2021-02-09",
				"tenant_id": 10001,
				"amount_given": 200000,
				"plot_id": 100010010001,
				"payment_mode": "cash",
				"comments": "Hello !!!",
				"updated_by": 10001,
				"updated_on": "2021-02-09"
			}
		]
	},

	{
		"table": "marketer",
		"rows":
		[
			{
				"marketer_id": 10001501,
				"tenant_id": 10001,
				"marketer_type": "person",
				"managing_director_id": 100010000002,
				"primary_company_id": 100010002,
				"display_name": "Suvarnabhoomi Developers",
				"primary_address_id": 100010002,
				"secondary_address_id": null,
				"contact_num": 89787669944,
				"alternate_contact": 986789378944,
				"email": "suvarnabhoomi@gmail.com",
				"alternate_email": "suvarnabhoomi1@gmail.com"
			}
		]
	},

	{
		"table": "salesrep",
		"rows":
		[
			{
				"venture_name": "Home Lands",
				"total_sales": 5,
				"sales_in_progress": 3,
				"total_commission": 10000,
				"commission_earned": 9000,
				"commission_pending": 1000,
				"commission_paid": 1000,
				"pending_payment": 8000
			},
			{
				"venture_name": "Dream City",
				"total_sales": 7,
				"sales_in_progress": 0,
				"total_commission": 10000,
				"commission_earned": 9000,
				"commission_pending": 1000,
				"commission_paid": 1000,
				"pending_payment": 8000
			},
			{
				"venture_name": "New City",
				"total_sales": 9,
				"sales_in_progress": 1,
				"total_commission": 10000,
				"commission_earned": 9000,
				"commission_pending": 1000,
				"commission_paid": 1000,
				"pending_payment": 8000
			},
			{
				"venture_name": "City Homes",
				"total_sales": 1,
				"sales_in_progress": 1,
				"total_commission": 10000,
				"commission_earned": 9000,
				"commission_pending": 1000,
				"commission_paid": 1000,
				"pending_payment": 8000
			},
			{
				"venture_name": "Unique Homes",
				"total_sales": 4,
				"sales_in_progress": 2,
				"total_commission": 10000,
				"commission_earned": 9000,
				"commission_pending": 1000,
				"commission_paid": 1000,
				"pending_payment": 8000
			}
		]
	},

	{
		"table": "sales",
		"rows":
			[
				{
						
					"plot_num": "1A",
					"plot_status": "registered",
					"size": 500,
					"sale_price":30000,
					"commission_rate":500,
					"total_commission":250000,
					"commission_earned":3000,
					"commission_pending":3000,
					"commission_paid":3000,
					"commission_payment":3000
				},
				{
						
					"plot_num":"1B",
					"plot_status": "sold",
					"size": 300,
					"sale_price":25000,
					"commission_rate":1000,
					"total_commission":30000,
					"commission_earned":3000,
					"commission_pending":3000,
					"commission_paid":3000,
					"commission_payment":3000
				},
				{
						
					"plot_num": "2A",
					"plot_status": "sold",
					"size": 400,
					"sale_price":30000,
					"commission_rate":500,
					"total_commission":250000,
					"commission_earned":3000,
					"commission_pending":3000,
					"commission_paid":3000,
					"commission_payment":3000
				},
				{
						
					"plot_num": "2B",
					"plot_status": "sold",
					"size": 500,
					"sale_price":30000,
					"commission_rate":500,
					"total_commission":250000,
					"commission_earned":3000,
					"commission_pending":3000,
					"commission_paid":3000,
					"commission_payment":3000
				},
				{
						
					"plot_num": "3C",
					"plot_status": "blocked",
					"size": 1000,
					"sale_price":30000,
					"commission_rate":500,
					"total_commission":250000,
					"commission_earned":3000,
					"commission_pending":3000,
					"commission_paid":3000,
					"commission_payment":3000
				},
			],
			"row":
			[
				{
						
					"sales_rep": "Abhijit",
					"total_sales": 4,
					"total_commission":2000,
					"commission_earned":1000,
					'expanded': false
				},
				{
						
					"sales_rep": "Sami",
					"total_sales": 5,
					"total_commission":2500,
					"commission_earned":1500,
					'expanded': false
				},
				{
						
					"sales_rep": "Pranitha",
					"total_sales": 3,
					"total_commission":1500,
					"commission_earned":500,
					'expanded': false
				},
				{
						
					"sales_rep": "Amitesh",
					"total_sales": 2,
					"total_commission":1000,
					"commission_earned":0,
					'expanded': false
				},
				
			]
			
	},

	{
		"table": "venture_marketing",
		"rows":
		[
			{
				"venture_id": 10001001,
				"marketer_id": 10001501,
				"tenant_id": 10001,
				"plots_sold": 20,
				"plots_blocked": 2,
				"plots_attached": 4,
				"value_sold": 20,
				"value_blocked": 3,
				"value_attached": 12,
				"value_receive": 500000,
				"developer_price": 500500,
				"min_sale_price": 500600,
				"max_sale_price": 600000,
				"venture_marketing_status": "open",
				"effective_date": "2021-02-01",
				"effective_enddate": "2021-03-01",
				"marketer_company_id": 100010002
			}
		]
	},

	{
		"table": "company",
		"rows":
		[
			{
				"company_id": 100010001,
				"tenant_id": 10001,
				"company_category": "tenant",
				"company_holder_id": 10001,
				"sequence_num": 1,
				"company_name": "Vanna Tech",
				"company_type": "",
				"registration_num": "VN1234",
				"tan_num": "BR1234",
				"gst_num": "GS1233849",
				"contact_num": 876484684884,
				"alternate_contact": 12344899,
				"email": "vanna@gmail.com",
				"alternate_email": "",
				"description": "Hello"
			}
		]
	},

	{
		"table": "person",
		"rows":
		[
			{
				"person_id": 100010000001,
				"tenant_id": 10001,
				"person_type": "customer",
				"first_name": "Amitesh",
				"last_name": "Anand",
				"dob": "2010-01-12",
				"gender": "male",
				"blood_group": "B",
				"nationality": "Indian",
				"pan_num": "123BR4566",
				"aadhar_num": "9754275872",
				"contact_number": 98593795030,
				"alternate_contact": 900334785,
				"email": "ami@gmail.com",
				"alternate_email": "anand@gmail.com",
				"facebook_id": "ami@fb.com",
				"picture_link": "http:aws.amipic",
				"theme_option": "localhost.dark"
			}
		]
	},

	{
		"table": "sales_level",
		"rows":
		[
			{
				"id":101,
				"level": 0,
				"name": 'Amitesh',
				"per_yard_commission": 300,
				"calculated": 200,
				"adjusted": 100,
			},
			{
				"id":102,
				"level": 1,
				"name": 'Sami',
				"per_yard_commission": 100,
				"calculated": 60,
				"adjusted": 40,
			},
			{
				"id":103,
				"level": 2,
				"name": 'Abhijit',
				"per_yard_commission": 50,
				"calculated": 30,
				"adjusted": 20,
			},
			{
				"id":104,
				"level": 3,
				"name": 'Ravi',
				"per_yard_commission": 25,
				"calculated": 20,
				"adjusted": 5,
			}
		]
	},
	{
		"table": "address",
		"rows":
		[
			{
				"address_id": 100010001,
				"tenant_id": 10001,
				"address_holder_id": 100010000003,
				"sequence_num": 1,
				"address_catogery": "person",
				"address_line_1": "chikkadpally",
				"address_line_2": "Hyderabad",
				"landmark": "ABC",
				"city": "Hyd",
				"mandal": "sec",
				"district": "hyd",
				"state": "TS",
				"pin": 123456,
				"country": "IND"
			}
		]
	}
]