import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Stepper from 'bs-stepper';
import { AuthServiceService } from '../../../services/auth-service.service';
import { Location } from '@angular/common'
import { RestService } from '../../../services/rest.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { UserPrivileges } from 'src/app/constants/AppConstants';

@Component({
  selector: 'app-updatemarketer',
  templateUrl: './updatemarketer.component.html',
  styleUrls: ['./updatemarketer.component.scss']
})
export class UpdatemarketerComponent implements OnInit {
  @Input() id;
  private stepper: Stepper;
  permAddress: any = {};
  commAddress: any = {};
  managingDirector: any = {};
  primaryCompany: any = {};
  marketer: any;
  dtOptions: any = {};
  marketerList: any = [];
  ventureData: any;
  userInfo: any;
  // ventureId: any;
  dtTrigger: any;
  error: boolean;
  errorMessage: any;
  personaldetails: any;
  data = false;
  marketerData: any;
  userPrivileges = UserPrivileges;

  constructor(private route: ActivatedRoute,
    private location: Location,
    private restService: RestService,
    private authService: AuthServiceService,
    private permissionsService: NgxPermissionsService
  ) {
    this.commAddress = {};
    this.userInfo = this.authService.getUserInfo();
  }
  ngOnInit() {
    this.marketer = this.id;
    let data = {
      "tenantId": this.userInfo.tenantId,
      "marketerId": this.id
    };
    const path: string = this.userInfo.role.toLowerCase() + '/marketer/getMarketerById';
    this.restService.submitPostRequest(path, data).subscribe(response => {
      this.marketerList = response;
      this.personaldetails = this.marketerList.managingDirector;
      this.primaryCompany = this.marketerList.primaryCompany;
      if (this.marketerList.primaryAddress) {
        this.permAddress = this.marketerList.primaryAddress;
      }
      if (this.marketerList.secondaryAddress) {
        this.commAddress = this.marketerList.secondaryAddress;
      }
    });
    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true
    });
  }
  onsubmit(value: any) {
    this.permissionsService.hasPermission(UserPrivileges.TENANT_PRIVILEGE).then(hasTenantPrivilege => {
      if (hasTenantPrivilege) {
        const pathF = this.userInfo.role.toLowerCase() + '/marketer/updateMarketer';
        this.marketerList.managingDirector = this.personaldetails;
        this.marketerList.primaryAddress = this.permAddress;
        this.marketerList.secondaryAddress = this.commAddress;
        this.restService.submitPostRequest(pathF, this.marketerList).subscribe(
          (response: any) => {
            if (response && response.data) {
              this.marketerData.push(response.data);
            }
            this.location.back();
          }, (error: any) => {
            // TODO Error handling
          });
      }
    });
  }
  next() {
    this.stepper.next();
  }
  sendBack() {
    this.location.back();
  }

}
