<ng-container *ngIf="userInfo?.scopes.includes('SALESREP_PRIVILEGE')">
  <div class="my-3">
    <div class="card">
      <div class="card-body">
        <form #details="ngForm">
          <div class="row">
            <div class="form-group col-md-4">
              <label class="star">Ventures</label>
              <select type="text" class="form-control" id="ventures" name="ventures" required
                [(ngModel)]="ventureSelected">
                <option [value]="'all'">All Ventures</option>
                <option *ngFor="let venture of ventures" [value]="venture.ventureId">{{venture.ventureName}}</option>
              </select>
            </div>
            <div class="col-md-2 mt-4" style="font-size: medium;">
              <button class="btn-info py-1 px-3 border rounded" (click)="getSalesByVenture()"
                [disabled]=details.invalid><b>Go</b></button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>
<div class="my-3">
  <div class="card">
    <div class="card-body">
      <h6 class="card-title text-dark position-relative pb-2 h6">Direct Sales</h6>
      <div class="wrap">
        <table class="table table-striped table-bordered table-sm row-border hover">
          <thead>
            <tr>
              <th>Venture Name</th>
              <th>Plot Number</th>
              <th>Plot Status</th>
              <th>Per {{commonService.getMeasure()}} Price (<i class="fas fa-rupee-sign mr-1"></i>)</th>
              <th>Sale Value (<i class="fas fa-rupee-sign mr-1"></i>)</th>
              <th>Amount Received (<i class="fas fa-rupee-sign mr-1"></i>)</th>
              <th>Sale Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let sale of mysalesArray">
              <td>{{sale.ventureName}}</td>
              <td>{{sale.plotNumber}}</td>
              <td>{{sale.plotStatus | titlecase}}</td>
              <td>{{sale.perMeasurePrice?.toLocaleString('en-IN')}}</td>
              <td>{{sale.saleValue?.toLocaleString('en-IN')}}</td>
              <td>{{sale.saleAmountReceived?.toLocaleString('en-IN')}}</td>
              <td>{{sale.saleDate | date}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div>
  <div class="card">
    <div class="card-body">
      <h6 class="card-title text-dark position-relative pb-2 h6">Indirect Sales</h6>
      <div class="wrap">
        <table class="table table-striped table-bordered table-sm row-border hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Venture Name</th>
              <th>Plot Number</th>
              <th>Plot Status</th>
              <th>Per {{commonService.getMeasure()}} Price (<i class="fas fa-rupee-sign mr-1"></i>)</th>
              <th>Sale Value (<i class="fas fa-rupee-sign mr-1"></i>)</th>
              <th>Amount Received (<i class="fas fa-rupee-sign mr-1"></i>)</th>
              <th>Sale Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let sale of othersSalesArray">
              <td>{{sale.salesrepName}}</td>
              <td>{{sale.ventureName}}</td>
              <td>{{sale.plotNumber}}</td>
              <td>{{sale.plotStatus | titlecase}}</td>
              <td>{{sale.perMeasurePrice?.toLocaleString('en-IN')}}</td>
              <td>{{sale.saleValue?.toLocaleString('en-IN')}}</td>
              <td>{{sale.saleAmountReceived?.toLocaleString('en-IN')}}</td>
              <td>{{sale.saleDate | date}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>