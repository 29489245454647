<div class="container-fluid mt-2 p-5 bg-white">
    <a class="position-absolute close-popup-link bg-white" (click)="sendBack()"><i class="fa fa-times"
            aria-hidden="true"></i>
    </a>
    <div class="card shadow  h-100 mx-5">

        <div class="card-body pb-2">
            <div class="row">
                <div class="col-md-3">

                    <h6 class="card-title text-dark position-relative pb-2 h6">Sale Details</h6>
                </div>
            </div>
            <div class="row px-5">
                <div class="col-md-12">
                    <div class="row my-4">
                        <div class="col-md-4">Plot Number:
                            <b class="margin">{{(plots.plotNum)}}</b>
                        </div>
                        <div class="col-md-4">Size:
                            <b class="margin">{{plots.size}}</b>
                        </div>
                        <div class="col-md-4">Plot Type:
                            <b class="margin">{{(plots.plotType)}}</b>
                        </div>
                    </div>
                    <div class="row my-4">
                        <div class="col-md-6">Customer Name:
                            <b class="margin">{{CustomerName}}</b>
                        </div>
                        <div class="col-md-6">City:
                            <b class="margin">{{customerCity}}</b>
                        </div>
                        <div class="col-md-6">Contact Number:
                            <b class="margin">{{contactNumber}}</b>
                        </div>
                        <div class="col-md-6">Introduced By:
                            <b class="margin">{{introducedBy}}</b>
                        </div>
                    </div>
                    <table class="table table-striped table-bordered table-sm row-border hover my-4">
                        <thead>
                            <tr>
                                <th>Level</th>
                                <th>Calculated</th>
                                <th>Commission Earned</th>
                                <th>Last Updated On</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let sales of viewSaleData[0].commissions">
                                <td>{{sales.salesrepCommissionLevel}}</td>
                                <td>{{sales.commissionAmount}}</td>
                                <td>{{sales.commissionEarned}}</td>
                                <td>{{sales.lastUpdatedOn | date}}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>

                <div class="p-2 col-md-12">
                    <h6 class="card-title text-dark position-relative pb-2 h6">Previous Payments</h6>
                    <table class="table table-striped table-bordered table-sm row-border hover my-4">
                        <thead>
                            <tr>
                                <th>Amount Received</th>
                                <th>Payment Mode</th>
                                <th>Payment Date</th>
                                <th>Last Updated On</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let payment of viewSaleData[0].customerPayins">
                                <td>{{payment.amountReceived}}</td>
                                <td>{{payment.paymentMode}}</td>
                                <td>{{payment.paidDate | date}}</td>
                                <td>{{payment.lastUpdatedOn | date}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>