import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { UserPrivileges } from 'src/app/constants/AppConstants';
import { RoutesConstants } from 'src/app/constants/RoutesConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-commissions',
  templateUrl: './commissions.component.html',
  styleUrls: ['./commissions.component.scss']
})
export class CommissionsComponent implements OnInit {
  Commissions: any = [];
  error: boolean;
  errorMessage: any;
  userInfo: any;
  ventureId: any;
  userPrivileges = UserPrivileges;
  constructor(private restService: RestService,
    private authService: AuthServiceService,
    private commonService: CommonServiceService,
    private router: Router,
    private route: ActivatedRoute) { }
  title = 'angulardatatables';
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();

  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    this.ventureId = this.commonService.getVenture()?.ventureId
    const path: string = this.userInfo.role.toLowerCase() + '/get-commissions';
    let data = {
      "tenantId": this.userInfo.tenantId,
      "marketerId": this.userInfo.marketerId,
      "ventureId": this.ventureId
    }
    this.restService.submitPostRequest(path, data).subscribe(response => {
      if (data != null) {
        this.Commissions = response;
        this.dtTrigger.next();
      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      })
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      searching: false
    };
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  checkComissionDetails(commission) {
    sessionStorage.setItem('activeTab', '2');
    this.commonService.setVentureTeam(commission);
    this.router.navigate(['../alldetails/'+commission.salesrepId], { relativeTo: this.route });
  }

}