import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { UserRoleConstants } from 'src/app/constants/AppConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-mypayments',
  templateUrl: './mypayments.component.html',
  styleUrls: ['./mypayments.component.scss']
})
export class MypaymentsComponent implements OnInit , OnChanges {
@Input() sales;
@Input() id;
  PaymentsArray: any = [];
  error: boolean;
  errorMessage: any;
  userInfo: any;
  data: any;
  constructor(private restService: RestService,
    private commonService: CommonServiceService,
    private authService: AuthServiceService) { }
  title = 'angulardatatables';
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  
  ngOnInit(): void {
    this.userInfo= this.authService.getUserInfo();
    
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      searching: false,
      bSort: false
    };
    if (this.sales) {
      this.getDetails();
    }
   else{
     this.onPayment();
   }
  }
ngOnChanges(){
}

onPayment(){
  let data = {
    "tenantId": this.userInfo.tenantId,
    "marketerId": this.userInfo.marketerId,
    "salesrepId": this.userInfo.salesrepId
  }
  this.restService.getmyPayments(data).subscribe(response => {
    if (data != null) {
      this.PaymentsArray = response.data
      this.dtTrigger.next()
    }
  },
    (error) => {
      this.error = true;
      this.errorMessage = error.status;
    })
}
  getDetails(){
    const path: string = (this.authService.hasRole(UserRoleConstants.SALESREP))? 'salesrep/my-payments':
    this.userInfo.role.toLowerCase() + '/salesrep/my-payments';
    if (this.id) {
      this.data = {
       "tenantId": this.userInfo.tenantId,
       "marketerId": this.userInfo.marketerId,
       "ventureId": this.commonService.getVenture()?.ventureId,
       "salesrepId": this.sales
     }
   }else{
     this.data = {
       "tenantId": this.userInfo.tenantId,
       "marketerId": this.userInfo.marketerId,
       //"ventureId": this.commonService.getVenture()?.ventureId,
       "salesrepId": this.sales
     }
   }
    this.restService.submitPostRequest(path,this.data).subscribe(response => {
      if (this.data != null) {
        this.PaymentsArray = response.data
      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      })

  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

}