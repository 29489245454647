import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common'
import Stepper from 'bs-stepper';
import { AddMarketer } from '../../../models/addmarketer';
import { AuthServiceService } from '../../../services/auth-service.service';
import { RestService } from '../../../services/rest.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-addmarketer',
  templateUrl: './addmarketer.component.html',
  styleUrls: ['./addmarketer.component.scss']
})
export class AddmarketerComponent implements OnInit {
  @ViewChild('successMsg') successMsg: ElementRef<HTMLElement>;
  private stepper: Stepper;
  permAddress: any = {};
  commAddress: any = {};
  managingDirector: any = {};
  primaryCompany: any = {};
  address2: any = false;
  addMarketer: AddMarketer = new AddMarketer();

  marketerData: any =[];
  path: string = 'tenant/marketer';
  alertSuccess: boolean;
  alertFail: boolean;
  message: any;
  closeResult: string;
  constructor(private location: Location,
    private restService: RestService,
    private authService: AuthServiceService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true
    });
  }

  onsubmit(value: any) {

    this.addMarketer.tenantId = this.authService.getUserInfo().tenantId;
    if (this.addMarketer.marketerType == 'company') {
      this.addMarketer.primaryCompany = this.primaryCompany;
      this.addMarketer.primaryCompany.tenantId = this.authService.getUserInfo().tenantId;

      this.addMarketer.managingDirector = this.managingDirector;
    } else {
      this.addMarketer.managingDirector = this.managingDirector;
    }
    this.addMarketer.primaryAddress = this.permAddress;
    if (this.address2 == true) {
      //  this.addMarketer.secondaryAddress = null;
      this.addMarketer.secondaryAddress = this.permAddress;

    } else {
      this.addMarketer.secondaryAddress = this.commAddress;
    }

    if (this.addMarketer) {
      const pathF = this.path + '/addMarketer';
      this.restService.submitPostRequest(pathF, this.addMarketer).subscribe(
        (response: any) => {
          if (response && response.data) {
            this.message = "Marketer created successfully!";
            this.marketerData.push(response.data);
          }
          this.open(value)
        }, (error: any) => {
          // TODO Error handling
          this.message = error.error.message;
          this.open(value)

        });
    }
  }

  sendBack() {
    this.modalService.dismissAll('Cross click');
    this.location.back();
  }
  next() {
    this.stepper.next();
  }

  closeAlert() {
    this.alertSuccess = false;
    this.alertFail = false;
    this.location.back();
  }

  open(content) {
    this.modalService.open(this.successMsg, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
