import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subject } from 'rxjs';
import { UserPrivileges, UserRoleConstants } from 'src/app/constants/AppConstants';
import { RoutesConstants } from 'src/app/constants/RoutesConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-plotspricing',
  templateUrl: './plotspricing.component.html',
  styleUrls: ['./plotspricing.component.scss']
})
export class PlotspricingComponent implements OnInit {

  plotPricing: any = [];
  error: boolean;
  errorMessage: any;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  userInfo: any;
  ventureId: any;
  userPrivileges = UserPrivileges;
  showHistory = false;
  plotPricingHistory: any = [];


  constructor(
    private restService: RestService,
    private router: Router,
    private authService: AuthServiceService,
    public commonService: CommonServiceService,
    private permissionsService: NgxPermissionsService
  ) { }

  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    this.ventureId = this.commonService.getVenture()?.ventureId
    if(!this.showHistory){
      this.getPlotPricing();
    }
    else{
      this.getPlotPricingHistory();
    }


    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      searching: false,
    };

  }

  getPlotPricingHistory(){
    this.showHistory = true;
    let url;
    let venture = '&ventureId='+this.ventureId;
    url = 'venzr/plotsPricingHistory?' + 'tenantId='+this.userInfo.tenantId+venture;
    this.restService.getSales(url).subscribe(response => {
      if (response != null && response != undefined) {
        this.plotPricingHistory = response;
        console.log(this.plotPricingHistory);
      }
    },
    (error) => {
      console.log(error.status);
    });
  }

  getPlotPricing(){
    let data = {
      "tenantId": this.userInfo.tenantId,
      "ventureId": this.ventureId
    }
    this.showHistory = false;
    this.restService.getplotPricing(data).subscribe(response => {
      if (data != null) {
        this.plotPricing = response
        this.dtTrigger.next()
      }
    }, (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  capitalFirst(type){
    const mySentence = type;
    return mySentence.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  }

  onClick(){
    this.router.navigate(['/'+this.commonService.getUrlSuffix(), RoutesConstants.VENTURELAYOUT, RoutesConstants.ADDPLOTPRICING]);
  }

  onEdit(price: any) {
    this.permissionsService.hasPermission(UserPrivileges.TENANT_PRIVILEGE).then(hasTenantPrivilege => {
      if (hasTenantPrivilege) {
        this.commonService.updatePlotPricing(price);
        this.router.navigate(['/'+this.commonService.getUrlSuffix(), RoutesConstants.VENTURELAYOUT, RoutesConstants.EDITPLOTPRICING]);
      }
    });
  }

}
