import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RestService } from '../../../services/rest.service';
import { Location } from '@angular/common';
import { AuthServiceService } from '../../../services/auth-service.service';
import { CommonServiceService } from '../../../services/common-service.service';

@Component({
  selector: 'app-plotsale',
  templateUrl: './plotsale.component.html',
  styleUrls: ['./plotsale.component.scss']
})
export class PlotsaleComponent implements OnInit {
  plotid: any;
  plots: any = [];
  activatedRouter: any;
  salesRepId: any;
  plotId: any;
  saleId: any;
  viewSaleData: any = [];
  error: boolean;
  errorMessage: any;
  CustomerName: string;
  customerCity: any;
  contactNumber: any;
  introducedBy: any;
  customerId: any;
  Customers: any;
  salesLevel: any = [];
  salesRep: any;
  salesrepId: any;
  userInfo: any;

  constructor(private restService: RestService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private commonService: CommonServiceService,
    private authService: AuthServiceService) {
    this.route.queryParams.subscribe((params => { this.plotId = params['plotId'], this.saleId = params['saleId'] }));
  }

  ngOnInit() {
    this.userInfo = this.authService.getUserInfo();
    this.viewSale();
    this.getPlots();
  }
  sendBack(): void {
    this.location.back();
  }

  getVentureCustomer() {
    let data = {
      "personId": this.customerId,
      "tenantId": this.userInfo.tenantId,
      "marketerId": this.userInfo.marketerId
    }
    this.restService.getVentureCustomer(data).subscribe(response => {
      if (data != null) {
        this.CustomerName = response.data.firstName + " " + response.data.lastName;
        this.customerCity = response.data.city;
        this.contactNumber = response.data.contactNumber;
        this.introducedBy = response.data.introducedBy;
      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      })
  }

  getSalesLevel() {
    let data = {
      "plotId": parseInt(this.plotId),
      "tenantId": this.userInfo.tenantId,
      "marketerId": this.userInfo.marketerId,
      "salesrepId": parseInt(this.salesrepId),
      "ventureId": this.commonService.getVenture()?.ventureId,
      "commissionLevel": 0
    }
    const path: string = 'pre-sale/get-commission';

    this.restService.submitPostRequest(path, data).subscribe(response => {
      if (response) {
        this.salesLevel = response;
      }
    }, (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });
  }

  getPlots() {
    let data = {
      "tenantId": this.userInfo.tenantId,
      "ventureId": this.commonService.getVenture().ventureId,
      "plotId": parseInt(this.plotId)
    }
    this.restService.getPlotsById(data).subscribe(response => {
      if (response.data != null) {
        this.plots = response.data;
      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      })
  }

  formulateData() {
    let sale = {
      "saleId": parseInt(this.saleId)
    }
    let plotSales = {
      //  "plotId": parseInt(this.plotId)
    }
    let commissions = [];
    let customerPayins = [];
    let getSalesDetails = {
      "tenantId": this.userInfo.tenantId,
      "ventureId": this.commonService.getVenture()?.ventureId,
      "marketerId": this.userInfo.marketerId,
      "sale": sale,
      "plotSales": plotSales,
      "commissions": commissions,
      "customerPayins": customerPayins
    }
    return getSalesDetails;
  }
  viewSale() {
    let sendObject = this.formulateData();
    this.restService.getSaleDetails(sendObject).subscribe((response: any) => {
      if (response?.data != null) {
        this.viewSaleData.push(response?.data)
        this.customerId = this.viewSaleData[0].sale.primaryCustomerId
        this.salesrepId = this.viewSaleData[0].commissions[0].salesrepId
      }
      this.getVentureCustomer();
      this.getSalesLevel();
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      })
  }

  capitalTitle(plot: any) {
    const str = plot;
    return str.toUpperCase();
  }

}