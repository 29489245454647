<div>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h6 class="modal-title" id="modal-basic-title">Release M-Sold Plot {{this.releasingMsoldPlot.plotNum}} ?</h6>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <ngb-alert [dismissible]="false" type="danger">
                <div class="d-flex align-items-baseline">
                    <i class="fa fa-exclamation-triangle"></i>
                    <h6 class="ml-2">Are you sure you want to release the m-sold plot {{this.releasingMsoldPlot.plotNum}} ? </h6>
                </div>
            </ngb-alert>
        </div>
        <div class="modal-footer">
            <button type="button" class="float-left btn btn-outline-danger" (click)="releaseMsoldPlot()">Yes, Release</button>
            <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('cancel click')">No</button>
        </div>
    </ng-template>




    <ng-container *ngIf="mSoldPlots.length > 0; else noPlots">
        <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger">
            <thead>
                <tr>
                    <th>Plot #</th>
                    <th>Status</th>
                    <th>Size ({{commonService.getMeasure()}})</th>
                    <th>East (dim)</th>
                    <th>West (dim)</th>
                    <th>North (dim)</th>
                    <th>South (dim)</th>
                    <th>Facing</th>
                    <th>Block</th>
                    <th>Type</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let plot of mSoldPlots">
                    <td>{{plot.plotNum}}</td>
                    <td>
                        <ng-template #popContent><a (click)="open(content, plot)">Release</a>
                        </ng-template>
                        <div type="button" [disabled]="popup" *ngIf="popup == false" class="btn p-0 m-0"
                            [ngbPopover]="popContent" style="font-size: 12px;">
                            {{capitalFirst(plot.plotStatus)}}
                        </div>
                        <div type="button" *ngIf="popup == true " class="btn p-0 m-0" style="font-size: 12px;">
                            {{capitalFirst(plot.plotStatus)}}
                        </div>
                    </td>
                    <td>{{plot.size.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}
                    </td>
                    <td>{{plot.eastLength.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2
                        })}}</td>
                    <td>{{plot.westLength.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2
                        })}}</td>
                    <td>{{plot.northLength.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2
                        })}}</td>
                    <td>{{plot.southLength.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2
                        })}}</td>
                    <td>{{plot.roadFacing | titlecase}}</td>
                    <td>{{plot.blockName | titlecase}}</td>
                    <td>{{plot.plotType | titlecase}}</td>
                </tr>
            </tbody>
        </table>
    </ng-container>

    <ng-template #noPlots>
        <div>
            <h5 class="text-center m-0 py-2">No M-Sold Plots</h5>
        </div>
    </ng-template>

</div>