import { Component, OnInit } from '@angular/core';
import { UserPrivileges } from 'src/app/constants/AppConstants';

@Component({
  selector: 'app-plot-blocking',
  templateUrl: './plot-blocking.component.html',
  styleUrls: ['./plot-blocking.component.scss']
})
export class PlotBlockingComponent implements OnInit {

  userPrivileges = UserPrivileges;
  selectedPlotStatus: string = 'blocked';

  constructor() { }

  ngOnInit(): void {
    this.loadPlotStatusComponent();
  }

  loadPlotStatusComponent() {
  }

}
