<div class="mt-3">
    <div class="card">
        <div class="card-body">
            <h6 class="card-title text-dark position-relative pb-2 h6">Plots</h6>
            <form #details="ngForm">
                <div class="row">
                    <div class="form-group col-md-4">
                        <label class="star">Ventures</label>
                        <select type="text" class="form-control" id="ventures" name="ventures" required
                            [(ngModel)]="ventureSelected">
                            <option *ngFor="let venture of ventures" [value]="venture.ventureId">{{venture.ventureName}}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-2 mt-4" style="font-size: medium;">
                        <button class="btn-info py-1 px-3 border rounded" (click)="venture()"
                            [disabled]=details.invalid><b>Go</b></button>
                    </div>
                </div>
            </form>
            <div class="wrap" [hidden]="show">
            <app-plots [plotinfo] = "plotinfo"></app-plots>
            </div>
        </div>
    </div>
</div>