import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-promos',
  templateUrl: './promos.component.html',
  styleUrls: ['./promos.component.scss']
})
export class PromosComponent implements OnInit {

  active = 1;
  closed = true;
  constructor() { }

  ngOnInit(): void {
  }

}
