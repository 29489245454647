<div class="container-fluid my-3">
    <form #details="ngForm" (ngSubmit)="onsubmit(details.value)">
        <div class="row mt-2" *ngIf="bankdetails == null">
            <div class="form-group col-md-6">
                <label class="">Bank Category</label>
                <input type="text" class="form-control" id="bankCategory" [(ngModel)]="account.bankCategory"
                    name="bankCategory">
            </div>
            <div class="form-group col-md-6"
                [ngClass]="{'invalid': (bankName.invalid && (bankName.dirty || bankName.touched))}">
                <label class="star">Bank Name</label>
                <input type="text" class="form-control" id="bankName" name="bankName" #bankName="ngModel" required
                    [(ngModel)]="account.bankName">
            </div>
            <div class="form-group col-md-6"
                [ngClass]="{'invalid': (ifscCode.invalid && (ifscCode.dirty || ifscCode.touched))}">
                <label class="star">IFSC Code</label>
                <input type="text" class="form-control" id="ifscCode" name="ifscCode" #ifscCode="ngModel" required
                    [(ngModel)]="account.ifscCode">
            </div>
            <div class="form-group col-md-6"
                [ngClass]="{'invalid': (accountNumber.invalid && (accountNumber.dirty || accountNumber.touched))}">
                <label class="star">Account Number</label>
                <input type="text" class="form-control" id="accountNumber" name="accountNumber" required
                    [(ngModel)]="account.accountNumber" #accountNumber="ngModel">
            </div>
            <div class="form-group col-md-6"
                [ngClass]="{'invalid': (accountName.invalid && (accountName.dirty || accountName.touched))}">
                <label class="star">Account Holder Name</label>
                <input type="text" class="form-control" id="accountName" name="accountName" #accountName="ngModel"
                    required [(ngModel)]="account.accountName">
            </div>
            <div class="form-group col-md-6"
                [ngClass]="{'invalid': (branchName.invalid && (branchName.dirty || branchName.touched))}">
                <label class="star">Branch Name</label>
                <input type="text" class="form-control" id="branchName" name="branchName" #branchName="ngModel" required
                    [(ngModel)]="account.branchName">
            </div>
            <div class="form-group col-md-6">
                <label class="">Branch Address</label>
                <input type="text" class="form-control" id="branchAddress" name="branchAddress" #branchAddress="ngModel"
                    [(ngModel)]="account.branchAddress">
            </div>
            <div class="form-group col-md-6">
                <label class="">Last Updated On</label>
                <input type="date" class="form-control" id="lastUpdatedOn" name="lastUpdatedOn" max="9999-12-31"
                    [(ngModel)]="account.lastUpdatedOn">
            </div>
            <div class="form-group col-md-6">
                <label class="">Comments</label>
                <input type="text" class="form-control" id="comments" [(ngModel)]="account.comments" name="comments"
                    #comments="ngModel">
            </div>
            <div class="col-md-12">
                <button type="submit" class=" btn btn-info" [disabled]=details.invalid>Submit</button>
            </div>
        </div>
        <div class="row mt-2" *ngIf="bankdetails != null">
            <div class="form-group col-md-6">
                <label class="">Bank Category</label>
                <input type="text" class="form-control" id="bankCategory" [(ngModel)]="bankdetails.bankCategory"
                    name="bankCategory">
            </div>
            <div class="form-group col-md-6"
                [ngClass]="{'invalid': (bankName.invalid && (bankName.dirty || bankName.touched))}">
                <label class="star">Bank Name</label>
                <input type="text" class="form-control" id="bankName" name="bankName" #bankName="ngModel" required
                    [(ngModel)]="bankdetails.bankName">
            </div>
            <div class="form-group col-md-6"
                [ngClass]="{'invalid': (ifscCode.invalid && (ifscCode.dirty || ifscCode.touched))}">
                <label class="star">IFSC Code</label>
                <input type="text" class="form-control" id="ifscCode" name="ifscCode" #ifscCode="ngModel" required
                    [(ngModel)]="bankdetails.ifscCode">
            </div>
            <div class="form-group col-md-6"
                [ngClass]="{'invalid': (accountNumber.invalid && (accountNumber.dirty || accountNumber.touched))}">
                <label class="star">Account Number</label>
                <input type="text" class="form-control" id="accountNumber" name="accountNumber" required
                    [(ngModel)]="bankdetails.accountNumber" #accountNumber="ngModel">
            </div>
            <div class="form-group col-md-6"
                [ngClass]="{'invalid': (accountName.invalid && (accountName.dirty || accountName.touched))}">
                <label class="star">Account Name</label>
                <input type="text" class="form-control" id="accountName" name="accountName" #accountName="ngModel"
                    required [(ngModel)]="bankdetails.accountName">
            </div>
            <div class="form-group col-md-6"
                [ngClass]="{'invalid': (branchName.invalid && (branchName.dirty || branchName.touched))}">
                <label class="star">Branch Name</label>
                <input type="text" class="form-control" id="branchName" name="branchName" #branchName="ngModel" required
                    [(ngModel)]="bankdetails.branchName">
            </div>
            <div class="form-group col-md-6">
                <label class="">Branch Address</label>
                <input type="text" class="form-control" id="branchAddress" name="branchAddress" #branchAddress="ngModel"
                    [(ngModel)]="bankdetails.branchAddress">
            </div>
            <div class="form-group col-md-6">
                <label class="">Last Updated On</label>
                <input type="date" class="form-control" max="9999-12-31" id="lastUpdatedOn" name="lastUpdatedOn"
                    [(ngModel)]="bankdetails.lastUpdatedOn">
            </div>
            <div class="form-group col-md-6">
                <label class="">Comments</label>
                <input type="text" class="form-control" id="comments" [(ngModel)]="bankdetails.comments" name="comments"
                    #comments="ngModel">
            </div>
            <div class="col-md-12">
                <button type="submit" class=" btn btn-info" [disabled]=details.invalid>Submit</button>
            </div>
        </div>
    </form>
</div>