import { Component, Input, OnInit } from '@angular/core';
import { UserPrivileges } from 'src/app/constants/AppConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-mycommissions',
  templateUrl: './mycommissions.component.html',
  styleUrls: ['./mycommissions.component.scss']
})
export class MycommissionsComponent implements OnInit {
  @Input() sales;
  @Input() id;
  userInfo: any;
  ventureSelected = 'all';
  ventures: any = [];

  directComm: any = [];
  indirectComm: any = [];
  error: boolean;
  errorMessage: any;
  userPrivileges = UserPrivileges;

  constructor(private authService: AuthServiceService,
    private restService: RestService,
    private commonService: CommonServiceService) { }

  ngOnInit() {
    this.userInfo = this.authService.getUserInfo();
    if(this.userInfo?.salesrepId) {
      const path: string = 'salesrep/assigned-ventures/' + this.userInfo.salesrepId;
      this.restService.submitGetRequest(path).subscribe(response => {
        this.ventures = response.data;
      });
    }
    if (this.sales) {
      this.getData();
    }
    else {
      this.onDisplay();
    }
  }

  onDisplay() { 
    let directUrl = 'generic/salesrep/commissions?salesrepId=' + this.userInfo.salesrepId + '&type=direct';
    let indirectUrl = 'generic/salesrep/commissions?salesrepId=' + this.userInfo.salesrepId + '&type=indirect';

    this.restService.getSales(directUrl).subscribe(response => {
      if (response.data != null && response.data != undefined) {
        this.directComm = response.data;
      }
    },
    (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });
    
    this.restService.getSales(indirectUrl).subscribe(response => {
      if (response.data != null && response.data != undefined) {
        this.indirectComm = response.data;
      }
    },
    (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });
  }

  getData() {
    let directUrl;
    let indirectUrl;
    if (this.id) {
      directUrl = 'generic/salesrep/commissions?salesrepId=' + this.sales + '&ventureId=' + this.commonService.getVenture()?.ventureId + '&type=direct';
      indirectUrl = 'generic/salesrep/commissions?salesrepId=' + this.sales + '&ventureId=' + this.commonService.getVenture()?.ventureId + '&type=indirect';
    } else {
      directUrl = 'generic/salesrep/commissions?salesrepId=' + this.sales + '&type=direct';
      indirectUrl = 'generic/salesrep/commissions?salesrepId=' + this.sales + '&type=indirect';
    }

    this.restService.getSales(directUrl).subscribe(response => {
      if (response.data != null && response.data != undefined) {
        this.directComm = response.data;
      }
    },
    (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });

    this.restService.getSales(indirectUrl).subscribe(response => {
      if (response.data != null && response.data != undefined) {
        this.indirectComm = response.data;
      }
    },
    (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });
  }

  getCommissionsByVenture() {
    if(this.ventureSelected == 'all') {
      this.onDisplay();
    } else {
      let directUrl = 'generic/salesrep/commissions?salesrepId=' + this.userInfo.salesrepId + '&ventureId=' + this.ventureSelected + '&type=direct';
      let indirectUrl = 'generic/salesrep/commissions?salesrepId=' + this.userInfo.salesrepId + '&ventureId=' + this.ventureSelected + '&type=indirect';

      this.restService.getSales(directUrl).subscribe(response => {
        if (response.data != null && response.data != undefined) {
          this.directComm = response.data;
        }
      },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      });
      
      this.restService.getSales(indirectUrl).subscribe(response => {
        if (response.data != null && response.data != undefined) {
          this.indirectComm = response.data;
        }
      },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      });
    }
  }
}
