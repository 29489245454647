import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import * as pluginDataLabels from 'ng2-charts';
import { ActivatedRoute } from '@angular/router';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-marketer-sale',
  templateUrl: './marketer-sale.component.html',
  styleUrls: ['./marketer-sale.component.scss']
})
export class MarketerSaleComponent implements OnInit {
  Performance: any = [];
  reportsData: any = [];
  saleData: any = [];
  report: any;
  constructor(private restService: RestService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.restService.getPerformance().subscribe(data => this.Performance = data);
    this.restService.getReports().subscribe(data => {
      const response = data;
      this.reportsData = response;
      this.saleData = response.revenue;
      this.pieChartData = [
        this.saleData.map(a => a.revenuePercentage),
      ],
        this.saleData.map(a => {
          this.pieChartLabels.push(a.ventureName);
        })
    });
  }
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{}], yAxes: [{
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabels: Label[] = ['Schon', 'VasaviSandal', 'Sweet Home', 'villas 1'];
  public barChartType: ChartType = 'horizontalBar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];
  public barChartColors: Color[] = [
    {
      backgroundColor: ['rgba(70, 86, 197, 0.8)', 'rgba(70, 86, 197, 0.8)', 'rgba(70, 86, 197, 0.8)', 'rgba(70, 86, 197, 0.8)']
    }
  ]
  public barChartData: ChartDataSets[]
    = [
      { data: [2000000, 1800000, 3200000, 1100000, 100000, 5000000], label: 'Sold value' },
      { data: [1000000, 800000, 2200000, 200000], label: 'Revenue' }

    ];

  public lineChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{}], yAxes: [{
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public lineChartLabels: Label[] =
    [' Schon', ' Vasavi Sandal County ', ' Sweet Home', ' villas 1'];
  public lineChartType: ChartType = 'line';
  public lineChartLegend = true;
  public lineChartPlugins = [pluginDataLabels];
  public lineChartData: ChartDataSets[]
    = [{ data: [20, 10, 30, 10, 0], label: 'Sold plots' }];
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'right',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  public pieChartLabels: string[] = []
  //= [' Schon', ' Vasavi Sandal County ', ' Sweet Home', ' villas 1'];
  public pieChartData: number[]
  // = [27 , 17, 23, 33];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
    },
  ];
}

