import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-venture',
  templateUrl: './venture.component.html',
  styleUrls: ['./venture.component.scss']
})
export class VentureComponent implements OnInit {
  ventureData: any[] = [];
  submit = false;
  dtTrigger: Subject<any> = new Subject<any>();
  title = 'angulardatatables';
  dtOptions: any = {};
  path: string = 'tenant/venture';
  constructor(
    private commonService: CommonServiceService,
    private restService: RestService
  ) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      searching: false
    };
    this.restService.submitGetRequest(this.path + '/getVenturesList').subscribe(
      (response: any) => {
        if (response && response.data) {
          this.ventureData = response.data;
        }
        this.dtTrigger.next();
      }, (error: any) => {
        // TODO Error handling
      });
  }
  editDetails(venture) {
    // TODO
  }
  onCheck() {
    this.submit = true;
  }
  onShow() {
    this.submit = false;
  }
  onSubmit(ventureObj: any) {
    if (ventureObj) {
      const pathF = this.path + '/addVenture';
      this.restService.submitPostRequest(pathF, ventureObj).subscribe(
        (response: any) => {
          if (response && response.data) {
            this.ventureData.push(response.data);
            this.dtTrigger.next();
          }
          this.onShow();
        }, (error: any) => {
          // TODO Error handling
        });
    }
  }

}
