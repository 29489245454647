<ng-template #content let-modal>
  <div class="modal-header">
    <h4>Oops!</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body ">
    <p>Something went wrong, Please try again after sometime!!</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger " (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>

<button [hidden]="true" class="btn  d-none btn-outline-primary" (click)="open(content)">Launch demo modal</button>

