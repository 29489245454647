<div class="pr-4">
    <div class="input-group mt-4 mb-3 search-block">
        <input class="form-control py-0 rounded-pill mr-1 pr-5" type="search" value="" id=""
            [ngClass]="{'border-0': catalogueSearch}">
        <span class="input-group-append">
            <button class="btn rounded-pill border-0 ml-n5 pt-1" type="button">
                <small><i class="fas fa-search"
                        [ngClass]="{'text-white': catalogueSearch, 'text-muted': userSearch}"></i></small>
            </button>
        </span>
    </div>
</div>
<div [ngClass]="{' bg-secondary ml-n3 mr-n3 mb-n4 px-2 py-2': catalogueSearch}">
    <div class="d-flex" [ngClass]="{'mb-2': catalogueSearch}" *ngFor="let search of data">
        <a [routerLink]="statsRoute">
            <p class="mx-2 mb-0 font-weight-bold"
                [ngClass]="{'badge': catalogueSearch, 'badge-light': catalogueSearch}">
                {{search.name}} </p>
        </a>
        <p class="mx-2 mb-0" [ngClass]="{'text-white font-weight-bold': catalogueSearch}">{{search.value}}</p>
    </div>
</div>