import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { data } from 'jquery';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subject } from 'rxjs';
import { UserPrivileges } from 'src/app/constants/AppConstants';
import { RoutesConstants } from 'src/app/constants/RoutesConstants';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-marketer-list',
  templateUrl: './marketer-list.component.html',
  styleUrls: ['./marketer-list.component.scss']
})
export class MarketerListComponent implements OnInit {

  dtOptions: any = {};
  marketerList: any = [];
  error: boolean;
  errorMessage: any;
  userPrivileges = UserPrivileges;
  path: string = 'tenant/marketer';
  dtTrigger: Subject<any> = new Subject<any>();
  constructor(
    private router: Router,
    public commonService: CommonServiceService,
    private route: ActivatedRoute,
    private restService: RestService,
    private permissionsService: NgxPermissionsService
  ) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      searching: false,
    };
    const rePath = this.path + '/getAllMarketers';
    this.restService.submitGetRequest(rePath).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.marketerList = response.data;
          this.dtTrigger.next();
        }
      }, (error: any) => {
        // TODO Error Handling
      });

  }

   capitalFirst(type){
    const mySentence = type;
    return mySentence.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  }

  editDetails(row: any) {
    this.permissionsService.hasPermission(UserPrivileges.TENANT_PRIVILEGE).then(hasTenantPrivilege => {
      if (hasTenantPrivilege) {
        this.router.navigate(['../editmarketer', row.marketerId], { relativeTo: this.route });
      }
    });
  }

  onCheck() {
    this.router.navigate(['/'+this.commonService.getUrlSuffix(), RoutesConstants.TENANT_LAYOUT, RoutesConstants.ADDMARKETER]);
  }

}
