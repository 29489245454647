import { Location, formatDate } from '@angular/common';
import _ from "underscore";
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoutesConstants } from 'src/app/constants/RoutesConstants';

export class PromoItem {
constructor(public area:Number, public paymentPercentage: Number, public prize: String){
}

}

@Component({
  selector: 'app-addpromo',
  templateUrl: './addpromo.component.html',
  styleUrls: ['./addpromo.component.scss']
})
export class AddpromoComponent implements OnInit {

  error: boolean;
  errorMessage: any;
  selectedItems = [];
  ventures: any = [];
  newPromoWithDetails: any;
  dropdownSettings:IDropdownSettings={};
  promoItems:PromoItem[] = [{area :null, paymentPercentage:null, prize:''},{area :null, paymentPercentage:null, prize:''}];
  details = {
    name: null,
    ventures: [],
    startDate: null,
    endDate: null
  };
  closeResult: string;
  currentDate;
  constructor(private location: Location,
    private modalService: NgbModal,
    private restService: RestService,
    private authService: AuthServiceService,
    public commonService: CommonServiceService,
    private router: Router) { }

  ngOnInit(): void {


    this.dropdownSettings = {
      idField: 'ventureId',
      textField: 'displayName',
    };

    let path = 'marketer/venture/getVenturesList';
    this.restService.submitGetRequest(path).subscribe(response => {
      this.ventures = response.data;
    });
    const cValue = new Date();
    this.currentDate =formatDate(cValue, 'yyyy-MM-dd', 'en-US');

  }

  onItemSelect(item: any) {
    this.selectedItems.push(item.ventureId)
  }

  onItemDeSelect(item: any) {
    this.selectedItems = this.selectedItems.filter(ele => {
      return ele != item.ventureId
    });
  }

  onSelectAll(items: any) {
    this.selectedItems = [];
    this.ventures.forEach(element => {
      this.selectedItems.push(element.ventureId)
    });
  }

  onUnSelectAll() {
    this.selectedItems = []
  }

  onSubmit(content) {
    let addIncentiveProgramWithDetails: any = {}; 
    const userInfo = this.authService.getUserInfo();
  
    addIncentiveProgramWithDetails.incentiveProgram = {
      name: this.details.name,
      tenantId: userInfo.tenantId,
      marketerId: userInfo.marketerId,
      venturesList:this.selectedItems.map(ventureId => ventureId.toString()).join(','),
      incentiveProgramStatus: 'active',
      startDate: this.details.startDate,
      endDate: this.details.endDate
    };
  
    addIncentiveProgramWithDetails.incentiveDetailList = this.promoItems;

    this.restService.addIncentiveProgramWithDetails(addIncentiveProgramWithDetails).subscribe(
      (response: any) => {
        if (response != null) {
          this.newPromoWithDetails = response.data;
          this.open(content);
        }
      }, (error) => {
        this.error = true;
        this.errorMessage = error.status;
      })

  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  sendBack() {
    this.location.back();
  }

  addRecord() {
    console.log("this.promoItems"+JSON.stringify(this.promoItems));
    let newObj = {area :null, paymentPercentage:null, prize:''};
    this.promoItems.push(newObj);
    console.log("this.promoItems"+JSON.stringify(this.promoItems));
  }

  removeRecord(promoItem: any){
    this.promoItems.splice(this.promoItems.indexOf(promoItem),1);
  }

  isFormValid(): boolean {

    const inputs = [
      this.details.name,
      this.details.startDate,
      this.details.endDate,
    ];
    for (const input of inputs) {
      if (!input) {
        return false;
      } else {
        if (this.selectedItems.length === 0 || (this.details.startDate > this.details.endDate || this.currentDate > this.details.endDate)) {
          return false;
        } else {
          for (const promoItem of this.promoItems) {
            if (!promoItem.area || !promoItem.paymentPercentage || !promoItem.prize) {
              return false;
            } else {
              return true;
            }
          }
        }
      }
    }

  }
  

}