import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-editmarketer',
  templateUrl: './editmarketer.component.html',
  styleUrls: ['./editmarketer.component.scss']
})
export class EditmarketerComponent implements OnInit {

  active = 1;
  marketerId: any;


  constructor(private location: Location,
    private route: ActivatedRoute,) {
    this.route.params.subscribe(params => {
      let id = params['marketerid'];
      this.marketerId = id;
    });
  }

  ngOnInit(): void {
  }

  sendBack(): void {
    this.location.back()
  }
}
