import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserPrivileges, UserRoleConstants } from 'src/app/constants/AppConstants';
import { RoutesConstants } from 'src/app/constants/RoutesConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-venturedashboard',
  templateUrl: './venturedashboard.component.html',
  styleUrls: ['./venturedashboard.component.scss']
})
export class VenturedashboardComponent implements OnInit {
  dashpathstatus = 85;
  classTab: any = [];
  setAdmin: boolean;
  performance: any = [];
  ventureId: string;
  isshow = false;
  marketerSale: any = [];
  marketerCommission: any = [];
  marketerPlot: any = [];
  ventureName: any;
  userPrivileges = UserPrivileges;
  userInfo: any;


  constructor(
    private restService: RestService,
    public commService: CommonServiceService,
    public authService: AuthServiceService,
    public themeService: ThemeService,
    public router: Router,
    private activatedRouter: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    this.ventureName = this.commService.getVenture()?.displayName;
    const path: string = (this.authService.hasRole(UserRoleConstants.MARKETER)) ? 'marketer/marketerVentureDashboard' :
      'tenant/tenantVentureDashboard';
    const userInfo: any = this.authService.getUserInfo();
    const data: any = {
      tenantId: userInfo.tenantId,
      marketerId: userInfo.marketerId,
      ventureId: this.commService.getVenture()?.ventureId,
    };
    this.restService.submitPostRequest(path, data).subscribe(response => {
      this.performance = response.data;
      this.marketerSale.push(this.performance.marketerSaleInfo);
      this.marketerCommission.push(this.performance.marketerCommission);
      this.marketerPlot.push(this.performance.marketerPlot);
    });
    this.setAdmin = this.authService.hasRole(UserRoleConstants.MARKETER);
    this.readVentureName();
  }
  readVentureName() {
    this.activatedRouter.queryParams.subscribe(
      (params: any) => {
        this.ventureId = params['venture']
      });
  }
  expand(id: any) {
    //   this.isshow = !this.isshow
    this.router.navigate(['/'+this.commService.getUrlSuffix(),RoutesConstants.VENTURELAYOUT, RoutesConstants.VENTURESALES], {
      queryParams: { 'currentMonth': id.currentMonth }
    });
  }
  expandsale(id: any) {
    //   this.isshow = !this.isshow
    this.router.navigate(['/'+this.commService.getUrlSuffix(),RoutesConstants.VENTURELAYOUT, RoutesConstants.VENTURESALES], {
      queryParams: { 'ventureId': id.ventureId }
    });
  }
  expandcommission(id: any) {
    //   this.isshow = !this.isshow
    this.router.navigate(['/'+this.commService.getUrlSuffix(),RoutesConstants.VENTURELAYOUT, RoutesConstants.COMMISSIONDETAILS], {
      queryParams: { 'ventureId': id.ventureId }
    });
  }
  onDisply(row) {
    this.router.navigate(['../alldetails', row.salesrepId], { relativeTo: this.activatedRouter });
  }

}