import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DATA } from 'src/app/data';
import { Commissions, CustomerPayins, NewSale, PlotSales, Sale } from 'src/app/models/new-sale';
import { customerDetails, PlotsDetails, salesRepDetails } from 'src/app/models/plots-details';
import { RestService } from 'src/app/services/rest.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserKeys } from 'src/app/models/user.model';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { RoutesConstants } from 'src/app/constants/RoutesConstants';
import { CommonServiceService } from 'src/app/services/common-service.service';

@Component({
  selector: 'app-addnewsale',
  templateUrl: './addnewsale.component.html',
  styleUrls: ['./addnewsale.component.scss']
})
export class AddnewsaleComponent implements OnInit {

  data: any[] = DATA;
  salesLevel: any = [];
  radioSelected: string;
  Customers: any = [];
  myDetails: any = [];
  personalDetails: any = { personType: 'customer', themeOption: 'theme1' };
  permAddress: any = {};
  error: boolean;
  errorMessage: any;
  result: any;
  isShown: boolean = false
  submitNew = false;
  genders: any = ['Male', 'Female', 'Others', 'Prefer not to say'];
  newCustomerData: any = [];
  salesRep: any = [];
  keyword = 'name';
  plots: any = [];
  keyword1 = 'plotNum';
  plotDetails: PlotsDetails;
  salesRepDetails: salesRepDetails
  ModelObject: any = {};
  keyword2 = 'name';
  submitExisting = false;
  customerDetails: customerDetails;
  newSale: NewSale;
  newSalesData: any = [];
  payments: any = ['Online', 'Wire Transfer', 'Cash', 'Cheque'];
  //salestatus: any = ['Sold', 'Registered'];
  payment: any = {};
  closeResult: string;
  userInfo: any;
  ventureId: any;
  plotPricing: any[] = [];
  price: any = [];
  isDisabled: boolean;
  adjustedCommission: number = null;
  irregularPlotReason: boolean = false;

  constructor(
    private restService: RestService,
    private router: Router,
    private modalService: NgbModal,
    private authService: AuthServiceService,
    public commonService: CommonServiceService
  ) {
    this.userInfo = this.authService.getUserInfo();
    this.radioSelected = "existing";
    this.getCustomerData();
    this.getPlots();
    this.getsalesRep();
  }

  ngOnInit() {
    let initiateSalePlot = this.commonService.getInitiateSalePlot();
    if (initiateSalePlot) {
      this.plotDetails = initiateSalePlot;
      this.commonService.setInitiateSalePlot(null);
    }
  }

  multiply(price) {
    this.ModelObject.saleValue = Math.round(price * this.ModelObject.size).toLocaleString('en-IN');
  }

  display() {
    this.getPlotPricing();

    this.ModelObject.plotNum = this.plotDetails.plotNum;
    this.ModelObject.size = this.plotDetails.size;
    this.ModelObject.plotType = this.plotDetails.plotType;
    this.ModelObject.blockName = this.plotDetails.blockName;
    this.ModelObject.eastSideContent = this.plotDetails.eastSideContent;
    this.ModelObject.westSideContent = this.plotDetails.westSideContent;
    this.ModelObject.northSideContent = this.plotDetails.northSideContent;
    this.ModelObject.southSideContent = this.plotDetails.southSideContent;
    this.ModelObject.roadFacing = this.plotDetails.roadFacing;
    this.isShown = !this.isShown;
    this.getSalesLevel();
  }

  goBack() {
    this.router.navigate(['/'+this.commonService.getUrlSuffix(), RoutesConstants.VENTURELAYOUT, RoutesConstants.VENTURESALES]);
  }

  onSubmit(customer: any) {
    this.personalDetails.tenantId = this.userInfo.tenantId;
    this.personalDetails.marketerId = this.userInfo.marketerId;
    this.personalDetails.primaryAddress = this.permAddress;
    this.personalDetails.secondaryAddress = null;
    this.personalDetails.introducedPlot = this.plotDetails.plotId;
    let introducedSalesrep = this.salesLevel[0];
    this.personalDetails.introducedBy = introducedSalesrep.salesrepId;
    this.personalDetails.introducedFor = this.commonService.getVenture()?.ventureId;
    this.restService.addCustomerData(this.personalDetails).subscribe(response => {
      if (this.data != null) {
        this.newCustomerData.push(response.data);
      }
      this.getCustomerData();
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      })
    this.submitNew = true;
  }

  getCustomerData() {
    const user = <UserKeys>this.authService.getUserInfo();

    const data = {
      ventureId: this.commonService.getVenture()?.ventureId,
      marketerId: user.marketerId,
      tenantId: user.tenantId
    }
    this.restService.getVentureCustomers(data).subscribe(response => {
      if (response != null) {
        this.Customers = response.customers;
      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      })
  }
  getPlotPricing() {
    this.userInfo = this.authService.getUserInfo();
    this.ventureId = this.commonService.getVenture()?.ventureId;

    let data = {
      "tenantId": this.userInfo.tenantId,
      //  "marketerId": this.userInfo.marketerId,
      "ventureId": this.ventureId
    }
    this.restService.getplotPricing(data).subscribe(response => {
      if (response) {
        this.plotPricing = response;
        this.price = this.plotPricing.filter(a => a.plotType == this.plotDetails.plotType);
        this.multiply(this.price[0].maxSellingPrice)
        this.ModelObject.agreedPrice = this.price[0].maxSellingPrice;
        this.ModelObject.perMeasurePriceForPercentageCommission = this.price[0].maxSellingPrice;
        this.ModelObject.saleValueForPercentageCommission = Math.round(this.ModelObject.perMeasurePriceForPercentageCommission * this.ModelObject.size).toLocaleString('en-IN');
      }
    }, (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });
  }

  getSalesLevel() {
    const user = <UserKeys>this.authService.getUserInfo();
    user.ventureId = this.commonService.getVenture()?.ventureId;

    let data = {
      "plotId": this.plotDetails ? this.plotDetails['plotId'] : '',
      "tenantId": user.tenantId,
      "marketerId": user.marketerId,
      "salesrepId": this.salesRepDetails ? this.salesRepDetails['salesrepId'] : '',
      "ventureId": user.ventureId,
    };
    const path: string = 'pre-sale/get-commission';

    this.restService.submitPostRequest(path, data).subscribe(response => {
      if (response) {
        this.salesLevel = response;
        this.salesLevel.forEach(comRec => {
          if(comRec.commissionType === 'perMeasure') {
            comRec.calculatedCommission = Math.round(comRec.perMeasureCommission * this.ModelObject.size);
          } else {            
              comRec.calculatedCommission = Math.round(this.ModelObject.saleValue ? Math.round(((comRec.percentageCommission/100) * parseFloat(this.ModelObject.saleValue.replace(/,/g, '')))) : 0);
          }
          comRec.adjustedCommission = comRec.calculatedCommission;
        });
      }
    }, (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });
  }

  calculateSalePrice(agreedprice) {
    this.multiply(agreedprice);
  }

  calculatePercentageCommissions(perMeasurePriceForPercentageCommission){
    this.ModelObject.saleValueForPercentageCommission = Math.round(perMeasurePriceForPercentageCommission * this.ModelObject.size).toLocaleString('en-IN');
    this.salesLevel.forEach(comRec => {
      if (comRec.commissionType === 'percentage') {
        comRec.calculatedCommission = Math.round(this.ModelObject.saleValueForPercentageCommission ? Math.round(((comRec.percentageCommission/100) * parseFloat(this.ModelObject.saleValueForPercentageCommission.replace(/,/g, '')))) : 0);
      }
      comRec.adjustedCommission = comRec.calculatedCommission;
    });
  }

  getPlots() {
    const user = <UserKeys>this.authService.getUserInfo();
    user.ventureId = this.commonService.getVenture()?.ventureId;

    let url = 'plot/get-sale-permitted-plots?ventureId=' + user.ventureId;

    this.restService.getSalePermittedPlots(url).subscribe(response => {
      if (response != null && response.data != null) {
        this.plots = response.data;
      }
    }, (error) => {
      this.error = true;
      this.errorMessage = error.status;
    })

  }

  getsalesRep() {
    const user = this.authService.getUserInfo();
    let data = {
      'tenantId': user.tenantId,
      'marketerId': user.marketerId,
      'ventureId': this.commonService.getVenture()?.ventureId
    }
    const path: string = 'venture-team/get-venture-team';
    this.restService.submitPostRequest(path, data).subscribe(response => {
      if (response && response.data && response.data.teams) {
        this.salesRep = response.data.teams.filter(a => a.name != null);
      }
    }, (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });
  }

  formulateData() {
    let sale: Sale = {
      "salesrepId": this.salesRepDetails['salesrepId'],
      "saleValue": parseInt(this.ModelObject.saleValue.replace(/,/g, '')),
      "saleAmountReceived": Math.round(this.payment.amount),
      "numberOfPlots": 1,
      "saleStatus": 'Sold',
      "primaryCustomerId": this.ModelObject.customer.customerId,
      "lastUpdatedBy": this.userInfo.personId,
      "lastUpdatedOn": this.payment.paidDate,
      "saleDate": this.payment.paidDate,
    }
    let plotsales: PlotSales = {
      "plotId": this.plotDetails['plotId'],
      "salesrepId": this.salesRepDetails['salesrepId'],
      "plotOwnerId": this.commonService.getVenture()?.ventureId,
      "saleValue": parseInt(this.ModelObject.saleValue.replace(/,/g, '')),
      "saleAmountReceived": Math.round(this.payment.amount),
      "primaryCustomerId": this.ModelObject.customer.customerId,
      "registrationNum": this.payment.registrationNum,
      "documentLink": "http://aws.doc.pdf",
      "registrationDate": this.payment.registrationDate,
      "perMeasurePrice": Math.round(this.ModelObject.agreedPrice),
      "lastUpdatedBy": this.userInfo.personId,
      "lastUpdatedOn": this.payment.paidDate,
      
    }

    let commissions: Commissions[] = this.salesLevel;
    commissions.forEach(comRec => {
      comRec.salesrepCommissionLevel = comRec.commissionLevel;      
      comRec.commissionAmount = comRec.adjustedCommission ? Math.round(comRec.adjustedCommission) : Math.round(comRec.calculatedCommission);
      comRec.adjustedCommission = null;
      comRec.calculatedCommission = null;
    });
    let customerpayins: CustomerPayins[] = [{
      "plotId": this.plotDetails['plotId'],
      "amountReceived": Math.round(this.payment.amount),
      "paidFor": this.ModelObject.payforCustomer,
      "paidBy": this.ModelObject.paybyCustomer,
      "paymentMode": this.payment.paymentMode,
      "comments": this.payment.comments,
      "lastUpdatedBy": this.userInfo.personId,
      "paidDate": this.payment.paidDate
    }];

    if(this.irregularPlotReason) {
      plotsales.comments = "The plot is irregular, customer opted out of irregular area!"
    }

    let object: NewSale = {
      "tenantId": this.authService.getUserInfo().tenantId,
      "ventureId": this.commonService.getVenture()?.ventureId,
      "marketerId": this.authService.getUserInfo().marketerId,
      "sale": sale,
      "plotSales": plotsales,
      "commissions": commissions,
      "customerPayins": customerpayins
    }
    return object;
  }

  checkIrregularPlotSize(mymodal, irregularPlotContent) {
    if(this.ModelObject.agreedPrice !== this.price[0].maxSellingPrice){
      this.open(irregularPlotContent);
    } else {
      this.confirmSale(mymodal);
    }
  }

  confirmSale(content) {
    this.modalService.dismissAll();
    let sendObject: NewSale
    sendObject = this.formulateData();
    this.restService.addNewSale(sendObject).subscribe(response => {
      if (response.message === "Request processed successfully") {
        this.newSalesData.push(response.data);
        this.open(content);
      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      });
  }

  onExisting(e) {
    this.ModelObject.payFor = e.name;
    this.ModelObject.payforCustomer = e.customerId;
    this.ModelObject.payBy = e.name;
    this.ModelObject.paybyCustomer = e.customerId;
    this.submitExisting = true;
  }

  capitalTitle(plot) {
    const str = plot;
    return str.toUpperCase();
  }

  // For further reference this function displays the list of salesreps in the list
  // public receivedEvent(s): void {
  //   let name = s.target.value;
  //   let list = this.salesRep.filter(x => x.name === name)[0];
  //   this.ModelObject.salesrepId = list.salesrepId;
  // }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  isNumber(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  validateData() {
    if (!this.ModelObject.agreedPrice || !this.payment.amount || !this.payment.paymentMode || !this.payment.paidDate || !this.ModelObject.payFor || !this.ModelObject.payBy) {     
      return true;
    }
  }

  checkForMortgageAlert(releaseContent){

    if (this.plotDetails.plotStatus === 'mortgage') {
      this.modalService.open(releaseContent, { ariaLabelledBy: 'modal-basic-title' }).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    }

    else{
      this.display();
    }

  }

  proceedToMortgagePlotSale(){
    this.modalService.dismissAll();
    this.display();
  }


}
