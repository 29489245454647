<div class=" my-3">
    <div class="card">
        <a class="position-absolute close-popup-link bg-white" (click)="sendBack()"><i class="fa fa-times"
                aria-hidden="true"></i>
        </a>
        <div class="card-body">
            <h6 class="card-title text-dark position-relative pb-2 ">New Promo</h6>
                <div class="row mt-2">

                    <div class="form-group col-md-4">
                        <label class="star">Promo Name</label>
                        <input type="text" class="form-control" id="name" required name="name" [(ngModel)]="details.name">
                    </div>

                    <div class="form-group col-md-4">
                        <label class="star">Promo Ventures</label>
                        <ng-multiselect-dropdown required name="ventures" [placeholder]="'Select'" [data]="ventures"
                            [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelect($event)"
                            (onDeSelectAll)="onUnSelectAll()" [(ngModel)]="details.ventures">
                        </ng-multiselect-dropdown>
                    </div>

                    <div class="form-group col-md-4">&nbsp;</div>

                    <div class="form-group col-md-4"
                        [ngClass]="{'invalid': (startDate.invalid && (startDate.dirty || startDate.touched))}">
                        <label class="star">Promo Start Date</label>
                        <input type="date" required class="form-control" id="startDate" required [(ngModel)]="details.startDate" name="startDate" #startDate="ngModel">
                    </div>
                    <div class="form-group col-md-4">
                        <label class="star">Promo End Date</label>
                        <input type="date" required class="form-control" name="endDate" id="endDate" [(ngModel)]="details.endDate"
                            #endDate="ngModel">
                        <div class="text-danger" *ngIf="endDate.dirty && (endDate.value < startDate.value)">End date
                            should be after start date</div>
                        <div class="text-danger" *ngIf="endDate.dirty && (endDate.value < currentDate)">End date
                            should be after current date</div>
                    </div>

                </div>

                <ng-template #mymodal let-modal>
                    <div class="modal-header">
                        <h4 class="modal-title" id="modal-basic-title">Success</h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');sendBack()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Promo was Successfully Added!
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-dark" (click)="sendBack()" (click)="modal.close('Save click')">Ok</button>
                    </div>
                </ng-template>

                <div>

                    <hr> 

                    <h6 class="card-title text-dark position-relative pb-2 ">Promo Items</h6>

                    <button type="button"  class="btn " (click)="addRecord()" ><small><i
                        class="fas fa-plus "></i></small></button>

                    <br>
                    <br>

                    <section class="container-fluid mt--6">

                        <div class="row" *ngFor="let promoItem of promoItems;">

                            <div class="form-group col-md-3">
                                <label class="star">Qualifying Area ({{commonService.getMeasure()}})</label>
                                <input type="number" class="form-control" id="area" name="area" min="1" [(ngModel)]="promoItem.area" required>
                            </div>

                            <div class="form-group col-md-3">
                                <label class="star">Required Payment Percentage</label>
                                <input type="number" class="form-control" id="paymentPercentage"
                                    name="paymentPercentage" min="1" [(ngModel)]="promoItem.paymentPercentage" required>
                            </div>

                            <div class="form-group col-md-3">
                                <label class="star">Reward</label>
                                <input type="text" class="form-control" id="prize" name="prize" [(ngModel)]="promoItem.prize" required> 
                            </div>
                            <button type="button" class="btn " (click)="removeRecord(promoItem)" >
                                <small>
                                    <i class="fas fa-minus"></i>
                                </small>
                            </button>

                        </div>

                    </section>

                </div>

                <button type="submit" class="btn btn-info" (click)="onSubmit(mymodal)" #submitButton [disabled]="!isFormValid()">Submit</button>

        </div>
    </div>
</div>