<div class="container-fluid">

    <div class="row py-3" [hidden]="submit">
        <div class="col-md-4">
            <div class="card ">
                <div class="card-body" *ngFor="let personalDetails of (person ? person : [])">
                    <div class="d-flex flex-column align-items-center text-center">
                        <img src="assets/images/user.png" alt="Admin" class="rounded-circle" width="150">
                        <div class="mt-3">
                            <h4>{{personalDetails.firstName}} {{personalDetails.lastName}}</h4>
                            <p class="text-secondary mb-1">{{personalDetails.personType}}</p>
                            <div class="row mt-4">
                                <a class="p-2" href="https://www.facebook.com/" target="_blank">
                                    <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-twitter mr-2 icon-inline text-info">
                                            <path
                                                d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z">
                                            </path>
                                        </svg></h6>
                                </a>
                                <a class="p-2" href="https://www.instagram.com/" target="_blank">
                                    <p class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-instagram mr-2 icon-inline text-danger">
                                            <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                                            <path d="M16 11.37A4 4 0 1 1 12.63 8  4 4 0 0 1 16 11.37z"></path>
                                            <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                                        </svg></p>
                                </a>
                                <a class="p-2" href="https://www.facebook.com/VasaviSandalCounty" target="_blank">
                                    <h6 class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-facebook mr-2 icon-inline text-primary">
                                            <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z">
                                            </path>
                                        </svg></h6>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <h6 class=" text-dark position-relative pb-2">
                        Customize Theme
                    </h6>
                    <div class="py-3 bg-white text-center">
                        <h6 class="text-dark font-weight-bold">Themes</h6>
                        <nav ngbNav #nav="ngbNav"
                            class="nav-tabs border-0 d-flex justify-content-center align-items-center">
                            <ng-container ngbNavItem>
                                <a ngbNavLink class="p-1" (click)="themeColorChange('primary')">
                                    <i class="fas fa-circle fa-2x  text-primary"></i>
                                </a>
                            </ng-container>

                            <ng-container ngbNavItem>
                                <a ngbNavLink class="p-1" (click)="themeColorChange('danger')">
                                    <i class="fas fa-circle fa-2x  text-danger"></i>
                                </a>
                            </ng-container>

                            <ng-container ngbNavItem>
                                <a ngbNavLink class="p-1" (click)="themeColorChange('success')">
                                    <i class="fas fa-circle fa-2x  text-success"></i>
                                </a>
                            </ng-container>

                            <ng-container ngbNavItem>
                                <a ngbNavLink class="p-1" (click)="themeColorChange('info')">
                                    <i class="fas fa-circle fa-2x  text-info"></i>
                                </a>
                            </ng-container>

                            <ng-container ngbNavItem>
                                <a ngbNavLink class="p-1" (click)="themeColorChange('dark')">
                                    <i class="fas fa-circle fa-2x  text-dark"></i>
                                </a>
                            </ng-container>

                        </nav>

                        <div class="dropdown-divider"></div>


                        <ul class="list-inline">
                            <li class=" d-md-block d-lg-block d-none">
                                <h6 class="text-dark font-weight-bold">Header</h6>
                            </li>
                            <li class="list-inline-item d-md-block d-lg-block d-none">
                                <a class="p-1 d-flex justify-content-center align-content-centers">
                                    <label class="font-weight-bold text-dark">Left</label>
                                    <label class="switch mx-2" for="checkbox">
                                        <input type="checkbox" id="checkbox" [ngModel]="checked"
                                            (change)="horizontalHeader($event)" />
                                        <div class="slider round bg-light"></div>
                                    </label>
                                    <label class="font-weight-bold text-dark">Top</label>
                                </a>

                            </li>
                            <li class=" d-md-block d-lg-block d-none">
                                <!--  <div class="dropdown-divider"></div>-->
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="card pb-2 pt-3">
                <div class="row m-2">
                    <div (click)="onSubmit()" class="col-md-12 text-right">
                        <a class="text-primary pl-5 ml-2" style="font-size: large;"><i class="far fa-edit"></i></a>
                    </div>
                    <div class="col-md-6 border-right border-bottom">
                        <div class=" h-100">
                            <div class="card-body">
                                <div class="d-flex">
                                    <h6 class="card-title text-dark position-relative pb-2 h6 ">Personal Details</h6>

                                </div>
                                <div class=" row border-boxing" *ngFor="let personalDetails of (person ? person : [])"
                                    style="font-size:14px ;">
                                    <div class="col-md-6">First Name: </div>
                                    <div class="col-md-6"><b>{{personalDetails.firstName}}</b></div>
                                    <div class="col-md-6">Last Name: </div>
                                    <div class="col-md-6"><b>{{personalDetails.lastName}}</b></div>
                                    <div class="col-md-6">DOB: </div>
                                    <div class="col-md-6"><b>{{personalDetails.dob}}</b></div>
                                    <div class="col-md-6">Gender: </div>
                                    <div class="col-md-6"><b>{{personalDetails.gender}}</b></div>
                                    <div class="col-md-6">Blood Group: </div>
                                    <div class="col-md-6"><b>{{personalDetails.bloodGroup}}</b></div>
                                    <div class="col-md-6">Nationality: </div>
                                    <div class="col-md-6"><b>{{personalDetails.nationality}}</b></div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 border-left border-bottom">
                        <div class=" h-100">
                            <div class="card-body p-3">
                                <h6 class="card-title text-dark position-relative pb-2 h6">Contact Details</h6>
                                <div class="row border-boxing" *ngFor="let personalDetails of (person ? person : [])"
                                    style="font-size:13px ;">
                                    <div class="col-md-6">Contact: </div>
                                    <div class="col-md-6"><b>{{personalDetails.contactNumber}}</b></div>
                                    <div class="col-md-6">Alternate Contact: </div>
                                    <div class="col-md-6"><b>{{personalDetails.alternateContact}}</b></div>
                                    <div class="col-md-6">Email: </div>
                                    <div class="col-md-6"><b>{{personalDetails.email}}</b></div>
                                    <div class="col-md-6">Alternate Email: </div>
                                    <div class="col-md-6"><b>{{personalDetails.alternateEmail}}</b></div>
                                    <div class="col-md-6">Facebook: </div>
                                    <div class="col-md-6"><b>{{personalDetails.facebookId}}</b></div>
                                    <div class="col-md-6">Twitter: </div>
                                    <div class="col-md-6"><b>{{personalDetails.twitter}}</b></div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 border-right">
                        <div class="h-100" *ngFor="let personalDetails of address">
                            <div class=" mt-3 ml-0" *ngIf="personalDetails.sequenceNum == 1">
                                <div class="card-body p-3">
                                    <h6 class="card-title text-dark position-relative pb-2 h6">Permanent Address</h6>
                                    <div class="row border-boxing" style="font-size:14px ;">
                                        <div class="col-md-6">Address Line1: </div>
                                        <div class="col-md-6"><b>{{personalDetails.addressLine1}}</b></div>
                                        <div class="col-md-6">Address Line2: </div>
                                        <div class="col-md-6"><b>{{personalDetails.addressLine2}}</b></div>
                                        <div class="col-md-6">Landmark: </div>
                                        <div class="col-md-6"><b>{{personalDetails.landmark}}</b></div>
                                        <div class="col-md-6">City: </div>
                                        <div class="col-md-6"><b>{{personalDetails.city}}</b></div>
                                        <div class="col-md-6">State: </div>
                                        <div class="col-md-6"><b>{{personalDetails.state}}</b></div>
                                        <div class="col-md-6">Pin Code: </div>
                                        <div class="col-md-6"><b>{{personalDetails.pin}}</b></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class=" mt-3">
                            <div class="card-body">
                                <h6 class="card-title text-dark position-relative pb-2 h6">Bank Details</h6>
                                <div class="border-boxing" *ngFor="let personalDetails of (person ? person : [])"
                                    style="font-size:13px ;">
                                    <!-- <p>Contact Number: {{personalDetails.contactNumber}}</p>
                        <p>Alternate Contact: {{personalDetails.alternateContact}}</p>
                        <p>Email Address: {{personalDetails.email}}</p>
                        <p>Alternate Email: {{personalDetails.alternateEmail}}</p>
                        <p>Twitter: {{personalDetails.facebookId}}</p>
                        <p>Facebook: {{personalDetails.facebookId}}</p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [hidden]="!submit">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <h6 class="card-title text-dark position-relative pb-2 h6">Update SalesRep</h6>
                    </div>
                    <div class="col-md-6 text-right">
                        <button class="btn btn-sm btn-info" (click)="onShow()">Show Team</button>
                    </div>
                </div>
                <app-editprofile *ngIf="submit" [sales]="salesrepId"></app-editprofile>
            </div>
        </div>
    </div>

</div>