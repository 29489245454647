<div class="d-flex" id="wrapper" [ngClass]="themeService.status ? '' : 'toggled'">
    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- /#sidebar-wrapper -->
    <!-- Page Content -->
    <div id="page-content-wrapper">
        <div class="menu-toggle-block w-100 bg-dark d-flex align-items-center" [ngClass]="'color-' + themeService.themeColor">
            <span class="d-md-none d-lg-none d-block w-25">
                <span class="d-flex justify-content-center align-items-center">
                    <img src="assets/images/venzr-logo.png" alt="logo" class="img-fluid w-75" *ngIf="themeService.status">
                    <img src="assets/images/venzr-mini-logo.png" alt="logo" class="img-fluid w-75" *ngIf="!themeService.status">
                </span>               
            </span>
            <span (click)="menuToggle()" class="d-inline-block bg-white pb-1 position-fixed menu-toggle ml-md-n5 mt-1">
                <span class="menu-icon" role="presentation">
                    <svg class="d-md-block d-lg-block d-none" width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation">
                        <path
                            d="M13.706 9.698a.988.988 0 0 0 0-1.407 1.01 1.01 0 0 0-1.419 0l-2.965 2.94a1.09 1.09 0 0 0 0 1.548l2.955 2.93a1.01 1.01 0 0 0 1.42 0 .988.988 0 0 0 0-1.407l-2.318-2.297 2.327-2.307z"
                            fill="currentColor" fill-rule="evenodd"></path>
                    </svg>                    
                </span>
                <svg class="d-md-none d-lg-none d-block" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="#f9f9f9" stroke="#f9f9f9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <line x1="17" y1="10" x2="3" y2="10"></line>
                    <line x1="21" y1="6" x2="3" y2="6"></line>
                    <line x1="21" y1="14" x2="3" y2="14"></line>
                    <line x1="17" y1="18" x2="3" y2="18"></line>
                </svg>    
            </span>
        </div>
       

        <div class="container-fluid">
            <router-outlet></router-outlet>          
        </div>
    </div>
</div>