import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-addplot',
  templateUrl: './addplot.component.html',
  styleUrls: ['./addplot.component.scss']
})
export class AddplotComponent implements OnInit {

  newPlot: any;
  error: boolean;
  errorMessage: any;
  roadfacing: any = [ 'East', 'West','South','North','South East','South West','North East','North West'];
  plottype: any =[];

  constructor(
    private location: Location,
    private restService: RestService,
    private authService: AuthServiceService,
    private commonService: CommonServiceService
  ) { }

  ngOnInit() { 
    let data = {
      "tenantId" : this.authService.getUserInfo().tenantId,
      "ventureId" : this.commonService.getVenture()?.ventureId,
    }
    const path : string = '/plot/get-plot-types'
    this.restService.submitPostRequest(path,data).subscribe(response =>{
     this.plottype = response.data;
    });
  }

  onSubmit(addPlot: any) {
    const userInfo = this.authService.getUserInfo();

    addPlot.tenantId = userInfo.tenantId;
    addPlot.ventureId = this.commonService.getVenture()?.ventureId;
    this.restService.addPlots(addPlot).subscribe(
      (response: any) => {
        if (response != null) {
          this.newPlot = response.data;
        }
      }, (error) => {
        this.error = true;
        this.errorMessage = error.status;
      })
    this.location.back()
  }

  sendBack() {
    this.location.back()
  }

}
