<div class="container-fluid my-3">
    <div class="card mx-3">

        <div class="card-body mx-3 ">
            <form #newSalesrep="ngForm">
                <div id="personaldetails" class="content">
                    <div class="row my-3">
                        <h6 class="card-title text-dark position-relative pb-2 h6 col-md-12 ">Personal Details</h6>

                        <div class="form-group col-md-3" [ngClass]="{'invalid': firstName.invalid && (firstName.dirty || firstName.touched)}">
                            <label class="star">First Name </label>
                            <input type="text" class="form-control" id="firstName" required [(ngModel)]="salesRepDetails.firstName" name="firstName" #firstName="ngModel">
                        </div>

                        <div class="form-group col-md-3" [ngClass]="{'invalid': lastName.invalid && (lastName.dirty || lastName.touched)}">
                            <label class="star">Last Name</label>
                            <input type="text" class="form-control" id="lastName" required [(ngModel)]="salesRepDetails.lastName" name="lastName" #lastName="ngModel">
                        </div>

                        <div class="form-group col-md-3" [ngClass]="{'invalid': dob.invalid && (dob.dirty || dob.touched)}">
                            <label class="star">DOB</label>
                            <input type="date" class="form-control" id="dob" required [(ngModel)]="salesRepDetails.dob" name="dob" #dob="ngModel">
                        </div>

                        <div class="form-group col-md-3">
                            <label for="gender">Gender</label>
                            <select class="form-control" id="gender" required [(ngModel)]="salesRepDetails.gender" name="gender" #gender="ngModel">
                                <option *ngFor="let gen of genders" [value]="gen">
                                    {{gen}}
                                </option>
                            </select>
                        </div>

                        <div class="form-group col-md-3" [ngClass]="{'invalid': joiningDate.invalid && (joiningDate.dirty || joiningDate.touched)}">
                            <label >Joining Date</label>
                            <input type="date" class="form-control" id="joiningDate" [(ngModel)]="salesRepDetails.joiningDate" name="joiningDate" #joiningDate="ngModel">
                        </div>

                        <div class="form-group col-md-3" [ngClass]="{'invalid': bloodGroup.invalid && (bloodGroup.dirty || bloodGroup.touched)}">
                            <label class="star">Blood Group</label>
                            <input type="text" class="form-control" id="bloodGroup" [(ngModel)]="salesRepDetails.bloodGroup" name="bloodGroup" #bloodGroup="ngModel">
                        </div>

                        <div class="form-group col-md-3" [ngClass]="{'invalid': nationality.invalid && (nationality.dirty || nationality.touched)}">
                            <label class="star">Nationality</label>
                            <input type="text" class="form-control" id="nationality" required [(ngModel)]="salesRepDetails.nationality" name="nationality" #nationality="ngModel">
                        </div>

                        <div class="form-group col-md-3" [ngClass]="{'invalid': panNum.invalid && (panNum.dirty || panNum.touched)}">
                            <label >PAN Number</label>
                            <input type="text" class="form-control" id="panNum" [(ngModel)]="salesRepDetails.panNum" name="panNum" #panNum="ngModel">
                        </div>

                        <div class="form-group col-md-3" [ngClass]="{'invalid': aadharNum.invalid && (aadharNum.dirty || aadharNum.touched)}">
                            <label >Aadhaar</label>
                            <input type="text" class="form-control" id="aadharNum" [(ngModel)]="salesRepDetails.aadharNum" name="aadharNum" #aadharNum="ngModel">
                        </div>

                        <div class="form-group col-md-3" [ngClass]="{'invalid': contactNumber.invalid && (contactNumber.dirty || contactNumber.touched)}">
                            <label class="star">Contact Number</label>
                            <input type="text" class="form-control" id="contactNumber" required [(ngModel)]="salesRepDetails.contactNumber" name="contactNumber" #contactNumber="ngModel">
                        </div>

                        <div class="form-group col-md-3" [ngClass]="{'invalid': alternateContact.invalid && (alternateContact.dirty || alternateContact.touched)}">
                            <label >Alternate Number</label>
                            <input type="number" class="form-control" id="alternateContact" [(ngModel)]="salesRepDetails.alternateContact" name="alternateContact" #alternateContact="ngModel">
                        </div>

                        <div class="form-group col-md-3" [ngClass]="{'invalid': email.invalid && (email.dirty || email.touched)}">
                            <label >Email</label>
                            <input type="text" class="form-control" id="email" [(ngModel)]="salesRepDetails.email" name="email" #email="ngModel">
                        </div>

                        <div class="form-group col-md-3">
                            <label for="alternateContact">Alternate Email</label>
                            <input type="text" class="form-control" id="alternateEmail" [(ngModel)]="salesRepDetails.alternateEmail" name="alternateEmail" #alternateEmail="ngModel">
                        </div>
                    </div>
                    <div class="row my-3">
                        <h6 class="card-title text-dark position-relative pb-2 h6 col-md-12 ">Primary Address</h6>

                        <div class="form-group col-md-3" [ngClass]="{'invalid': addressLine1.invalid && (addressLine1.dirty || addressLine1.touched)}">
                            <label class="star">Address Line 1</label>
                            <input type="text" class="form-control" id="addressLine1" required [(ngModel)]="permAddress.addressLine1" name="addressLine1" #addressLine1="ngModel">
                        </div>

                        <div class="form-group col-md-3" [ngClass]="{'invalid': addressLine2.invalid && (addressLine2.dirty || addressLine2.touched)}">
                            <label class="star">Address Line 2</label>
                            <input type="text" class="form-control" id="addressLine2" required [(ngModel)]="permAddress.addressLine2" name="addressLine2" #addressLine2="ngModel">
                        </div>

                        <div class="form-group col-md-3" [ngClass]="{'invalid': landmark.invalid && (landmark.dirty || landmark.touched)}">
                            <label class="star">LandMark</label>
                            <input type="text" class="form-control" id="landmark" required [(ngModel)]="permAddress.landmark" name="landmark" #landmark="ngModel">
                        </div>

                        <div class="form-group col-md-3" [ngClass]="{'invalid': city.invalid && (city.dirty || city.touched)}">
                            <label class="star">City</label>
                            <input type="text" class="form-control" id="city" required [(ngModel)]="permAddress.city" name="city" #city="ngModel">
                        </div>

                        <div class="form-group col-md-3" [ngClass]="{'invalid': mandal.invalid && (mandal.dirty || mandal.touched)}">
                            <label class="star">Mandal</label>
                            <input type="text" class="form-control" id="mandal" required [(ngModel)]="permAddress.mandal" name="mandal" #mandal="ngModel">
                        </div>

                        <div class="form-group col-md-3" [ngClass]="{'invalid': district.invalid && (district.dirty || district.touched)}">
                            <label class="star">District</label>
                            <input type="text" class="form-control" id="district" required [(ngModel)]="permAddress.district" name="district" #district="ngModel">
                        </div>

                        <div class="form-group col-md-3" [ngClass]="{'invalid': state.invalid && (state.dirty || state.touched)}">
                            <label class="star">State</label>
                            <input type="text" class="form-control" id="state" required [(ngModel)]="permAddress.state" name="state" #state="ngModel">
                        </div>

                        <div class="form-group col-md-3" [ngClass]="{'invalid': pin.invalid && (pin.dirty || pin.touched)}">
                            <label class="star">PIN</label>
                            <input type="number" class="form-control" id="pin" required [(ngModel)]="permAddress.pin" name="pin" #pin="ngModel">
                        </div>

                        <div class="form-group col-md-3" [ngClass]="{'invalid': country.invalid && (country.dirty || country.touched)}">
                            <label class="star">Country</label>
                            <input type="text" class="form-control" id="country" required [(ngModel)]="permAddress.country" name="country" #country="ngModel">
                        </div>
                    </div>
                    <div class="row">
                        <h6 class="card-title text-dark position-relative pb-2 h6 col-md-12 ">Secondary Address</h6>

                        <div class="form-group col-md-3">
                            <label class="star">Address Line 1</label>
                            <input type="text" class="form-control" id="addressLine11" [(ngModel)]="commAddress.addressLine1" name="addressLine11">
                        </div>

                        <div class="form-group col-md-3">
                            <label class="star">Address Line 2</label>
                            <input type="text" class="form-control" id="addressLine21" [(ngModel)]="commAddress.addressLine2" name="addressLine21">
                        </div>

                        <div class="form-group col-md-3">
                            <label class="star">LandMark</label>
                            <input type="text" class="form-control" id="landmark1" [(ngModel)]="commAddress.landmark" name="landmark1">
                        </div>

                        <div class="form-group col-md-3">
                            <label class="star">City</label>
                            <input type="text" class="form-control" id="city1" [(ngModel)]="commAddress.city" name="city1">
                        </div>

                        <div class="form-group col-md-3">
                            <label class="star">Mandal</label>
                            <input type="text" class="form-control" id="mandal1" [(ngModel)]="commAddress.mandal" name="mandal">
                        </div>

                        <div class="form-group col-md-3">
                            <label class="star">District</label>
                            <input type="text" class="form-control" id="district1" [(ngModel)]="commAddress.district" name="district1">
                        </div>

                        <div class="form-group col-md-3">
                            <label class="star">State</label>
                            <input type="text" class="form-control" id="state1" [(ngModel)]="commAddress.state" name="state1">
                        </div>

                        <div class="form-group col-md-3">
                            <label class="star">PIN</label>
                            <input type="number" class="form-control" id="pin1" [(ngModel)]="commAddress.pin" name="pin1">
                        </div>

                        <div class="form-group col-md-3">
                            <label class="star">Country</label>
                            <input type="text" class="form-control" id="country1" [(ngModel)]="commAddress.country" name="country1">
                        </div>
                    </div>
                    <button type="submit" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE]" class="btn btn-sm btn-info" (click)="onSubmit(newSalesrep)" [disabled]="!newSalesrep.form.valid">Submit</button>
                </div>
            </form>
            <ng-template #successMsg let-modal>
                <div class="card">
                    <div class="modal-header">
                        <button type="button" class="close" aria-label="Close" (click)="sendBack()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="card-body modal-body">
                        <div class="text-center">
                            <p class="close-content">{{message}}</p>
                        </div>
                        <div class="row">
                            <div class="col-md-12 d-flex justify-content-center">
                                <div class="submit-btn px-2">
                                    <button type="submit" class="btn btn-info" (click)="sendBack()"
                                        #submitButton>Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>           
        </div>
    </div>
</div>