import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { RestService } from 'src/app/services/rest.service';
import { RoutesConstants } from "src/app/constants/RoutesConstants";
import { CommonServiceService } from 'src/app/services/common-service.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-venture-list',
  templateUrl: './venture-list.component.html',
  styleUrls: ['./venture-list.component.scss']
})
export class VentureListComponent implements OnInit {

  @Output()
  ventureEvent: EventEmitter<any> = new EventEmitter<any>();
  ventureList: any = [];
  path: string = '';
  ventureSelected: any;

  constructor(
    private restService: RestService,
    private authService: AuthServiceService,
    private router: Router,
    private commonService: CommonServiceService
  ) {
    const roleName: string = this.authService.getUserInfo().role.toLowerCase();
    this.path = roleName + '/venture/getVenturesList';
  }

  isVentureLaunched(ventureId) {
    return ventureId === this.commonService.getVenture()?.ventureId;
  }

  ngOnInit() {
   
    this.restService.submitGetRequest(this.path).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.ventureList = response.data;

          if (this.ventureList.length == 1) {
            this.onVentureChange(this.ventureList[0]);
            this.launchDashboard();
          }
        }
      }, (error: any) => {
        // TODO Error handling
      });
  }

  onVentureChange(venture: any){
    this.ventureEvent.emit(venture);    
    this.ventureSelected = venture;
    this.commonService.setVenture(venture);
  }
  launchDashboard(){
    this.router.navigate(['/'+this.commonService.getUrlSuffix(), RoutesConstants.VENTURELAYOUT, RoutesConstants.VENTUREDASHBOARD],
      {queryParams:{'venture': this.ventureSelected.ventureId}});
  }
}

