import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { RoutesConstants } from 'src/app/constants/RoutesConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-salesSummary',
  templateUrl: './salesSummary.component.html',
  styleUrls: ['./salesSummary.component.scss']
})
export class SalesSummaryComponent implements OnInit {
  salesData: any = [];
  error: boolean;
  errorMessage: any;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  salesRepId: number = 0;

  constructor(
    private router: Router,
    public commonService: CommonServiceService,
    private restService: RestService,
    private authService: AuthServiceService
  ) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
    };
    this.salesRepId = this.authService.getUserInfo().salesrepId;
    this.restService.submitGetRequest('venzr/salesrep-report?salesrepId=' + this.salesRepId).subscribe(data => {
      if (data instanceof Array) { this.salesData = data; }
      else
        this.salesData.push(data)
      this.dtTrigger.next();
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      });
  }
  editDetails() {
    this.router.navigate([RoutesConstants.TENANT_LAYOUT, RoutesConstants.SALES]);
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

}
