<div class=" my-3" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.MARKETER_BASIC_PRIVILEGE]">
    <div class="card">
        <div class="card-body">
            <div [hidden]="submit" class="row">
                <div class="col-md-6 mb-0">
                    <h6 class="card-title text-dark position-relative pb-2 h6">Sales Team</h6>
                </div>
                <div (click)="onSubmit()" class="col-md-6 text-right mb-0" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE]">
                    <button class="btn btn-sm btn-info">Add Sales Rep</button>
                </div>
            </div>
            <div [hidden]="submit" class="wrap table-responsive">
                <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th>User ID</th>
                            <th>Name</th>
                            <th>Contact Number</th>
                            <th>Email</th>
                            <th>Lead</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let member of teamMembers.teams" (click)="onDisply(member)">
                            <td>{{member.salesrepId}}</td>
                            <td class="pointer">{{member.name}}</td>
                            <td>{{member.contactNumber}}</td>
                            <td>{{member.email}}</td>
                            <td>{{member.leadName}}
                                <span *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE]" class="text-primary float-right" [ngClass]="'pointer'" (click)="showChangeReportingManager($event, member.salesrepId,member.name,member.leadName)">Change Lead <i class="fa fa-external-link"></i></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div [hidden]="!submit">
                <a class="position-absolute close-popup-link bg-white" (click)="onShow()"><i class="fa fa-times" aria-hidden="true"></i></a>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <h6 class="card-title text-dark position-relative pb-2 h6">New Member</h6>
                        </div>
                        <div class="col-md-6 text-right">
                        </div>
                    </div>
                    <app-attach-salesrep *ngIf="submit" (displayTeam) = "onShow()" [isMarketerTeams]="isMarketerTeams" [ventureTeam]="teamMembers">
                    </app-attach-salesrep>
                </div>
            </div>
        </div>
    </div>

    <ng-template #changeLead let-modal>
        <div class="modal-header">
            <h6 class="card-title text-dark position-relative pb-2 ">Change Lead</h6>
            <button type="button"  class="position-absolute close-popup-link bg-white change-lead"  (click)="modal.dismiss('Cross click')"><i class="fa fa-times"
                aria-hidden="true"></i></button>
        </div>
        <div class="modal-body">
            <form>
                <div class="container-fluid">
                    <div class="row mt-2">
                    <div class="form-group col-md-6">
                        <label for="salesrepName">Salesrep</label>
                        <input type="text" class="form-control" id="salesrepName" name="salesrepName" readonly [(ngModel)]="salesrep.salesrepName">
                      </div>
                      <div class="form-group col-md-6">
                        <label >Lead</label>
                        <select class="form-control" name="reportingManagerId" [(ngModel)]="reportingManagerId" >
                            <option value=''>Select</option>
                            <option *ngIf="reportingManagerExist" value=0>No Lead</option>
                            <option *ngFor="let reportingManager of reportingManagers" [value]="reportingManager.salesrepId">{{reportingManager.salesrepName}}</option>
                          </select>
                      </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-info" (click)="modal.close('Save click');changeReportingManager()" [disabled]="!reportingManagerId" >Update</button>
        </div>
    </ng-template>
    

</div>