import { Component, OnInit, AfterContentInit } from '@angular/core';
import { AuthServiceService } from '../../services/auth-service.service';
import { ThemeService } from '../../services/theme.service';
import { animate, state, style, transition, trigger } from '@angular/animations';


@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(150%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class MainLayoutComponent implements OnInit, AfterContentInit {

  settingsMenuState: string = 'out';
  headerhrizontal: boolean = false;
  constructor(public themeService: ThemeService, public authService: AuthServiceService) {

   }

  ngOnInit() { }

  sideBarEvent() {
    this.headerhrizontal = !this.headerhrizontal;
  }

  //  settings
  toggleSettingsMenu() {
    this.settingsMenuState = this.settingsMenuState === 'out' ? 'in' : 'out';
  }

  ngAfterContentInit(){
    this.themeService.getColorCookie();    
  }

  ngAfterViewInit(){
    if(this.themeService.getThemeCookie()){
      this.themeService.toggleField()
    }
  }

  menuToggle() {
    this.themeService.menuToggle();
  }

}

