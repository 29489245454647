
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { UserPrivileges, UserRoleConstants } from 'src/app/constants/AppConstants';
import { RoutesConstants } from 'src/app/constants/RoutesConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  mobileNavToggle: () => void;
  @Input() showVenture: boolean;
  menuitems: any;
  ventureMenus: any;
  isShown: boolean = false
  error: boolean;
  errorMessage: any;
  ventureName: any = '';
  public isCollapsed = false;

  constructor(
    private restService: RestService,
    public themeService: ThemeService,
    public authService: AuthServiceService,
    private router: Router,
    private commonService: CommonServiceService,
    private permissionsService: NgxPermissionsService
  ) {
    const venture: any = this.commonService.getVenture();
    if (venture && venture.ventureId) {
      this.showVenture = true;
    }
  }
  showMenuLayout() {
    if (this.authService.hasRole(UserRoleConstants.TENANT)) {
      this.restService.getsideBarMenu().subscribe(
        (data: any) => {
          this.menuitems = data
          this.permissionsService.hasPermission(UserPrivileges.TENANT_BASIC_PRIVILEGE).then(hasTenantBasicPrivilege => {
            if (hasTenantBasicPrivilege) {
              this.menuitems = data.filter(item => item.id !== 'User');
            }
          });
        }, (error) => {
          this.error = true;
          this.errorMessage = error.status;
        });
    } else if (this.authService.hasRole(UserRoleConstants.MARKETER)) {
      this.restService.getsideBarMenuMarketer().subscribe(
        (data: any) => {
          this.menuitems = data
          this.permissionsService.hasPermission(UserPrivileges.MARKETER_BASIC_PRIVILEGE).then(hasMarketerBasicPrivilege => {
            if (hasMarketerBasicPrivilege) {
              this.menuitems = data.filter(item => item.id !== 'User');
            }
          });
        }, (error) => {
          this.error = true;
          this.errorMessage = error.status;
        });
    }
    else if (this.authService.hasRole(UserRoleConstants.ADMIN)) {
      this.restService.getsideBarMenuAdmin().subscribe(
        (data: any) => {
          this.menuitems = data;
        }, (error) => {
          this.error = true;
          this.errorMessage = error.status;
        });
    } else if (this.authService.hasRole(UserRoleConstants.SALESREP)) {
      this.restService.getsalesRepMenu().subscribe(
        (data: any) => {
          this.menuitems = data
        }, (error) => {
          this.error = true;
          this.errorMessage = error.status;
        });
    }
    if (this.showVenture) {
      this.ventureName = this.commonService.getVenture()?.displayName;
      this.restService.getventureRepMenu().subscribe(
        (data: any) => {
          this.ventureMenus = data;
        }, (error) => {
          this.error = true;
          this.errorMessage = error.status;
        });
    }
  }
  ngOnInit() {
    this.showMenuLayout();
    if (window.screen.width < 768) { // 768px portrait
      this.mobileNavToggle = () => {
        this.themeService.menuToggle();
      }
    }
    else {
      this.mobileNavToggle = () => {
        return true
      }
    }
  }
  /**
   * Navigates to Venture Dashboard
   */
  dashboard() {
    this.router.navigate(['/'+this.commonService.getUrlSuffix(), RoutesConstants.VENTURELAYOUT, RoutesConstants.VENTUREDASHBOARD])
  }
}
