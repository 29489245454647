import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-mortgage-marking',
  templateUrl: './mortgage-marking.component.html',
  styleUrls: ['./mortgage-marking.component.scss']
})
export class MortgageMarkingComponent implements OnInit {

  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  availablePlots: any = [];
  error: boolean;
  errorMessage: any;
  selectedPlots: any[] = []

  constructor(
    private location: Location,
    public commonService: CommonServiceService,
    private restService: RestService,
    private authService: AuthServiceService
  ) { }

  ngOnInit(): void {

    this.fetchAvailablePlots();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      searching: false,
    };

  }

  sendBack(){
    this.location.back()
  }

  fetchAvailablePlots(){

    const user = this.authService.getUserInfo();
    let data = {
      'tenantId': user.tenantId,
      'ventureId': this.commonService.getVenture()?.ventureId
    }

    this.restService.getAvailablePlots(data).subscribe(response => {
      if (response != null && response.data != null) {
        this.availablePlots = response.data;
      }
    }, (error) => {
      this.error = true;
      this.errorMessage = error.status;
    })

  }

  capitalFirst(type) {
    const mySentence = type;
    return mySentence.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  }


  selectPlot(plot: any) {
    if (plot.selected) {
        this.selectedPlots.push(plot);
    } else {
        this.selectedPlots = this.selectedPlots.filter((selectedPlot: any) => selectedPlot !== plot);
    }
}

markPlotsToMortgage() {

  const plotIdList = this.selectedPlots.map(plot => plot.plotId);
    
  const requestData = {
      plotIdList: plotIdList
  };

  this.restService.markPlotsToMortgage(requestData).subscribe(
      (response) => {
          this.location.back()
      },
      (error) => {
          this.error = true;
          this.errorMessage = error.status;
      }
  );

}


}
