<div>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h6 class="modal-title" id="modal-basic-title">Unblock Plot {{this.unBlockingPlot.plotNum}} ?</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <ngb-alert [dismissible]="false" type="danger">
        <div class="d-flex align-items-baseline">
          <i class="fa fa-exclamation-triangle"></i>
          <h6 class="ml-2">Are you sure you want to unblock the plot {{this.unBlockingPlot.plotNum}} ? </h6>
        </div>
      </ngb-alert>
    </div>
    <div class="modal-footer">
      <button type="button" class="float-left btn btn-outline-danger" (click)="unBlockPlot()">Yes, Unblock</button>
      <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('cancel click')">No</button>
    </div>
  </ng-template>

  <ng-container *ngIf="blockedPlots.length > 0; else noPlots">
    <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions"
      [dtTrigger]="dtTrigger">
      <thead>
        <tr>
          <th style="min-width: 90px;">Plot #</th>
          <th style="min-width: 100px;">Status</th>
          <th style="min-width: 145px;">Blocked By</th>
          <th style="min-width: 145px;">Blocked For</th>
          <th style="min-width: 130px;">Blocked Date</th>
          <th>Comments</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let plot of blockedPlots">
          <td>{{plot.plotNum}}</td>
          <td>
            <ng-template #popContent><a (click)="open(content, plot)">Unblock</a>
            </ng-template>
            <div type="button" [disabled]="popup" *ngIf="popup == false" class="btn p-0 m-0" [ngbPopover]="popContent"
              style="font-size: 12px;">
              {{capitalFirst(plot.plotStatus)}}
            </div>
            <div type="button" *ngIf="popup == true " class="btn p-0 m-0" style="font-size: 12px;">
              {{capitalFirst(plot.plotStatus)}}
            </div>
          </td>
          <td>{{plot.blockedByPersonName | titlecase}}</td>
          <td>{{plot.blockedForPersonName | titlecase}}</td>
          <td>{{plot.blockingDate | date}}</td>
          <td>{{plot.blockingComments}}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <ng-template #noPlots>
    <div>
      <h5 class="text-center m-0 py-2">No Blocked Plots</h5>
    </div>
  </ng-template>

</div>