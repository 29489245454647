import { EventEmitter, Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  checked = false;
  themeVar: any;
  themeLayout: any;
  themLayoutTop: any;
  showGraphsBlock: boolean = false;
  showGraphsContent: any;
  constructor() { }
  value: string;
  name: any;
  vals: any;
  allcookies: any;
  cookiearray: any;
  public navMode: EventEmitter<boolean> = new EventEmitter();
  public color: EventEmitter<any> = new EventEmitter();
  public menuShow: EventEmitter<boolean> = new EventEmitter();

  //setting the theme colors
  // primary: any;
  // public danger: any;
  public themeColor: string;
  status = true;

  toggleSidebarNew() {
    const bgHeader = document.querySelectorAll(".bg-header");
    for (var i = 0; i < bgHeader.length; i++) {
      bgHeader[i].classList.toggle('d-none');
    }
    const bgHeaderColorBlack = document.getElementsByClassName("sidebar-bg")[0];
    bgHeaderColorBlack.classList.toggle("d-none");
  }

  setActiveTheme(themeVar) {
    this.themeColor = themeVar;
    return this.themeColor;
  }

  setActiveLayout(themeLayout) {
    this.themeLayout = themeLayout;
    return this.themeLayout;
  }

  toggleField() {
    const wrapper = document.getElementById("wrapper");
    wrapper.classList.toggle("horizontal-header");
    const horizontalSubMenu = document.getElementsByClassName("horizontal-sub-menu-content")[0];
    horizontalSubMenu.classList.toggle("d-flex");
    const sideBarFlush = document.getElementsByClassName("sub-menu-wrapper")[0];
    sideBarFlush.classList.toggle("d-flex");
    const sideBarScroll = document.getElementsByClassName("sidebar-scroll")[0];
    sideBarScroll.classList.toggle("d-flex");
  }

  getThemeCookie() {
    this.allcookies = document.cookie;
    this.cookiearray = this.allcookies.split(';');
    for (var i = 0; i < this.cookiearray.length; i++) {
      this.name = this.cookiearray[i].split('=')[0];
      this.vals = this.cookiearray[i].split('=')[1];
      if(this.vals == "horizontal"){
        this.themLayoutTop = this.vals;
        return true;
      }
      if(this.vals == "left"){
        this.themLayoutTop = this.vals;
        return false;
      }
    }
  }

  getColorCookie() {
    this.allcookies = document.cookie;
    this.cookiearray = this.allcookies.split(';');
    for (var i = 0; i < this.cookiearray.length; i++) {
      this.name = this.cookiearray[i].split('=')[0];
      this.vals = this.cookiearray[i].split('=')[1];

      //for theme colors
      if (this.vals == "primary") {
        this.themeColor = "primary";
      }
      if (this.vals == "danger") {
        this.themeColor = this.vals;
      }
      if (this.vals == "success") {
        this.themeColor = this.vals;
      }
      if (this.vals == "info") {
        this.themeColor = this.vals;
      }
      if (this.vals == "dark") {
        this.themeColor = this.vals;
      }
      
  }
  }

  menuToggle() {
    this.status = !this.status;
  }

  showGraphs(){
    this.showGraphsBlock = !this.showGraphsBlock;
    this.showGraphsContent = !this.showGraphsContent;
  }
}
