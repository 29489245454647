<div class="container-fluid full-page-wrapper login-block bg-white">
    <div class="row h-100">
      <div class="col-lg-7 login-bg d-lg-flex flex-row d-none pattern  p-0" >
        <img [src]="this.commonService.getBannerUrl()" alt="logo" class="img-fluid">
      </div>
      <div class="col-lg-5 d-flex align-items-center login-bg-color  justify-content-center">
        <div class="login-bg-color_img"></div>
        <div class="row  text-left p-3 w-100">
          <div class="col-md-8 offset-md-2">
            <div class="brand-logo text-center mb-3 ">
              <img [src]="this.commonService.getLogoUrl()" alt="logo" class="img-fluid">
            </div>
            <form [formGroup]="forgotPasswordForm" class="pt-3 my-3" *ngIf="!isResetForm && !isPasswordChanged" (ngSubmit)="onSubmitForgotPassword(forgotPasswordForm.valid)">
              <div *ngIf="error" class="text-danger text-center">
                <div>{{errorMessage}}</div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text border-0">
                      <i class="far fa-user"></i>
                    </span>
                  </div>
                  <input type="text" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                    formControlName="username" class="form-control border-left-0" id="exampleInputEmail"
                    placeholder="Username">
                  <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required">Username is required</div>
                  </div>
                </div>
              </div>
              <div style="line-height: 0.5;"><a [routerLink]= "['/'+commonService.getUrlSuffix() +'/login']" class="text-white">Back to login</a></div>
              <div class="my-3">
                <button type="submit" value="Tenant" class="btn btn-sm btn-primary w-100">Reset password</button>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    &nbsp;
                    <br>
                    <br>
                  </div>
                </div>
              </div>
            </form>
            <form [formGroup]="resetForm" class="pt-3 my-3" *ngIf="isResetForm && !isPasswordChanged" (ngSubmit)="onSubmit(resetForm.valid)">
                <div *ngIf="error" class="text-danger text-center">
                  <div>{{errorMessage}}</div>
                </div>
                <div class="form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text border-0">
                        <i class="far fa-user"></i>
                      </span>
                    </div>
                    <input type="text" [ngClass]="{ 'is-invalid': resetSubmitted && r.otp.errors }"
                      formControlName="otp" class="form-control border-left-0" id="exampleInputEmail"
                      placeholder="OTP">
                    <div *ngIf="resetSubmitted && r.otp.errors" class="invalid-feedback">
                      <div *ngIf="r.otp.errors.required">OTP is required</div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                        <span class="input-group-text border-0">
                            <i class="fas fa-unlock-alt"></i>
                        </span>
                        </div>
                        <input type="password" [ngClass]="{ 'is-invalid': (resetSubmitted && r.newPassword.invalid && r.newPassword.errors && (r.newPassword.dirty || r.newPassword.touched))}"
                        formControlName="newPassword" class="form-control border-left-0" id="exampleInputPassword"
                        placeholder="New Password">
                        <div *ngIf="resetSubmitted && r.newPassword.errors" class="invalid-feedback">
                        <div *ngIf="r.newPassword.errors.required">New Password is required</div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                        <span class="input-group-text border-0">
                            <i class="fas fa-unlock-alt"></i>
                        </span>
                        </div>
                        <input type="password" [ngClass]="{ 'is-invalid': (resetSubmitted && r.confirmPassword.invalid && r.confirmPassword.errors && (r.confirmPassword.value != r.newPassword.value) && (r.confirmPassword.dirty || r.confirmPassword.touched))}"
                        formControlName="confirmPassword" class="form-control border-left-0" id="exampleInputPassword"
                        placeholder="Confirm Password">
                        <div *ngIf="resetSubmitted && r.confirmPassword.errors" class="invalid-feedback">
                        <div *ngIf="r.confirmPassword.errors.required">Password is required</div>
                        <div *ngIf= "r.confirmPassword.valid && r.confirmPassword.value != r.newPassword.value">password shold match </div>
                        </div>
                    </div>
                </div>
                <div style="line-height: 0.5;">
                    <a (click)="onResendOtp()" class="text-white">Resend OTP</a>
                    <a [routerLink]= "['/'+commonService.getUrlSuffix() +'/login']"  class="text-white float-right">Back to login</a>
                </div>
                <div class="my-3">
                  <button type="submit" value="Tenant" class="btn btn-sm btn-primary w-100">Submit</button>
                </div>
              </form>
              <div *ngIf="isPasswordChanged">
                <h4 class="py-3 text-white">Password changed successfully</h4>
                <div style="line-height: 0.5;" class="text-center"><a [routerLink]= "['/'+commonService.getUrlSuffix() +'/login']" class="text-white">Back to login</a></div>
                <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        &nbsp;
                        <br>
                        <br>
                        <br>
                        <br>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
        </div>
        <p class="font-weight-medium text-center position-absolute align-self-end login-copyright pb-2">
          <span class="text-center w-50 d-flex justify-content-center align-items-center mr-auto ml-auto mb-2">
            <img src="assets/images/student-logo.png" alt="" class="d-block text-center img-fluid w-75" />
          </span>
  
          <small class="text-white"> Copyright <i class="far fa-copyright"></i>
            Vanna Infotech Pvt Ltd. All Rights Reserved.</small>
        </p>
      </div>
    </div>
    <!-- content-wrapper ends -->
  </div>