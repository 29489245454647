<div class=" my-3">
  <div class="card">
    <div class="card-body">
      <h6 class="card-title text-dark position-relative pb-2 h6">My Team Sales</h6>
      <div class="wrap">
        <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions"
          [dtTrigger]="dtTrigger">
          <thead>
            <tr>
              <th>Name</th>
              <th>Venture Name</th>
              <th>Plot #</th>
              <th>Plot Status</th>
              <th>Sale Date</th>
              <th>Price/Measure (<i class="fas fa-rupee-sign mr-1"></i>)</th>
              <th>Sale Value (<i class="fas fa-rupee-sign mr-1"></i>)</th>
              <th>Amount Received (<i class="fas fa-rupee-sign mr-1"></i>)</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let sale of (myTeamsales.teamsales ? myTeamsales.teamsales : [])" (click)="editSale(sale)"
              style="cursor:pointer;">
              <td>{{sale.name}}</td>
              <td>{{sale.ventureName}}</td>
              <td>{{sale.plotNum}}</td>
              <td>{{sale.plotStatus}}</td>
              <td>{{sale.saleDate | date}}</td>
              <td>{{sale.perMeasurePrice}}</td>
              <td>{{sale.saleValue.toLocaleString('en-IN')}}</td>
              <td>{{sale.amountReceived.toLocaleString('en-IN')}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>