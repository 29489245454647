import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, ThemeService } from 'ng2-charts';
import * as pluginDataLabels from 'ng2-charts';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthServiceService } from 'src/app/services/auth-service.service';

@Component({
  selector: 'app-salesrepplots',
  templateUrl: './salesrepplots.component.html',
  styleUrls: ['./salesrepplots.component.scss']
})
export class SalesrepplotsComponent implements OnInit {

  constructor(public themeService: ThemeService, public authService: AuthServiceService) { }

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabels: Label[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'june'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];
  public barChartColors: Color[] = [
    {
      backgroundColor: ['rgba(19, 27, 197, 0.8)', 'rgba(19, 27, 197, 0.8)', 'rgba(19, 27, 197, 0.8)', 'rgba(19, 27, 197, 0.8)', 'rgba(19, 27, 197, 0.8)', 'rgba(19, 27, 197, 0.8)']
    }
  ]
  public barChartData: ChartDataSets[] = [
    { data: [20, 10, 8, 10, 4, 6, 0], label: 'sold' },
    { data: [10, 8, 20, 14, 12, 8, 0], label: 'sold' },
    { data: [6, 12, 6, 12, 6, 14, 0], label: 'sold' }


  ];

  ngOnInit(): void {
  }

}

