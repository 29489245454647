<div class="my-2">
  <div class="card my-3">
    <div class="card-body px-4">

      <div class="row">
        <div class="col-md-6">
          <h6 class="card-title text-dark position-relative pb-2 ">Marketers</h6>
        </div>
        <div (click)="onCheck()" class="col-md-6 text-right" *ngxPermissionsOnly="[userPrivileges.TENANT_PRIVILEGE]">
          <button class="btn btn-sm btn-info mb-2 ">Add Marketer</button>
        </div>
      </div>
      <div class="wrap">
      <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions"
        [dtTrigger]="dtTrigger">
        <thead>
          <tr>
            <th>User ID</th>
            <th>Marketer Type</th>
            <th>Display Name</th>
            <th>Director</th>
            <th>Email</th>
            <th>Contact Number</th>
            <th>Location</th>

          </tr>
        </thead>
        <tbody>
          <tr class="pointer" *ngFor="let marketer of marketerList" (click)="editDetails(marketer)">
            <td>{{marketer.managingDirector?.personId}}</td>
            <td>{{marketer.marketerType === 'person' ? 'Individual' : 'Company'}}</td>
            <td>{{marketer.displayName}}</td>
            <td>{{marketer.managingDirector?.firstName}}</td>

            <td>{{marketer.managingDirector?.email}}</td>
            <td>{{marketer.managingDirector?.contactNumber}}</td>
            <td>{{capitalFirst(marketer.primaryAddress?.city)}}</td>

          </tr>
        </tbody>
      </table>
    </div>
    </div>

  </div>

</div>