import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { UserPrivileges } from 'src/app/constants/AppConstants';
import { UserKeys } from 'src/app/models/user.model';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  customers: any = [];
  error: boolean;
  errorMessage: any;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  userPrivileges = UserPrivileges;

  constructor(
    private restService: RestService,
    private router: Router,
    private authService: AuthServiceService,
    private route: ActivatedRoute,
    private commonService: CommonServiceService
  ) { }

  ngOnInit(): void {
    const userInfo = this.authService.getUserInfo();
    const user = <UserKeys>this.authService.getUserInfo();
    user.ventureId = this.commonService.getVenture()?.ventureId;
    if (user.ventureId && window.location.href.includes('vl')) {
      let path: string = user.marketerId ? 'venture-customers?marketerId=' + user.marketerId + '&ventureId=' + user.ventureId : 'venture-customers?tenantId=' + user.tenantId + '&ventureId=' + user.ventureId;
      this.restService.submitGetRequest(path).subscribe(response => {
        if (response != null) {
          this.customers = response.data;
          this.dtTrigger.next();
        }
      },
        (error) => {
          this.error = true;
          this.errorMessage = error.status;
        })
    } else {
      let path: string = userInfo.role.toLowerCase() + '/get-customers'
      let data = {
        "tenantId": this.authService.getUserInfo().tenantId,
        "marketerId": this.authService.getUserInfo().marketerId
      }
      this.restService.submitPostRequest(path, data).subscribe(response => {
        if (response != null) {
          this.customers = response.customers;
          this.dtTrigger.next();
        }
      },
        (error) => {
          this.error = true;
          this.errorMessage = error.status;
        })
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      searching: false,
    };
  }

  ngOnDestroy() {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  onShowDetails(details) {
    this.router.navigate(['../cust-info', details.customerId], { relativeTo: this.route }
    );
  }

}
