<div class=" my-3">
  <div class="card my-3">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <h6 class="card-title text-dark position-relative pb-2 ">Ventures</h6>
        </div>
        <!-- <div [hidden]="submit" (click)="onCheck()" class="col-md-6 text-right">
          <button class="btn btn-sm btn-info">Add Venture</button>
        </div> -->
      </div>
      <div [hidden]="submit" class="wrap">
        <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions"
          [dtTrigger]="dtTrigger">
          <thead>
            <tr>
              <th style="width: 25%;">Name</th>
              <th>Display Name</th>
              <th>Venture Type</th>
              <th>No of plots</th>
              <th>Location</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let venture of ventureData" (click)="editDetails(venture)">
              <td>{{venture.ventureName}}</td>
              <td>{{venture.displayName}}</td>
              <td>{{venture.ventureType}}</td>
              <td>{{venture.numOfPlots}}</td>
              <td>{{venture.locationAddress}}</td>
              <td>{{venture.ventureStatus}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="submit">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <h6 class="card-title text-dark position-relative pb-2 h6">New Venture</h6>
              </div>
              <div class="col-md-6 text-right">
                <button class="btn btn-sm btn-info" (click)="onShow()">Show Ventures</button>
              </div>
            </div>
            <app-addventure (emitObj)="onSubmit($event)"></app-addventure>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>