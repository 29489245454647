<div class=" my-3 ">
    <div class="card h-100">
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <h6 class="card-title text-dark position-relative pb-2 h6">Customer Payments ({{ventureName}})</h6>
                </div>
                <div class="col-md-6 text-right mb-2">
                    <button class="btn btn-sm btn-info mr-2" (click)="exportPayments()">Export to Excel</button>
                    <button class="btn btn-sm btn-info" [hidden]="!submit" (click)="onSubmit()">New Payment</button>
                </div>
            </div>            
            <div [hidden]="submit" class="wrap">
                <table class="table table-striped table-bordered table-sm row-border hover" datatable
                    [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th>Customer</th>
                            <th>Plot #</th>
                            <th><i class="fas fa-rupee-sign mr-1"></i>Received</th>
                            <th>Received Date</th>
                            <th>Payment Mode</th>
                            <!-- <th>Paid By</th> -->
                            <th>Comments</th>
                            <th>Last Updated By</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let rec of Receivables.receivables">
                            <td>{{rec.name}}</td>
                            <td>{{capitalPlot(rec.plotNumber)}}</td>
                            <td>{{rec.amount.toLocaleString('en-IN')}}</td>
                            <td>{{rec.date | date}}</td>
                            <td>{{capitalFirst(rec.mode)}}</td>
                            <!-- <td>{{rec.paidBy}}</td> -->
                            <td>{{rec.comments}}</td>
                            <td>{{rec.lastUpdatedBy}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="submit">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <!-- <div class="col-md-6">
                                <h6 class="card-title text-dark position-relative pb-2 h6">New Payment</h6>
                            </div> -->
                            <div class="col-md-12 text-right">
                                <button class="btn btn-sm btn-info" (click)="onShow()">Show Payments</button>
                            </div>
                        </div>
                        <app-pay-now></app-pay-now>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>