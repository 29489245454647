import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-mysales',
  templateUrl: './mysales.component.html',
  styleUrls: ['./mysales.component.scss']
})
export class MysalesComponent implements OnInit {
  @Input() sales;
  @Input() id;
  mysalesArray: any = [];
  othersSalesArray: any = [];
  error: boolean;
  errorMessage: any;
  salesrepvalue: any;
  userInfo: any;
  display: any = false;
  data;
  ventureSelected = 'all';
  ventures: any = [];

  constructor(
    private restService: RestService,
    private router: Router,
    public commonService: CommonServiceService,
    private authService: AuthServiceService
  ) { }

  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    if(this.userInfo?.salesrepId) {
      const path: string = 'salesrep/assigned-ventures/' + this.userInfo.salesrepId;
      this.restService.submitGetRequest(path).subscribe(response => {
        this.ventures = response.data;
      });
    }
    if (this.sales) {
      this.getSales();
      this.display = true;
    }
    else {
      this.displayData();
      this.display = false;
    }
  }
  displayData() {
    let directUrl = 'generic/salesrep/sales?salesrepId=' + this.userInfo.salesrepId + '&type=direct';
    let indirectUrl = 'generic/salesrep/sales?salesrepId=' + this.userInfo.salesrepId + '&type=indirect';
    this.restService.getSales(directUrl).subscribe(response => {
      if (response.data != null && response.data != undefined) {
        this.mysalesArray = response.data;
      }
    },
    (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });
    this.restService.getSales(indirectUrl).subscribe(response => {
      if (response.data != null && response.data != undefined) {
        this.othersSalesArray = response.data;
      }
    },
    (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });
  }
  getSales() {
    let directUrl;
    let indirectUrl;
    if (this.id) {
      directUrl = 'generic/salesrep/sales?salesrepId=' + this.sales + '&ventureId=' + this.commonService.getVenture()?.ventureId + '&type=direct';
      indirectUrl = 'generic/salesrep/sales?salesrepId=' + this.sales + '&ventureId=' + this.commonService.getVenture()?.ventureId + '&type=indirect';
    } else {
      directUrl = 'generic/salesrep/sales?salesrepId=' + this.sales + '&type=direct';
      indirectUrl = 'generic/salesrep/sales?salesrepId=' + this.sales + '&type=indirect';
    }

    this.restService.getSales(directUrl).subscribe(response => {
      if (response.data != null && response.data != undefined) {
        this.mysalesArray = response.data
      }
    },
    (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });
    this.restService.getSales(indirectUrl).subscribe(response => {
      if (response.data != null && response.data != undefined) {
        this.othersSalesArray = response.data
      }
    },
    (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });
  }

  getSalesByVenture() {
    if(this.ventureSelected == 'all') {
      this.displayData();
    } else {
      let directUrl = 'generic/salesrep/sales?salesrepId=' + this.userInfo.salesrepId + '&ventureId=' + this.ventureSelected + '&type=direct';
    let indirectUrl = 'generic/salesrep/sales?salesrepId=' + this.userInfo.salesrepId + '&ventureId=' + this.ventureSelected + '&type=indirect';
    this.restService.getSales(directUrl).subscribe(response => {
      if (response.data != null && response.data != undefined) {
        this.mysalesArray = response.data;
      }
    },
    (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });
    this.restService.getSales(indirectUrl).subscribe(response => {
      if (response.data != null && response.data != undefined) {
        this.othersSalesArray = response.data;
      }
    },
    (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });
    }
  }

  onDisply(row) {
  }
}
