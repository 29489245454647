<div class="container-fluid mt-2">
    <div class="card shadow  h-100 mb-2">
        <div class="card-body pb-2">
            <div class="row" *ngIf="isShown">
                <div class="col-md-3">
                    <h6 class="card-title text-dark position-relative pb-2 h6">Add a New Sale</h6>
                </div>
                <div class="col-md-8 text-right">
                    <button (click)="checkIrregularPlotSize(mymodal, irregularPlotContent)" [disabled]="validateData()"
                        class="btn btn-info btn-sm">Confirm
                        Sale
                    </button>
                </div>
                <div class="col-md-1 text-right size">
                    <i (click)="goBack()" class="fas fa-times-circle pointer float-right"></i>
                </div>
            </div>
            <div class="row" *ngIf="!isShown">
                <div class="col-md-6">
                    <h6 class="card-title text-dark position-relative pb-2 h6">Add a New Sale</h6>
                </div>
                <div class="col-md-6 text-right size">
                    <i (click)="goBack()" class="fas fa-times-circle pointer float-right"></i>
                </div>
            </div>
            <ng-template #irregularPlotContent let-modal>
                <div class="modal-header">
                    <h6 class="modal-title" id="modal-basic-title">Agreed Price is different than Standard Price</h6>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <ngb-alert [dismissible]="false" type="danger">
                        <div class="d-flex align-items-baseline">
                            <i class="fa fa-exclamation-triangle"></i>
                            <p class="ml-2">Agreed Price and Standard Price are not equal</p>
                        </div>
                    </ngb-alert>
                    <p>Please select reason:</p>
                    <div class="form-check pl-4">
                        <input style="margin-top: 2px;" class="form-check-input" type="checkbox" id="irregularPlotReason"
                            [(ngModel)]="irregularPlotReason">
                        <label class="form-check-label" for="irregularPlotReason">
                            The Plot area is irregular
                        </label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="float-left btn btn-outline-info"
                        (click)="confirmSale(mymodal)">Yes, Proceed</button>
                    <button type="button" class="btn btn-outline-dark"
                        (click)="modal.dismiss('cancel click')">No</button>
                </div>
            </ng-template>

            <ng-template #mymodal let-modal>
                <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">Success</h4>
                    <button type="button" class="close" aria-label="Close"
                        (click)="modal.dismiss('Cross click');goBack()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    Congrats! Sale was successfully completed!
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-dark" (click)="goBack()"
                        (click)="modal.close('Save click')">Ok</button>
                </div>
            </ng-template>
            <div class="row" *ngIf="!isShown">
                <div class="col-md-3">
                    <ng-autocomplete [data]="salesRep" [focusFirst]=true [(ngModel)]="salesRepDetails"
                        [searchKeyword]="keyword" placeholder="Enter Sales Rep Name" [itemTemplate]="salesRepTemplate"
                        [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>

                    <ng-template #salesRepTemplate let-item>
                        <div class="item">
                            <span>{{ item.name }}</span>
                        </div>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                        <div style="font-size: large;">Not Found</div>
                    </ng-template>
                </div>
                <div class="col-md-3">
                    <ng-autocomplete [data]="plots" [focusFirst]=true [(ngModel)]="plotDetails"
                        [searchKeyword]="keyword1" placeholder="Enter Plot Number" [itemTemplate]="plotTemplate"
                        [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>

                    <ng-template #plotTemplate let-item1>
                        <div class="item">
                            <span [innerHTML]="item1.plotNum"></span>
                        </div>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                    </ng-template>
                </div>
                <div class="col-md-1">
                    <button (click)="checkForMortgageAlert(mortgageContent)"
                        [disabled]="!salesRepDetails || !plotDetails || !plotDetails.plotId != '' || !salesRepDetails.salesrepId != ''"
                        class="btn btn-info btn-sm">Go</button>
                </div>

                <ng-template #mortgageContent let-modal>
                    <div class="modal-header">
                        <h6 class="modal-title" id="modal-basic-title">Add Sale for Mortgage Plot ?</h6>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ngb-alert [dismissible]="false" type="danger">
                            <div class="d-flex align-items-baseline">
                                <i class="fa fa-exclamation-triangle"></i>
                                <h6 class="ml-2">Are you sure you want to proceed for the sale of mortgage plot ? </h6>
                            </div>
                        </ngb-alert>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="float-left btn btn-outline-danger"
                            (click)="proceedToMortgagePlotSale()">Yes, Proceed</button>
                        <button type="button" class="btn btn-outline-dark"
                            (click)="modal.dismiss('cancel click')">No</button>
                    </div>
                </ng-template>

            </div>
            <div *ngIf="isShown">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row my-4">
                            <div class="col-md-4">Plot number:
                                <b class="margin">{{capitalTitle(ModelObject.plotNum)}}</b>
                            </div>
                            <div class="col-md-4">Size({{commonService.getMeasure()}}):
                                <b class="margin">{{ModelObject.size.toLocaleString('en-IN', { minimumFractionDigits: 2,
                                    maximumFractionDigits: 2 })}}</b>
                            </div>
                            <div class="col-md-4">Plot Type:
                                <b class="margin">{{capitalTitle(ModelObject.plotType)}}</b>
                            </div>
                            <div class="col-md-4">Facing:
                                <b class="margin">{{ModelObject.roadFacing | titlecase}}</b>
                            </div>
                            <div class="col-md-4">Block:
                                <b class="margin">{{ModelObject.blockName | titlecase}}</b>
                            </div>
                            <!-- <div class="col-md-4">East:
                                <b class="margin">{{ModelObject.eastSideContent | titlecase}}</b>
                            </div>
                            <div class="col-md-4">West:
                                <b class="margin">{{ModelObject.westSideContent | titlecase}}</b>
                            </div>
                            <div class="col-md-4">North:
                                <b class="margin">{{ModelObject.northSideContent | titlecase}}</b>
                            </div>
                            <div class="col-md-4">South:
                                <b class="margin">{{ModelObject.southSideContent | titlecase}}</b>
                            </div> -->
                        </div>
                        <form class="row">
                            <div class="form-group col-md-4" *ngFor="let price of price">
                                <label>Standard Price/{{commonService.getMeasure()}} (<i
                                        class="fas fa-rupee-sign mr-0"></i>)</label>
                                <input type="text" #standardprice
                                    value="{{price.maxSellingPrice.toLocaleString('en-IN')}}" readonly
                                    class="form-control">
                            </div>
                            <div class="form-group col-md-4"
                                [ngClass]="{'invalid': (agreedprice.invalid && (agreedprice.dirty || agreedprice.touched))}">
                                <label for="">Agreed Price/{{commonService.getMeasure()}} (<i
                                        class="fas fa-rupee-sign mr-0"></i>)</label>
                                <div class="input-group">
                                    <input type="number" #agreedprice='ngModel' name="agreedPrice" class="form-control"
                                        value="{{ModelObject.agreedPrice}}" required
                                        [(ngModel)]="ModelObject.agreedPrice" [ngModelOptions]="{standalone: true}"
                                        (keyup)="calculateSalePrice(agreedprice.value)">
                                </div>
                            </div>


                            <div class="form-group col-md-4">
                                <label for="">Total Sale Value (<i class="fas fa-rupee-sign mr-0"></i>)</label>
                                <input type="text" [(ngModel)]="ModelObject.saleValue"
                                    [ngModelOptions]="{standalone: true}" readonly class="form-control">
                            </div>
                            <hr>
                            <div *ngIf="salesLevel[0]?.commissionType === 'percentage'">

                                <div>
                                    <h6 class="card-title text-dark position-relative pb-2 h6 ml-3">Flexible Pricing
                                        Commissions</h6>
                                </div>

                                <div class="d-flex">

                                    <div class="form-group col-md-4" *ngFor="let price of price">
                                        <label>Commission Standard Price/{{commonService.getMeasure()}} (<i
                                                class="fas fa-rupee-sign mr-0"></i>)</label>
                                        <input type="text" #standardprice
                                            value="{{price.maxSellingPrice.toLocaleString('en-IN')}}" readonly
                                            class="form-control">
                                    </div>
                                    <div class="form-group col-md-4"
                                        [ngClass]="{'invalid': (perMeasurePriceForPercentageCommission.invalid && (perMeasurePriceForPercentageCommission.dirty || perMeasurePriceForPercentageCommission.touched))}">
                                        <label for="">Commission Formula Price/{{commonService.getMeasure()}} (<i
                                                class="fas fa-rupee-sign mr-0"></i>)</label>
                                        <input type="number" #perMeasurePriceForPercentageCommission='ngModel'
                                            name="perMeasurePriceForPercentageCommission" class="form-control"
                                            value="{{ModelObject.perMeasurePriceForPercentageCommission}}" required
                                            [(ngModel)]="ModelObject.perMeasurePriceForPercentageCommission"
                                            [ngModelOptions]="{standalone: true}"
                                            (keyup)="calculatePercentageCommissions(perMeasurePriceForPercentageCommission.value)">
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="">Base for Commission Calculations (<i
                                                class="fas fa-rupee-sign mr-0"></i>)</label>
                                        <input type="text" [(ngModel)]="ModelObject.saleValueForPercentageCommission"
                                            [ngModelOptions]="{standalone: true}" readonly class="form-control">
                                    </div>

                                </div>

                            </div>
                        </form>
                        <div class="wrap">
                            <table class="table table-striped table-bordered table-sm row-border hover my-4">
                                <thead>
                                    <tr>
                                        <th>Designation</th>
                                        <th>Level</th>
                                        <th>Salesrep</th>
                                        <th>{{salesLevel[0]?.commissionType === 'perMeasure' ? 'PerMeasure' :
                                            'Percentage'}} <!-- (<i class="fas fa-rupee-sign mr-0"></i>) --></th>
                                        <th>Calculated <!-- (<i class="fas fa-rupee-sign mr-0"></i>) --></th>
                                        <th style="min-width: 110px">Adjusted
                                            <!-- (<i class="fas fa-rupee-sign mr-0"></i>) -->
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let sales of salesLevel">
                                        <td>{{sales.salesrepDesignation}}</td>
                                        <td>{{sales.commissionLevel+1}}</td>
                                        <td>{{sales.salesrepName}}</td>
                                        <td>{{sales.commissionType === 'perMeasure' ?
                                            sales.perMeasureCommission.toLocaleString('en-IN') :
                                            sales.percentageCommission.toLocaleString('en-IN')}}</td>
                                        <td>{{sales.calculatedCommission ?
                                            sales.calculatedCommission.toLocaleString('en-IN') : null}}</td>
                                        <td class="p-0 " style="width: 30px;">
                                            <input type="number" class="form-control p-0" style="font-size: 13px; "
                                                [(ngModel)]="sales.adjustedCommission">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card p-3">
                            <div class="row">
                                <div class="col-6">
                                    <h6 class="card-title text-dark position-relative pb-2 h6">Add Payment
                                    </h6>
                                </div>
                            </div>
                            <form (ngSubmit)="onSubmit(payment)" #myPayment="ngForm">
                                <div class="row mt-2">
                                    <div class="form-group col-6"
                                        [ngClass]="{'invalid': amount.invalid && (amount.dirty || amount.touched || myPayment.submitted)}">
                                        <label class="star">Amount Received (<i
                                                class="fas fa-rupee-sign mr-1"></i>)</label>
                                        <input type="number" class="form-control" id="amount" required
                                            [(ngModel)]="payment.amount" name="amount" #amount="ngModel">
                                    </div>

                                    <div class="form-group col-6"
                                        [ngClass]="{'invalid': paidFor.invalid && (paidFor.dirty || paidFor.touched || myPayment.submitted)}">
                                        <label class="star">Paid For</label>
                                        <input type="text" class="form-control" id="paidFor" name="paidFor" readonly
                                            [(ngModel)]="ModelObject.payFor" #paidFor="ngModel">
                                    </div>

                                    <div class="form-group col-6"
                                        [ngClass]="{'invalid': paidBy.invalid && (paidBy.dirty || paidBy.touched || myPayment.submitted)}">
                                        <label class="star">Paid By</label>
                                        <input type="text" class="form-control" id="paidBy" name="paidBy" readonly
                                            [(ngModel)]="ModelObject.payBy" #paidBy="ngModel">
                                    </div>

                                    <div class="form-group col-6"
                                        [ngClass]="{'invalid': paymentMode.invalid && (paymentMode.dirty || paymentMode.touched || myPayment.submitted)}">
                                        <label class="star" for="gender">Payment Mode</label>
                                        <select class="form-control" id="paymentMode" required
                                            [(ngModel)]="payment.paymentMode" name="paymentMode" #paymentMode="ngModel">
                                            <option *ngFor="let pay of payments" [value]="pay">
                                                {{pay}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group col-6"
                                        [ngClass]="{'invalid': paidDate.invalid && (paidDate.dirty || paidDate.touched || myPayment.submitted)}">
                                        <label class="star">Paid Date</label>
                                        <input type="date" class="form-control" id="paidDate" name="paidDate"
                                            [(ngModel)]="payment.paidDate" max="9999-12-31" #paidDate="ngModel"
                                            required>
                                    </div>
                                    <div class="form-group col-6" *ngIf="payment.saleStatus == 'Registered'"
                                        [ngClass]="{'invalid': registrationNum.invalid && (registrationNum.dirty || registrationNum.touched || myPayment.submitted)}">
                                        <label class="star">Registration Number</label>
                                        <input type="text" class="form-control" id="registrationNum" required
                                            [(ngModel)]="payment.registrationNum" name="registrationNum"
                                            #registrationNum="ngModel">
                                    </div>
                                    <div class="form-group col-6" *ngIf="payment.saleStatus == 'Registered'"
                                        [ngClass]="{'invalid': registrationDate.invalid && (registrationDate.dirty || registrationDate.touched || myPayment.submitted)}">
                                        <label class="star">Registration Date</label>
                                        <input type="date" class="form-control" id="registrationDate" required
                                            [(ngModel)]="payment.registrationDate" name="registrationDate"
                                            #registrationDate="ngModel">
                                    </div>
                                    <div class="form-group col-6"
                                        [ngClass]="{'invalid': comments.invalid && (comments.dirty || comments.touched || myPayment.submitted)}">
                                        <label>Comments</label>
                                        <input type="text" class="form-control" id="comments"
                                            [(ngModel)]="payment.comments" name="comments" #comments="ngModel">
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-md-4 card wrapper my-4">
                        <div *ngIf="alert" class="alert alert-success alert-dismissible fade show" role="alert">
                            <strong>Success!</strong> Your details have been saved successfully!
                            <button type="button" (click)="closeAlert()" class="close" data-dismiss="alert"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="row my-4">
                            <ul class="list-unstyled margin">
                                <li>
                                    <span class="customer-style">Customer:</span>
                                    <input type="radio" class="radioStyle" [(ngModel)]="radioSelected" name="new"
                                        value="new" /> <span class="new">New</span>
                                    <input type="radio" class="radioStyle" [(ngModel)]="radioSelected" name="existing"
                                        value="existing" /><span class="new">Existing</span>
                                </li>
                            </ul>
                            <div *ngIf="radioSelected === 'new'">
                                <div class="col-md-12">
                                    <div *ngIf="!submitNew">
                                        <form #myProfile="ngForm" (ngSubmit)="onSubmit(myProfile.value)">
                                            <div class="row mt-2">
                                                <div class="form-group col-md-4"
                                                    [ngClass]="{'invalid': firstName.invalid && (firstName.dirty || firstName.touched || myProfile.submitted)}">
                                                    <label class="star">First Name </label>
                                                    <input type="text" class="form-control" id="firstName" required
                                                        [(ngModel)]="personalDetails.firstName" name="firstName"
                                                        #firstName="ngModel">
                                                </div>

                                                <div class="form-group col-md-4"
                                                    [ngClass]="{'invalid': lastName.invalid && (lastName.dirty || lastName.touched || myProfile.submitted)}">
                                                    <label class="star">Last Name</label>
                                                    <input type="text" class="form-control" id="lastName" required
                                                        [(ngModel)]="personalDetails.lastName" name="lastName"
                                                        #lastName="ngModel">
                                                </div>

                                                <div class="form-group col-md-4"
                                                    [ngClass]="{'invalid': fatherOrHusbandName.invalid && (fatherOrHusbandName.dirty || fatherOrHusbandName.touched || myProfile.submitted)}">
                                                    <label>Father/Husband Name</label>
                                                    <input type="text" class="form-control" id="fatherOrHusbandName"
                                                        [(ngModel)]="personalDetails.fatherOrHusbandName"
                                                        name="fatherOrHusbandName" #fatherOrHusbandName="ngModel">
                                                </div>

                                                <div class="form-group col-md-4"
                                                    [ngClass]="{'invalid': dob.invalid && (dob.dirty || dob.touched || myProfile.submitted)}">
                                                    <label>DOB</label>
                                                    <input type="date" class="form-control" id="dob"
                                                        [(ngModel)]="personalDetails.dob" name="dob" #dob="ngModel"
                                                        max="9999-12-31">
                                                </div>

                                                <div class="form-group col-md-4">
                                                    <label for="gender">Gender</label>
                                                    <select class="form-control" id="gender"
                                                        [(ngModel)]="personalDetails.gender" name="gender"
                                                        #gender="ngModel">
                                                        <option *ngFor="let gen of genders" [value]="gen">
                                                            {{gen}}
                                                        </option>
                                                    </select>
                                                </div>

                                                <div class="form-group col-md-4"
                                                    [ngClass]="{'invalid': bloodGroup.invalid && (bloodGroup.dirty || bloodGroup.touched || myProfile.submitted)}">
                                                    <label>Blood Group</label>
                                                    <input type="text" class="form-control" id="bloodGroup"
                                                        [(ngModel)]="personalDetails.bloodGroup" name="bloodGroup"
                                                        #bloodGroup="ngModel">
                                                </div>

                                                <div class="form-group col-md-4"
                                                    [ngClass]="{'invalid': nationality.invalid && (nationality.dirty || nationality.touched || myProfile.submitted)}">
                                                    <label>Nationality</label>
                                                    <input type="text" class="form-control" id="nationality"
                                                        [(ngModel)]="personalDetails.nationality" name="nationality"
                                                        #nationality="ngModel">
                                                </div>

                                                <div class="form-group col-md-4"
                                                    [ngClass]="{'invalid': panNum.invalid && (panNum.dirty || panNum.touched || myProfile.submitted)}">
                                                    <label>PAN Number</label>
                                                    <input type="text" class="form-control" id="panNum"
                                                        [(ngModel)]="personalDetails.panNum" name="panNum"
                                                        #panNum="ngModel">
                                                </div>

                                                <div class="form-group col-md-4"
                                                    [ngClass]="{'invalid': aadharNum.invalid && (aadharNum.dirty || aadharNum.touched || myProfile.submitted)}">
                                                    <label>Aadhaar</label>
                                                    <input type="text" class="form-control" id="aadharNum"
                                                        [(ngModel)]="personalDetails.aadharNum" name="aadharNum"
                                                        #aadharNum="ngModel">
                                                </div>

                                                <div class="form-group col-md-4"
                                                    [ngClass]="{'invalid': contactNumber.invalid && (contactNumber.dirty || contactNumber.touched || myProfile.submitted)}">
                                                    <label class="star">Contact Number</label>
                                                    <input type="text" class="form-control" id="contactNumber" required
                                                        [(ngModel)]="personalDetails.contactNumber" name="contactNumber"
                                                        #contactNumber="ngModel">
                                                </div>

                                                <div class="form-group col-md-4"
                                                    [ngClass]="{'invalid': alternateContact.invalid && (alternateContact.dirty || alternateContact.touched || myProfile.submitted)}">
                                                    <label>Alternate Number</label>
                                                    <input type="text" class="form-control" id="alternateContact"
                                                        [(ngModel)]="personalDetails.alternateContact"
                                                        name="alternateContact" #alternateContact="ngModel">
                                                </div>

                                                <div class="form-group col-md-4"
                                                    [ngClass]="{'invalid': email.invalid && (email.dirty || email.touched || myProfile.submitted)}">
                                                    <label>Email</label>
                                                    <input type="text" class="form-control" id="email"
                                                        [(ngModel)]="personalDetails.email" name="email"
                                                        #email="ngModel">
                                                </div>

                                                <div class="form-group col-md-4">
                                                    <label>Alternate Email</label>
                                                    <input type="text" class="form-control" id="alternateEmail"
                                                        [(ngModel)]="personalDetails.alternateEmail"
                                                        name="alternateEmail" #alternateEmail="ngModel">
                                                </div>

                                                <div class="form-group col-md-4"
                                                    [ngClass]="{'invalid': addressLine1.invalid && (addressLine1.dirty || addressLine1.touched)}">
                                                    <label>Address Line 1</label>
                                                    <input type="text" class="form-control" id="addressLine1"
                                                        [(ngModel)]="permAddress.addressLine1" name="addressLine1"
                                                        #addressLine1="ngModel">
                                                </div>

                                                <div class="form-group col-md-4"
                                                    [ngClass]="{'invalid': landmark.invalid && (landmark.dirty || landmark.touched)}">
                                                    <label>LandMark</label>
                                                    <input type="text" class="form-control" id="landmark"
                                                        [(ngModel)]="permAddress.landmark" name="landmark"
                                                        #landmark="ngModel">
                                                </div>

                                                <div class="form-group col-md-4"
                                                    [ngClass]="{'invalid': city.invalid && (city.dirty || city.touched)}">
                                                    <label>City</label>
                                                    <input type="text" class="form-control" id="city"
                                                        [(ngModel)]="permAddress.city" name="city" #city="ngModel">
                                                </div>

                                                <div class="form-group col-md-4"
                                                    [ngClass]="{'invalid': mandal.invalid && (mandal.dirty || mandal.touched)}">
                                                    <label>Mandal</label>
                                                    <input type="text" class="form-control" id="mandal"
                                                        [(ngModel)]="permAddress.mandal" name="mandal"
                                                        #mandal="ngModel">
                                                </div>

                                                <div class="form-group col-md-4"
                                                    [ngClass]="{'invalid': district.invalid && (district.dirty || district.touched)}">
                                                    <label>District</label>
                                                    <input type="text" class="form-control" id="district"
                                                        [(ngModel)]="permAddress.district" name="district"
                                                        #district="ngModel">
                                                </div>

                                                <div class="form-group col-md-4"
                                                    [ngClass]="{'invalid': state.invalid && (state.dirty || state.touched)}">
                                                    <label>State</label>
                                                    <input type="text" class="form-control" id="state"
                                                        [(ngModel)]="permAddress.state" name="state" #state="ngModel">
                                                </div>

                                                <div class="form-group col-md-4"
                                                    [ngClass]="{'invalid': pin.invalid && (pin.dirty || pin.touched)}">
                                                    <label>Pin Code</label>
                                                    <input type="text" class="form-control" id="pin"
                                                        [(ngModel)]="permAddress.pin" name="pin" #pin="ngModel">
                                                </div>

                                            </div>

                                            <button type="submit" class="btn btn-sm btn-info"
                                                [disabled]="!myProfile.form.valid">Submit</button>
                                        </form>
                                    </div>
                                    <div *ngIf="submitNew">
                                        <div class="col-md-12">
                                            <app-new-customer [customerData]="newCustomerData"></app-new-customer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="radioSelected === 'existing'">
                                <div class="col-md-12">
                                    <ng-autocomplete [data]="Customers" [focusFirst]=true
                                        [(ngModel)]="ModelObject.customer" [searchKeyword]="keyword2"
                                        placeholder="Select customer" (selected)='onExisting($event)'
                                        [itemTemplate]="customerTemplate" [notFoundTemplate]="notFoundTemplate">
                                    </ng-autocomplete>
                                    <ng-template #customerTemplate let-item2>
                                        <div class="item">
                                            <span [innerHTML]="item2.name"></span>
                                        </div>
                                    </ng-template>

                                    <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>
                            </div>
                            <div *ngIf="radioSelected === 'existing' && submitExisting && ModelObject.customer">
                                <div class="col-md-12">
                                    <app-existing-customer
                                        [selectedCustomer]="ModelObject.customer"></app-existing-customer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>