<div class="container-fluid my-2" style="font-size: 13px;">
    <div class="card-body">
        <div class="row">
            <div class="col-md-6">
            </div>
            <div [hidden]="submit" class="col-md-6 text-right" *ngxPermissionsOnly="[userPrivileges.TENANT_PRIVILEGE]">
                <button class="btn btn-sm btn-info mb-3 " (click)="onSubmit()">Attach Venture</button>
            </div>
        </div>
        <div [hidden]="submit" class="wrap">
            <table class="table table-striped table-bordered table-sm row-border hover" datatable
                [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                <thead>
                    <tr >
                        <th>Venture Name</th>
                        <th>Effective Date</th>
                        <!-- <th>Comments</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let venture of marketerventureList">
                        <td>{{venture.ventureName}}</td>
                        <td>{{venture.effectiveDate | date}}</td>
                        <!-- <td>{{venture?.comments}}</td> -->
                    </tr>
                </tbody>
            </table>
        </div>
        <div [hidden]="!submit">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <button class="btn btn-sm btn-info" (click)="onShow()">Show Marketer</button>
                        </div>
                    </div>
                    <app-addingmarketerventure [id]="marketer"></app-addingmarketerventure>
                </div>
            </div>
        </div>
    </div>
</div>