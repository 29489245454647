import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import * as pluginDataLabels from 'ng2-charts';
import { HttpClient } from '@angular/common/http';
import { ThemeService } from 'src/app/services/theme.service';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { max } from 'rxjs/operators';

@Component({
  selector: 'app-venture-graph',
  templateUrl: './venture-graph.component.html',
  styleUrls: ['./venture-graph.component.scss']
})
export class VentureGraphComponent implements OnInit, OnChanges {

  @Input() plots: any;
  plotsData: any = [];

  constructor(public themeService: ThemeService, public authService: AuthServiceService) { }

  
  public pieChartOptions: ChartOptions = {
    responsive: true,
//     scales: { xAxes: [{}], yAxes: [{
//       display: false,
//     ticks: {
// max: 120
//     }
//     }] },
legend: {
  position: 'bottom',
},
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    },
  //   animation: {
  //     onComplete: function () {
  //         var chartInstance = this.chart,
  //         ctx = chartInstance.ctx;
  //         ctx.textAlign = 'center';
  //         ctx.textBaseline = 'bottom';
  //         this.data.datasets.forEach(function (dataset, i) {
  //             var meta = chartInstance.controller.getDatasetMeta(i);
  //             meta.data.forEach(function (bar, index) {
  //                 var data = dataset.data[index];
  //                 ctx.fillText(data, bar._model.x, bar._model.y - 5);
  //             });
  //         });
  //     }
  // }
  };
  public pieChartLabels: string[] = [];

  public pieChartType: ChartType = 'pie';
  
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors: Color[] = [
    { 
      backgroundColor: ['rgba(70, 86, 197, 0.8)', 'rgba(170, 197, 85 , 0.8)', 'rgba(197,70 ,85 , 0.8)', 'rgba(170, 86, 197, 0.8)']
    }
]

  public pieChartData: number[];
  
  ngOnChanges() {
    if (this.plots) {
      this.plotsData = this.plots.filter(a => a.lable != 'totalPlots')
      this.pieChartData =[
        this.plotsData.map(a => a.plots)
      ];

    //  this.pieChartLabels = this.plotsData.map(a => a.lable);
      this.plotsData.map(a => {
        this.pieChartLabels.push(a.lable.replace(/\b\w/g, (l) => l.toUpperCase()));
      });
    }
  }

  ngOnInit(): void {
  }

}

