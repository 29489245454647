import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { UserRoleConstants } from '../../../constants/AppConstants';
import { AuthServiceService } from '../../../services/auth-service.service';
import { CommonServiceService } from '../../../services/common-service.service';
import { RestService } from '../../../services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-salesbysalesrep',
  templateUrl: './salesbysalesrep.component.html',
  styleUrls: ['./salesbysalesrep.component.scss']
})
export class SalesbysalesrepComponent implements OnInit, OnDestroy {
  salesrepVentureSales: any = [];
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private authService: AuthServiceService,
    public commService: CommonServiceService,
    private commonService: CommonServiceService,
    private restService: RestService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    const userInfo: any = this.authService.getUserInfo();
    let tenantId = userInfo.tenantId;
    let marketerId = userInfo.marketerId;
    let ventureId = this.commService.getVenture()?.ventureId;
    let url;
    if (marketerId !== null) {
      url = 'venture-sales-by-salesrep?' + 'ventureId=' + ventureId + '&tenantId=' + tenantId + '&marketerId=' + marketerId;
    } else {
      url = 'venture-sales-by-salesrep?' + 'ventureId=' + ventureId + '&tenantId=' + tenantId;
    }
    this.restService.getVentureSalesBySalesrep(url).subscribe(response => {
      if (response.data != null && response.data != undefined) {
        this.salesrepVentureSales = response.data;
        this.dtTrigger.next();
      }
    },
    (error) => {
      console.log(error.status);
    });
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      searching: false
    };
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  checkSalesDetails(sales) {
    sessionStorage.setItem('activeTab', '1');
    this.commonService.setVentureTeam(sales);
    this.router.navigate(['../alldetails/'+sales.salesrepId], { relativeTo: this.route });
  }
}
