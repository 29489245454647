import { Component, ElementRef, OnChanges, OnInit, ViewChild } from '@angular/core';
import { DatePipe, Location } from '@angular/common'
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UserPrivileges, UserRoleConstants } from 'src/app/constants/AppConstants';
import { EditPlot } from 'src/app/models/editplot';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-plotbyid',
  templateUrl: './plotbyid.component.html',
  styleUrls: ['./plotbyid.component.scss']
})
export class PlotbyidComponent implements OnInit {
  editPlot: EditPlot;
  plotNum: string;
  measures: string;
  sizes: number;
  east: number;
  west: number;
  south: number;
  north: number;
  road: string;
  type: string;
  status: string;
  lastUpdatedOn: Date;
  lastUpdatedBy: number;
  error: boolean;
  errorMessage: any;
  updatedPlot: any;
  plotId: any;
  plotdetails: any = {};
  userInfo: any = {};
  edit = true;
  userPrivileges = UserPrivileges;
  isSplit = false;
  facings: any[] = [];
  plotTypes: any[] = [];
  splitCount:number = 0;
  modifiedPlots: any[] = [];
  plotSeries: any[] = ['A','B','C','D','E','F','G','H','I','J'];
  message: string;
  @ViewChild('successMsg') successMsg: ElementRef<HTMLElement>;
  closeResult: string;

  constructor(
    private restService: RestService,
    private location: Location,
    private route: ActivatedRoute,
    private authService: AuthServiceService,
    private commonService: CommonServiceService,
    private datePipe: DatePipe,
    private modalService: NgbModal
  ) {
    this.userInfo = this.authService.getUserInfo();
    this.route.params.subscribe(params => {
      this.plotId = params['plotId'];
    });
  }
  ngOnInit() {
    let data = {
      "tenantId": this.userInfo.tenantId,
      "ventureId": this.commonService.getVenture()?.ventureId,
      "plotId": this.plotId
    }
    this.restService.getPlotsById(data).subscribe(response => {
      if (response.data != null) {
        this.plotdetails = response.data;
        this.plotdetails.plotStatus = this.plotdetails.plotStatus.charAt(0).toUpperCase() + this.plotdetails.plotStatus.slice(1);
        this.plotdetails.lastUpdatedOn = this.datePipe.transform(this.plotdetails.lastUpdatedOn, 'MMM dd, yyyy');
      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      });
    if (this.authService.hasRole(UserRoleConstants.TENANT)) {
      this.edit = false;
    }
    this.getPlotFacingsAndTypes();
  }

  sendBack() {
    this.location.back();
  }

  getPlotFacingsAndTypes() {
    this.restService.getPlotFacingsAndTypes(this.commonService.getVenture()?.ventureId).subscribe(response => {
      if (response.data != null) {
        this.facings = response.data.facings;
        this.plotTypes = response.data.plotTypes;
      }
    });
  }

  splitPlot() {
    this.isSplit = !this.isSplit;
  }

  splitIntoPlots() {
    sessionStorage.setItem('selectedPlotDetails', JSON.stringify(this.plotdetails));
    if(this.splitCount) {
      this.modifiedPlots = [];
      for(let i=0; i < this.splitCount; i++) {
        let plot = JSON.parse(sessionStorage.getItem('selectedPlotDetails'));
        plot.plotNum = plot.plotNum.concat('-',this.plotSeries[i]);
        plot.size = null;
        plot.eastLength = null;
        plot.westLength = null;
        plot.northLength = null;
        plot.southLength = null;
        plot.roadFacing = null;
        plot.plotType = null;
        plot.eastSideContent = null;
        plot.westSideContent = null;
        plot.northSideContent = null;
        plot.southSideContent = null;
        this.modifiedPlots.push(plot);
      }
    } else {
      alert("Please select split count.");
    }
  }

  submitsplitPlots() {
    let isInvalid = false;
    this.modifiedPlots.forEach(plot => {
      if(!plot.size || !plot.eastLength || !plot.westLength || !plot.northLength || !plot.southLength || !plot.roadFacing || !plot.plotType || !plot.eastSideContent || !plot.westSideContent || !plot.northSideContent || !plot.southSideContent) {
        isInvalid = true;
      }
    });
    setTimeout(() => {
      if(!isInvalid) {
        let obj = {
          originalPlot: this.plotdetails,
          modifiedPlots: this.modifiedPlots
        }
        this.restService.submitSplitPlots(obj).subscribe(response => {
          if (response.data != null) {
             this.message = response.message;
            this.open(response.data);
          }
        });
      } else {
        alert("Please provide all required fields.");
      }
    }, 10);
  }

  open(content) {
    this.modalService.open(this.successMsg, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  closeAlert() {
    this.modalService.dismissAll('Cross click');
    this.sendBack();
  }

}
