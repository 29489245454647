import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Color, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { UserRoleConstants } from 'src/app/constants/AppConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-commissionsummary',
  templateUrl: './commissionsummary.component.html',
  styleUrls: ['./commissionsummary.component.scss']
})
export class CommissionsummaryComponent implements OnInit {
  summary: any = [];
  performance: any = [];
  list: any = [];
  plots: any = [];
  ventureId: any;
  plotData: any = [];
  marketerSale: any = [];

  constructor(private restService: RestService,
    private authService: AuthServiceService,
    private route: ActivatedRoute,
    private location: Location,
    private commonService: CommonServiceService) {
  }
  ngOnInit(): void {
    const userInfo: any = this.authService.getUserInfo();
    const data: any = {
      tenantId: userInfo.tenantId,
      marketerId: userInfo.marketerId,
      ventureId: this.commonService.getVenture()?.ventureId,
    };
    const pathF: string = (this.authService.hasRole(UserRoleConstants.MARKETER)) ? 'marketer/commissionSummary' : 'tenant/commissionSummary';
    this.restService.submitPostRequest(pathF, data).subscribe(response => {
      this.summary = response.data.commissionsDetailsList;
      this.lineChartData = [
        { data: this.summary.map(a => a.totalCommission), label: 'Total Commission' },
        { data: this.summary.map(a => a.commissionPaid), label: 'Commission Paid' },
      ]
      this.lineChartLabels = this.summary.map(a => a.month);
    });
    const path: string = (this.authService.hasRole(UserRoleConstants.MARKETER)) ? 'marketer/marketerVentureDashboard' :
      'tenant/tenantVentureDashboard';
    this.restService.submitPostRequest(path, data).subscribe(response => {
      this.performance = response.data;
      this.marketerSale.push(this.performance.marketerCommission);
    });
  }
  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[]
  public lineChartOptions: ChartOptions = {
    responsive: true,
    legend:{ 
      display:false
    },
    scales: {
      xAxes: [{}], yAxes: [{
        //  display: false,
        ticks: {
          //max: 120
        }
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    },
  };
  public lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];
}
