import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UserRoleConstants } from 'src/app/constants/AppConstants';
import { RoutesConstants } from 'src/app/constants/RoutesConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  userdata: any;
  submitted = false;
  errorMessage: any;
  error: boolean;
  urlSuffix: any;
  logoUrl: any;
  bannerUrl: any;
  measure: any;
  userRoles = UserRoleConstants;
  validUrlSuffix : boolean = true;
  pwaAccess = true;

  constructor(
    private route: ActivatedRoute,
    public authService: AuthServiceService,
    public commonService: CommonServiceService,
    private formBuilder: FormBuilder,
    public restService: RestService,
    private router: Router
  ) {
    if(this.router.url.includes('logout')) {
      this.restService.submitGetRequest('logout').subscribe();
      sessionStorage.clear();
      this.commonService.clear();
    }
  }

    get f() {
      return this.loginForm.controls;
    }

    onSubmit(isValid:boolean) {
      this.submitted = true;

      sessionStorage.clear();

      if(isValid){
      let data = {
        username: this.loginForm.get('username').value,
        password: this.loginForm.get('password').value
      }          
  
      this.authService
			.login(data)
			.pipe(
				tap((response) => {
					this.authService.setUserInfo(response);
            if (response.scopes.length === 1 && response.scopes.includes('CHANGE_PASSWORD_PRIVILEGE')) {
            this.router.navigate([
              this.urlSuffix,
              RoutesConstants.CHANGE_PASSWORD
            ]);
          } else {
            switch (response.role) {
              case UserRoleConstants.TENANT:
                this.router.navigate([
                  this.urlSuffix,
                  RoutesConstants.TENANT_LAYOUT,
                  RoutesConstants.TENANT_DASHBOARD,
                ]);
                break;
              case UserRoleConstants.MARKETER:
                this.router.navigate([
                  this.urlSuffix,
                  RoutesConstants.MARKETERLAYOUT,
                  RoutesConstants.MARKETERDASHBOARD,
                ]);
                break;
              case UserRoleConstants.SALESREP:
                this.router.navigate([
                  this.urlSuffix,
                  RoutesConstants.SALESREPLAYOUT,
                  RoutesConstants.SALESREPDASHBOARD,
                ]);
                break;
              default:
                this.router.navigate([RoutesConstants.LOGOUT]);
            }
          }
				}),
				catchError((error) => {
					this.error = true;
					this.errorMessage = error.status;
					return of(error);
				})
			)
			.subscribe();
      }
    }

  ngOnInit() {

    this.route.params.subscribe((params: Params) => {
      this.urlSuffix = params['urlSuffix'];
    });
    if(this.urlSuffix) {
      this.urlSuffixVerification(this.urlSuffix);
    }


    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  urlSuffixVerification(urlSuffix : String) {
    let url;
    url = '/public/urlSuffixVerification?' + 'urlSuffix='+urlSuffix;
    
    this.restService.urlSuffixVerification(url).subscribe((response: any) => {
      if (response != null && response != undefined) {
        this.getLandingPageFilesUrls(urlSuffix);
      }
    },
    (error: any) => {
      console.log(error);
      if(error.status == 500 && error.error.message == "invalid") {
        this.validUrlSuffix = false;  
        if(urlSuffix != "pwa"){
          this.pwaAccess = false;
        }
        this.logoUrl = 'assets/images/logo.svg';
        this.bannerUrl = 'assets/images/login-bg.png';     
      }
    });
  }
  getLandingPageFilesUrls(urlSuffix : any) {
    let url;
    url = '/public/landingPageFilesUrls?' + 'urlSuffix='+urlSuffix;
    
    this.restService.getLandingPageFilesUrls(url).subscribe(response => {
      if (response != null && response != undefined) {
        this.logoUrl = response.data.logoUrl;
        this.bannerUrl = response.data.bannerUrl;   
        this.measure = response.data.measure;
        localStorage.setItem('urlSuffix',urlSuffix); 
        localStorage.setItem('logoUrl',this.logoUrl);
        localStorage.setItem('bannerUrl',this.bannerUrl);
        localStorage.setItem('measure',this.measure);
      }
    },
    (error) => {
      this.logoUrl = 'assets/images/logo.svg';
      this.bannerUrl = 'assets/images/login-bg.png'; 
      console.log(error.status);
    });
  }

}
