<ng-container>
    <div class="my-3">
      <div class="card">
        <div class="card-body">
          <form #details="ngForm">
            <div class="row">
              <div class="form-group col-md-4">
                <label>Ventures</label>
                <ng-multiselect-dropdown name="select" [placeholder]="'Select'" [data]="ventures" [settings]="dropdownSettings"
                  (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelect($event)"
                  (onDeSelectAll)="onUnSelectAll()">
                </ng-multiselect-dropdown>
              </div>
              <div class="form-group col-md-4" *ngIf="userInfo?.scopes.includes('MARKETER_SUPER_PRIVILEGE') || userInfo?.scopes.includes('MARKETER_BASIC_PRIVILEGE')">
                <label class="star">Salesrep</label>
                <select type="text" class="form-control" id="team" name="team" [(ngModel)]="filters.salesrepId">
                  <option *ngFor="let team of teamList" [value]="team.salesrepId">{{team.name}}</option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <label>Type</label>
                <select type="text" class="form-control" id="type" name="type" [(ngModel)]="filters.type">
                  <option [value]="'all'">All</option>
                  <option [value]="'direct'">Direct</option>
                  <option [value]="'indirect'">Indirect</option>
                </select>
              </div>
              <div class="form-group col-md-4" *ngIf="userInfo?.scopes.includes('SALESREP_PRIVILEGE')">&nbsp;</div>
              <div class="form-group col-md-4">
                  <label>From Date</label>
                  <input type="date" class="form-control" id="fromDate" name="fromDate" [(ngModel)]="filters.fromDate">
              </div>
              <div class="form-group col-md-4">
                <label>To Date</label>
                <input type="date" class="form-control" id="toDate" name="toDate" [(ngModel)]="filters.toDate">
              </div>
              <div class="col-md-2 mt-4 pt-1" style="font-size: medium;">
                <button class="btn-info py-1 px-3 border rounded" (click)="getPerformances()"
                  [disabled]=details.invalid><b>Go</b></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="my-3" *ngIf="salesrepSalesArray?.salesrepSalesList?.length > 0">
    <div class="card">
        <div class="card-body">
          <h6 class="card-title text-dark position-relative pb-2">Sales</h6>
          <div class="container-fluid my-4">
              <div class="row">
                  <div class="col-2">Total Plots Sold: <b>{{salesrepSalesArray?.salesrepSalesSummary?.plotsSold }}</b></div>
                  <div class="col-2">Total Sale Value: <b>&#8377;{{salesrepSalesArray?.salesrepSalesSummary?.saleValue?.toLocaleString('en-IN')}}</b></div>
                  <div class="col-2">Amount Received: <b>&#8377;{{salesrepSalesArray?.salesrepSalesSummary?.amountReceived?.toLocaleString('en-IN')}}</b></div>
                  <div class="col-3">Amount Pending: <b>&#8377;{{salesrepSalesArray?.salesrepSalesSummary?.amountPending?.toLocaleString('en-IN')}}</b></div>
                  <div class="col-2">Area Sold ({{commonService.getMeasure()}}): <b>{{salesrepSalesArray?.salesrepSalesSummary?.areaSold.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</b></div>
              </div>
            </div>
        </div>
      </div>
  </div>
  <div class="my-3">
    <div class="card" *ngIf="salesrepSalesArray?.salesrepSalesList?.length > 0">
        <div class="card-body">
            <div class="wrap">
                <table class="table table-striped table-bordered table-sm row-border hover">
                    <thead>
                        <tr>
                            <th>Salesrep</th>
                            <th>Venture Name</th>
                            <th>Plot Number</th>
                            <th>Plot Status</th>
                            <th>Plot Size ({{commonService.getMeasure()}})</th>
                            <th>Per {{commonService.getMeasure()}} Price (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                            <th>Sale Value (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                            <th>Amount Received (<i class="fas fa-rupee-sign mr-1"></i>)</th>
                            <th>Sale Date</th>                
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let sale of salesrepSalesArray?.salesrepSalesList">
                            <td>{{sale.salesrepName}}</td>
                            <td>{{sale.ventureName}}</td>
                            <td>{{sale.plotNumber}}</td>
                            <td>{{sale.plotStatus | titlecase}}</td>
                            <td>{{sale.plotSize.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                            <td>{{sale.perMeasurePrice?.toLocaleString('en-IN')}}</td>
                            <td>{{sale.saleValue?.toLocaleString('en-IN')}}</td>
                            <td>{{sale.saleAmountReceived?.toLocaleString('en-IN')}}</td>
                            <td>{{sale.saleDate | date}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>