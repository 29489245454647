import { Component, OnChanges, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-plots-salesrep',
  templateUrl: './plots-salesrep.component.html',
  styleUrls: ['./plots-salesrep.component.scss']
})
export class PlotsSalesrepComponent implements OnInit {
  plots = [];
  dtOptions: any = {};
  ventureSelected: any;
  ventures: any = [];
  plotinfo: any = [];
  plotData = [];
  show: any = true;
  dtTrigger: Subject<any> = new Subject<any>();
  constructor(
    private restService: RestService,
    private authService: AuthServiceService
  ) { }

  ngOnInit(): void {
    const userInfo = this.authService.getUserInfo();
    const path: string = 'salesrep/assigned-ventures/' + userInfo.salesrepId;
    this.restService.submitGetRequest(path).subscribe(response => {
      this.ventures = response.data;
    });
  }

  venture() {
    this.show = false;
    const userInfo = this.authService.getUserInfo();
    let data = {
      "tenantId": userInfo?.tenantId,
      "ventureId": this.ventureSelected
    }
  this.restService.getPlots(data).subscribe(response => {
      this.plotinfo = response.data;

    });


  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
