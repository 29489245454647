import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-receivables',
  templateUrl: './receivables.component.html',
  styleUrls: ['./receivables.component.scss']
})
export class ReceivablesComponent implements OnInit {

  Receivables: any = [];
  error: boolean;
  errorMessage: any;
  submit = false;
  userInfo: any;
  ventureName: any;

  constructor(
    private restService: RestService,
    private authService: AuthServiceService,
    private commonService: CommonServiceService,
    private datePipe: DatePipe
  ) { }

  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();

  ngOnInit(): void {
    this.ventureName = this.commonService.getVenture()?.displayName;

    this.userInfo = this.authService.getUserInfo();
    const path: string = this.userInfo.role.toLowerCase() + '/get-receivables';
    let data = {
      "tenantId": this.userInfo.tenantId,
      "marketerId": this.userInfo.marketerId,
      "ventureId": this.commonService.getVenture()?.ventureId
    }
    this.restService.submitPostRequest(path, data).subscribe(response => {
      if (response) {
        this.Receivables = response
        this.dtTrigger.next()
      }
    }, (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      bSort: false,
      searching: false,
    };
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  capitalPlot(plot) {
    const str = plot;
    return str.toUpperCase()
  }

  capitalFirst(type) {
    const mySentence = type;
    return mySentence.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  }

  onSubmit() { this.submit = true; }

  onShow() { this.submit = false; }

  exportPayments(){
    const formattedData = this.Receivables.receivables.map(customerPayment => ({
      "Plot": customerPayment.plotNumber, 
      "Customer": customerPayment.name,
      "Received": customerPayment.amount,
      "Payment Mode": customerPayment.mode,
      "Comments": customerPayment.comments,
      "Received Date": this.datePipe.transform(customerPayment.date, 'dd-MM-yyyy'),
    }));
    this.commonService.exportToExcel(formattedData, 'CustomerPayments', 'customer_payments');
  }
}
