import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterContentInit, AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(150%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})

export class AdminLayoutComponent implements OnInit, AfterContentInit {
  settingsMenuState: string = 'out';
  headerhrizontal: boolean = false;
  constructor(public themeService: ThemeService, public authService: AuthServiceService) {

   }

  ngOnInit() { }

  sideBarEvent() {
    this.headerhrizontal = !this.headerhrizontal;
  }

  //  settings
  toggleSettingsMenu() {
    this.settingsMenuState = this.settingsMenuState === 'out' ? 'in' : 'out';
  }

  ngAfterContentInit(){
    this.themeService.getColorCookie();    
  }

  ngAfterViewInit(){
    if(this.themeService.getThemeCookie()){
      this.themeService.toggleField()
    }
  }

  menuToggle() {
    this.themeService.menuToggle();
  }

}
