<div id="container">
    <div *ngxPermissionsOnly="[userPrivileges.TENANT_PRIVILEGE]">
        <div class="card mt-3" *ngIf="showUploadVenture">
            <div class="card-body">
                <h6 class="card-title text-dark position-relative pb-2 mb-3">
                    Upload File
                </h6>
                <div class="col-md-6">
                    <div>
                        <p>Browse Files</p>
                        <div class="d-flex justify-content-between">
                            <input type="file" (change)="onLayoutFileSelected($event)" [(ngModel)]="layoutFile">
                            <button (click)="uploadLayoutFile()" class="btn btn-info btn-sm">Upload</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!showUploadVenture">
        <h4 class="text-dark position-relative pb-2 text-center">{{plotDetails?.ventureLayoutData?.ventureName}}</h4>
        <canvas #canvas width="150" height="150"></canvas>
        <div id="tooltip"
            style="position: absolute; display: none; background-color: #f9f9f9; padding: 5px; border: 1px solid black;">
        </div>
    </div>
</div>