<div class="container-fluid my-3 text-dark">
    <form #details="ngForm">
        <div id="ventureList" class="content">
            <div class="row">
                <div class="col-md-6">
                    <label>Venture List</label>
                    <ng-autocomplete [data]="ventureList" name="ventureList_Name" [focusFirst]=true
                        [(ngModel)]="ventureSelected" [searchKeyword]="'ventureName'" [itemTemplate]="ventureTemplate"
                        [notFoundTemplate]="notFoundTemplate" id="ventureList_Name_id">
                    </ng-autocomplete>

                    <ng-template #ventureTemplate let-item>
                        <div class="item">
                            <a [innerHTML]="item.ventureName"></a>
                        </div>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                        <div>Not Found</div>
                    </ng-template>
                </div>
                <!-- <div class="form-group col-md-6"
                    [ngClass]="{'invalid': (plotsSold.invalid && (plotsSold.dirty || plotsSold.touched))}">
                    <label class="star">Plots Sold</label>
                    <input type="text" class="form-control" id="plotsSold" required name="plotsSold"
                        [(ngModel)]="marketerventure.plotsSold" #plotsSold="ngModel">
                </div>

                <div class="form-group col-md-6"
                    [ngClass]="{'invalid': (plotsBlocked.invalid && (plotsBlocked.dirty || plotsBlocked.touched))}">
                    <label class="star">Plots Blocked</label>
                    <input type="text" class="form-control" id="plotsBlocked" name="plotsBlocked"
                        [(ngModel)]="marketerventure.plotsBlocked" #plotsBlocked="ngModel" required>
                </div>
                <div class="form-group col-md-6"
                    [ngClass]="{'invalid': (plotsAttached.invalid && (plotsAttached.dirty || plotsAttached.touched))}">
                    <label class="star">Plots Attached</label>
                    <input type="number" class="form-control" id="plotsAttached" name="plotsAttached"
                        #plotsAttached="ngModel" required [(ngModel)]="marketerventure.plotsAttached">
                </div>

                <div class="form-group col-md-6"
                    [ngClass]="{'invalid': (valueSold.invalid && (valueSold.dirty || valueSold.touched))}">
                    <label class="star">Value Sold</label>
                    <input type="number" class="form-control" id="valueSold" name="valueSold" #valueSold="ngModel"
                        required [(ngModel)]="marketerventure.valueSold">
                </div>

                <div class="form-group col-md-6"
                    [ngClass]="{'invalid': (valueBlocked.invalid && (valueBlocked.dirty || valueBlocked.touched))}">
                    <label class="star">Value Blocked</label>
                    <input type="text" class="form-control" id="valueBlocked" name="valueBlocked"
                        #valueBlocked="ngModel" required [(ngModel)]="marketerventure.valueBlocked">
                </div>

                <div class="form-group col-md-6"
                    [ngClass]="{'invalid': (valueAttached.invalid && (valueAttached.dirty || valueAttached.touched))}">
                    <label class="star">Value Attached</label>
                    <input type="text" class="form-control" id="valueAttached" name="valueAttached"
                        #valueAttached="ngModel" required [(ngModel)]="marketerventure.valueAttached">
                </div>
                <div class="form-group col-md-6"
                    [ngClass]="{'invalid': (valueReceive.invalid && (valueReceive.dirty || valueReceive.touched))}">
                    <label class="star">Value Receive</label>
                    <input type="text" class="form-control" id="valueReceive" name="valueReceive"
                        #valueReceive="ngModel" required [(ngModel)]="marketerventure.valueReceive">
                </div>
                <div class="form-group col-md-6"
                    [ngClass]="{'invalid': (developerPrice.invalid && (developerPrice.dirty || developerPrice.touched))}">
                    <label class="star">Developer Price</label>
                    <input type="text" class="form-control" id="developerPrice" name="developerPrice"
                        #developerPrice="ngModel" required [(ngModel)]="marketerventure.developerPrice">
                </div>
                <div class="form-group col-md-6"
                    [ngClass]="{'invalid': (maxSellingPrice.invalid && (maxSellingPrice.dirty || maxSellingPrice.touched))}">
                    <label class="star">Max Selling Price</label>
                    <input type="number" class="form-control" id="maxSellingPrice" name="maxSellingPrice"
                        #maxSellingPrice="ngModel" required [(ngModel)]="marketerventure.maxSalePrice">
                </div>

                <div class="form-group col-md-6"
                    [ngClass]="{'invalid': (minSellingPrice.invalid && (minSellingPrice.dirty || minSellingPrice.touched))}">
                    <label class="star">Min Selling Price</label>
                    <input type="number" class="form-control" id="minSellingPrice" name="minSellingPrice"
                        #minSellingPrice="ngModel" required [(ngModel)]="marketerventure.minSalePrice">
                </div>
                <div class="form-group col-md-6"
                    [ngClass]="{'invalid': (ventureMarketingStatus.invalid && (ventureMarketingStatus.dirty || ventureMarketingStatus.touched))}">
                    <label class="star">Status</label>
                    <select type="text" class="form-control" name="ventureMarketingStatus" id="ventureMarketingStatus"
                        required #ventureMarketingStatus="ngModel" [(ngModel)]="marketerventure.ventureMarketingStatus">
                        <option value="available">Available</option>
                        <option value="notavailable">Not Available</option>
                    </select>
                </div> -->
                <div class="form-group col-md-6"
                    [ngClass]="{'invalid': (effectiveDate.invalid && (effectiveDate.dirty || effectiveDate.touched))}">
                    <label class="star">Effective Date</label>
                    <input type="date" class="form-control" id="effectiveDate" required name="effectiveDate"
                        #effectiveDate="ngModel" [(ngModel)]="marketerventure.effectiveDate" max="9999-12-31">
                </div>
                <!-- <div class="form-group col-md-6">
                    <label class="star">Comments</label>
                    <input type="text" class="form-control" id="comments" name="comments" #comments="ngModel"
                        [(ngModel)]="marketerventure.comments">
                </div> -->
                <div class="col-md-12">
                    <button type="submit" class="btn btn-info" [disabled]=details.invalid
                        (click)="onSubmit(details.value)">Submit</button>
                </div>
            </div>
        </div>
    </form>
</div>