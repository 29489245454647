import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CommonServiceService } from '../../../services/common-service.service';

@Component({
  selector: 'app-venturesaledetails',
  templateUrl: './venturesaledetails.component.html',
  styleUrls: ['./venturesaledetails.component.scss']
})
export class VenturesaledetailsComponent implements OnInit {
  active = 1;
  venture: any;
  name: any;
  ventureName: any;
  show: any = false;

  constructor(
    private activatedRouter: ActivatedRoute,
    private location: Location,
    private commonService: CommonServiceService
  ) {
    this.activatedRouter.queryParams.subscribe(
      (params: any) => {
        this.venture = params['ventureId'];
      });
    this.activatedRouter.queryParams.subscribe(
      (params: any) => {
        this.name = params['currentMonth'];
      });
  }
  ngOnInit(): void {
    this.ventureName = this.commonService.getVenture()?.displayName;
    if (this.name) {
      this.show = true;
      this.active = 2;
    }
    if (this.venture) {
      this.show = true;
      this.active = 4;
    }
  }
  sendBack() {
    this.location.back();
  }
}
