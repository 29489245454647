import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subject } from 'rxjs';
import { UserPrivileges, UserRoleConstants } from 'src/app/constants/AppConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-m-sold-plots',
  templateUrl: './m-sold-plots.component.html',
  styleUrls: ['./m-sold-plots.component.scss']
})
export class MSoldPlotsComponent implements OnInit {

  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  mSoldPlots: any = [];
  userInfo: any;
  popup = true;
  releasingMsoldPlot: any;
  closeResult = '';
  error: boolean;
  errorMessage: any;
  ventureId: any;


  constructor(
    private restService: RestService,
    public commonService: CommonServiceService,
    private authService: AuthServiceService,
    private modalService: NgbModal,
    private permissionsService: NgxPermissionsService
  ) { }

  ngOnInit(): void {

    this.ventureId = this.commonService.getVenture()?.ventureId;

    this.fetchMsoldPlots();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      searching: false,
    };
  }

  fetchMsoldPlots(){

    this.userInfo = this.authService.getUserInfo();

    let url;

    if(this.userInfo.scopes.includes('TENANT_PRIVILEGE') || this.userInfo.scopes.includes('TENANT_BASIC_PRIVILEGE')) {
      url = 'tenant/get-m-sold-plots?ventureId=' + this.ventureId;
    } else if(this.userInfo.scopes.includes('MARKETER_SUPER_PRIVILEGE') || this.userInfo.scopes.includes('MARKETER_BASIC_PRIVILEGE')) {
      url = 'marketer/get-m-sold-plots?ventureId=' + this.ventureId;
  }

  this.dtTrigger.next();
    this.restService.getMsoldPlots(url).subscribe(response => {
      if (response != null && response != undefined) {
        this.mSoldPlots = response.data;

        if (this.authService.hasRole(UserRoleConstants.TENANT)) {
          this.permissionsService.hasPermission(UserPrivileges.TENANT_PRIVILEGE).then(hasTenantPrivilege => {
            if (hasTenantPrivilege) {
              this.popup = false;      
            }
          });
        }

      }
    },
    (error) => {
      console.log(error.status);
    });

  }

  capitalFirst(type) {
    const mySentence = type;
    return mySentence.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  }

  open(content, plot) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.releasingMsoldPlot = plot;
  }

  private getDismissReason(reason: any): string {

    return `with: ${reason}`;

  }

  releaseMsoldPlot(){

    const requestData = {
      plotId: this.releasingMsoldPlot.plotId
    };
    this.restService.releaseMsoldPlots(requestData).subscribe((response) => {
        this.modalService.dismissAll();
        this.fetchMsoldPlots();
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      });

  }
}
