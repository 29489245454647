import { Component, Input, OnInit } from '@angular/core';
import { UserPrivileges, UserRoleConstants } from 'src/app/constants/AppConstants';
import { Person } from 'src/app/models/personal-details';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { RestService } from 'src/app/services/rest.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.scss']
})
export class MyprofileComponent implements OnInit {
@Input() sales;
  setAdmin: boolean;
  isNavSolid: boolean;
  checked = false;
  navMode: any;
  personalDetails: any = [];
  mode: string;
  layout: string;
  themeLayout: string;
  myDetails: any = [];
  person: any = [];
  bankArray: any = [];
  error: boolean;
  errorMessage: any;
  bankDeatils: any;
  address: any = [];
  commAddress: any = [];
  isshow = false;
  path: any;
  logoFile: File;
  bannerFile: File;
  userInfo: any;
  logoUrl: any;
  bannerUrl: any;
  userPrivileges = UserPrivileges;

  constructor(
    public themeService: ThemeService,
    private authService: AuthServiceService,
    private restService: RestService
  ) {
  }

  value: string;
  name: any;
  vals: any;
  bgPrimary: any;
  allcookies: any;
  cookiearray: any;
  salesrepId = 100015010001;

  setColorCookie(name: string, val: string) {
    this.value = val;
    document.cookie = name + "=" + this.value + ";  path=/";
  }


  sidebarToggleNewTenant(e) {
    this.checked = e.target.checked;
    if (this.checked) {
      this.navMode = "solid";
      document.cookie = this.mode + "=" + this.navMode + "; path=/";
    }
    this.themeService.toggleSidebarNew();
    if (!this.checked) {
      this.navMode = "image"
      document.cookie = this.mode + "=" + this.navMode + "; path=/";
    }
  }


  horizontalHeader(e) {
    this.checked = e.target.checked;
    if (this.checked) {
      document.cookie = 'layout' + "=" + 'horizontal' + "; path=/";
    }
    if (!this.checked) {
      document.cookie = 'layout' + "=" + 'left' + "; path=/";
    }
    this.themeService.toggleField();
  }


  themeColorChange(value) {
    if (value === 'primary') {
      this.setColorCookie("color", "primary");
      this.themeService.setActiveTheme("primary")
    }
    else if (value === 'danger') {
      this.setColorCookie("color", "danger");
      this.themeService.setActiveTheme("danger")
    }
    else if (value === 'success') {
      this.setColorCookie("color", "success");
      this.themeService.setActiveTheme("success")
    }
    else if (value === 'info') {
      this.setColorCookie("color", "info");
      this.themeService.setActiveTheme("info")
    }
    else if (value === 'dark') {
      this.setColorCookie("color", "dark");
      this.themeService.setActiveTheme("dark")
    }
  }

  ngOnInit() {

    this.userInfo = this.authService.getUserInfo();

    if (this.sales) {
      this.personal();
    //  this.bank()
        }
   else{
    this.personalDetail();
    this.bankDetails();
     }
    
    this.setAdmin = this.authService.hasRole(UserRoleConstants.ADMIN);
    localStorage.setItem("mode", "image");

    this.getFIlesUrls();

  }
  ngAfterContentInit() {
    if (this.themeService.themLayoutTop == "horizontal") {
      this.checked = true;
    }
  }

  onChangeSave(personalDetails: Person, myProfile) {
    if (myProfile.valid) {
      this.saveProgramData();
    }
  }
  saveProgramData() {
  }

  bankDetails() {
    let bankData = {
      "tenantId": this.authService.getUserInfo().tenantId, 
      "bankAccountId": this.authService.getUserInfo().personId,
    }
    this.restService.getbankDetails(bankData).subscribe(response => {
      if (response )
        this.bankArray =response.data;
        //  this.bankArray.map((value)=>{
        //    this.bankDeatils = value;
        // })
    }, (error) => {
        this.error = true;
        this.errorMessage = error.status;
      });

  }
  personalDetail() {
if (this.authService.hasRole(UserRoleConstants.MARKETER)) {
  this.path = 'marketer/getMarketerById';
}
else if (this.authService.hasRole(UserRoleConstants.SALESREP)) {
  this.path = 'venture-team/salesrep/get-salesrep';
}else{
  this.path = 'tenant/getTenantById';
}
//     const path: string = (this.authService.hasRole(UserRoleConstants.MARKETER))? 'marketer/getMarketerById':
//     'tenant/getTenantById';
 // const  path: string = 'marketer/getMarketerById'
    let data = {
      "salesrepId": this.authService.getUserInfo().salesrepId,
      "tenantId": this.authService.getUserInfo().tenantId,
      "marketerId": this.authService.getUserInfo().marketerId,
    }
    this.restService.submitPostRequest(this.path,data).subscribe(response => {
      if (response) {
        this.myDetails = response;
        if (this.authService.hasRole(UserRoleConstants.SALESREP)) {
          this.person = this.myDetails.data;
          this.address = this.myDetails.data.primaryAddress;
          this.commAddress = this.myDetails.data.secondaryAddress;
        }else{
        this.person = this.myDetails.managingDirector;
        this.address = this.myDetails.primaryAddress;
        this.commAddress = this.myDetails.secondaryAddress;
        }
       

        this.personalDetails = this.person;
        //push(this.myDetails);
      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      });
  }
  // bank() {
  //   let bankData = {
  //     "tenantId": 10001,
  //     "bankAccountId": this.sales
  //   }
  //   this.restService.getbankDetails(bankData).subscribe(response => {
  //     if (bankData != null )
  //       this.bankArray.push(response)
  //       this.bankArray.map((value)=>{
  //         this.bankDeatils = value;
  //       });
  //   }, (error) => {
  //       this.error = true;
  //       this.errorMessage = error.status;
  //     });

  // }
  personal() {
    let data = {
      "salesrepId": this.sales,
      "tenantId": 10001,
      "marketerId": 10001501
    }
    this.restService.getmyDetails(data).subscribe(response => {
      if (response.data != null) {
        this.myDetails = response.data;
        this.person.push(this.myDetails)
      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      });
  }
  addbank(){
  this.isshow = true;
}
goBack(){
  this.isshow = false;

}

onLogoFileSelected(event: any) {
  this.logoFile = event.target.files[0];
}

onBannerFileSelected(event: any) {
  this.bannerFile = event.target.files[0];
}

uploadLogoFile(){
  if (this.logoFile) {
    const formData = new FormData();
    formData.append('file', this.logoFile);
    formData.append('tenantId',this.userInfo.tenantId);

    if (this.logoFile && this.logoFile.type.startsWith('image/')) {      
    this.restService.uploadLogoFile(formData).subscribe(response => {
      if (response) {
        this.logoFile = null;
        this.ngOnInit();
      }
    });
    } else {
      alert('Invalid file type. Please select an image file.');
    }
  } else {
    console.log('No file selected');
  }
}

uploadBannerFile(){
  if (this.bannerFile) {
    const formData = new FormData();
    formData.append('file', this.bannerFile);
    formData.append('tenantId',this.userInfo.tenantId);
    if (this.bannerFile && this.bannerFile.type.startsWith('image/')) { 
      this.restService.uploadBannerFile(formData).subscribe(response => {
        if (response) {
          this.bannerFile = null;
          this.ngOnInit();
        }
      });
      } else {
        alert('Invalid file type. Please select an image file.');
      }
  } else {
    console.log('No file selected');
  }
}

getFIlesUrls(){
  let url1;
  url1 = '/tenant/logo?' +'tenantId='+this.userInfo.tenantId;
  this.restService.getFilesUrls(url1).subscribe(response => {
    if (response != null && response != undefined) {
      this.logoUrl = response.data;   
    }
  });
  let url2;
  url2 = '/tenant/banner?' +'tenantId='+this.userInfo.tenantId;
  this.restService.getFilesUrls(url2).subscribe(response => {
    if (response != null && response != undefined) {
      this.bannerUrl = response.data;   
    }
  });
}

extractFileNameFromUrl(url: string): string {
  const fileNameWithExtension = url.substring(url.lastIndexOf('/') + 1);
  const decodedFileName = decodeURIComponent(fileNameWithExtension);
  const fileName = decodedFileName.split('%2F').pop();
  return fileName.substring(fileName.lastIndexOf('/') + 1);
}

showErrorMessage(message: string): void {
  this.errorMessage = message;
  setTimeout(() => {
    this.errorMessage = ''; 
  }, 3000);
}

}

