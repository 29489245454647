<div class=" my-3">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-6 mb-0">
                    <h6 class="card-title text-dark position-relative pb-2 h6">Promos</h6>
                </div>
                <div class="col-md-6 text-right mb-0" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE]">
                    <button  class="btn btn-sm btn-info" (click)="addPromo()">Add Promo</button>
                </div>
            </div>
            <div class="wrap table-responsive">
                <table *ngIf="promos?.length > 0" class="table table-striped table-bordered table-sm row-border hover" datatable
                    [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Ventures</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Last Updated On</th>
                            <th *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE]">Edit</th>
                            <th *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE]">Close</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let promo of promos">
                        <tr>
                            <td class="pointer" (click)="expand(promo)">{{promo.name}}</td>
                            <td><ng-container
                                    *ngFor="let venturesList of promo.incentiveProgramVenturesList; let last = last">
                                    {{venturesList.ventureName}}<ng-container *ngIf="!last">,
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td>{{ promo.startDate | date:'dd/MM/yyyy'  }}</td>
                            <td>{{promo.endDate | date:'dd/MM/yyyy' }}</td>
                            <td>{{promo.lastUpdatedAt | date:'dd/MM/yyyy' }}</td>
                            <td *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE]" class="pointer" (click)="promoById(promo, editPromo)"><i class="fa fa-pencil" aria-hidden="true"></i>
                            </td>
                            <td *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE]" class="pointer" (click)="open(closePromo)"><i class="fa fa-times" aria-hidden="true"></i>
                            </td>
                        </tr>
                        <tr *ngIf="promo?.expand">
                            <td colspan="7">
                                <div class=" text-right mb-2">
                                    <button class="btn btn-sm btn-info" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE]"
                                        (click)="openAddPromoItem(addOrEditPromoItem, promo)">Add Promo Item</button>
                                </div>
                                <table *ngIf="promo.incentiveDetails?.length > 0" class="table table-striped table-bordered table-sm row-border hover" datatable
                                    [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                    <thead>
                                        <tr class=" bg-white">
                                            <th>#</th>
                                            <th>Area ({{commonService.getMeasure()}})</th>
                                            <th>Payment Percentage</th>
                                            <th>Reward</th>
                                            <th *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE]">Edit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let incentive of promo.incentiveDetails; let i= index">
                                            <td>{{i+1}}</td>
                                            <td>{{incentive.area.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                                            <td>{{incentive.paymentPercentage}}</td>
                                            <td>{{incentive.prize}}</td>
                                            <td *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE]" class="pointer" (click)="openEditPromoItem(promo, incentive, addOrEditPromoItem)"><i class="fa fa-pencil"
                                                    aria-hidden="true"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p *ngIf="promo.incentiveDetails?.length > 0">
                                    <a class="pointer" (click)="displayPromoSales(promo)">
                                        <i *ngIf="!promo.isPromoSales" class="fa fa-chevron-right" aria-hidden="true"></i>
                                        <i *ngIf="promo.isPromoSales" class="fa fa-chevron-down" aria-hidden="true"></i> Promo Sales</a>
                                </p>
                                <div *ngIf="promo.incentiveDetails?.length == 0" class="no-data">
                                    <p>No Incentives are available</p>
                                </div>
                                <div *ngIf="promo.isPromoSales">
                                    <div class="row pb-3 px-2">
                                        <div class="col-md-6">
                                            <p *ngIf="promo.promoSales?.lastRunAt">
                                                Last Run At: {{promo.promoSales.lastRunAt | date:'dd/MM/yyyy hh:mm'}}
                                            </p>
                                            <p *ngIf="!promo.promoSales?.lastRunAt">
                                                Last Run At: No Initial Run
                                            </p>
                                        </div>
                                        <div class="col-md-6 text-right mb-0" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE]">
                                            <button
                                                *ngIf="promo.promoSales?.status == null && !closed && role== 'Marketer'"
                                                class="btn btn-sm btn-info" (click)="runPromoSales(promo)">Run</button>
                                            <p *ngIf="promo.promoSales?.status != null"><b>Status:
                                                </b>Please check after sometime for the latest promo sales</p>
                                        </div>
                                    </div>
                                    <div *ngIf="promo.promoSales?.lastRunAt && promo.promoSales?.incentiveProgramSalesrepResultList.length > 0">
                                        <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions"
                                            [dtTrigger]="dtTrigger">
                                            <thead>
                                                <tr>
                                                    <th>Salesrep Name</th>
                                                    <th>Applicable Area Sold ({{commonService.getMeasure()}})</th>
                                                    <th>Qualified Area ({{commonService.getMeasure()}})</th>
                                                    <th>Reward</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let promoSales of promo.promoSales?.incentiveProgramSalesrepResultList">
                                                    <td>{{promoSales.salesrepName}}</td>
                                                    <td>{{promoSales.applicableAreaSold.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                                                    <td>{{promoSales.qualifiedArea.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                                                    <td>{{promoSales.prize}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="no-data" *ngIf="promo.promoSales?.incentiveProgramSalesrepResultList.length == 0">
                                        <p>No salesrep qualified for the incentive program</p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <ng-template #closePromo let-modal>
                            <div class="card">
                                <div class="modal-header">
                                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div class="card-body modal-body">
                                    <div class="text-center">
                                        <p class="close-content">Do want to close this Promo?</p>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 d-flex justify-content-center">
                                            <div class="submit-btn px-2">
                                                <button type="submit" class="btn btn-info" (click)="modal.dismiss('Cross click');"
                                                    #submitButton>Cancel</button>
                                            </div>
                                            <div class="submit-btn px-2">
                                                <button type="submit" class="btn btn-info" (click)="updatePromo(promo, true)"
                                                    #submitButton>Ok</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </tbody>
                </table>
                <div *ngIf="promos?.length == 0" class="no-data">
                    <p>No promos are available</p>
                </div>
            </div>
        </div>
    </div>
    <ng-template #editPromo let-modal>
        <div class="card">
            <div class="modal-header">
                <h6 class="modal-title" id="modal-basic-title">Edit Promo</h6>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="card-body modal-body">
                <div class="row mt-2">
    
                    <div class="form-group col-md-6">
                        <label class="star">Promo Name</label>
                        <input type="text" class="form-control" id="name" [(ngModel)]="promoData.name" required name="name">
                    </div>
    
                    <div class="form-group col-md-6">
                        <label class="star">Promo Ventures</label>
                        <ng-multiselect-dropdown required name="ventures" [placeholder]="'Select'" [data]="ventures"
                            [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelect($event)"
                            (onDeSelectAll)="onUnSelectAll()" [(ngModel)]="promoData.incentiveProgramVenturesList">
                        </ng-multiselect-dropdown>
                    </div>
    
                    <div class="form-group col-md-6">
                        <label class="star">Promo Start Date</label>
                        <input type="date" required class="form-control" id="startDate" [(ngModel)]="promoData.startDate"
                            #startDate="ngModel" required name="startDate">
                    </div>

                    <div class="form-group col-md-6">
                        <label class="star">Promo End Date</label>
                        <input type="date" required class="form-control" name="endDate" id="endDate"
                            [(ngModel)]="promoData.endDate" #endDate="ngModel">
                        <div class="text-danger" *ngIf="endDate.dirty && (endDate.value < startDate.value)">End date
                            should be after start date</div>
                        <div class="text-danger" *ngIf="endDate.dirty && (endDate.value < currentDate)">End date
                            should be after current date</div>    
                    </div>
                    
                    <div class="col-md-12 submit-btn">
                        <button type="submit" class="btn btn-info" (click)="onSubmit()" #submitButton [disabled]="!isPromoFormValid()">Submit</button>
                    </div>
    
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #addOrEditPromoItem let-modal>
        <div class="card">
            <div class="modal-header">
                <h6 class="modal-title" id="modal-basic-title">Promo Item - {{this.promoItem.promoname}}</h6>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
                    <span aria-hidden="true">×</span>
                </button>
            </div>

            <div class="card-body modal-body">
                <div class="row mt-2">

                    <div class="form-group col-md-6">
                        <label class="star">Qualifying Area ({{commonService.getMeasure()}})</label>
                        <input type="number" class="form-control" id="area" name="area" min="1" [(ngModel)]="promoItem.area" required>
                    </div>

                    <div class="form-group col-md-6">
                        <label class="star">Required Payment Percentage</label>
                        <input type="number" class="form-control" id="paymentPercentage"
                            name="paymentPercentage" min="1" [(ngModel)]="promoItem.paymentPercentage" required>
                    </div>

                    <div class="form-group col-md-6">
                        <label class="star">Reward</label>
                        <input type="text" class="form-control" id="prize" name="prize" [(ngModel)]="promoItem.prize" required> 
                    </div>


                    <div class="col-md-12 submit-btn">
                        <button type="submit" class="btn btn-info" (click)="onPromoItemSubmit()" #submitButton [disabled]="!isFormValid()">Submit</button>
                    </div>

                </div>
            </div>

        </div>
    </ng-template>
</div>