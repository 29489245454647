import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subject } from 'rxjs';
import { UserPrivileges, UserRoleConstants } from 'src/app/constants/AppConstants';
import { RoutesConstants } from 'src/app/constants/RoutesConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-landlord-plots',
  templateUrl: './landlord-plots.component.html',
  styleUrls: ['./landlord-plots.component.scss']
})
export class LandlordPlotsComponent implements OnInit {

  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  landlordPlots: any = [];
  userInfo: any;
  popup = true;
  releasingLandlordPlot: any;
  closeResult = '';
  error: boolean;
  errorMessage: any;
  ventureId: any;
  userPrivileges = UserPrivileges;

  constructor(
    private restService: RestService,
    public commonService: CommonServiceService,
    private authService: AuthServiceService,
    private modalService: NgbModal,
    private permissionsService: NgxPermissionsService,
    private router: Router,
  ) { }

  ngOnInit(): void {

    this.ventureId = this.commonService.getVenture()?.ventureId;

    this.fetchLandlordPlots();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      searching: false,
    };
  }

  fetchLandlordPlots(){

    this.userInfo = this.authService.getUserInfo();

    let url = 'plot/get-landlord-plots?ventureId=' + this.ventureId;

  this.dtTrigger.next();
    this.restService.getLandlordPlots(url).subscribe(response => {
      if (response != null && response != undefined) {
        this.landlordPlots = response.data;

        if (this.authService.hasRole(UserRoleConstants.TENANT)) {
          this.permissionsService.hasPermission(UserPrivileges.TENANT_PRIVILEGE).then(hasTenantPrivilege => {
            if (hasTenantPrivilege) {
              this.popup = false;      
            }
          });
        }

      }
    },
    (error) => {
      console.log(error.status);
    });
  }

  capitalFirst(type) {
    const mySentence = type;
    return mySentence.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  }

  open(content, plot) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.releasingLandlordPlot = plot;
  }

  private getDismissReason(reason: any): string {

    return `with: ${reason}`;

  }

  openReleaseAlert(releaseContent){

    if (this.landlordPlots && this.landlordPlots.length > 0) {
      this.modalService.open(releaseContent, { ariaLabelledBy: 'modal-basic-title' }).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    }

  }

  goToMarkPlotsToLandlord(){
    this.router.navigate(['/'+this.commonService.getUrlSuffix(), RoutesConstants.VENTURELAYOUT, RoutesConstants.LANDLORDMARKING]);
  }

  releaseLandlordPlots() {
    const plotIdList = this.landlordPlots.map(plot => plot.plotId);
    
    const requestData = {
        plotIdList: plotIdList
    };

    this.restService.releaseLandlordPlots(requestData).subscribe(
        (response) => {
            this.modalService.dismissAll();
            this.fetchLandlordPlots();
        },
        (error) => {
            this.error = true;
            this.errorMessage = error.status;
        }
    );
}

releaseLandlordPlot(){

  const requestData = {
    plotIdList: [this.releasingLandlordPlot.plotId]
  };
  this.restService.releaseLandlordPlots(requestData).subscribe((response) => {
      this.modalService.dismissAll();
      this.fetchLandlordPlots();
  },
    (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });

}

}
