import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { UserRoleConstants } from 'src/app/constants/AppConstants';
import { User } from 'src/app/models/user.model';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({ marginLeft: '0' })),
      state('false', style({ marginLeft: '-20rem' })),
      transition('false <=> true', animate(500))
    ])
  ],
})
export class SidebarComponent implements OnInit {

  menuitems: any;
  tenantUser: boolean = false;
  userInfo: User = new User();
  id: any;

  constructor(
    public themeService: ThemeService,
    public commonService: CommonServiceService,
    private authService: AuthServiceService
    ) { }

  ngOnInit() {
    this.tenantUser = this.authService.hasRole(UserRoleConstants.TENANT);
    this.userInfo = this.authService.getUserInfo();
   this.id = this.authService.getUserInfo().lastLoggedIn
  }

}
