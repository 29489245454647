<section class=" my-3">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-md-12 mb-3">
          <div class="card shadow  h-100 mb-2">
            <div class="card-body pb-5 px-3 pt-2">
              <h6 class="card-title text-dark position-relative pb-2">Notifications</h6>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="card mb-3">
              <div class="card-body">
                  <h6 class="card-title text-dark position-relative pb-2 w-100">Plots</h6>
                  <div class="wrap">
                  <table class="table table-striped table-bordered table-sm row-border hover">
                      <thead>
                          <tr>
                            <th>Venture Name</th>
                              <th>Registered</th>
                              <th>Sold</th>
                              <th>Available</th>
                              <th>Total</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor= "let plot of plots">
                            <td>{{plot.ventureName}}</td>
                              <td>{{plot.registered ? plot.registered: 0}}</td>
                              <td>{{plot.sold ? plot.sold: 0}}</td>
                              <td>{{plot.available}}</td>
                              <td>{{plot.total}}</td>
                          </tr>
                      </tbody>
                  </table>
                </div>
              </div>
          </div>
          <div class="card mb-3">
            <div class="card-body">
                <h6 class="card-title text-dark position-relative pb-2 w-100">Sale(Based On Ventures)</h6>
                <div class="wrap">
                <table class="table table-striped table-bordered table-sm row-border hover">
                    <thead>
                        <tr>
                          <th>Venture Name</th>
                            <th>Plots Sold</th>
                            <th>{{commonService.getMeasure()}} Sold</th>
                            <th>Sale Value(<i class="fas fa-rupee-sign mr-1"></i>)</th>
                            <th>Amount Received(<i class="fas fa-rupee-sign mr-1"></i>)</th>
                            <th>Amount Pending(<i class="fas fa-rupee-sign mr-1"></i>)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor= "let plot of ventureSale">
                          <td>{{plot.ventureName}}</td>
                            <td>{{plot.plotsSold}}</td>
                            <td>{{plot.yardsSold.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                            <td>{{plot.saleValue?.toLocaleString('en-IN')}}</td>
                            <td>{{plot.amountReceived?.toLocaleString('en-IN')}}</td>
                            <td>{{plot.amountPending?.toLocaleString('en-IN')}}</td>
                        </tr>
                    </tbody>
                </table>
              </div>
            </div>
        </div>
        <div class="card mb-3">
          <div class="card-body">
            <h6 class="card-title text-dark position-relative pb-2 w-100">Venture Layout</h6>
            <div class="wrap">
              <table class="table table-striped table-bordered table-sm row-border hover">
                <thead>
                  <tr>
                    <th>Venture Name</th>
                    <th>Layout</th>
                  </tr>
        
                </thead>
                <tbody>
                  <tr *ngFor="let venture of ventureLayout">
                    <td>{{venture.ventureName}}</td>
                    <td><a class="pointer" (click)="open(venture)">View layout <i class="fas fa-external-link"
                          aria-hidden="true"></i></a></td>
                    <ng-template #layout let-modal>
                      <div class="card">
                        <div class="modal-header">
                          <h6 class="modal-title" id="modal-basic-title">Layout View ({{venture.ventureName}})</h6>
                          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div class="card-body modal-body">
                          <div>
                            <img [src]="venture.layoutUrl" alt="logo" class="img-fluid">
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</section>