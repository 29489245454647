import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {debounceTime, distinctUntilChanged, map, filter} from 'rxjs/operators';
import { DATA } from 'src/app/data';

type SalesPerson = {id: number, name: string};
type plotData = {id: number, name: string};

const salesRep: SalesPerson[] = [
  {id: 0, name: 'Sami'},
  {id: 1, name: 'Abhijit'},
  {id: 2, name: 'Amitesh'},
  {id: 3, name: 'Ravi'},
  {id: 4, name: 'Raghu'},
  {id: 5, name: 'Rohit'},
  {id: 6, name: 'Sriman'},]

  const plots: plotData[] = [
    {id: 1010, name: 'America'},
    {id: 1011, name: 'Begumpet'},
    {id: 1012, name: 'chandannagar'},
    {id: 1033, name: 'Delhi'},
    {id: 1044, name: 'Egypt'},
    {id: 1055, name: 'France'},
    {id: 1066, name: 'Germany'},]

@Component({
  selector: 'app-new-sale',
  templateUrl: './new-sale.component.html',
  styleUrls: ['./new-sale.component.scss']
})

export class NewSaleComponent implements OnInit {
  data: any[] = DATA;
  salesLevel: any = [];

  formatter = (sales_rep: SalesPerson) => sales_rep.name;
  formatter1 = (plotnumber: plotData) => plotnumber.name;
  
  constructor() {
    this.data.forEach((ele) => {
      if (ele.table == "sales_level")
        this.salesLevel.push(ele.rows);
    })

  }

  ngOnInit() {
    
  }
  
   searchRep = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 2),
    map(term => salesRep.filter(salesrep => new RegExp(term, 'mi').test(salesrep.name)).slice(0, 10))
  )
  searchPlots = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 2),
    map(term => plots.filter(plots => new RegExp(term, 'mi').test(plots.name)).slice(0, 10))
  )
  editSale(sale: any){
    sale.editable = !sale.editable;
  }

}
