import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserRoleConstants } from 'src/app/constants/AppConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-salesdashboard',
  templateUrl: './salesdashboard.component.html',
  styleUrls: ['./salesdashboard.component.scss']
})
export class SalesdashboardComponent implements OnInit {
  classTab: any = [];
  setAdmin: boolean;
  myCommission: any = [];
  salesrep: any = [];
  indirectCommission: any = [];
  sale: any = [];
  plots: any = [];
  ventureSale: any = [];
  ventureLayout: any;
  closeResult: string;

  constructor(private restService: RestService, private modalService: NgbModal,
    private router: Router,
    public commonService: CommonServiceService, public authService: AuthServiceService, public themeService: ThemeService) { }

  ngOnInit(): void {
    const path: string = "salesrep/salesrepDashboard"
    let data = {
      tenantId: this.authService.getUserInfo().tenantId,
      marketerId: this.authService.getUserInfo().marketerId,
      salesrepId: this.authService.getUserInfo().salesrepId,
    }
    this.restService.submitPostRequest(path, data).subscribe(response => {
      if (response) {
        this.salesrep = response.data;
        this.myCommission = this.salesrep.directSalesrepCommission;
        this.indirectCommission = this.salesrep.indirectSalesrepCommission;
        this.sale = this.salesrep.salesrepSaleInfo;
        this.plots = this.salesrep.ventureSales;
        this.ventureSale = this.salesrep.marketerSaleInfoList;
      }
    });
    this.setAdmin = this.authService.hasRole(UserRoleConstants.ADMIN);
    this.getVentureLayout();
  }

  getVentureLayout() {
    const userInfo = this.authService.getUserInfo();
    let path = "generic/salesrep/ventureLayout?salesrepId=" + userInfo.username;
    this.restService.getVentureLayout(path).subscribe(response => {
      if (response) {
        this.ventureLayout = response.data;
      }
    })
  }

  open(content) {
    let tenantId = this.authService.getUserInfo().tenantId;
    let suffix = this.commonService.getUrlSuffix();
    this.router.navigate([suffix+'/sl/venture-layout-design/'+content.ventureId])
    // this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
