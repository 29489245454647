import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subject } from 'rxjs';
import { UserPrivileges } from 'src/app/constants/AppConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {
  @ViewChild('changeLead', { static: false }) changeLead: ElementRef;
  teamMembers: any = [];
  error: boolean;
  errorMessage: any;
  submit = false;
  salesrepvalue: any;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  userInfo: any;
  isMarketerTeams: boolean = true;
  userPrivileges = UserPrivileges;
  closeResult : String;
  salesrep: any;
  reportingManagers: any;
  reportingManagerId: number = null;
  salesrepTeam: any = {};
  reportingManagerExist : boolean;

  constructor(
    private restService: RestService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthServiceService,
    private commonService: CommonServiceService,
    private permissionsService: NgxPermissionsService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.commonService.clearVentureTeam();
    this.userInfo = this.authService.getUserInfo();
    this.getTeamMembers();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
    };
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  onSubmit() { this.submit = true; }
  onShow() {
    this.submit = false;
    this.ngOnInit();
  }
  onDisply(row) {
    this.permissionsService.hasPermission(UserPrivileges.MARKETER_SUPER_PRIVILEGE).then(hasMarketerSuperPrivilege => {
      if (hasMarketerSuperPrivilege) {
        this.router.navigate(['../alldetails', row.salesrepId], { relativeTo: this.route });
        this.salesrepvalue = row.salesrepId;
      }
    });

  }

  open(content) {
		this.modalService.open(this.changeLead, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

  private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

  showChangeReportingManager(e:Event,salesrepId,salesrepName,leadName) {
    e.stopPropagation();
    this.salesrep = {"salesrepId" :salesrepId, "salesrepName" : salesrepName,"leadName" : leadName};
    this.reportingManagerExist = leadName.length > 0;
    let path = '/marketer/reportingManagers';
    this.restService.fetchReportingManagers(salesrepId, path).subscribe(response => {
      this.reportingManagers = response.data;
    });
    this.open(this.salesrep);
  }

  changeReportingManager(){
   let reqBody = {"salesrepId" :this.salesrep.salesrepId, "reportingManagerId" : this.reportingManagerId};
   let path = '/marketer/reportingManager';
   this.restService.updateReportingManager(path,reqBody).subscribe(
    (response: any) => {
      if (response != null) {
        this.getTeamMembers();
        this.reportingManagerId=null;
      }
    }, (error) => {
      this.error = true;
      this.errorMessage = error.status;
    })
  }

  getTeamMembers(){
    let data = {
      'tenantId': this.userInfo.tenantId,
      'marketerId': this.userInfo.marketerId,
      'ventureId': this.commonService.getVenture()?.ventureId
    };
    const path: string = 'marketer/get-markerter-teams';
    this.restService.submitPostRequest(path, data).subscribe(response => {
      if (response) {
        this.teamMembers = response;
      }
    }, (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });
  }

}
