<div class="container-fluid my-3 text-dark">
    <div class="card shadow p-4">
        <a class="position-absolute close-popup-link bg-white" (click)="sendBack()"><i class="fa fa-times"
                aria-hidden="true"></i>
        </a>
        <div class="card-body">
            <h6 class="card-title text-dark position-relative pb-2 ">New Tenant</h6>
            <div id="stepper1" class="bs-stepper">
                <div class="bs-stepper-header">
                    <div class="step" data-target="#marketerdetails">
                        <button class="step-trigger">
                            <span class="bs-stepper-circle">1</span>
                            <span class="bs-stepper-label">Tenant Info.</span>
                        </button>
                    </div>
                    <div class="line"></div>
                    <div class="step" data-target="#permanentAddress">
                        <button class="step-trigger">
                            <span class="bs-stepper-circle">2</span>
                            <span class="bs-stepper-label">Perm. Address</span>
                        </button>
                    </div>
                    <div class="line"></div>
                    <div class="step" data-target="#communicateAddress">
                        <button class="step-trigger">
                            <span class="bs-stepper-circle">3</span>
                            <span class="bs-stepper-label">Comm. Address</span>
                        </button>
                    </div>
                </div>
                <div class="bs-stepper-content">
                    <form #details="ngForm">

                        <div id="marketerdetails" class="content">
                            <div class="row">
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (tenantType.invalid && (tenantType.dirty || tenantType.touched))}">
                                    <label class="star">Type</label>
                                    <select type="text" class="form-control" id="tenantType" name="tenantType"
                                        #tenantType="ngModel" required [(ngModel)]="addTenant.tenantType">
                                        <option value="person">Person</option>
                                        <option value="company">Company</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (displayName.invalid && (displayName.dirty || displayName.touched))}">
                                    <label class="star">Display Name</label>
                                    <input type="text" class="form-control" id="displayName" name="displayName"
                                        #displayName="ngModel" required [(ngModel)]="addTenant.displayName">
                                </div>
                            </div>
                            <div class="row" *ngIf="addTenant.tenantType == 'company'">
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (companyCategory.invalid && (companyCategory.dirty || companyCategory.touched))}">
                                    <label class="star">Company Category</label>
                                    <input type="text" class="form-control" id="companyCategory" name="companyCategory"
                                        #companyCategory="ngModel" [(ngModel)]="primaryCompany.companyCategory">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (companyName.invalid && (companyName.dirty || companyName.touched))}">
                                    <label class="star">Company Name</label>
                                    <input type="text" class="form-control" id="companyName" name="companyName"
                                        #companyName="ngModel" [(ngModel)]="primaryCompany.companyName">
                                </div>

                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (companyType.invalid && (companyType.dirty || companyType.touched))}">
                                    <label class="star">Company Type</label>
                                    <input type="text" class="form-control" id="companyType" #companyType="ngModel"
                                        name="companyType" [(ngModel)]="primaryCompany.companyType">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (registrationNumber.invalid && (registrationNumber.dirty || registrationNumber.touched))}">
                                    <label class="star">Registration Number</label>
                                    <input type="text" class="form-control" id="registrationNumber"
                                        #registrationNumber="ngModel" name="registrationNumber"
                                        [(ngModel)]="primaryCompany.registrationNumber">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (tanNumber.invalid && (tanNumber.dirty || tanNumber.touched))}">
                                    <label class="star">TAN Number</label>
                                    <input type="text" class="form-control" id="tanNumber" #tanNumber="ngModel"
                                        name="tanNumber" [(ngModel)]="primaryCompany.tanNumber">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (gstNumber.invalid && (gstNumber.dirty || gstNumber.touched))}">
                                    <label class="star">GST Number</label>
                                    <input type="text" class="form-control" id="gstNumber" #gstNumber="ngModel"
                                        name="gstNumber" [(ngModel)]="primaryCompany.gstNumber">
                                </div>

                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (contactNumber.invalid && (contactNumber.dirty || contactNumber.touched))}">
                                    <label class="star">Contact Number</label>
                                    <input type="tel" class="form-control" id="contactNumber" #contactNumber="ngModel"
                                        name="contactNumber" [(ngModel)]="primaryCompany.contactNumber">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (alternateContact.invalid && (alternateContact.dirty || alternateContact.touched))}">
                                    <label class="">Alternate Contact Number</label>
                                    <input type="tel" class="form-control" name="alternateContact" id="alternateContact"
                                        #alternateContact="ngModel" [(ngModel)]="primaryCompany.alternateContact">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (email.invalid && (email.dirty || email.touched))}">
                                    <label class="star">Email ID</label>
                                    <input type="email" class="form-control" id="email" name="email" #email="ngModel"
                                        [(ngModel)]="primaryCompany.email">
                                </div>
                                <div class="form-group col-md-4">
                                    <label class="">Alternate Email ID</label>
                                    <input type="email" class="form-control" id="alternateEmail" name="alternateEmail"
                                        [(ngModel)]="primaryCompany.alternateEmail">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Description</label>
                                    <input type="text" class="form-control" id="description" name="description"
                                        [(ngModel)]="primaryCompany.description">
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <h6 class="card-title text-dark position-relative pb-2 h6 ">Managing Director
                                        </h6>
                                    </div>
                                    <div class="form-group col-md-4"
                                        [ngClass]="{'invalid': (firstName.invalid && (firstName.dirty || firstName.touched))}">
                                        <label class="star">First Name</label>
                                        <input type="text" class="form-control" id="firstName" required
                                            #firstName="ngModel" name="firstName"
                                            [(ngModel)]="managingDirector.firstName">
                                    </div>
                                    <div class="form-group col-md-4"
                                        [ngClass]="{'invalid': (lastName.invalid && (lastName.dirty || lastName.touched))}">
                                        <label class="star">Last Name</label>
                                        <input type="text" class="form-control" id="lastName" required
                                            #lastName="ngModel" name="lastName" [(ngModel)]="managingDirector.lastName">
                                    </div>
                                    <div class="form-group col-md-4"
                                        [ngClass]="{'invalid': (dob.invalid && (dob.dirty || dob.touched))}">
                                        <label class="star">DOB</label>
                                        <input type="date" class="form-control" id="dob" required #dob="ngModel"
                                            name="dob" [(ngModel)]="managingDirector.dob" max="9999-12-31">
                                    </div>
                                    <div class="form-group col-md-4"
                                        [ngClass]="{'invalid': (gender.invalid && (gender.dirty || gender.touched))}">
                                        <label class="star">Gender</label>
                                        <select type="text" class="form-control" id="gender" required #gender="ngModel"
                                            name="gender" [(ngModel)]="managingDirector.gender">
                                            <option value="Female">Female</option>
                                            <option value="Male">Male</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-4"
                                        [ngClass]="{'invalid': (bloodGroup.invalid && (bloodGroup.dirty || bloodGroup.touched))}">
                                        <label class="star">Blood Group</label>
                                        <select type="text" class="form-control" id="bloodGroup" required
                                            #bloodGroup="ngModel" name="bloodGroup"
                                            [(ngModel)]="managingDirector.bloodGroup">
                                            <option value="A+">A+</option>
                                            <option value="A-">A-</option>
                                            <option value="B+">B+</option>
                                            <option value="B-">B-</option>
                                            <option value="O+">O+</option>
                                            <option value="O-">O-</option>
                                            <option value="AB+">AB+</option>
                                            <option value="AB-">AB-</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-4"
                                        [ngClass]="{'invalid': (nationality.invalid && (nationality.dirty || nationality.touched))}">
                                        <label class="star">Nationality</label>
                                        <input type="text" class="form-control" id="nationality" required
                                            #nationality="ngModel" name="nationality"
                                            [(ngModel)]="managingDirector.nationality">
                                    </div>
                                    <div class="form-group col-md-4"
                                        [ngClass]="{'invalid': (panNum.invalid && (panNum.dirty || panNum.touched))}">
                                        <label class="star">PAN Number</label>
                                        <input type="text" class="form-control" id="panNum" required #panNum="ngModel"
                                            minlength="10" maxlength="10" name="panNum"
                                            [(ngModel)]="managingDirector.panNum">
                                        <div class="text-danger" *ngIf="panNum.dirty && panNum.invalid">PAN number
                                            should be 10 digit</div>
                                    </div>
                                    <div class="form-group col-md-4"
                                        [ngClass]="{'invalid': (aadharNum.invalid && (aadharNum.dirty || aadharNum.touched))}">
                                        <label class="star">Aadhar Number</label>
                                        <input type="number" class="form-control" id="aadharNum" required minlength="12"
                                            maxlength="12" #aadharNum="ngModel" name="aadharNum" placeholder="XXXX-XXXX-XXXX"
                                            [(ngModel)]="managingDirector.aadharNum">
                                        <div class="text-danger" *ngIf="aadharNum.dirty && aadharNum.invalid">Aadhar
                                            number should be 12 digit</div>
                                    </div>

                                    <div class="form-group col-md-4"
                                        [ngClass]="{'invalid': (contactNumber.invalid && (contactNumber.dirty || contactNumber.touched))}">
                                        <label class="star">Contact Number</label>
                                        <input type="number" class="form-control" id="contactNumber" required
                                            #contactNumber="ngModel" name="contactNumber" placeholder="99X XXX XXXX"
                                            [(ngModel)]="managingDirector.contactNumber">
                                    </div>
                                    <div class="form-group col-md-4"
                                        [ngClass]="{'invalid': (alternateContact.invalid && (alternateContact.dirty || alternateContact.touched))}">
                                        <label class="">Alternate Contact Number</label>
                                        <input type="tel" class="form-control" name="alternateContact"
                                            id="alternateContact" #alternateContact="ngModel" placeholder="95X XXX XXXX"
                                            [(ngModel)]="managingDirector.alternateContact">
                                    </div>
                                    <div class="form-group col-md-4"
                                        [ngClass]="{'invalid': (email.invalid && (email.dirty || email.touched))}">
                                        <label class="star">Email ID</label>
                                        <input type="email" class="form-control" id="email" required name="email"
                                            #email="ngModel" [(ngModel)]="managingDirector.email">
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="">Alternate Email ID</label>
                                        <input type="email" class="form-control" id="alternateEmail"
                                            name="alternateEmail" [(ngModel)]="managingDirector.alternateEmail">
                                    </div>
                                    <div class="form-group col-md-4"
                                        [ngClass]="{'invalid': (facebookId.invalid && (facebookId.dirty || facebookId.touched))}">
                                        <label class="">Facebook Id</label>
                                        <input type="text" class="form-control" id="facebookId" name="facebookId"
                                            #facebookId="ngModel" [(ngModel)]="managingDirector.facebookId">
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label>Comments</label>
                                        <input type="text" class="form-control" id="comments" name="comments"
                                            [(ngModel)]="managingDirector.comments">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <button type="submit" class="btn btn-sm btn-info" (click)="next()">Next</button>
                                </div>
                            </div>
                            <div class="row" *ngIf="addTenant.tenantType == 'person'">
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (firstName.invalid && (firstName.dirty || firstName.touched))}">
                                    <label class="star">First Name</label>
                                    <input type="text" class="form-control" id="firstName" required #firstName="ngModel"
                                        name="firstName" [(ngModel)]="managingDirector.firstName">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (lastName.invalid && (lastName.dirty || lastName.touched))}">
                                    <label class="star">Last Name</label>
                                    <input type="text" class="form-control" id="lastName" required #lastName="ngModel"
                                        name="lastName" [(ngModel)]="managingDirector.lastName">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (dob.invalid && (dob.dirty || dob.touched))}">
                                    <label class="star">DOB</label>
                                    <input type="date" class="form-control" id="dob" required #dob="ngModel" name="dob"
                                        [(ngModel)]="managingDirector.dob" max="9999-12-31">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (gender.invalid && (gender.dirty || gender.touched))}">
                                    <label class="star">Gender</label>
                                    <select type="text" class="form-control" id="gender" required #gender="ngModel"
                                        name="gender" [(ngModel)]="managingDirector.gender">
                                        <option value="Female">Female</option>
                                        <option value="Male">Male</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (bloodGroup.invalid && (bloodGroup.dirty || bloodGroup.touched))}">
                                    <label class="star">Blood Group</label>
                                    <select type="text" class="form-control" id="bloodGroup" required
                                        #bloodGroup="ngModel" name="bloodGroup"
                                        [(ngModel)]="managingDirector.bloodGroup">
                                        <option value="A+">A+</option>
                                        <option value="A-">A-</option>
                                        <option value="B+">B+</option>
                                        <option value="B-">B-</option>
                                        <option value="O+">O+</option>
                                        <option value="O-">O-</option>
                                        <option value="AB+">AB+</option>
                                        <option value="AB-">AB-</option>

                                    </select>
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (nationality.invalid && (nationality.dirty || nationality.touched))}">
                                    <label class="star">Nationality</label>
                                    <input type="text" class="form-control" id="nationality" required
                                        #nationality="ngModel" name="nationality"
                                        [(ngModel)]="managingDirector.nationality">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (panNum.invalid && (panNum.dirty || panNum.touched))}">
                                    <label class="star">PAN Number</label>
                                    <input type="text" class="form-control" id="panNum" required #panNum="ngModel"
                                        minlength="10" maxlength="10" name="panNum"
                                        [(ngModel)]="managingDirector.panNum">
                                    <div class="text-danger" *ngIf="panNum.dirty && panNum.invalid">PAN number should be
                                        10 digit</div>
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (aadharNum.invalid && (aadharNum.dirty || aadharNum.touched))}">
                                    <label class="star">Aadhar Number</label>
                                    <input type="text" class="form-control" id="aadharNum" required minlength="12"
                                        maxlength="12" #aadharNum="ngModel" name="aadharNum"
                                        [(ngModel)]="managingDirector.aadharNum">
                                    <div class="text-danger" *ngIf="aadharNum.dirty && aadharNum.invalid">Aadhar number
                                        should be 12 digit</div>
                                </div>

                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (contactNumber.invalid && (contactNumber.dirty || contactNumber.touched))}">
                                    <label class="star">Contact Number</label>
                                    <input type="number" class="form-control" id="contactNumber" required
                                        placeholder="99X XXX XXXX"  #contactNumber="ngModel" name="contactNumber"
                                        [(ngModel)]="managingDirector.contactNumber">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (alternateContact.invalid && (alternateContact.dirty || alternateContact.touched))}">
                                    <label class="">Alternate Contact Number</label>
                                    <input type="number" placeholder="95X XXX XXXX" class="form-control" name="alternateContact" id="alternateContact"
                                        #alternateContact="ngModel" [(ngModel)]="managingDirector.alternateContact">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (email.invalid && (email.dirty || email.touched))}">
                                    <label class="star">Email Address</label>
                                    <input type="email" class="form-control" id="email" required name="email"
                                        #email="ngModel" [(ngModel)]="managingDirector.email">
                                </div>
                                <div class="form-group col-md-4">
                                    <label class="">Alternate Email Address</label>
                                    <input type="email" class="form-control" id="alternateEmail" name="alternateEmail"
                                        [(ngModel)]="managingDirector.alternateEmail">
                                </div>
                                <div class="form-group col-md-4"
                                    [ngClass]="{'invalid': (facebookId.invalid && (facebookId.dirty || facebookId.touched))}">
                                    <label class="">Facebook Id</label>
                                    <input type="text" class="form-control" id="facebookId" name="facebookId"
                                        #facebookId="ngModel" [(ngModel)]="managingDirector.facebookId">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Comments</label>
                                    <input type="text" class="form-control" id="comments" name="comments"
                                        [(ngModel)]="managingDirector.comments">
                                </div>
                                <div class="col-6">
                                    <button type="submit" class="btn btn-sm btn-info" (click)="next()">Next</button>
                                </div>
                            </div>
                        </div>
                        <div id="permanentAddress" class="content">
                            <div class="row">
                                <!-- <div class="form-group col-3"
                                    [ngClass]="{'invalid': addressCatogery.invalid && (addressCatogery.dirty || addressCatogery.touched)}">
                                    <label class="star">Address Category </label>
                                    <input type="text" class="form-control" id="addressCatogery" required
                                        [(ngModel)]="permAddress.addressCatogery" name="addressCatogery"
                                        #addressCatogery="ngModel">
                                </div> -->

                                <div class="form-group col-3"
                                    [ngClass]="{'invalid': addressLine1.invalid && (addressLine1.dirty || addressLine1.touched)}">
                                    <label class="star">Address Line 1</label>
                                    <input type="text" class="form-control" id="addressLine1" required
                                        [(ngModel)]="permAddress.addressLine1" name="addressLine1"
                                        #addressLine1="ngModel">
                                </div>

                                <div class="form-group col-3"
                                    [ngClass]="{'invalid': addressLine2.invalid && (addressLine2.dirty || addressLine2.touched)}">
                                    <label class="">Address Line 2</label>
                                    <input type="text" class="form-control" id="addressLine2"
                                        [(ngModel)]="permAddress.addressLine2" name="addressLine2"
                                        #addressLine2="ngModel">
                                </div>

                                <div class="form-group col-3"
                                    [ngClass]="{'invalid': landmark.invalid && (landmark.dirty || landmark.touched)}">
                                    <label class="star">Landmark</label>
                                    <input type="text" class="form-control" id="landmark" required
                                        [(ngModel)]="permAddress.landmark" name="landmark" #landmark="ngModel">
                                </div>

                                <div class="form-group col-3"
                                    [ngClass]="{'invalid': city.invalid && (city.dirty || city.touched)}">
                                    <label class="star">City</label>
                                    <input type="text" class="form-control" id="city" required
                                        [(ngModel)]="permAddress.city" name="city" #city="ngModel">
                                </div>

                                <div class="form-group col-3"
                                    [ngClass]="{'invalid': mandal.invalid && (mandal.dirty || mandal.touched)}">
                                    <label class="star">Mandal</label>
                                    <input type="text" class="form-control" id="mandal" required
                                        [(ngModel)]="permAddress.mandal" name="mandal" #mandal="ngModel">
                                </div>

                                <div class="form-group col-3"
                                    [ngClass]="{'invalid': district.invalid && (district.dirty || district.touched)}">
                                    <label class="star">District</label>
                                    <input type="text" class="form-control" id="district" required
                                        [(ngModel)]="permAddress.district" name="district" #district="ngModel">
                                </div>

                                <div class="form-group col-3"
                                    [ngClass]="{'invalid': state.invalid && (state.dirty || state.touched)}">
                                    <label class="star">State</label>
                                    <input type="text" class="form-control" id="state" required
                                        [(ngModel)]="permAddress.state" name="state" #state="ngModel">
                                </div>

                                <div class="form-group col-3"
                                    [ngClass]="{'invalid': country.invalid && (country.dirty || country.touched)}">
                                    <label class="star">Country</label>
                                    <input type="text" class="form-control" id="country" required
                                        [(ngModel)]="permAddress.country" name="country" #country="ngModel">
                                </div>

                                <div class="form-group col-3"
                                    [ngClass]="{'invalid': pin.invalid && (pin.dirty || pin.touched)}">
                                    <label class="star">PIN Code</label>
                                    <input type="text" class="form-control" id="pin" required minlength="6"
                                        maxlength="6" [(ngModel)]="permAddress.pin" name="pin" #pin="ngModel">
                                    <div class="text-danger" *ngIf="pin.dirty && pin.invalid">PIN number should be 6
                                        digit</div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-sm btn-info" (click)="next()">Next</button>
                        </div>
                        <div id="communicateAddress" class="content">
                            <div class="row">
                            <ul class="list-unstyled mb-0">
                                <li class="form-group col-12">
                                    <span class="d-flex"><input type="checkbox" class="form-control mr-2" name="checked"
                                            [(ngModel)]="address2" value="checked">Communication Address same as permanentAddress</span>
                                </li>
                            </ul>
                        </div>
                            <div class="row"  *ngIf="address2 == false">
                                <!-- <div class="form-group col-3"
                                    [ngClass]="{'invalid': addressCatogery.invalid && (addressCatogery.dirty || addressCatogery.touched)}">
                                    <label class="star">Address Category </label>
                                    <input type="text" class="form-control" id="addressCatogery" required
                                        [(ngModel)]="commAddress.addressCatogery" name="addressCatogery"
                                        #addressCatogery="ngModel">
                                </div> -->

                                <div class="form-group col-3"
                                    [ngClass]="{'invalid': addressLine1.invalid && (addressLine1.dirty || addressLine1.touched)}">
                                    <label class="star">Address Line 1</label>
                                    <input type="text" class="form-control" id="addressLine1" required
                                        [(ngModel)]="commAddress.addressLine1" name="addressLine1"
                                        #addressLine1="ngModel">
                                </div>

                                <div class="form-group col-3"
                                    [ngClass]="{'invalid': addressLine2.invalid && (addressLine2.dirty || addressLine2.touched)}">
                                    <label class="">Address Line 2</label>
                                    <input type="text" class="form-control" id="addressLine2"
                                        [(ngModel)]="commAddress.addressLine2" name="addressLine2"
                                        #addressLine2="ngModel">
                                </div>

                                <div class="form-group col-3"
                                    [ngClass]="{'invalid': landmark.invalid && (landmark.dirty || landmark.touched)}">
                                    <label class="star">Landmark</label>
                                    <input type="text" class="form-control" id="landmark" required
                                        [(ngModel)]="commAddress.landmark" name="landmark" #landmark="ngModel">
                                </div>

                                <div class="form-group col-3"
                                    [ngClass]="{'invalid': city.invalid && (city.dirty || city.touched)}">
                                    <label class="star">City</label>
                                    <input type="text" class="form-control" id="city" required
                                        [(ngModel)]="commAddress.city" name="city" #city="ngModel">
                                </div>

                                <div class="form-group col-3"
                                    [ngClass]="{'invalid': mandal.invalid && (mandal.dirty || mandal.touched)}">
                                    <label class="star">Mandal</label>
                                    <input type="text" class="form-control" id="mandal" required
                                        [(ngModel)]="commAddress.mandal" name="mandal" #mandal="ngModel">
                                </div>

                                <div class="form-group col-3"
                                    [ngClass]="{'invalid': district.invalid && (district.dirty || district.touched)}">
                                    <label class="star">District</label>
                                    <input type="text" class="form-control" id="district" required
                                        [(ngModel)]="commAddress.district" name="district" #district="ngModel">
                                </div>

                                <div class="form-group col-3"
                                    [ngClass]="{'invalid': state.invalid && (state.dirty || state.touched)}">
                                    <label class="star">State</label>
                                    <input type="text" class="form-control" id="state" required
                                        [(ngModel)]="commAddress.state" name="state" #state="ngModel">
                                </div>

                                <div class="form-group col-3"
                                    [ngClass]="{'invalid': country.invalid && (country.dirty || country.touched)}">
                                    <label class="star">Country</label>
                                    <input type="text" class="form-control" id="country" required
                                        [(ngModel)]="commAddress.country" name="country" #country="ngModel">
                                </div>
                                <div class="form-group col-3"
                                    [ngClass]="{'invalid': pin.invalid && (pin.dirty || pin.touched)}">
                                    <label class="star">PIN Code</label>
                                    <input type="number" class="form-control" id="pin" required minlength="6"
                                        maxlength="6" [(ngModel)]="commAddress.pin" name="pin" #pin="ngModel">
                                    <div class="text-danger" *ngIf="pin.dirty && pin.invalid">PIN number should be 6
                                        digit</div>
                                </div>

                            </div>
                            <button type="submit" class="btn btn-info" [disabled]=details.invalid
                                (click)="onsubmit(details.value)">Submit</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>