import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import * as pluginDataLabels from 'ng2-charts';


@Component({
  selector: 'app-path-status',
  templateUrl: './path-status.component.html',
  styleUrls: ['./path-status.component.scss']
})
export class PathStatusComponent implements OnInit {
 
  public lineChartData: ChartDataSets[] = [
    { data: [1, 0, 1, 56,94], label: 'Series A' },
  ];
  public lineChartLabels: Label[] = ['January', 'February', 'March', 'April','May'];
  public lineChartOptions: (ChartOptions) = {
    responsive: true,
  };
  public lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];

  
  ngOnChanges() {
    // if (this.plots) {
    //   this.barChartData =[
    //     {data: this.plots.map(a => a.plots), label: ''}
    //   ];
    //   this.barChartLabels = this.plots.map(a => a.lable);
    // }
  }

  ngOnInit(): void {
  }

}
