import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-addplotpricing',
  templateUrl: './addplotpricing.component.html',
  styleUrls: ['./addplotpricing.component.scss']
})
export class AddplotpricingComponent implements OnInit {
  newPlotpriceing: any;
  error: boolean;
  errorMessage: any;
  plottype: any = [];
  isEditView = false;
  plotPricing: any = {};
  measureValue: string;

  constructor(private location: Location,
    private restService: RestService,
    private authService: AuthServiceService,
    public commonService: CommonServiceService,
    private router: Router) { }

  ngOnInit(): void {

    this.measureValue = this.commonService.getMeasure();

    if (this.router.url.includes('edit-plot-pricing')){
			this.isEditView = true;
		}
    if(this.isEditView) {
      this.plotPricing = this.commonService.getPlotPricing();
      this.plotPricing.measure ='Square ' + this.measureValue;
    }
    let data = {
      "tenantId": this.authService.getUserInfo().tenantId,
      "ventureId": this.commonService.getVenture()?.ventureId,
    }
    const path: string = '/plot/get-plot-types'
    this.restService.submitPostRequest(path, data).subscribe(response => {
      this.plottype = response.data;
    });
  }

  onSubmit(addPlotPricing: any) {
    const userInfo = this.authService.getUserInfo();
    addPlotPricing.tenantId = userInfo.tenantId;
    addPlotPricing.ventureId = this.commonService.getVenture()?.ventureId;
    addPlotPricing.effectiveDate = new Date().toISOString();
    addPlotPricing.maxSellingPrice = Math.round(addPlotPricing.maxSellingPrice);
    addPlotPricing.minSellingPrice = Math.round(addPlotPricing.minSellingPrice);
    addPlotPricing.maxDiscountAllowed = Math.round(addPlotPricing.maxDiscountAllowed);
    addPlotPricing.priceForMarketer = Math.round(addPlotPricing.priceForMarketer);
    if(this.isEditView){
      addPlotPricing.plotType = this.plotPricing.plotType;
      this.restService.editPlotPricing(addPlotPricing).subscribe(
        (response: any) => {
          if (response != null) {
            this.newPlotpriceing = response.data;
          }
        }, (error) => {
          this.error = true;
          this.errorMessage = error.status;
        })      
    }
    else{
      this.restService.addPlotPricing(addPlotPricing).subscribe(
        (response: any) => {
          if (response != null) {
            this.newPlotpriceing = response.data;
          }
        }, (error) => {
          this.error = true;
          this.errorMessage = error.status;
        })
    }
    this.location.back()
  }
  sendBack() {
    this.location.back()
  }
}
