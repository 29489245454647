import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import Stepper from 'bs-stepper';
import { Location } from '@angular/common';
import { UserPrivileges } from 'src/app/constants/AppConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { RestService } from 'src/app/services/rest.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-editprofile',
  templateUrl: './editprofile.component.html',
  styleUrls: ['./editprofile.component.scss']
})
export class EditprofileComponent implements OnInit {
  @ViewChild('successMsg') successMsg: ElementRef<HTMLElement>;
  @Input() sales;

  private stepper: Stepper;
  genders: any = ['Male', 'Female', 'Others', 'Prefer not to say'];
  isNavSolid: boolean;
  checked = false;
  navMode: any;
  mode: string;
  layout: string;
  themeLayout: string;
  myDetails: any = [];
  salesRepDetails: any = [];
  person: any = [];
  bankArray: any = [];
  error: boolean;
  errorMessage: any;
  bankDeatils: any;
  commAddress: any = {};
  permAddress: any = {};
  updatedDetails: any = [];
  userPrivileges = UserPrivileges;
  message: any;
	closeResult: string;

  constructor(private restService: RestService,
    private location: Location,
    private authService: AuthServiceService,
    private modalService: NgbModal) 
    {
    this.salesRepDetails = [];
  }

  ngOnInit() {

    let data = {
      "salesrepId": this.sales,
      "tenantId": this.authService.getUserInfo().tenantId,
      "marketerId": this.authService.getUserInfo().marketerId,
    }
    this.restService.getmyDetails(data).subscribe(response => {
      if (response.data != null) {
        this.myDetails = response.data;
        this.salesRepDetails = response.data;
        if (this.salesRepDetails.secondaryAddress) {
          this.commAddress = this.salesRepDetails.secondaryAddress;
        }
        if (this.salesRepDetails.primaryAddress) {
          this.permAddress = this.salesRepDetails.primaryAddress;
        }

      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      });

    // this.stepper = new Stepper(document.querySelector('#stepper1'), {
    //   linear: false,
    //   animation: true
    // });    

  }
  bank() {
    let bankData = {
      "tenantId": this.authService.getUserInfo().tenantId,
      "bankAccountId": this.sales
    }
    this.restService.getbankDetails(bankData).subscribe(response => {
      if (bankData != null)
        this.bankArray.push(response)
      this.bankArray.map((value) => {
        this.bankDeatils = value;
      });
    }, (error) => {
      this.error = true;
      this.errorMessage = error.status;
    });

  }
  onSubmit(value: any) {
    const path: string = 'marketer/salesrep/update-salesrep';
    this.salesRepDetails.primaryAddress = this.permAddress;
    if (this.commAddress && this.commAddress.addressLine1) {
      this.salesRepDetails.secondaryAddress = this.commAddress;
    } else {
      this.salesRepDetails.secondaryAddress = null;
    }
    this.restService.submitPutRequest(path, this.salesRepDetails).subscribe((response: any) => {
      if (response) {
        this.message = "Details updated successfully!";
        this.updatedDetails = response.data;
        // this.location.back()
        this.open(value)

      }
    },
    error => {
      this.message = error.error.message;
      this.error = true;
      this.errorMessage = error.status;
      this.open(value)
    }
    )

  }
  next() {
    this.stepper.next()

  }

  sendBack() {
    this.modalService.dismissAll('Cross click')
    this.location.back()
	}

  open(content) {
		this.modalService.open(this.successMsg, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
		  this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
		  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	  }

    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }

}
