<div class="spinner d-flex justify-content-center align-items-center w-100" *ngIf="isLoading">
    <p class="text-white d-block">
        <img src="assets/images/loader.svg" />
        <span class="d-block w-100">Please wait...</span>
    </p>
</div>
<div class="my-3" *ngIf="!isLoading">
    <div class="card body py-4 ">
        <form #details="ngForm" (ngSubmit)="onSubmit(details)">
            <div class="row m-1">
                <div class="col-md-7">
                    <div class="row">
                        <!-- <div class="form-group col-md-4" [ngClass]="{'invalid': (username.invalid && (username.dirty || username.touched))}">
                            <label class="star">User Name</label>
                            <input type="text" class="form-control" id="username" required #username="ngModel" name="username" [(ngModel)]="userDetails.username">
                        </div>
                        <div class="form-group col-md-4" [ngClass]="{'invalid': (password.invalid && (password.dirty || password.touched))}">
                            <label class="star">Password</label>
                            <input type="password" class="form-control" id="password" required #password="ngModel" name="password" [(ngModel)]="userDetails.password">
                        </div>
                        <div class="form-group col-md-4" [ngClass]="{'invalid': (personType.invalid && (personType.dirty || personType.touched))}">
                            <label class="star">Person Type</label>
                            <input type="text" class="form-control" id="personType" required #personType="ngModel" name="personType" [(ngModel)]="person.personType">
                        </div> -->
                        <div class="form-group col-md-4"
                            [ngClass]="{'invalid': (firstName.invalid && (firstName.dirty || firstName.touched))}">
                            <label class="star">First Name</label>
                            <input type="text" class="form-control" id="firstName" required #firstName="ngModel"
                                name="firstName" [(ngModel)]="person.firstName" [readonly]="readOnly">
                        </div>
                        <div class="form-group col-md-4"
                            [ngClass]="{'invalid': (lastName.invalid && (lastName.dirty || lastName.touched))}">
                            <label class="star">Last Name</label>
                            <input type="text" class="form-control" id="lastName" required #lastName="ngModel"
                                name="lastName" [(ngModel)]="person.lastName" [readonly]="readOnly">
                        </div>
                        <div class="form-group col-md-4"
                            [ngClass]="{'invalid': (dob.invalid && (dob.dirty || dob.touched))}">
                            <label class="star">DOB</label>
                            <input type="date" class="form-control" id="dob" required #dob="ngModel" name="dob"
                                [(ngModel)]="person.dob" max="9999-12-31" [readonly]="readOnly">
                        </div>
                        <div class="form-group col-md-4"
                            [ngClass]="{'invalid': (gender.invalid && (gender.dirty || gender.touched))}">
                            <label class="star">Gender</label>
                            <select type="text" class="form-control" id="gender" required #gender="ngModel"
                                name="gender" [(ngModel)]="person.gender" [disabled]="readOnly">
                                <option value="Female">Female</option>
                                <option value="Male">Male</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div class="form-group col-md-4"
                            [ngClass]="{'invalid': (bloodGroup.invalid && (bloodGroup.dirty || bloodGroup.touched))}">
                            <label class="star">Blood Group</label>
                            <select type="text" class="form-control" id="bloodGroup" required #bloodGroup="ngModel"
                                name="bloodGroup" [(ngModel)]="person.bloodGroup" [disabled]="readOnly">
                                <option value="A+">A+</option>
                                <option value="A-">A-</option>
                                <option value="B+">B+</option>
                                <option value="B-">B-</option>
                                <option value="O+">O+</option>
                                <option value="O-">O-</option>
                                <option value="AB+">AB+</option>
                                <option value="AB-">AB-</option>
                            </select>
                        </div>
                        <div class="form-group col-md-4"
                            [ngClass]="{'invalid': (nationality.invalid && (nationality.dirty || nationality.touched))}">
                            <label class="star">Nationality</label>
                            <input type="text" class="form-control" id="nationality" required #nationality="ngModel"
                                name="nationality" [(ngModel)]="person.nationality" [readonly]="readOnly">
                        </div>
                        <div class="form-group col-md-4"
                            [ngClass]="{'invalid': (panNum.invalid && (panNum.dirty || panNum.touched))}">
                            <label class="star">PAN Number</label>
                            <input type="text" class="form-control" id="panNum" required #panNum="ngModel"
                                maxlength="10" minlength="10" name="panNum" [(ngModel)]="person.panNum" [readonly]="readOnly">
                            <div class="text-danger" *ngIf="panNum.dirty && panNum.invalid">Pan number should be 10
                                digit
                            </div>
                        </div>
                        <div class="form-group col-md-4"
                            [ngClass]="{'invalid': (aadharNum.invalid && (aadharNum.dirty || aadharNum.touched))}">
                            <label class="star">Aadhar Number</label>
                            <input type="text" class="form-control" id="aadharNum" maxlength="12" minlength="12"
                                required #aadharNum="ngModel" name="aadharNum" [(ngModel)]="person.aadharNum" [readonly]="readOnly">
                            <div class="text-danger" *ngIf="aadharNum.dirty && aadharNum.invalid">Aadhar number should
                                be 12 digit
                            </div>

                        </div>
                        <div class="form-group col-md-4"
                            [ngClass]="{'invalid': (contactNumber.invalid && (contactNumber.dirty || contactNumber.touched))}">
                            <label class="star">Contact Number</label>
                            <input type="number" class="form-control" id="contactNumber" required
                                #contactNumber="ngModel" name="contactNumber" [(ngModel)]="person.contactNumber" [readonly]="readOnly">
                        </div>
                        <div class="form-group col-md-4"
                            [ngClass]="{'invalid': (alternateContact.invalid && (alternateContact.dirty || alternateContact.touched))}">
                            <label class="">Alternate Contact Number</label>
                            <input type="number" class="form-control" name="alternateContact" id="alternateContact"
                                #alternateContact="ngModel" [(ngModel)]="person.alternateContact" [readonly]="readOnly">
                        </div>
                        <div class="form-group col-md-4"
                            [ngClass]="{'invalid': (email.invalid && (email.dirty || email.touched))}">
                            <label class="star">Email ID</label>
                            <input type="email" class="form-control" id="email" required name="email" #email="ngModel"
                                [(ngModel)]="person.email" [readonly]="readOnly">
                        </div>
                        <div class="form-group col-md-4">
                            <label class="">Alternate Email ID</label>
                            <input type="email" class="form-control" id="alternateEmail" name="alternateEmail"
                                [(ngModel)]="person.alternateEmail" [readonly]="readOnly">
                        </div>
                        <div class="form-group col-md-4"
                            [ngClass]="{'invalid': (facebookId.invalid && (facebookId.dirty || facebookId.touched))}">
                            <label class="">Facebook Id</label>
                            <input type="text" class="form-control" id="facebookId" name="facebookId"
                                #facebookId="ngModel" [(ngModel)]="person.facebookId" [readonly]="readOnly">
                        </div>
                        <div class="form-group col-md-4">
                            <label>Comments</label>
                            <input type="text" class="form-control" id="comments" name="comments"
                                [(ngModel)]="person.comments" [readonly]="readOnly">
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <app-accesscontrol [resourceScopes]="resourceScopes" [basicUserVenturesList]="basicUserVenturesList" (emitObj)="onChecking($event)">
                    </app-accesscontrol>
                </div>
                <div class="col-12 text-left">
                    <button type="submit" class="btn btn-info my-3"
                        [disabled]="userId ? disableSubmit : details.invalid">{{userId ? 'Update' : 'Save'}}</button>
                </div>
            </div>
        </form>
    </div>
</div>