import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Color, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { UserPrivileges, UserRoleConstants } from 'src/app/constants/AppConstants';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-salesummary',
  templateUrl: './salesummary.component.html',
  styleUrls: ['./salesummary.component.scss']
})
export class SalesummaryComponent implements OnInit {
  summary: any = [];
  performance: any = [];
  list: any = [];
  plots: any = [];
  ventureId: any;
  plotData: any = [];
  marketerSale: any = [];
  graphdata: any = [];
  userPrivileges=UserPrivileges;

  constructor(private restService: RestService,
    private authService: AuthServiceService,
    private route: ActivatedRoute,
    private location: Location,
    private commonService: CommonServiceService) {
    this.route.params.subscribe(params => {
      this.ventureId = params['ventureId'];
    });
  }
  ngOnInit(): void {
    const userInfo: any = this.authService.getUserInfo();
    const pathF: string = (this.authService.hasRole(UserRoleConstants.MARKETER)) ? 'marketer/saleSummary' : 'tenant/saleSummary';
    const pathP: string = (this.authService.hasRole(UserRoleConstants.MARKETER)) ? 'marketer/plotSummary' : 'tenant/plotSummary';
    const data: any = {
      tenantId: userInfo.tenantId,
      marketerId: userInfo.marketerId,
      ventureId: this.commonService.getVenture()?.ventureId,
    };
    this.restService.submitPostRequest(pathF, data).subscribe(response => {
      this.graphdata = response.data.saleRevenueList;
      this.lineChartData = [
        { data: this.graphdata.map(a => a.amountReceived), label: 'amountReceived' },
        { data: this.graphdata.map(a => a.saleValue), label: 'saleValue' },
      ]
      this.lineChartLabels = this.graphdata.map(a => a.month);
    });
    this.restService.submitPostRequest(pathP, data).subscribe(response => {
      this.summary = response.data.plotTypeslist;
    });
    const path: string = (this.authService.hasRole(UserRoleConstants.MARKETER)) ? 'marketer/marketerVentureDashboard' :
      'tenant/tenantVentureDashboard';
    this.restService.submitPostRequest(path, data).subscribe(response => {
      this.performance = response.data;
      this.marketerSale.push(this.performance.marketerSaleInfo);
    });
  }
  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false
    },
    scales: {
      xAxes: [{}], yAxes: [{
        ticks: {
          //max: 120
        }
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    },
  };
  public lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];
}
