<div class="">
    <div class=" mt-3 position-relative right-tabs clearfix mb-0 card">
        <a class="position-absolute close-popup-link bg-white" (click)="sendBack()" *ngIf="show"><i class="fa fa-times"
        aria-hidden="true"></i>
    </a>
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="justify-content-end pr-3">
            <li class="mr-auto pt-3 px-4 mb-0">
                <h6>Plot Details ({{ventureName}})</h6>
            </li>
            <li [ngbNavItem]="1">
                <a ngbNavLink>Plot Details</a>
                <ng-template ngbNavContent>
                    <div class="mt-0">
                        <app-plots></app-plots>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="3">
                <a ngbNavLink>Plot Summary</a>
                <ng-template ngbNavContent>
                    <app-plotsummary></app-plotsummary>
                </ng-template>
            </li>
            <li [ngbNavItem]="2">
                <a ngbNavLink>Plot Pricing</a>
                <ng-template ngbNavContent>
                    <app-plotspricing></app-plotspricing>
                </ng-template>
            </li>
            <li [ngbNavItem]="4">
                <a ngbNavLink>Plot Blocking</a>
                <ng-template ngbNavContent>
                    <app-plot-blocking></app-plot-blocking>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
</div>
<router-outlet></router-outlet>