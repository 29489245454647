<div class=" my-3">
    <div class="card">
        <a class="position-absolute close-popup-link bg-white" (click)="sendBack()"><i class="fa fa-times"
                aria-hidden="true"></i>
        </a>
        <div class="card-body">
            <h6 class="card-title text-dark position-relative pb-2 ">Mark Plots As Landlord</h6>
        </div>

        <div class="card-body">

            <ng-container *ngIf="availablePlots.length > 0; else noPlots">
                <table class="table table-striped table-bordered table-sm row-border hover" datatable
                    [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Plot #</th>
                            <th>Status</th>
                            <th>Size ({{commonService.getMeasure()}})</th>
                            <th>East (dim)</th>
                            <th>West (dim)</th>
                            <th>North (dim)</th>
                            <th>South (dim)</th>
                            <th>Facing</th>
                            <th>Block</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let plot of availablePlots">
                            <td><input type="checkbox" [(ngModel)]="plot.selected" (change)="selectPlot(plot)" /></td>
                            <td>{{plot.plotNum}}</td>
                            <td>{{capitalFirst(plot.plotStatus)}}</td>
                            <td>{{plot.size.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2
                                })}}
                            </td>
                            <td>{{plot.eastLength.toLocaleString('en-IN', { minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                                })}}</td>
                            <td>{{plot.westLength.toLocaleString('en-IN', { minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                                })}}</td>
                            <td>{{plot.northLength.toLocaleString('en-IN', { minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                                })}}</td>
                            <td>{{plot.southLength.toLocaleString('en-IN', { minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                                })}}</td>
                            <td>{{plot.roadFacing | titlecase}}</td>
                            <td>{{plot.blockName | titlecase}}</td>
                            <td>{{plot.plotType | titlecase}}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="mt-3"> <button class="btn btn-info" (click)="markPlotsToLandlord()">Submit</button>
                </div>

            </ng-container>

            <ng-template #noPlots>
                <div>
                    <h5 class="text-center m-0 py-2">No Available Plots</h5>
                </div>
            </ng-template>

        </div>
    </div>
</div>