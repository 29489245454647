import { Component, Input, OnInit } from '@angular/core';
import{Location} from '@angular/common'
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-addingmarketerventure',
  templateUrl: './addingmarketerventure.component.html',
  styleUrls: ['./addingmarketerventure.component.scss']
})
export class AddingmarketerventureComponent implements OnInit {

  @Input() id;

  ventureList: any= [];
  ventureSelected: any;
  keyword = 'name';
  userInfo: any;
  marketerventure: any ={};
  marketerData: any;


  constructor( private restService: RestService,
    private authService: AuthServiceService,
    private location: Location) { }

  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    const roleName: string = this.userInfo.role.toLowerCase();
    const pathF: string = roleName + '/venture/getVenturesList';
    this.restService.submitGetRequest(pathF).subscribe(response => {
      if (response && response.data) {
        this.ventureList = response.data;
      }
    });
  }

  onSubmit(value) {
    this.marketerventure.tenantId = this.userInfo.tenantId;
    this.marketerventure.marketerId = this.id;
    this.marketerventure.ventureId = this.ventureSelected.ventureId;
    const path: string = 'venture-marketing/add-venture-marketing'
    this.restService.submitPostRequest(path, this.marketerventure).subscribe(
      (response: any) => {
        if (response) {
          this.marketerData = response;
        }
        this.location.back();
      }, (error: any) => {
        // TODO Error handling
      });
  }

}
