<div class=" mt-3 position-relative right-tabs clearfix mb-0 card">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="justify-content-end pr-3">
    <li class="mr-auto pt-3 px-4 mb-0">
      <h6>Analytics</h6>
    </li>
    <li [ngbNavItem]="1" *ngIf="userInfo?.scopes.includes('TENANT_PRIVILEGE') || userInfo?.scopes.includes('TENANT_BASIC_PRIVILEGE') || userInfo?.scopes.includes('MARKETER_SUPER_PRIVILEGE') || userInfo?.scopes.includes('MARKETER_BASIC_PRIVILEGE')">
      <a ngbNavLink>Performance</a>
      <ng-template ngbNavContent>
        <div class="mx-4 mb-4">
          <app-performance></app-performance>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2" *ngIf="userInfo?.scopes.includes('TENANT_PRIVILEGE') || userInfo?.scopes.includes('TENANT_BASIC_PRIVILEGE')  || userInfo?.scopes.includes('MARKETER_SUPER_PRIVILEGE') || userInfo?.scopes.includes('MARKETER_BASIC_PRIVILEGE')">
      <a ngbNavLink>Sales</a>
      <ng-template ngbNavContent>
        <div class="mx-4 mb-4">
          <app-sales-analytics></app-sales-analytics>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="3" *ngIf="userInfo?.scopes.includes('SALESREP_PRIVILEGE') || userInfo?.scopes.includes('MARKETER_SUPER_PRIVILEGE') || userInfo?.scopes.includes('MARKETER_BASIC_PRIVILEGE')">
      <a ngbNavLink>Salesrep Sales</a>
      <ng-template ngbNavContent>
        <div class="mx-4 mb-4">
          <app-salesrep-sales-analytics></app-salesrep-sales-analytics>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="4" *ngIf="userInfo?.scopes.includes('SALESREP_PRIVILEGE') || userInfo?.scopes.includes('MARKETER_SUPER_PRIVILEGE') || userInfo?.scopes.includes('MARKETER_BASIC_PRIVILEGE')">
      <a ngbNavLink>Salesrep Commissions</a>
      <ng-template ngbNavContent>
        <div class="mx-4 mb-4">
          <app-salesrep-commissions-analytics></app-salesrep-commissions-analytics>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>