<div>
  <div class="shadow h-100" id="sidebar-wrapper">
    <div class="sub-menu-wrapper position-relative h-100 bg-dark sidebar-bg" [ngClass]="'color-' + themeService.themeColor"  style="background-image: url('https://www.themeselection.com/static/app-assets/images/backgrounds/04.jpg');">
       <div class="sidebar-heading position-relative bg-header">
        <div class="horizontal-sub-menu-content circle-user">
          <ul class="list-unstyled mb-0 sub-menu-logo d-flex justify-content-center align-items-center align-items-center">
            <li>
              <a class="list-group-item bg-transparent text-white border-0 position-relative p-0 m-0">
                <img src="assets/images/venzr-logo.png" alt="logo" class="img-fluid w-75 venzr-logo" [ngClass]="{'d-none':!themeService.status}">
                <img src="assets/images/venzr-mini-logo.png" alt="logo" class="img-fluid w-50 mx-auto venzr-mini-logo" [ngClass]="{'d-none':themeService.status, 'd-block':!themeService.status}">
              </a>
            </li>
          </ul>
        </div>
      </div>

      <!-- sidebar scroll -->
      <div class="sidebar-scroll">
        <div class="sidebar-heading position-relative pb-2 bg-header">
          <div class="horizontal-sub-menu-content circle-user">
            <ul class="list-unstyled mb-0">
              <li class="dropdown">
                <a class="list-group-item bg-transparent text-white border-0 position-relative my-0 ml-1">
                  <div class="media">
                    <img class="rounded-circle mr-3 profile-pic" src="{{tenantUser ? 'assets/images/user.png' : 'assets/images/salesrep.jpeg'}}" alt="student cloud" />
                    <div class="media-body">
                      <span class="sidebar-link-content text-white pt-1 d-flex">
                        <a class="text-white" routerLink = "./myprofile">{{(userInfo.firstName + ' ' + userInfo.lastName).length > 15 ? (userInfo.firstName + ' ' + userInfo.lastName).substring(0,15)+"..." : (userInfo.firstName + ' ' + userInfo.lastName) }}</a>  
                        <a class="pointer ml-4 h6 text-white" [routerLink]= "['/'+commonService.getUrlSuffix() +'/logout']" > <i class="fas fa-sign-out"></i> </a>                     
                      </span>
                        <small class="d-block text-white-50">Last logged in {{id | date}}</small> 
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>       
        <app-menu></app-menu>
      </div>
    </div>
  </div>
</div>