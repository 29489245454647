import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { RestService } from 'src/app/services/rest.service';
import { UserPrivileges } from 'src/app/constants/AppConstants';
import { Router } from '@angular/router';
import { RoutesConstants } from 'src/app/constants/RoutesConstants';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-promo-details',
  templateUrl: './promo-details.component.html',
  styleUrls: ['./promo-details.component.scss']
})
export class PromoDetailsComponent implements OnInit {
  @Input() closed: boolean;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {};
  promos: any;
  show: any;
  incentiveDetails: any;
  userPrivileges = UserPrivileges;
  role: any;
  closeResult: string;
  promoData: any;
  ventures: any;
  dropdownSettings: { idField: string; textField: string; };
  selectedItems: any =[];
  error: boolean;
  errorMessage: any;
  isEditPromoItem = false;
  promoItem :any ={};
  currentDate: any;

  constructor(private authService: AuthServiceService, private restService: RestService,
    private router: Router, private modalService: NgbModal,public commonService: CommonServiceService,
    ) { 
  }

  ngOnInit(): void {
    const userInfo = this.authService.getUserInfo();
    let path;
    this.role = this.authService.getUserInfo().role;
    if (userInfo.role == 'SalesRep') {
      path = 'salesrep/incentives/activeProgram?salesrepId=' + userInfo.username;
    } else {
      if (this.closed) {
        path = 'marketer/incentives/closedProgram?marketerId=' + userInfo.marketerId;
      } else {
        path = 'marketer/incentives/activeProgram?marketerId=' + userInfo.marketerId;
      }
    }
    this.restService.submitGetRequest(path).subscribe(response => {
      this.promos = response.data;
    });
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
    };
    const cValue = new Date();
    this.currentDate =formatDate(cValue, 'yyyy-MM-dd', 'en-US');
  }
  expand(promo) {
    promo.expand = !promo.expand;
    if (promo.expand) {
      let path = 'generic/incentives/detail/program?incentiveProgramId=' + promo.incentiveProgramId;
      this.restService.submitGetRequest(path).subscribe(response => {
        this.incentiveDetails = response.data;
        promo.incentiveDetails = response.data;
      });
    }
  }

  updatePromo(promo, closePromo) {
    if (closePromo) {
      promo.incentiveProgramStatus = 'closed'
    }
    let path = 'marketer/incentives/program'
    this.restService.submitPutRequest(path, promo).subscribe(response => {
      if (response) {
        this.modalService.dismissAll('Cross click');
        this.ngOnInit();
      }
    });

  }

  addPromo(){
    this.router.navigate(['/'+this.commonService.getUrlSuffix(),RoutesConstants.MARKETERLAYOUT, RoutesConstants.ADDPROMO]);
  }
  
  displayPromoSales(promo) {
    promo.isPromoSales = !promo.isPromoSales;
    let path = 'generic/incentives/program/salesrepResult?incentiveProgramId=' + promo.incentiveProgramId;
    this.restService.submitGetRequest(path).subscribe(response => {
      promo.promoSales = response.data;
    });
  }

  runPromoSales(promo) {
    let path = 'marketer/schedule/program?incentiveProgramId=' + promo.incentiveProgramId;
    this.restService.submitPostRequest(path, {}).subscribe(response => {
      promo.promoSales.status = response.data;
    })
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  promoById(promo, editPromo) {
    this.dropdownSettings = {
      idField: 'ventureId',
      textField: 'ventureName',
    };
    let path = 'generic/incentives/program?incentiveProgramId=' + promo.incentiveProgramId;
    this.restService.submitGetRequest(path).subscribe(response => {
      this.promoData = response.data;
      let path = 'marketer/venture/getVenturesList';
      this.restService.submitGetRequest(path).subscribe(response => {
        this.ventures = response.data;
        this.ventures.forEach(element => {
          element.ventureName = element.displayName
        });
      });
      this.open(editPromo);
    });

  }

  onItemSelect(item: any) {
    this.promoData.incentiveProgramVenturesList.push(item)
  }

  onItemDeSelect(item: any) {
    this.promoData.incentiveProgramVenturesList = this.promoData.incentiveProgramVenturesList.filter(ele => {
      return ele.ventureId != item.ventureId
    });
  }

  onSelectAll(items: any) {
    this.promoData.incentiveProgramVenturesList = [];
    this.ventures.forEach(element => {
      this.promoData.incentiveProgramVenturesList.push(element.ventureId)
    });
  }

  onUnSelectAll() {
    this.promoData.incentiveProgramVenturesList = []
  }
  onSubmit() {
    this.promoData.incentiveProgramVenturesList.forEach(element => {
      this.selectedItems.push(element.ventureId);
    });
    this.promoData.venturesList = this.selectedItems.toString();
    let path = "marketer/incentives/program";
    this.restService.submitPutRequest(path, this.promoData).subscribe(response => {
      this.modalService.dismissAll('Cross click');
      this.ngOnInit();
    })
  }

  openAddPromoItem(addOrEditPromoItem, promo: any){
    this.promoItem.promoname = promo.name;
    this.promoItem.incentiveProgramId = promo.incentiveProgramId;
    this.open(addOrEditPromoItem);
  }

  onPromoItemSubmit(){
    if(this.isEditPromoItem){
      this.restService.editIncentiveDetail(this.promoItem).subscribe(
        (response: any) => {
          if (response != null) {
            this.modalService.dismissAll('Cross click');
            this.ngOnInit();
          }
        }, (error) => {
          this.error = true;
          this.errorMessage = error.status;
        })
      this.isEditPromoItem = false;
    } else {
      this.restService.addIncentiveDetail(this.promoItem).subscribe(
        (response: any) => {
          if (response != null) {
            this.modalService.dismissAll('Cross click');
            this.ngOnInit();
          }
        }, (error) => {
          this.error = true;
          this.errorMessage = error.status;
        })
    }
  }

  isFormValid(): boolean {
    const inputs = [
      this.promoItem.area,
      this.promoItem.paymentPercentage,
      this.promoItem.prize,
    ];  
    for (const input of inputs) {
      if (!input) {
        return false;
      }
    }
    return true;
  }

  openEditPromoItem(promo : any, incentiveDetail : any, addOrEditPromoItem){
    this.promoItem = incentiveDetail;
    this.promoItem.promoname = promo.name;
    this.isEditPromoItem = true;
    this.open(addOrEditPromoItem);
  }
  
  isPromoFormValid() {
    if (this.promoData.startDate > this.promoData.endDate || this.currentDate > this.promoData.endDate) {
      return false;
    }else{
      return true;
    }
  }
}
