

<div class=" my-3">
    <div class="card">
        <a class="position-absolute close-popup-link bg-white" (click)="sendBack()"><i class="fa fa-times"
                aria-hidden="true"></i>
        </a>
        <div class="card-body">
            <h6 class="card-title text-dark position-relative pb-2 ">{{ isEditView ? 'Update Commission Structure' : 'New Commission Structure' }}</h6>
            <form #commissionForm="ngForm" (ngSubmit)="onSubmit(commissionForm.value)">
                <div class="row mt-2">
                <div class="form-group col-md-6">
                  <label for="salesrepDesignation">Salesrep Designation:</label>
                  <input type="text" class="form-control" id="salesrepDesignation" name="salesrepDesignation" [disabled]="isEditView"  [(ngModel)]="commissionStructure.salesrepDesignation" required>
                </div>
                <div class="form-group col-md-6">
                  <label for="commissionType">Commission Type:</label>
                  <select class="form-control" id="commissionType" name="commissionType" [disabled]="isEditView" [(ngModel)]="commissionStructure.commissionType" required>
                    <option value="perMeasure">Per Measure</option>
                    <option value="percentage">Percentage</option>
                    <!-- <option value="fixed">Fixed</option> -->
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="effectiveDate">Effective Date:</label>
                  <input type="date" class="form-control" id="effectiveDate"  name="effectiveDate" [disabled]="isEditView" [(ngModel)]="commissionStructure.effectiveDate" required>
                </div>
                <div class="form-group col-md-6">
                  <label for="fieldValue">Commission Value:</label>
                  <input type="number" class="form-control" id="fieldValue" name="fieldValue" [(ngModel)]="commissionStructure.fieldValue" required>
                </div>
                <button type="submit" class="btn btn-info">Submit</button>
            </div>
              </form>
        </div>
    </div>
</div>