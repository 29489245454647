<div>                      
    <div class="" *ngIf="pieChartData">
        <canvas #mycanvas baseChart height="250%"
        [data]="pieChartData" 
        [labels]="pieChartLabels"
        [chartType]="pieChartType" 
        [options]="pieChartOptions" 
        [plugins]="pieChartPlugins"
        [colors]="pieChartColors" 
        [legend]="pieChartLegend">
        </canvas>
    </div>
</div>