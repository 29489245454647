<ol class="breadcrumb bg-transparent text-dark position-relative p-0 m-0 d-flex align-items-center justify-content-start">
  <li class="breadcrumb-item">
    <a routerLink = "/studentlayout/studentdashboard" class=" d-flex justify-content-center align-items-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="14"
        height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
        stroke-linejoin="round">
        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
        <polyline points="9 22 9 12 15 12 15 22"></polyline>
      </svg></a></li>
      <ng-container>
        <li *ngFor="let breadcrumb of breadcrumbs">
          <span *ngIf = "i > 0"> /</span>
              <a> {{ breadcrumb}} </a>
        </li>
      </ng-container>
</ol>