<div class="container-fluid my-3">
    <div class="card">
        <a class="position-absolute close-popup-link bg-white" (click)="sendBack()"><i class="fa fa-times"
                aria-hidden="true"></i>
        </a>
        <div class="card-body">
            <form #details="ngForm" (ngSubmit)="onSubmit(details.value)">
                <div class="row">
                    <div class="form-group col-6"
                        [ngClass]="{'invalid': plotStatus.invalid && (plotStatus.dirty || plotStatus.touched)}">
                        <label class="star">Plot Status</label>
                        <select type="text" class="form-control" id="plotStatus" required
                            [(ngModel)]="status.plotStatus" name="plotStatus" #plotStatus="ngModel">
                            <option value="Registered">Registered</option>
                        </select>
                    </div>
                    <div class="form-group col-6"
                        [ngClass]="{'invalid': registrationNum.invalid && (registrationNum.dirty || registrationNum.touched)}">
                        <label class="star">Registration Number</label>
                        <input type="text" class="form-control" id="registrationNum" required
                            [(ngModel)]="status.registrationNum" name="registrationNum" #registrationNum="ngModel">
                    </div>
                    <div class="form-group col-6"
                        [ngClass]="{'invalid': registrationDate.invalid && (registrationDate.dirty || registrationDate.touched)}">
                        <label class="star">Registration Date</label>
                        <input type="date" class="form-control" id="registrationDate" required
                            [(ngModel)]="status.registrationDate" name="registrationDate" #registrationDate="ngModel">
                    </div>
                    <div class="form-group col-6"
                        [ngClass]="{'invalid': comments.invalid && (comments.dirty || comments.touched)}">
                        <label class="star">Comments</label>
                        <input type="text" class="form-control" id="comments" [(ngModel)]="status.comments"
                            name="comments" #comments="ngModel">
                    </div>
                </div>
                <button type="submit" class="btn btn-info m-3" [disabled]=details.invalid>Submit</button>
            </form>
        </div>
    </div>
</div>