<section class="my-3 ">
  <div class="card pb-4 total">
    <h6 class="py-4 px-4 mb-4 header" style="background-color: #f7f7f4;
    ">Reports</h6>
    <div class="row px-4">
      <div class="col-lg-4 ">
        <div class="card mb-4" >
          <div class="card-body" >
            <!-- <img align="right"  src="https://image.shutterstock.com/image-photo/tiny-white-toy-house-red-260nw-1378839710.jpg"
      height="120" width="120">-->
            <h6 class="card-title text-dark position-relative pb-2 ">Sale Report</h6>
            <ul style="list-style: none;" *ngFor="let sale of sales.saleReports ">
              <li>
                <p><a routerLink="../report/totalSale">{{sale.total}}</a></p>
              </li>
              <li>
                <p><a routerLink="../report/saleVenture">{{sale.venture}}</a></p>
              </li>
              <li>
                <p><a routerLink="../report/saleSalesRep">{{sale.salesRep}}</a></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card mb-4">
          <div class="card-body">
            <h6 class="card-title text-dark position-relative pb-2">Revenue Report</h6>
            <ul style="list-style: none;" *ngFor="let revenue of sales.revenueReports ">
              <li>
                <p><a routerLink="../report/totalRevenue">{{revenue.total}}</a></p>
              </li>
              <li>
                <p><a routerLink="../report/revenueMarketer">{{revenue.marketer}}</a></p>
              </li>
              <li>
                <p><a routerLink="../report/revenueVenture">{{revenue.venture}}</a></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card mb-4">
          <div class="card-body">
            <h6 class="card-title text-dark position-relative pb-2 ">Commission Report</h6>
            <ul style="list-style: none;" *ngFor="let commission of sales.commissionReports">
              <li>
                <p><a routerLink="../report/commission">{{commission.total}}</a></p>
              </li>
              <li>
                <p><a routerLink="../report/salesrepCommission">{{commission.salesRep}}</a></p>
              </li>
              <li>
                <p><a routerLink="../report/ventureCommission">{{commission.venture}}</a></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-4 mb-4">
        <div class="card  h-100">
          <div class="card-body">
            <h6 class="card-title text-dark position-relative pb-2 ">Profit Report</h6>
            <ul style="list-style: none;" *ngFor="let profit of sales.profitReports">
              <li>
                <p><a routerLink="../report/Profit">{{profit.total}}</a></p>
              </li>
              <li>
                <p><a routerLink="../report/ProfitVenture">{{profit.venture}}</a></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-4 mb-4">
        <div class="card  h-100">
          <div class="card-body">
            <h6 class="card-title text-dark position-relative pb-2 ">Team Report</h6>
            <ul style="list-style: none;" *ngFor="let team of sales.teamReports">
              <li>
                <p><a routerLink="../report/team">{{team.total}}</a></p>
              </li>
              <li>
                <p><a routerLink="../report/teamVenture">{{team.venture}}</a></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-4 mb-4">
        <div class="card  h-100">
          <div class="card-body">
            <h6 class="card-title text-dark position-relative pb-2 ">Customer Report</h6>
            <ul style="list-style: none;" *ngFor="let customer of sales.customerReports">
              <li>
                <p><a routerLink="../report/customerPayment">{{customer.total}}</a></p>
              </li>
              <li>
                <p><a routerLink="../report/pendingPayment">{{customer.pending}}</a></p>
              </li>
              <li>
                <p><a routerLink="../report/paymentVenture">{{customer.venture}}</a></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<router-outlet></router-outlet>