<div>
      <div  *ngIf="lineChartData">
          <canvas baseChart [datasets]="lineChartData"
              [labels]="lineChartLabels"
              [options]="lineChartOptions"
              [plugins]="lineChartPlugins"
              [legend]="lineChartLegend"
              [colors]="lineChartColors"
              [chartType]="lineChartType">
          </canvas>
      </div>
    </div>