<!-- <div class="wrap">
    <table class="table table-striped table-bordered table-sm row-border hover">
        <thead>
            <tr>
                <th>First Name</th>
                <th>Lead Total Commission(<i class="fas fa-rupee-sign mr-1"></i>)</th>
                <th>Lead Commission Earned(<i class="fas fa-rupee-sign mr-1"></i>)</th>
                <th *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE, userPrivileges.TENANT_PRIVILEGE] ">Commission Paid(
                    <i class="fas fa-rupee-sign mr-1"></i>)</th>
                <th *ngxPermissionsOnly="[userPrivileges.SALESREP_PRIVILEGE]">Commission Received(<i class="fas fa-rupee-sign mr-1"></i>)</th>
                <th>Lead Commission Pending(<i class="fas fa-rupee-sign mr-1"></i>)</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let commission of indirectComm">
                <tr (click)="commission.expanded =! commission.expanded" (click)="display(commission.salesrepId)" style="cursor:pointer;">
                    <td>{{commission.name}}</td>
                    <td>{{commission.leadTotalCommission?.toLocaleString('en-IN')}}</td>
                    <td>{{commission.leadEarnedCommission?.toLocaleString('en-IN')}}</td>
                    <td>{{commission.leadPaidCommission?.toLocaleString('en-IN')}}</td>
                    <td>{{commission.leadPendingCommission?.toLocaleString('en-IN')}}</td>
                </tr>
                <ng-container *ngFor="let plot of (commissionOnPlots.indirectCommissionsOnPlots ? commissionOnPlots.indirectCommissionsOnPlots: [])">
                    <tr *ngIf=" commission.expanded  && plot.salesrepId == number">
                        <td>{{plot.plotNum}}</td>
                        <td>{{plot.leadTotalCommission?.toLocaleString('en-IN')}}</td>
                        <td>{{plot.leadEarnedCommission?.toLocaleString('en-IN')}}</td>
                        <td>{{plot.leadPaidCommission?.toLocaleString('en-IN')}}</td>
                        <td>{{plot.leadPendingCommission?.toLocaleString('en-IN')}}</td>
                    </tr>
                </ng-container>
            </ng-container>
        </tbody>
    </table>
</div> -->
<div class="wrap">
    <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
        <thead>
            <tr>
                <th>Name</th>
                <th>Venture Name</th>
                <th>Plot Number</th>
                <th>Plot Status</th>
                <th>Total Commission(<i class="fas fa-rupee-sign"></i>)</th>
                <th>Commission Earned(<i class="fas fa-rupee-sign"></i>)</th>
                <th *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE, userPrivileges.TENANT_PRIVILEGE] ">Commission Paid(
                    <i class="fas fa-rupee-sign"></i>)</th>
                <th *ngxPermissionsOnly="[userPrivileges.SALESREP_PRIVILEGE]">Commission Received(<i class="fas fa-rupee-sign"></i>)</th>
                <th>Commission Pending(<i class="fas fa-rupee-sign"></i>)</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let commission of indirectComm">
                <td>{{commission.salesrepName}}</td>
                <td>{{commission.ventureName}}</td>
                <td (click)="display(commission)">{{commission.plotNumber}}</td>
                <td>{{commission.plotStatus}}</td>
                <td>{{commission.totalCommission?.toLocaleString('en-IN')}}</td>
                <td>{{commission.commissionEarned?.toLocaleString('en-IN')}}</td>
                <td>{{commission.commissionPaid?.toLocaleString('en-IN')}}</td>
                <td>{{commission.commissionPending?.toLocaleString('en-IN')}}</td>
            </tr>
        </tbody>
    </table>
</div>