export class RoutesConstants {

    public static LOGIN = 'login';
    public static LOGOUT = 'logout';
    public static RESETPASSWORD = 'reset-password';

    public static CHANGE_PASSWORD = 'change-password';

    public static ADD_USER = 'user/add';
    public static EDIT_USER = 'user/:id';
    public static PROFILE = 'profile';
    public static PLOTS = 'plots';
    public static VENTURE = 'venture';
    public static ANALYTICS = 'analytics';
    public static SALESSUMMARY = 'salessummary';
    public static SALES = 'sales';
    public static MARKETER = 'marketers';
    public static TENANT = 'tenant';
    public static NEWSALE = 'newsale';
    public static SALESREPDASHBOARD = 's-dashboard';
    public static MYPROFILE = 'myprofile';
    public static MYTEAM = 'myteam';
    public static MYSALES = 'mysales';
    public static MYTEAMSALES = 'myteamsales';
    public static SEPARATECOMMISSIONS = 'separatecommissions';
    public static MYPAYMENTS = 'mypayments';
    public static MYCOMMISSIONS = 'mycommissions';
    public static MARKETERDASHBOARD = 'm-dashboard';
    public static REPORTS = 'reports';
    public static TEAMS = 'teams';

    public static CUSTOMERS = 'customers';
    public static CUSTOMERS_DETAILS = 'cust-info/:customerid';
    public static NOPERMISSIONS = 'nopermissions';
    public static NOTENANTPERMISSIONS = 'notenantpermissions';
    public static NOMARKETERPERMISSIONS = 'nomarketerpermissions';

    public static PLOTSPRICING = 'plotspricing';
    public static COMMISSIONDETAILS = 'commissiondetails';
    public static VENTURETEAM = 'ventureteam';
    public static PAYMENTS = 'payments';
    public static RECEIVABLES = 'receivables';
    public static PROMOTIONS = 'promotions';
    public static SALESCHALLENGES = 'saleschallenges';
    public static VENTUREDASHBOARD = 'v-dashboard';
    public static VENTURESALES = 'venturesales';
    public static VENTURELAYOUTDETAILS = 'venture-layout-details';
    public static VENTURELAYOUTDESIGN = 'venture-layout-design';
    public static COMMISSIONS = 'commissions';
    public static COMMISSION_PAYMENTS = 'add-commissions';
    public static ALLDETAILS = 'alldetails/:salesrepid';
    public static USERDETAILS = 'userdetails/:username';
    public static SALEDETAILS = 'saledetails';
    public static SALESREPPLOTS = 'salesrepplots/:plotId';
    public static REPORT = 'report/:reporttype';
    public static ADDPLOT = 'addplot';
    public static ADDNEWSALE = 'add-sale';
    public static ADDPLOTPRICING = 'addplotpricing';
    public static EDITPLOTPRICING = 'edit-plot-pricing';
    public static ADDMARKETERVENTURE ='addmarketerventure'
    public static ADDMARKETER = 'addmarketer';
    public static ADDTENANT = 'addtenant';
    public static ADDCOMMISSIONSTRUCTURE = 'addcommissionstructure';
    public static EDITCOMMISSIONSTRUCTURE = 'editcommissionstructure';
    public static PLOTBYID = 'plotbyid';
    public static PLOTSUMMARY = 'plotsummary';
    public static SALESUMMARY = 'salesummary';
    public static EDITSALE = 'editsale';
    public static USER = 'user';
    public static PLOTSTATUS = 'plotstatus';
    public static VENTUREDESIGN = 'venture-layout-design/:ventureId';

    public static EDITMARKETER = 'editmarketer/:marketerid';
    public static TENANT_STATS = 'tenantstats';
    public static PROMOS = 'promos';

    public static ADDPROMO = 'addpromo'

    public static MORTGAGEMARKING = 'mortgagemarking';
    public static COMPANYBLOCKEDMARKING = 'companyblockedmarking';
    public static LANDLORDMARKING = 'landlordmarking';

    public static TENANT_DASHBOARD = 't-dashboard';

    public static ADMIN_LAYOUT = 'al';
    public static TENANT_LAYOUT = 'tl';
    public static MARKETERLAYOUT = 'ml';
    public static SALESREPLAYOUT = 'sl';
    public static VENTURELAYOUT = 'vl';

}