import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-commission-payments',
  templateUrl: './commission-payments.component.html',
  styleUrls: ['./commission-payments.component.scss']
})
export class CommissionPaymentsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
