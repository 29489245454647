import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UserPrivileges } from '../../constants/AppConstants';
import _ from 'underscore';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { RestService } from 'src/app/services/rest.service';
@Component({
  selector: 'app-accesscontrol',
  templateUrl: './accesscontrol.component.html',
  styleUrls: ['./accesscontrol.component.scss']
})
export class AccesscontrolComponent implements OnInit {
  onCheck: any = {};
  @Input() resourceScopes: any;
  @Output() emitObj: EventEmitter<any> = new EventEmitter<any>();
  @Input() basicUserVenturesList: any; 
  userPrivileges = UserPrivileges;

  ventureList: any = [];
  path: string = '';

  constructor(
    private restService: RestService,
    private authService: AuthServiceService
  ) {
    const roleName: string = this.authService.getUserInfo().role.toLowerCase();
    this.path = roleName + '/venture/getVenturesList';
  }

  ngOnInit(): void {

    this.restService.submitGetRequest(this.path).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.ventureList = response.data;
          if (this.basicUserVenturesList && this.basicUserVenturesList.length > 0) {
            this.ventureList.forEach(venture => {
              venture.selected = this.basicUserVenturesList.includes(venture.ventureId);
            });
          }

        }
      }, (error: any) => {
        // TODO Error handling
      });


    if (!_.isEmpty(this.resourceScopes)) {
      this.resourceScopes.forEach(scope => {
        this.onCheck[scope] = true;
      });
    }



  }
  onChanges() {

    const selectedVentures = this.ventureList
      .filter(venture => venture.selected)
      .map(venture => venture.ventureId);

    const resourceScopes = Object.keys(this.onCheck).filter(key => this.onCheck[key]);
    
    const keyOnly = {
      ventures: selectedVentures,
      resourceScopes: resourceScopes
    };

    this.emitObj.emit(keyOnly);
  }




}
