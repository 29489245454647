<div class=" my-3 bg-white" *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.TENANT_PRIVILEGE,userPrivileges.TENANT_BASIC_PRIVILEGE,userPrivileges.MARKETER_BASIC_PRIVILEGE]">
    <div class="row mx-3 mb-3">
        <div class="col-md-8 mb-3">
            <div class="card">
                <div class="card-body">
                    <div class="wrap">
                        <table class="table table-striped table-bordered table-sm row-border hover">
                            <thead>
                                <tr>
                                    <th>Plot Type</th>
                                    <th>Total</th>
                                    <th>Sold</th>
                                    <th>Blocked</th>
                                    <th>Registered</th>
                                    <th>Available</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let plot of summary">
                                    <td>{{plot.plotType}}</td>
                                    <td>{{plot.total}}</td>
                                    <td>{{plot.sold}}</td>
                                    <td>{{plot.blocked}}</td>
                                    <td>{{plot.registered}}</td>
                                    <td>{{plot.available}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card mb-3">
                <div class="card-body px-3 pb-4 pt-2">
                    <ul class="list-unstyled my-4">
                        <li *ngFor="let plot of marketerSale">
                            <div class="row">
                                <div class="col-6">Total Plots Sold:</div>
                                <div class="col-6"><b>{{plot.plotsSold}}</b></div>
                                <div class="col-6">Total Sale Value:</div>
                                <div class="col-6"><b>&#8377;{{plot.saleValue.toLocaleString('en-IN')}}</b>
                                </div>
                                <div class="col-6">Amount Received:</div>
                                <div class="col-6"><b>&#8377;{{plot.amountReceived.toLocaleString('en-IN')}}</b>
                                </div>
                                <div class="col-6">Amount Pending:</div>
                                <div class="col-6"><b>&#8377;{{plot.amountPending.toLocaleString('en-IN')}}</b>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="card mb-3">
                <div class="card-body">
                    <div>
                        <div *ngIf="lineChartData">
                            <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions" [plugins]="lineChartPlugins" [legend]="lineChartLegend" [colors]="lineChartColors" [chartType]="lineChartType">
                            </canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>