import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { RoutesConstants } from '../../../constants/RoutesConstants';
import { AuthServiceService } from '../../../services/auth-service.service';
import { RestService } from '../../../services/rest.service';

@Component({
  selector: 'app-tenant',
  templateUrl: './tenant.component.html',
  styleUrls: ['./tenant.component.scss']
})
export class TenantComponent implements OnInit {
  dtOptions:any= {};
  dtTrigger: Subject<any> = new Subject<any>();
  tenantList: any = [];

  constructor(private restService: RestService,
    private authService: AuthServiceService,
    private router: Router) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
    };

    const rePath = '/getAllMarketers';
    this.restService.submitGetRequest(rePath).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.tenantList = response.data;
          this.dtTrigger.next();
        }
    }, (error: any) => {
      // TODO Error Handling
    });
  }

  onCheck(){
    this.router.navigate([RoutesConstants.ADMIN_LAYOUT, RoutesConstants.ADDTENANT]);
  }
}
