import { Component, OnInit, } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { AuthServiceService } from 'src/app/services/auth-service.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  Performance: any = [];
  reportsData: any = [];
  saleData: any = [];
  sales: any = [];
  mode: string;

  constructor(
    private restService: RestService,
    private authService: AuthServiceService
  ) { }

  ngOnInit() {
    this.restService.getReports().subscribe(data => { this.sales = data });
    this.mode = this.authService.getUserInfo().role;
  }

}
