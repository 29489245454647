import { Component, OnInit } from '@angular/core';
import { AuthServiceService } from '../../services/auth-service.service';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-venturelayout',
  templateUrl: './venturelayout.component.html',
  styleUrls: ['./venturelayout.component.scss']
})
export class VenturelayoutComponent implements OnInit {

  setAdmin: boolean;
  status = true;

  constructor(
    public themeService: ThemeService,
    public authService: AuthServiceService
  ) { }

  ngOnInit(): void {
  }

  menuToggle() {
    this.themeService.menuToggle();
  }

  ngAfterContentInit(){
    this.themeService.getColorCookie();
  }

  ngAfterViewInit(){
    if(this.themeService.getThemeCookie()){
      this.themeService.toggleField()
    }
  }

}