<div class=" my-3">
    <div class="card">
        <div class="card-body">
            <h6 class="card-title text-dark position-relative pb-2 h6" *ngIf="!display">My Team</h6>
            <div class="wrap">
                <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Designation</th>
                            <th>Contact Number</th>
                            <th>Email Id</th>
                            <th>Team Lead</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let member of (teamsArray.teams ? teamsArray.teams : [])">
                            <td>{{member.name}}</td>
                            <td>{{member.designation? member.designation: "Salesrep" }}</td>
                            <td>{{member.contactNumber}}</td>
                            <td>{{member.email}}</td>
                            <td>{{member.leadName}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>