export class payNow {
    payNow: any;
    constructor(
        public amount: number,
        public paidFor: String,
        public paidBy: String,
        public paymentMode: String,
        public receivedBy: String,
        public comments: String,
        public paidDate: String
    ) { }
}