
<div class="mb-2"
    *ngxPermissionsOnly="[userPrivileges.MARKETER_SUPER_PRIVILEGE,userPrivileges.TENANT_PRIVILEGE,userPrivileges.TENANT_BASIC_PRIVILEGE,userPrivileges.MARKETER_BASIC_PRIVILEGE]">
    <div class="card">
        <div class="card-body">
            <div class="row p-2 mx-0 mb-2 ">
                <form class="form-inline">
                    <div class="form-check px-3">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" [(ngModel)]="selectedPlotStatus" value="blocked" name="plotStatus"
                                (change)="loadPlotStatusComponent()"> Blocked
                        </label>
                    </div>
                    <div class="form-check px-3">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" [(ngModel)]="selectedPlotStatus" value="mortgage" name="plotStatus"
                                (change)="loadPlotStatusComponent()"> Mortgage
                        </label>
                    </div>
                    <div class="form-check px-3">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" [(ngModel)]="selectedPlotStatus" value="m-sold" name="plotStatus"
                                (change)="loadPlotStatusComponent()"> M-sold
                        </label>
                    </div>
                    <div class="form-check px-3">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" [(ngModel)]="selectedPlotStatus" value="c-blocked" name="plotStatus"
                                (change)="loadPlotStatusComponent()"> C-blocked
                        </label>
                    </div>
                    <div class="form-check px-3">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" [(ngModel)]="selectedPlotStatus" value="landlord" name="plotStatus"
                                (change)="loadPlotStatusComponent()"> Landlord
                        </label>
                    </div>
                </form>
            </div>
            <div>
                <div [ngSwitch]="selectedPlotStatus">
                    <app-blocked-plots *ngSwitchCase="'blocked'"></app-blocked-plots>
                    <app-mortgage-plots *ngSwitchCase="'mortgage'"></app-mortgage-plots>
                    <app-m-sold-plots *ngSwitchCase="'m-sold'"></app-m-sold-plots>
                    <app-c-blocked-plots *ngSwitchCase="'c-blocked'"></app-c-blocked-plots>
                    <app-landlord-plots *ngSwitchCase="'landlord'"></app-landlord-plots>
                </div>
            </div>
        </div>
    </div>
</div>
