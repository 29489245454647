import { Component, Input, OnChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { from, Subject } from 'rxjs';
import { RestService } from 'src/app/services/rest.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { UserRoleConstants } from 'src/app/constants/AppConstants';

@Component({
  selector: 'app-myteam',
  templateUrl: './myteam.component.html',
  styleUrls: ['./myteam.component.scss']
})
export class MyteamComponent implements OnInit {
  @Input('sales') salesrep: any;
  @Input() id: any;
  @Output() salesRepName = new EventEmitter<any>();
  teamsArray: any = [];
  error: boolean;
  errorMessage: any;
  userInfo: any;
  data: any;
  display: any = false;
  constructor(private restService: RestService,
    private commonService: CommonServiceService,
    private router: Router,
    private authService: AuthServiceService,
    private route: ActivatedRoute) { }
  title = 'angulardatatables';
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  salesrepvalue;

  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      bPaginate: false,
      bInfo: false,
      searching: false
    };
    if (this.salesrep) {
      this.onGetdata();
      this.display = true;
    }
    else {
      this.onClick();
      this.display = false;
    }
  }
  onClick() {
    let data = {
      "tenantId": this.userInfo.tenantId,
      "marketerId": this.userInfo.marketerId,
      "salesrepId": this.userInfo.salesrepId
    }
    this.restService.getTeams(data).subscribe(response => {
      if (data != null) {
        this.teamsArray = response.data;
        this.dtTrigger.next();
      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      })
  }

  onGetdata() {
    const path: string = (this.authService.hasRole(UserRoleConstants.MARKETER)) ? 'marketer/salesrep/my-teams' :
      'salesrep/my-teams';
    if (this.id) {
      this.data = {
        "tenantId": this.userInfo.tenantId,
        "marketerId": this.userInfo.marketerId,
        "ventureId": this.commonService.getVenture()?.ventureId,
        "salesrepId": this.salesrep
      }
    } else {
      this.data = {
        "tenantId": this.userInfo.tenantId,
        "marketerId": this.userInfo.marketerId,
        //"ventureId": this.commonService.getVenture()?.ventureId,
        "salesrepId": this.salesrep
      }
    }
    this.restService.submitPostRequest(path, this.data).subscribe(response => {
      if (this.data != null) {
        this.teamsArray = response.data;
      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.status;
      })
  }
  // onDisply(row) {
  //   this.router.navigate(['../alldetails', row.salesrepId], { relativeTo: this.route });
  // }
  onName(value) {
    this.salesRepName.emit(value);
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
