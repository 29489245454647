import {
	HttpClient,
	HttpErrorResponse,
	HttpHeaders,
	HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { NewSale } from "../models/new-sale";
import { User } from "../models/user.model";

@Injectable({
	providedIn: "root",
})
export class RestService {
	api: any;
	requestBody: any;

	constructor(private http: HttpClient, private router: Router) {
		this.api = environment.api;
	}

	getUsersList(): Observable<any> {
		const token: string = localStorage.getItem("accessToken");

		const headers: HttpHeaders = new HttpHeaders()
			.append("X-Header", "X-Header-Value")
			.append("Authorization", `Bearer ${token}`);

		return this.http.get(this.api + "/list");
	}

	//post for adding users

	addNewUser(user: User): Observable<User> {
		return this.http.post<User>(this.api, user);
	}

	// requests for loading local json data

	getsideBarMenu(): Observable<any> {
		return this.http.get("assets/menu.json");
	}
	getsideBarMenuAdmin(): Observable<any> {
		return this.http.get("assets/adminmenu.json");
	}
	getsideBarMenuMarketer(): Observable<any> {
		return this.http.get("assets/marketermenu.json");
	}
	getplotDetails(): Observable<any> {
		return this.http.get("assets/plot-details.json");
	}
	getsalesRepMenu(): Observable<any> {
		return this.http.get("assets/salesrepmenu.json");
	}

	getventureList(): Observable<any> {
		return this.http.get("assets/venturelist.json");
	}

	getventureRepMenu(): Observable<any> {
		return this.http.get("assets/venturedashboard.json");
	}

	getPerformance(): Observable<any> {
		return this.http.get("assets/performance.json");
	}

	getSaleStatus(): Observable<any> {
		return this.http.get("assets/Markerterdashboard.json");
	}
	getPlotdetails(): Observable<any> {
		return this.http.get("assets/plots.json");
	}

	getReports(): Observable<any> {
		return this.http.get("assets/reports.json");
	}
	getReportDetails(): Observable<any> {
		return this.http.get("assets/reportDetails.json");
	}
	getSalesrepDashboard(): Observable<any> {
		return this.http.get("assets/salesrepdashboard.json");
	}

	/**
	 * HTTP PUT Request
	 * @param path
	 * @param reqBody
	 * @returns
	 */
	submitPutRequest(path: string, reqBody: any): Observable<any> {
		return this.http.put(this.api + "/" + path, reqBody);
	}
	/**
	 * HTTP POST Request
	 * @param path
	 * @param reqBody
	 * @returns
	 */
	submitPostRequest(path: string, reqBody: any): Observable<any> {
		return this.http.post(this.api + "/" + path, reqBody);
	}

	getSales(path: string): Observable<any> {
		return this.http.get(this.api + "/" + path).pipe(catchError(this.handleError.bind(this)));
	}

	/**
	 * HTTP GET Request
	 * @param path
	 * @returns
	 */
	submitGetRequest(path: string): Observable<any> {
		return this.http
			.get(this.api + "/" + path)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getTeams(data): Observable<any> {
		return this.http
			.post(this.api + "/salesrep/my-teams", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getmySales(data): Observable<any> {
		return this.http
			.post(this.api + "/salesrep/salesrep-sales", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getmyTeamSales(data): Observable<any> {
		return this.http
			.post(this.api + "/salesrep/my-team-sale", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getDirectCommission(data): Observable<any> {
		return this.http
			.post(this.api + "/salesrep/direct-commissions", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getinDirectCommission(data): Observable<any> {
		return this.http
			.post(this.api + "/salesrep/indirect-commissions", data)
			.pipe(catchError(this.handleError.bind(this)));
	}
	getinDirectCommissionPlots(data): Observable<any> {
		return this.http
			.post(this.api + "/salesrep/indirect-commissions-onplot", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getmyPayments(data): Observable<any> {
		return this.http
			.post(this.api + "/salesrep/my-payments", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getmyDetails(data): Observable<any> {
		return this.http
			.post(this.api + "/venture-team/salesrep/get-salesrep", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getbankDetails(data): Observable<any> {
		return this.http
			.post(this.api + "/bank/get-account", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getCustomers(data): Observable<any> {
		return this.http
			.post(this.api + "/marketer/get-customers", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getCustomer(data): Observable<any> {
		return this.http
			.post(this.api + "/customer/get-customer", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getVentureCustomers(data): Observable<any> {
		return this.http
			.post(this.api + "/marketer/get-venture-customers", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getPlots(data): Observable<any> {
		return this.http
			.post(this.api + "/plot/get-plots", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getplotPricing(data): Observable<any> {
		return this.http
			.post(this.api + "/venzr/plotsPricing/getAllPlotsPricing", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getPayments(data): Observable<any> {
		return this.http
			.post(this.api + "/marketer/get-payments", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getReceivables(data): Observable<any> {
		return this.http
			.post(this.api + "/marketer/get-receivables", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getVentureCustomer(data): Observable<any> {
		return this.http
			.post(this.api + "/customer/get-customer", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	addCustomerData(dataToSave: any): Observable<any> {
		return this.http
			.post(this.api + "/customer/add-customer", dataToSave)
			.pipe(catchError(this.handleError.bind(this)));
	}

	updateCustomerData(dataToSave: any): Observable<any> {
		return this.http.put(this.api + "/customer/update-customer", dataToSave);
	}

	addNewSale(dataToSave: any): Observable<any> {
		return this.http
			.post(this.api + "/sale/add-sale", dataToSave)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getAvailablePlots(data): Observable<any> {
		return this.http
			.post(this.api + "/plot/get-available-plots", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getPlotsById(data): Observable<any> {
		return this.http
			.post(this.api + "/plot/get-plot", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getPlotFacingsAndTypes(ventureId): Observable<any> {
		return this.http
			.get(this.api + "/plot/get-facings-and-plot-types?ventureId="+ventureId)
			.pipe(catchError(this.handleError.bind(this)));
	}

	submitSplitPlots(data): Observable<any> {
		return this.http
			.post(this.api + "/plot/split-plot", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getSaleDetails(data): Observable<NewSale> {
		return this.http
			.post(this.api + "/sale/get-sale", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	fetchUser(username): Observable<any> {
		return this.http.get(this.api + "/fetchUser", { params: { username } });
	}

	addPayment(data): Observable<any> {
		return this.http
			.post(this.api + "/sale/add-payment", data)
	}

	addPlots(data): Observable<any> {
		return this.http
			.post(this.api + "/plot/add-plot", data)
			.pipe(catchError(this.handleError.bind(this)));
	}
	addPlotPricing(data): Observable<any> {
		return this.http
			.post(this.api + "/tenant/plotsPricing/addPlotsPricing", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	editPlotPricing(data): Observable<any> {
		return this.http
			.post(this.api + "/tenant/plotsPricing/updatePlotsPricing", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	updatePlot(data): Observable<any> {
		return this.http
			.put(this.api + "/plot/update-plot", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	/**
	 * Get Cancellation status of sale
	 * @param personId The ID of the person associated with the sale.
	 * @param saleId The ID of the sale.
	 * @param ventureId The ID of the venture associated with the sale.
	 * @returns {observable} that emits the cancel status of the sale.
	 */
	getSaleCancelStatus(personId, saleId, ventureId): Observable<any> {
		return this.http
			.get(this.api + "/sale/cancelStatus", {
				params: { personId, saleId, ventureId },
			})
			.pipe(catchError(this.handleError.bind(this)));
	}

	/**
	 * Initiate Cancel Request for a sale
	 * @param {Object} data The data to include in the request body.
	 * @returns {observable} that emits the result of the cancellation request.
	 */
	cancelSaleRequest(data): Observable<any> {
		return this.http
			.post(this.api + "/sale/cancelSaleRequest", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	/**
	 * @description - Transfer sale to a different plot
	 */
	transferPlotNumber(data): Observable<any> {
		return this.http
			.post(this.api + "/sale/transfer/plot", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	/**
	 * Cancel a sale (Assuming Cancel request is active)
	 * @param {Object} data
	 * @yield {Object}
	 */
	cancelSale(data) {
		return this.http
			.post(this.api + "/sale/saleCancel", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	/**
	 * Get list of cancelled sales
	 * @param {any} ventureId
	 * @param {any} tenantId
	 * @yield {Object}
	 */
	fetchCancelledSales(ventureId, tenantId, path, marketerId?) {
		return this.http
			.get(this.api + path, { params: { ventureId, tenantId, marketerId } })
			.pipe(catchError(this.handleError.bind(this)));
	}

	fetchCancelledSale(saleId){
		return this.http.get(this.api + '/sale/getCancelledSale', { params: { saleId } }).pipe(catchError(this.handleError.bind(this)));
	}

	getPlotPricingHistory(path: string): Observable<any> {
		return this.http.get(this.api + "/" + path).pipe(catchError(this.handleError.bind(this)));
	}

	fetchReportingManagers(salesrepId, path) {
		return this.http
			.get(this.api + path, { params: {salesrepId} })
			.pipe(catchError(this.handleError.bind(this)));
	}

	updateReportingManager(path: string, reqBody: any): Observable<any> {
		return this.http.put(this.api + "/" + path, reqBody);
	}

	addIncentiveProgramWithDetails(data): Observable<any> {
		return this.http
			.post(this.api + "/marketer/incentives/program/detail", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	urlSuffixVerification(path: string): Observable<any> {
		return this.http.get(this.api + "/" + path);
	}
	getLandingPageFilesUrls(path: string): Observable<any> {
		return this.http.get(this.api + "/" + path).pipe(catchError(this.handleError.bind(this)));
	}

	addIncentiveDetail(data): Observable<any> {
		return this.http
			.post(this.api + "/marketer/incentives/detail", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	editIncentiveDetail(data): Observable<any> {
		return this.http
			.put(this.api + "marketer/incentives/detail", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	uploadSaleFile(data): Observable<any>{
		return this.http
			.post(this.api + "/marketer/saleDocument", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getSaleFilesUrls(path: string): Observable<any> {
		return this.http.get(this.api + "/" + path).pipe(catchError(this.handleError.bind(this)));
	}

	uploadLogoFile(data): Observable<any>{
		return this.http
			.post(this.api + "/tenant/logo", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	uploadBannerFile(data): Observable<any>{
		return this.http
			.post(this.api + "/tenant/banner", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getFilesUrls(path: string): Observable<any> {
		return this.http.get(this.api + "/" + path).pipe(catchError(this.handleError.bind(this)));
	}

	uploadLayoutFile(data): Observable<any>{
		return this.http
			.post(this.api + "/tenant/ventureLayout", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getVentureLayout(path: string): Observable<any> {
		return this.http.get(this.api + "/" + path).pipe(catchError(this.handleError.bind(this)));
	}

	getVentureSalesBySalesrep(path: string): Observable<any> {
		return this.http.get(this.api + "/" + path).pipe(catchError(this.handleError.bind(this)));
	}

	getAllSalesrepAdvances(path: string): Observable<any> {
		return this.http.get(this.api + "/" + path).pipe(catchError(this.handleError.bind(this)));
	}

	getSalesrepAdvances(path: string): Observable<any> {
		return this.http.get(this.api + "/" + path).pipe(catchError(this.handleError.bind(this)));
	}

	getSalesrepAllVentureAdvances(path: string): Observable<any> {
		return this.http.get(this.api + "/" + path).pipe(catchError(this.handleError.bind(this)));
	}
	
	createPassiveAdvance(data): Observable<any> {
		return this.http
			.post(this.api + "/marketer/passiveAdvance", data)
			.pipe(catchError(this.handleError.bind(this)));
	}
	
	getBlockedPlots(path: string): Observable<any> {
		return this.http.get(this.api + "/" + path).pipe(catchError(this.handleError.bind(this)));
	}

	unBlockPlot(data): Observable<any> {
		return this.http
			.put(this.api + "/plot/unblock-plot", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getMortgagePlots(path: string): Observable<any> {
		return this.http.get(this.api + "/" + path).pipe(catchError(this.handleError.bind(this)));
	}

	releaseMortgagePlots(data): Observable<any> {
		return this.http
			.put(this.api + "/tenant/release-mortgage-plots", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	markPlotsToMortgage(data): Observable<any> {
		return this.http
			.put(this.api + "/tenant/mark-plots-mortgage", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getCompanyBlockedPlots(path: string): Observable<any> {
		return this.http.get(this.api + "/" + path).pipe(catchError(this.handleError.bind(this)));
	}

	releaseCompanyBlockedPlots(data): Observable<any> {
		return this.http
			.put(this.api + "/tenant/release-company-blocked-plots", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	markPlotsToCompanyBlocked(data): Observable<any> {
		return this.http
			.put(this.api + "/tenant/mark-plots-company-blocked", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getLandlordPlots(path: string): Observable<any> {
		return this.http.get(this.api + "/" + path).pipe(catchError(this.handleError.bind(this)));
	}

	releaseLandlordPlots(data): Observable<any> {
		return this.http
			.put(this.api + "/tenant/release-landlord-plots", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	markPlotsToLandlord(data): Observable<any> {
		return this.http
			.put(this.api + "/tenant/mark-plots-landlord", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getSalePermittedPlots(path: string): Observable<any> {
		return this.http.get(this.api + "/" + path).pipe(catchError(this.handleError.bind(this)));
	}

	getMsoldPlots(path: string): Observable<any> {
		return this.http.get(this.api + "/" + path).pipe(catchError(this.handleError.bind(this)));
	}

	releaseMsoldPlots(data): Observable<any> {
		return this.http
			.put(this.api + "/tenant/release-m-sold-plots", data)
			.pipe(catchError(this.handleError.bind(this)));
	}

	getUsersForPlotBlocking(path: string): Observable<any> {
		return this.http.get(this.api + "/" + path).pipe(catchError(this.handleError.bind(this)));
	}

	getCommissionStructureHistory(path: string): Observable<any> {
		return this.http.get(this.api + "/" + path).pipe(catchError(this.handleError.bind(this)));
	}

	getCommissionStructure(path: string): Observable<any> {
		return this.http.get(this.api + "/" + path).pipe(catchError(this.handleError.bind(this)));
	}

	addCommissionDefinition(data): Observable<any> {
		return this.http
			.post(this.api + "/marketer/add-commission-definition", data)
	}

	updateCommissionDefinition(data): Observable<any> {
		return this.http
			.post(this.api + "/marketer/update-commission-definition", data)
	}

	getSalesrepAllowedDesignations(path: string): Observable<any> {
		return this.http.get(this.api + "/" + path).pipe(catchError(this.handleError.bind(this)));
	}

	updateSalesrepDesignation(data): Observable<any> {
		return this.http
			.put(this.api + "/venture-team/update-salesrep-designation", data)
	}
	
	handleError(error: HttpErrorResponse) {
		let errorMsg: string;
		if (error.status === 401) {
			// this.authService.logout();
			this.router.navigate["/"];
		} else if (error.status == 0 && error.statusText == "Unknown Error") {
			errorMsg = "Unexpected Error Occured";
		} else if (error.error instanceof ErrorEvent) {
			errorMsg = error.error.message;
		} else {
			switch (error.status) {
				case 404: {
					errorMsg = "Service not Found";
				}
				case 403: {
					errorMsg = "Forbidden Error Occured";
				}
				case 500: {
					if (error.error.message == "InternalError") {
						errorMsg = "Internal Server Error occured";
					} else {
						errorMsg = error.error.message;
					}
				}
				default: {
					errorMsg =
						"Unable to connect to the server, please try again";
				}
			}
		}

		setTimeout(() => {
			if (errorMsg) {
				// TODO: show a toast message on the ui instead of logging to the console
				console.log(errorMsg);
			}
		}, 10);
	}
}
