import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class CommonServiceService {

  venture: any;
  ventureTeam: any;
  displayname: any;
  commission: any;
  plotPricing: any;
  initiateSalePlot: any;

  constructor() {
    const ventureString: string = sessionStorage.getItem('ventureSelected');
    if (ventureString) {
      this.venture = JSON.parse(ventureString);
    }

    const ventureTeamString: string = sessionStorage.getItem('ventureTeamSelected');
    if (ventureTeamString) {
      this.ventureTeam = JSON.parse(ventureTeamString);
    }


    const displayNameString: string = sessionStorage.getItem('displayName');
    if (displayNameString) {
      this.displayname = JSON.parse(displayNameString);
    }
  }

  getVenture() {
    return this.venture;
  }

  setVenture(newVenture: any) {
    if (newVenture) {
      this.venture = newVenture;
      sessionStorage.setItem('ventureSelected', JSON.stringify(newVenture));
    }
  }

  updateCommissionStructure(commission: any) {
    this.commission = commission;
  }

  updatePlotPricing(price: any) {
    this.plotPricing = price;
  }

  getCommissionStructure() {
    return this.commission;
  }

  getPlotPricing() {
    return this.plotPricing;
  }

  getDisplayName() {
    return this.displayname;
  }

  setDisplayName(name: any) {
    if (name) {
      this.displayname = name;
      sessionStorage.setItem('displayName', JSON.stringify(name));
    }
  }

  getVentureTeam() {
    return this.ventureTeam;
  }

  setVentureTeam(ventureTeam: any) {
    if (ventureTeam) {
      this.ventureTeam = ventureTeam;
      sessionStorage.setItem('ventureTeamSelected', JSON.stringify(ventureTeam));
    }
  }

  clearVentureTeam() {
    this.ventureTeam = null;
    sessionStorage.removeItem('ventureTeamSelected');
  }

  cleardisplayName() {
    this.displayname = null;
    sessionStorage.removeItem('displayName');
  }

  clear() {
    this.cleardisplayName();
    this.clearVentureTeam();
    sessionStorage.removeItem('ventureSelected');
    this.venture = null;
  }

  getUrlSuffix(){
    return localStorage.getItem('urlSuffix');
  }

  getLogoUrl(){
    return localStorage.getItem('logoUrl');
  }
  
  getBannerUrl(){
    return localStorage.getItem('bannerUrl');
  }

  getMeasure(){
    return localStorage.getItem('measure');
  }

  setInitiateSalePlot(plot: any){
    this.initiateSalePlot = plot;
  }

  getInitiateSalePlot(){
    return this.initiateSalePlot;
  }

  exportToExcel(data: any[], sheetName: string, fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

}