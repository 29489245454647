import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserPrivileges, UserRoleConstants } from '../constants/AppConstants';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  api: any;
  private userInfo: User;
  error: any = null;

  constructor(
    private http: HttpClient,
    private permissionsService: NgxPermissionsService
  ) {
    this.userInfo = new User();
    this.api = environment.api;
  }

  login(data): Observable<any>{
    return this.http.post<any>(this.api+"/session/login", data);
  }

  register(data: any) {
    return this.http.post<any>(this.api+"/register", data);
  }

  requestOtp(uId: any) {
    return this.http.get<any>(this.api+"/sendOtp?userName="+uId);
  }

  resendOtp(uId: any) {
    return this.http.get<any>(this.api+"/reSendOtp?userName="+uId);
  }

  resetPassword(uId: any, obj: any) {
    return this.http.post<any>(this.api+"/resetPassword?userName="+uId, obj);
  }

  public setUserInfo(userInfo: User) {
    if (userInfo) {
      this.userInfo = userInfo;
      const scopes = this.userInfo.resourceScopes ? [...userInfo.scopes, ...userInfo.resourceScopes] : userInfo.scopes;
      this.permissionsService.loadPermissions(scopes);
    }
  }

  public getUserInfo(): User {
    return this.userInfo;
  }

  async loadUserSession(): Promise<boolean> {
    try {
      const response = await this.http.get<{ data: User }>(this.api + '/session/loginStatus').toPromise();
      if (response && response.data) {
        this.userInfo = response.data;
        this.setUserInfo(this.userInfo);
        return true;
      } else {
        this.setUserInfo(new User());
        this.error = { error: 'Session Expired, Please login' };
        return false;
      }
    } catch (error) {
      this.error = error;
      return false;
    }
  }

  hasRole(role: string): boolean {
    if (!this.userInfo.scopes) {
      return false;
    }
    const { scopes } = this.userInfo;
    switch (role) {
      case UserRoleConstants.ADMIN:
        return scopes.includes(UserPrivileges.ADMIN_PRIVILEGE);
      case UserRoleConstants.TENANT:
        return scopes.includes(UserPrivileges.TENANT_PRIVILEGE) ||
          scopes.includes(UserPrivileges.TENANT_BASIC_PRIVILEGE);
      case UserRoleConstants.MARKETER:
        return scopes.includes(UserPrivileges.MARKETER_SUPER_PRIVILEGE) ||
          scopes.includes(UserPrivileges.MARKETER_BASIC_PRIVILEGE);
      case UserRoleConstants.SALESREP:
        return scopes.includes(UserPrivileges.SALESREP_PRIVILEGE);
      default:
        return false;
    }
  }

  // hasResourceScopes(scopes: string[], requiredScopes: string[]) {
  //   let permitted: boolean;
  //   for (var x in requiredScopes) {
  //     permitted = scopes.includes(x);
  //     if (permitted) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }

}

export function configFactory(authService: AuthServiceService) {
  return () => authService.loadUserSession();
}
